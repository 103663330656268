import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BouEstimationLineItem } from 'src/app/models';

@Component({
  selector: 'app-job-bou-estimation-detail',
  templateUrl: './job-bou-estimation-detail.component.html',
  styleUrls: ['./job-bou-estimation-detail.component.scss']
})
export class JobBouEstimationDetailComponent implements OnInit {

  @Input() lineItem: BouEstimationLineItem;
  @Output() cancelled = new EventEmitter();
  @Output() saved = new EventEmitter();

  originSwitchHours: number;
  originSwitchMinutes: number;
  rate: number;
  strippingHours: number;
  strippingMinutes: number;

  constructor() { }

  ngOnInit(): void {
    this.originSwitchHours = this.lineItem.originSwitchHours;
    this.originSwitchMinutes = this.lineItem.originSwitchMinutes;
    this.rate = this.lineItem.rate;
    this.strippingHours = this.lineItem.strippingHours;
    this.strippingMinutes = this.lineItem.strippingMinutes;
  }

  cancel() {
    this.cancelled.emit();
  }

  save() {
    this.lineItem.originSwitchHours = this.originSwitchHours ? this.originSwitchHours : 0;
    this.lineItem.originSwitchMinutes = this.originSwitchMinutes ? this.originSwitchMinutes : 0;
    this.lineItem.rate = this.rate ? this.rate : 0;
    this.lineItem.strippingHours = this.strippingHours ? this.strippingHours : 0;
    this.lineItem.strippingMinutes = this.strippingMinutes ? this.strippingMinutes : 0;
    this.saved.emit();
  }

}
