import { Injectable } from "@angular/core";
import { JobPerformanceReport, TankPerformance, TimingInfo, LoadingInfo, TimingComparisonInfo, DownTimeGrouping } from 'src/app/models/reports/job-performance';
import { TerminalPerformanceReport } from 'src/app/models/reports/terminal-report';
import { TerminalTrendReport, TrendInfo } from 'src/app/models/reports/terminal-trend-report';
import { CategoryTiming, TerminalComparisonReport } from 'src/app/models/reports/terminal-comparison-report';
import { TimingDescriptions, TrendDescriptions } from 'src/app/models/reports/constants';
import { JobStatuses } from "../models";

@Injectable({
  providedIn: 'root'
})
export class JobReportDataService {

  constructor() { }

  createBlankJobPerformanceReport(isActive: boolean): JobPerformanceReport {
    var report = new JobPerformanceReport();
    report.status = isActive ? JobStatuses.active : JobStatuses.complete;
    report.jobTimings = [];
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.NorPeriond));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.Mooring));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.Gangway));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.IorApproval));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.PreCargo));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.CargoPeriod));
    if (isActive) {
      report.tankPerformances = [this.createTankPerformance()];
      report.loadingInfos = [];
      report.loadingInfos.push(this.createLoadingInfo(TimingDescriptions.GrossLoadingTime));
      report.loadingInfos.push(this.createLoadingInfo(TimingDescriptions.NetLoadingTime));
    } else {
      report.jobTimings.push(this.createTimingInfo(TimingDescriptions.PostCargo));
      report.jobTimings.push(this.createTimingInfo(TimingDescriptions.TotalTimeOnDock));
      report.jobTimings.push(this.createTimingInfo(TimingDescriptions.GrossPumpingRate));
      report.jobTimings.push(this.createTimingInfo(TimingDescriptions.NetPumpingRate));

      report.averageTimingsByClient = [];
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.NorPeriond));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.Mooring));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.Gangway));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.IorApproval));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.PreCargo));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.CargoPeriod));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.PostCargo));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.TotalTimeOnDock));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.GrossPumpingRate));
      report.averageTimingsByClient.push(this.createTimingInfo(TimingDescriptions.NetPumpingRate));

      report.averageTimingsForAll = [];
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.NorPeriond));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.Mooring));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.Gangway));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.IorApproval));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.PreCargo));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.CargoPeriod));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.PostCargo));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.TotalTimeOnDock));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.GrossPumpingRate));
      report.averageTimingsForAll.push(this.createTimingInfo(TimingDescriptions.NetPumpingRate));

    }

    return report;
  }

  createBlankTerminalPerformanceReport(): TerminalPerformanceReport {
    var report = new TerminalPerformanceReport();
    report.jobTimings = [];
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.NorPeriond));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.Mooring));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.Gangway));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.IorApproval));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.PreCargo));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.CargoPeriod));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.PostCargo));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.TotalTimeOnDock));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.GrossPumpingRate));
    report.jobTimings.push(this.createTimingInfo(TimingDescriptions.NetPumpingRate));

    report.jobTimingsAll = [];
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.NorPeriond));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.Mooring));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.Gangway));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.IorApproval));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.PreCargo));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.CargoPeriod));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.PostCargo));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.TotalTimeOnDock));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.GrossPumpingRate));
    report.jobTimingsAll.push(this.createTimingInfo(TimingDescriptions.NetPumpingRate));

    report.tankSwitchText = 'N/A';

    return report;
  }

  createBlankTerminalTrendReport(): TerminalTrendReport {
    var report = new TerminalTrendReport();
    report.trendInfos = [];
    report.trendInfos.push(this.createTrendInfo(TrendDescriptions.AverageDowntime));
    report.trendInfos.push(this.createTrendInfo(TrendDescriptions.AverageJobTimeOnDock));
    report.trendInfos.push(this.createTrendInfo(TrendDescriptions.AverageGrossPumpingRate));
    report.trendInfos.push(this.createTrendInfo(TrendDescriptions.AverageNetPumpingRate));
    report.trendInfos.push(this.createTrendInfo(TrendDescriptions.AveragePreCargoTime));
    report.downtimeByReasonsTrends = [];

    return report;
  }

  createBlankTerminalComparisonReport(): TerminalComparisonReport {
    var report = new TerminalComparisonReport();
    report.categoryTimings = []
    report.categoryTimings.push(this.createCategoryTimings(TrendDescriptions.AverageDowntime));
    report.categoryTimings.push(this.createCategoryTimings(TrendDescriptions.AverageJobTimeOnDock));
    report.categoryTimings.push(this.createCategoryTimings(TrendDescriptions.AverageGrossPumpingRate));
    report.categoryTimings.push(this.createCategoryTimings(TrendDescriptions.AverageNetPumpingRate));
    report.categoryTimings.push(this.createCategoryTimings(TrendDescriptions.AveragePreCargoTime));
    report.categoryTimings.push(this.createCategoryTimings(TrendDescriptions.AverageDowntimeByReasons));

    return report;
  }
  createTimingInfo(description: string): TimingInfo {
    var info = new TimingInfo();
    info.description = description;
    info.durationText = 'N/A';

    return info;
  }

  createTimingComparisonInfo(report: JobPerformanceReport): TimingComparisonInfo[] {
    var timingComparisonInfos = [];
    report.jobTimings.forEach(t => {
      var timingComparison = new TimingComparisonInfo();
      timingComparison.description = t.description;
      timingComparison.displayValue = t.displayValue;
      timingComparison.duration = t.duration;
      timingComparison.explanation = t.explanation;
      timingComparison.durationText = this.convertDuration(t.duration);
      var averageTimingByClient = report.averageTimingsByClient.find(a => a.description == t.description);
      if (averageTimingByClient) {
        timingComparison.averageDuration = averageTimingByClient.duration;
        timingComparison.averageDurationText = this.convertDuration(averageTimingByClient.duration);
      }
      var averageTimingForAll = report.averageTimingsForAll.find(a => a.description == t.description);
      if (averageTimingForAll) {
        timingComparison.averageDurationAll = averageTimingForAll.duration;
        timingComparison.averageDurationAllText = this.convertDuration(averageTimingForAll.duration);
      }
      timingComparisonInfos.push(timingComparison);
    })
    return timingComparisonInfos;
  }

  createTerminalTimingComparisonInfo(report: TerminalPerformanceReport): TimingComparisonInfo[] {
    var timingComparisonInfos = [];
    report.jobTimings.forEach(t => {
      var timingComparison = new TimingComparisonInfo();
      timingComparison.description = t.description;
      timingComparison.displayValue = t.displayValue;
      timingComparison.explanation = t.explanation;
      var averageTimingByClient = report.jobTimings.find(a => a.description == t.description);
      if (averageTimingByClient) {
        timingComparison.averageDuration = averageTimingByClient.duration;
        timingComparison.averageDurationText = this.convertDuration(averageTimingByClient.duration);
      }
      var averageTimingForAll = report.jobTimingsAll.find(a => a.description == t.description);
      if (averageTimingForAll) {
        timingComparison.averageDurationAll = averageTimingForAll.duration;
        timingComparison.averageDurationAllText = this.convertDuration(averageTimingForAll.duration);
      }
      timingComparisonInfos.push(timingComparison);
    })
    return timingComparisonInfos;
  }


  createLoadingInfo(description: string): LoadingInfo {
    var info = new LoadingInfo();
    info.description = description;

    return info;
  }

  createTrendInfo(category: string): TrendInfo {
    var info = new TrendInfo();
    info.category = category;
    info.trendTimingInfos = [];

    return info;
  }

  createCategoryTimings(category: string): CategoryTiming {
    var timing = new CategoryTiming();
    timing.category = category;
    timing.terminalTimings = [];

    return timing;
  }

  createTankPerformance(): TankPerformance {
    var performance = new TankPerformance();
    performance.tankTimings = [];
    performance.tankTimings.push(this.createTimingInfo(TimingDescriptions.Downtime));
    performance.tankTimings.push(this.createTimingInfo(TimingDescriptions.GrossPumpingTime));
    performance.tankTimings.push(this.createTimingInfo(TimingDescriptions.GrossPumpingRate));
    performance.tankTimings.push(this.createTimingInfo(TimingDescriptions.TankSwitch));
    performance.tankTimings.push(this.createTimingInfo(TimingDescriptions.NetPumpingTime));
    performance.tankTimings.push(this.createTimingInfo(TimingDescriptions.NetPumpingRate));

    performance.downtimeTimings = [];
    return performance;
  }

  createDownTimeGrouping(): DownTimeGrouping {
    var group = new DownTimeGrouping();
    group.grouping = "";
    group.groupingDurationText = "";
    group.groupingDuration = 0.0;
    group.details = [];

    return group;
  }

  convertDuration(duration: number): string {
    if (duration) {
      var minutes = Math.floor(duration);
      var hours = Math.floor(minutes / 60);
      var minutes = minutes % 60;
      return hours + 'h ' + minutes + 'm ';
    }
    return 'N/A';
  }

  convertDurationInHours(duration: number): string {
    if (duration) {
      if (duration > 2000) {
        return duration.toString();
      }
      var hours = Math.floor(duration);
      var minutes = Math.floor((duration - hours) * 60);
      return hours + 'h ' + minutes + 'm ';
    }
    return 'N/A';
  }

  getColorCode(index: number): string {
    var mod = index % 10;
    if (mod == 0) return '#FFA726';
    if (mod == 1) return '#42A5F5';
    if (mod == 2) return '#EC407A';
    if (mod == 3) return '#AB47BC';
    if (mod == 4) return '#7E57C2';
    if (mod == 5) return '#66BB6A';
    if (mod == 6) return '#FFCA28';
    if (mod == 7) return '#26A69A';
    if (mod == 8) return '#78909C';
    if (mod == 9) return '#495057';
    return 'black';
  }

}
