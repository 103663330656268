import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppMessageService } from "../app-message.service";
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { Store } from "@ngrx/store";
import { JobBlpRevision, TimeTrackingReportFormats } from "src/app/models";

@Injectable({
    providedIn: 'root'
})
export class DownloadsService {
    constructor(
        private store: Store<appState.State>,
        private appMessageService: AppMessageService,
        private http: HttpClient) {}

    downloadBou(jobId: string, jobNumber: string) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${jobId}/bou`, { responseType: 'text' }).subscribe(
        uri => {
        const httpOptions : any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'bou.pdf');
        });
      },
       error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Download Error', 'Could not download BOU for ' + jobNumber);
       }
    );
  }

  downloadBlpRevision(jobId: string, jobNumber: string, blpRevisionId: string) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${jobId}/blp/${blpRevisionId}`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions : any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'blp.pdf');
        });
      },
      error => {
       this.store.dispatch(appActions.hideSpinner());
       this.appMessageService.errorMessage('Download Error', 'Could not download BLP for job ' + jobNumber);
      }
    );
  }

  downloadTimeTracking(jobId: string, fileFormat: string) {
    var contentType = 'application/vnd.ms-excel';
    if (fileFormat === TimeTrackingReportFormats.PDF)
      contentType = 'application/pdf';

    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${jobId}/timetracking/${fileFormat}`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions : any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: contentType })
          const objUrl = window.URL.createObjectURL(file);
          if (fileFormat === TimeTrackingReportFormats.XLS) {
            const dlink: HTMLAnchorElement = document.createElement('a');
            dlink.download = 'timetracking_' + jobId + '.' + fileFormat;
            dlink.href = objUrl;
            dlink.target = '_blank'
            dlink.click(); // this will trigger the dialog window
            dlink.remove();
          }
          else
            window.open(objUrl, 'timetracking.pdf');
        });
      },
      error => {
      this.store.dispatch(appActions.hideSpinner());
      this.appMessageService.errorMessage('Download Error', 'Could not download BOU for ' + jobId);
      }
    );
  }

  downloadBfr(jobId: string, selectedTanks: string) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${jobId}/bfr/${selectedTanks}`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions: any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'bfr.pdf');
        });
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Download Error', 'Could not download BFR for ' + jobId);
      }
    );
  }

  downloadLatestBlp(jobId: string, jobNumber: string) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${jobId}/blp`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions: any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'blp.pdf');
        });
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Download Error', 'Could not download BLP for job ' + jobNumber);
      }
    );
  }

  downloadBfrAllTanks(jobId: string) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${jobId}/bfr`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions: any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'bfr.pdf');
        });
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Download Error', 'Could not download BFR for ' + jobId);
      }
    );
  }

  downloadJobsList(clientId: string, active: boolean) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${clientId}/jobslist/${active}`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions: any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'jobslist.pdf');
        });
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Download Error', 'Could not download Jobs List for Client: ' + clientId);
      }
    );
  }

  downloadKPIFirstReport(clientId: string) {
    this.store.dispatch(appActions.showSpinner());
    this.http.get(`/api/report/download/${clientId}/kpifirstreport`, { responseType: 'text' }).subscribe(
      uri => {
        const httpOptions: any = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          responseType: 'blob'
        };
        this.http.get(uri, httpOptions).subscribe(blob => {
          this.store.dispatch(appActions.hideSpinner());
          var file = new Blob([blob], { type: 'application/pdf' })
          const objUrl = window.URL.createObjectURL(file);
          window.open(objUrl, 'kpifirstreport.pdf');
        });
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Download Error', 'Could not download KPI First Report for Client: ' + clientId);
      }
    );
  }

}
