import { AuditableEntity } from "../auditable-entity";
import { Terminal } from "./terminal";
import { Ship, Product } from 'src/app/models';

export class Tank extends AuditableEntity {
  name: string;
  location: string;
  isShipTank: boolean;
  terminal: Terminal;
  terminalInfo: string;
  ship: Ship;
  shipInfo: string;

  constructor() {
    super();
  }
}
