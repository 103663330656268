import { AccountInfo } from "@azure/msal-common";
import { createAction, props } from "@ngrx/store";
import { Port, Terminal, Dock, InspectionCompany, Contact, TimeTrackingEvent, ClientCompany, JobOverview, JobBlpRevision, Job, Bfr, JobVdp, Bou, Tank, Ship, Product } from "../models";
import { TimeTrackingEventLog } from "../models/job/time-tracking/time-tracking-event-log";
import { SystemConfiguration } from "../models/system-config/system-configuration";
import { TimingCategory } from "../models/system-config/timing-category";
import { User } from "../models/user";

export const getTimingCategories = createAction(
  '[Configuration] Get Timing Categories'
);

export const getTimingCategoriesSuccess = createAction(
  '[Configuration] Get Timing Categories',
  props<{ data: TimingCategory[] }>()
);

export const setCurrentUserFromToken = createAction(
  '[App] Set Current User from Token',
  props<{ accountInfo: AccountInfo }>()
);

export const setCurrentUserFromTokenSuccess = createAction(
  '[App] Set Current User from Token Success',
  props<{ user: User }>()
);

export const setCurrentUserFromTokenFailure = createAction(
  '[App] Set Current User from Token Success Failure',
  props<{ error: any }>()
);

export const showSpinner = createAction(
  '[App] Show Spinner'
);

export const hideSpinner = createAction(
  '[App] Hide Spinner'
);

export const ShowUpdateAvailable = createAction(
  '[App] Show Update Available'
);

export const setJob = createAction(
  '[Job] Set Job',
  props<{ job: Job }>()
);

export const setIsJobNew = createAction(
  '[Job] Set if Job is New',
  props<{ isNew: boolean }>()
);

export const setIsJobLoading = createAction(
  '[Job] Set if Job is Loading',
  props<{ isLoading: boolean }>()
);

export const setJobOverview = createAction(
  '[Job] Set Job Overview Data',
  props<{ overview: SetJobActionProperties<JobOverview> }>()
);

export const setJobBlp = createAction(
  '[Job] Set BLP Data',
  props<{ blpRevision: SetJobActionProperties<JobBlpRevision> }>()
);

export const jobBlpSavedSuccessfully = createAction(
  '[Job] Job Blp Saved Successfully',
  props<{ blpRevision: JobBlpRevision }>()
);

export const setJobInBlpEditMode = createAction(
  '[Job] Set if Job is in Blp Edit Mode',
  props<{ inBlpEditMode: boolean }>()
);

export const setJobTimeTrackingEventLog = createAction(
  '[Job] Set Time Tracking Event Log',
  props<{ log: SetJobActionProperties<TimeTrackingEventLog> }>()
);

export const jobTimeTrackingSavedSuccessfully = createAction(
  '[Job] Job Time Tracking Saved Successfully',
  props<{ log: TimeTrackingEventLog }>()
);

export const setBfr = createAction(
  '[Job] Set BFR',
  props<{ bfr: SetJobActionProperties<Bfr> }>()
);

export const jobBfrSavedSuccessfully = createAction(
  '[Job] Job BFR Saved Successfully',
  props<{ bfr: Bfr }>()
);

export const setJobVdp = createAction(
  '[Job] Set VDP',
  props<{ vdp: SetJobActionProperties<JobVdp> }>()
);

export const jobVdpSavedSuccessfully = createAction(
  '[Job] Job VDP Saved Successfully',
  props<{ vdp: JobVdp }>()
);

export const setBou = createAction(
  '[Job] Set BOU',
  props<{ bou: SetJobActionProperties<Bou> }>()
);

export const jobBouSavedSuccessfully = createAction(
  '[Job] Job BOU Saved Successfully',
  props<{ bou: Bou }>()
);

export const jobSavedSuccessfully = createAction(
  '[Job] Job Saved Successfully',
  props<{ jobSection: string }>()
);

export const jobSavedFailed = createAction(
  '[Job] Job Save Failed',
  props<{ jobSection: string, error: any }>()
);

export const setSystemConfiguration = createAction(
  '[Configuration] Set System Configuration',
  props<{ configuration: SystemConfiguration }>()
);

export const refreshSystemConfiguration = createAction(
  '[Configuration] Refresh System Configuration'
);

export const setPortLocation = createAction(
  '[Configuration] Update Port/Location',
  props<{ data: Port }>()
);

export const setPortLocationSuccess = createAction(
  '[Configuration] Update Port/Location Success',
  props<{ data: Port }>()
);

export const setShip = createAction(
  '[Configuration] Update Ship',
  props<{ data: Ship }>()
);

export const setShipSuccess = createAction(
  '[Configuration] Update Ship Success',
  props<{ data: Ship }>()
);

export const setCargo = createAction(
  '[Configuration] Update Cargo',
  props<{ data: Product }>()
);

export const setCargoSuccess = createAction(
  '[Configuration] Update Cargo Success',
  props<{ data: Product }>()
);

export const setTerminal = createAction(
  '[Configuration] Update Terminal',
  props<{ data: Terminal }>()
);

export const setTerminalSuccess = createAction(
  '[Configuration] Update Terminal Success',
  props<{ data: Terminal }>()
);

export const setDock = createAction(
  '[Configuration] Update Dock',
  props<{ data: Dock }>()
);

export const setDockSuccess = createAction(
  '[Configuration] Update Dock Success',
  props<{ data: Dock }>()
);

export const setTank = createAction(
  '[Configuration] Update Tank',
  props<{ data: Tank }>()
);

export const setTankSuccess = createAction(
  '[Configuration] Update Tank Success',
  props<{ data: Tank }>()
);

export const setClientCompany = createAction(
  '[Configuration] Update Client',
  props<{ data: ClientCompany }>()
);

export const setClientCompanySuccess = createAction(
  '[Configuration] Update Client Success',
  props<{ data: ClientCompany }>()
);

export const setInspectionCompany = createAction(
  '[Configuration] Update Inspection Company',
  props<{ data: InspectionCompany }>()
);

export const setInspectionCompanySuccess = createAction(
  '[Configuration] Update Inspection Company Success',
  props<{ data: InspectionCompany }>()
);

export const setContact = createAction(
  '[Configuration] Update Contact',
  props<{ data: Contact }>()
);

export const setContactSuccess = createAction(
  '[Configuration] Update Contact Success',
  props<{ data: Contact }>()
);

export const setUser = createAction(
  '[Configuration] Update User',
  props<{ data: User }>()
);

export const setUserSuccess = createAction(
  '[Configuration] Update User Success',
  props<{ data: User }>()
);

export const setTimeTrackingEvent = createAction(
  '[Configuration] Update Time Tracking Event',
  props<{ data: TimeTrackingEvent }>()
);

export const setTimeTrackingEventSuccess = createAction(
  '[Configuration] Update Time Tracking Event Success',
  props<{ data: TimeTrackingEvent }>()
);

export const setTimingCategory = createAction(
  '[Configuration] Update Timing Categories',
  props<{ data: TimingCategory }>()
);

export const setTimingCategorySuccess = createAction(
  '[Configuration] Update Timing Categories Success',
  props<{ data: TimingCategory }>()
);

// Reusing for all configuration updates
export const updateConfigurationFailure = createAction(
  '[Configuration] Update Configuration Failed',
  props<{ configurationType: string, error: any }>()
);

export const collapseMenuToggled = createAction(
  '[UI] Collapse Job Menu Toggled'
);

export const setProduct = createAction(
  '[Configuration] Update Product',
  props<{ data: Product }>()
);

export const setProductSuccess = createAction(
  '[Configuration] Update Product Success',
  props<{ data: Product }>()
);


export class SetJobActionProperties<T> {
  id: string;
  jobId: string;
  jobNumber: string;
  data: T;
  silent: boolean = false;
}

export type GetTimingCategoriesAction = ReturnType<typeof getTimingCategories>;
export type SetUserAction = ReturnType<typeof setUser>;
export type SetCurrentUserFromToken = ReturnType<typeof setCurrentUserFromToken>;

export type RefreshSystemConfiguration = ReturnType<typeof refreshSystemConfiguration>;
export type SetPortLocationAction = ReturnType<typeof setPortLocation>;
export type SetShipAction = ReturnType<typeof setShip>;
export type SetCargoAction = ReturnType<typeof setCargo>;
export type SetTerminalAction = ReturnType<typeof setTerminal>;
export type SetDockAction = ReturnType<typeof setDock>;
export type SetTankAction = ReturnType<typeof setTank>;
export type SetTimeTrackingEventAction = ReturnType<typeof setTimeTrackingEvent>;
export type SetTimingCategoryAction = ReturnType<typeof setTimingCategory>;
export type SetClientCompanyAction = ReturnType<typeof setClientCompany>;
export type SetInspectionCompanyAction = ReturnType<typeof setInspectionCompany>;
export type SetContactAction = ReturnType<typeof setContact>;

export type SetJobOverview = ReturnType<typeof setJobOverview>;
export type SetBlp = ReturnType<typeof setJobBlp>;
export type SetTimeTrackingEventLog = ReturnType<typeof setJobTimeTrackingEventLog>;
export type SetBfr = ReturnType<typeof setBfr>;
export type SetVdp = ReturnType<typeof setJobVdp>;
export type SetBou = ReturnType<typeof setBou>;
export type JobSavedSuccessfully = ReturnType<typeof jobSavedSuccessfully>;
export type SetProductAction = ReturnType<typeof setProduct>;
