import { AuditableEntity } from "../auditable-entity";
import { TimeSpan } from "../timespan";

export class DowntimeInfoItem {
    category: string;
    name: string;
	duration: TimeSpan;

    constructor() {
    }
}
