import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { SelectItem } from 'primeng/api';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Job, JobBlpRevision, JobOverview, Tank } from 'src/app/models';
import { BlpManagementService } from 'src/app/services/blp-management.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppMessageService } from 'src/app/services/app-message.service';
import { Actions, ofType } from '@ngrx/effects';
import { DownloadsService } from 'src/app/services/http-services/downloads.service';

@Component({
  selector: 'app-job-blp',
  templateUrl: './job-blp.component.html',
  styleUrls: ['./job-blp.component.scss']
})
export class JobBlpComponent implements OnInit {

  @Input() shoreTanks: Tank[] = [];
  @Input() vesselTanks: Tank[] = [];

  blpRevisions: JobBlpRevision[] = [];
  jobId: string;
  jobNumber: string;
  isNew: boolean = false;
  jobOverview: JobOverview = new JobOverview();
  subscriptions: Subscription[] = [];
  ready: boolean = false;
  selectedBlpRevision: JobBlpRevision;
  openDetail: boolean = false;

  constructor(
    private http: HttpClient,
    private store: Store<appState.State>,
    private blpManagementService: BlpManagementService,
    private appMessageService: AppMessageService,
    private actions$: Actions,
    private downloadsService: DownloadsService) { }

  ngOnInit(): void {
    this.listenToJobChanges();
  }

  listenToJobChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJob)).subscribe(jobState => {
        this.blpRevisions = cloneDeep(jobState.blpRevisions);
        this.jobId = jobState.id;
        this.jobNumber = jobState.jobNumber;
        this.selectedBlpRevision = jobState.latestBlp;
        this.jobOverview = jobState.overview;
        this.ready = true;
      })
    );
  }

  save(blpSaved: JobBlpRevision) {
    const setBlpProperties = new appActions.SetJobActionProperties<JobBlpRevision>();
    setBlpProperties.id = blpSaved.id;
    setBlpProperties.jobId = blpSaved.jobId;
    setBlpProperties.jobNumber = this.jobNumber;
    let existingBlp = this.blpRevisions.find(b => b.id === blpSaved.id);
    if (existingBlp) {
      existingBlp = blpSaved;
    } else {
      this.blpRevisions.push(blpSaved);
    }
    setBlpProperties.data = blpSaved;
    this.store.dispatch(appActions.setJobBlp({ blpRevision: setBlpProperties }));
    this.listenForSucess();
  }

  listenForSucess() {
    this.subscriptions.push(
      this.actions$.pipe(ofType(appActions.jobBlpSavedSuccessfully)).subscribe(() => {
          this.store.dispatch(appActions.setJobInBlpEditMode({ inBlpEditMode: false }));
        }
      )
    )
  }

  async reset() {
    const initialJob = await this.store.pipe(select(appState.getSelectedJob), take(1)).toPromise();
    this.blpRevisions = cloneDeep(initialJob).blpRevisions;

    // Reset selected BLP to state's value if one exists matching Id, otherwise reset to new revision
    const foundBlpRevision = this.blpRevisions.find(b => b.id === this.selectedBlpRevision.id);
    if (foundBlpRevision) {
      this.selectedBlpRevision = foundBlpRevision;
    } else {
      this.revisionStarted();
    }
  }

  download(blp: JobBlpRevision) {
    this.downloadsService.downloadBlpRevision(this.jobId, this.jobNumber, blp.id);
  }

  async revisionStarted(): Promise<void> {
    const currentUser = await this.store.pipe(select(appState.getCurrentUser)).pipe(take(1)).toPromise();
    const latestBlp = await this.store.pipe(select(appState.getSelectedJobLatestBlp)).pipe(take(1)).toPromise();    
    
    this.selectedBlpRevision = this.blpManagementService.cloneBlp(this.jobId, currentUser, latestBlp);
  }

  selectRevision(blpId: string) {
    if (blpId) {
      this.selectedBlpRevision = this.blpRevisions.find(r => r.id === blpId);
      this.store.dispatch(appActions.setJobInBlpEditMode({ inBlpEditMode: false }));
      this.openDetail = true;
    }
  }

  resetOpenDetail() {
    this.openDetail = false;
  }

  get blpSet(): boolean {
    return this.selectedBlpRevision && this.selectedBlpRevision.revisionNumber !== null && this.selectedBlpRevision.revisionNumber !== undefined;
  }

  get selectedBlpRevisionJson(): string {
    return JSON.stringify(this.selectedBlpRevision);
  }

  get loadingPlanHeaderText(): string {
    return 'Loading Plan Header (' + (this.selectedBlpRevision.revisionNumber >= 0 ? 'Revision ' + this.selectedBlpRevision.revisionNumber : 'New Revision') + ')';
  }

  get loadingPlanText(): string {
    return 'Loading Plan (' + (this.selectedBlpRevision.revisionNumber >= 0 ? 'Revision ' + this.selectedBlpRevision.revisionNumber : 'New Revision') + ')';
  }
}
