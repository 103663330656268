<div class="p-grid">
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-col-12">
        <p-table #timetrackingDt
                 [value]="timeTrackingEventLogTableData"
                 [columns]="cols"
                 [rows]="50"
                 [responsive]="true"
                 [paginator]="true"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 [rowsPerPageOptions]="[5,10,25,50]"
                 [globalFilterFields]="globalFilterFields"
                 [rowHover]="true"
                 sortField="startTimeDate">
          <ng-template pTemplate="caption">
            <div class="p-grid">
              <div class="p-md-8 p-col-12 p-jc-start">
                <span class="p-input-icon-left p-text-left p-d-none p-d-lg-inline-flex full-width">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="timetrackingDt.filterGlobal($event.target.value, 'contains')" placeholder="Time Event Search" [style.width]="'40%'" />
                </span>
              </div>
              <div class="p-md-4 p-col-12 p-jc-end">
                <div class="p-grid">
                  <div class="p-col-4">
                    <p-button label="Add" icon="pi pi-plus" class="p-button-success" (onClick)="addLog()" [style]="{'width': '100%'}"></p-button>
                  </div>
                  <div class="p-col-4">
                    <p-button label="Download(XLS)" styleClass="p-button-help" icon="pi pi-download" class="p-button-secondary" (onClick)="downloadTimeTrackingXls()" [style]="{'width': '100%'}"></p-button>
                  </div>
                  <div class="p-col-4">
                    <p-button label="Download(PDF)" styleClass="p-button-help" icon="pi pi-download" class="p-button-secondary" (onClick)="downloadTimeTrackingPdf()" [style]="{'width': '100%'}"></p-button>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{col.header}}
                  <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                  <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                  <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-log>
            <tr>
              <td>{{log.timeTrackingEventName}}</td>
              <td [ngClass]="{'greyed-out': !log.requiresTank}">{{log.assignedTankName}}</td>
              <td>{{log.startTimeDate | date:'M/d/yyyy, HH:mm':cstTimeZoneAbbrev}}</td>
              <td *ngIf="log.isDuration">{{log.endTimeDate | date:'M/d/yyyy, HH:mm':cstTimeZoneAbbrev}}</td>
              <td *ngIf="!log.isDuration" class="greyed-out"></td>
              <td *ngIf="log.isDuration">{{log.duration}}</td>
              <td *ngIf="!log.isDuration" class="greyed-out"></td>
              <td>{{log.notes}}</td>
              <td>
                <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="openLog(log.id)" pTooltip="Edit this Event"></button>
                <button pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-trash" (click)="deleteLog(log.id)" pTooltip="Delete this Event"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="true">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
      <app-job-time-tracking-detail [log]="selectedLog"
                                    [shoreTanks]="jobShoreTanks"
                                    [availableEvents]="timeTrackingEvents"
                                    [tankToStepMappings]="tankToStepMappings"
                                    (logSaved)="saveEventLog($event)"
                                    (cancelled)="close()"></app-job-time-tracking-detail>
      <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
  </p-dialog>
  <p-dialog class="p-d-md-none" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '100%'}" [modal]="true" [closable]="true">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
      <app-job-time-tracking-detail [log]="selectedLog"
                                    [shoreTanks]="jobShoreTanks"
                                    [availableEvents]="timeTrackingEvents"
                                    [tankToStepMappings]="tankToStepMappings"
                                    (logSaved)="saveEventLog($event)"
                                    (cancelled)="close()"></app-job-time-tracking-detail>
      <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
  </p-dialog>
