<div *ngIf="ready && !isJobLoading" class="p-grid p-px-2 p-pr-md-0">
  <div class="p-col-12">
    <div class="p-fluid">
      <div *ngIf="!isNew" class="p-field p-grid">
        <label for="jobNumber" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Job Number</label>
        <div class="p-col-12 p-md-10">
          <input id="jobNumber" type="text" pInputText [(ngModel)]="jobOverview.jobNumber" readonly>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="ship" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Ship</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="shipOptions" [(ngModel)]="jobOverview.ship" appendTo="body" placeholder="Select Ship" (onChange)="onShipChanged($event.value)"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="client" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Customer</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="clientOptions" [(ngModel)]="selectedClientId" appendTo="body" placeholder="Select Customer" (onChange)="onClientChanged($event.value)"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="port" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Port/Location</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="portOptions" [(ngModel)]="selectedPortId" appendTo="body" placeholder="Select Port/Location" (onChange)="onPortChanged($event.value)"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="terminal" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Terminal</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="terminalOptions" [(ngModel)]="selectedTerminalId" appendTo="body" placeholder="Select Terminal" (onChange)="onTerminalChanged($event.value)"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="dock" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Dock</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="dockOptions" [(ngModel)]="selectedDockId" appendTo="body" placeholder="Select Dock" (onChange)="onDockChanged($event.value)"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="superintendent" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Superintendent</label>
        <div class="p-col-12 p-md-10">
          <p-multiSelect [options]="superintendentOptions" [(ngModel)]="selectedSuperintendents" appendTo="body" placeholder="Select Superintendent" (onChange)="onSuperintendentsChanged($event.value)"></p-multiSelect>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="date" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Date</label>
        <div class="p-col-12 p-md-10">
          <p-calendar [(ngModel)]="jobOverview.date" dateFormat="DD, MM dd, yy" [showTime]="false" appendTo="body"></p-calendar>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="cargo" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Cargo</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="cargoOptions" [(ngModel)]="jobOverview.cargoProduct" appendTo="body" placeholder="Select Cargo" (onChange)="onCargoChanged($event.value)"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="cargoQuantity" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Cargo Quantity</label>
        <div class="p-col-12 p-md-10">
            <input id="cargoQuantity" type="number" pInputText [(ngModel)]="jobOverview.cargoQuantity"> 
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="agent" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Agent</label>
        <div class="p-col-12 p-md-10">
          <input id="agent" type="text" pInputText [(ngModel)]="jobOverview.agent">
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="inspectionCompany" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Inspection Company</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="inspectionCompanyOptions" [(ngModel)]="selectedInspectionCompanyId" appendTo="body" (onChange)="onInspCompanyChanged($event.value)" placeholder="Select Inspection Company"></p-dropdown>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="reprortContacts" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Report Contacts</label>
        <div class="p-col-12 p-md-10">
          <p-multiSelect [options]="reportContactOptions" [(ngModel)]="selectedReportContacts" appendTo="body" placeholder="Select Contacts" (onChange)="onReportContactsChanged($event.value)"></p-multiSelect>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="reprortHours" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Report Hours</label>
        <div class="p-col-12 p-md-10">
          <p-multiSelect [options]="reportHourOptions" [(ngModel)]="selectedReportHours" appendTo="body" placeholder="Select Hours" (onChange)="reportHoursChanged()"></p-multiSelect>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="confirmed" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Is Confirmed?</label>
        <div class="p-col-12 p-md-10">
          <p-inputSwitch id="confirmed" [(ngModel)]="jobOverview.isConfirmed"></p-inputSwitch>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="IsInjection" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Injection?</label>
        <div class="p-col-12 p-md-10">
          <p-inputSwitch id="injection" [(ngModel)]="IsInjection"></p-inputSwitch>
        </div>
      </div>
      <div *ngIf="showClosedActions" class="p-field p-grid">
        <label for="isFiguresConfirmed" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Have Figures Been Confirmed?</label>
        <div class="p-col-12 p-md-10">
          <p-inputSwitch id="isFiguresConfirmed" [(ngModel)]="jobOverview.hasFiguresBeenConfirmed"></p-inputSwitch>
        </div>
      </div>
      <div *ngIf="showClosedActions" class="p-field p-grid">
        <label for="isVesselOnSpec" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Is Vessel On Spec?</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown id="isVesselOnSpec"
                      [options]="isVesselOnSpecOptions"
                      [(ngModel)]="jobOverview.isVesselOnSpec"
                      appendTo="body"
                      placeholder="Select"
                      pTooltip="N/A: Vessel may only have been partially loaded and this does not pertain to the job. If No a reason must be provided."></p-dropdown>
        </div>
      </div>
      <div *ngIf="jobOverview.status === jobStatuses.cancelled" class="p-field p-grid">
        <label for="cancelledReason" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Cancelled Reason</label>
        <div class="p-col-12 p-md-10">
          <label id="cancelledReason">{{jobOverview.cancelledReason}}</label>
        </div>
      </div>
      <div *ngIf="jobOverview.isVesselOnSpec === vesselOnSpecTypes.no" class="p-field p-grid">
        <label for="vesselNotSpecReason" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Vessel No On Spec Reason</label>
        <div class="p-col-12 p-md-10">
          <input id="vesselNotSpecReason" type="text" pInputText [(ngModel)]="jobOverview.vesselNotSpecReason">
        </div>
      </div>
      <div *ngIf="!isNew" class="p-field p-grid">
        <label for="status" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Status</label>
        <div class="p-col-12 p-md-10">
          <p-chip *ngIf="jobOverview.status === jobStatuses.unconfirmed" label="Unconfirmed" styleClass="p-mr-2 unconfirmed-chip"></p-chip>
          <p-chip *ngIf="jobOverview.status === jobStatuses.incoming" label="Incoming" styleClass="p-mr-2 incoming-chip"></p-chip>
          <p-chip *ngIf="jobOverview.status === jobStatuses.active" label="Active" styleClass="p-mr-2 active-chip"></p-chip>
          <p-chip *ngIf="jobOverview.status === jobStatuses.complete" label="Completed" styleClass="p-mr-2 completed-chip"></p-chip>
          <p-chip *ngIf="jobOverview.status === jobStatuses.cancelled" label="Cancelled" styleClass="p-mr-2 completed-chip"></p-chip>
          <p-chip *ngIf="jobOverview.status === jobStatuses.closed" label="Closed" styleClass="p-mr-2 closed-chip"></p-chip>
        </div>
      </div>

    </div>
  </div>
  <div class="p-col-12">
    <div *ngIf="showNewJobActions" class="p-grid">
      <div class="p-md-6 p-col-12">
        <p-button label="Back" icon="pi pi-arrow-left" styleClass="p-button-secondary" (onClick)="return()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-6 p-col-12">
        <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
    <div *ngIf="showNormalActions" class="p-grid">
      <div class="p-md-4 p-col-12">
        <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-4 p-col-12">
        <p-button label="Cancel" icon="pi pi-ban" styleClass="p-button-warning" (onClick)="confirmCancelJob()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-4 p-col-12">
        <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
    <div *ngIf="showCancelledActions" class="p-grid">
      <div class="p-md-6 p-col-12">
        <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-6 p-col-12">
        <p-button label="Reactivate Job" icon="pi pi-lock-open" styleClass="p-button-warning" (onClick)="confirmUnCancelJob()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
    <div *ngIf="showCompletedActions" class="p-grid">
      <div class="p-md-6 p-col-12">
        <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-6 p-col-12">
        <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
  </div>
</div>
<p-dialog *ngIf="showCancelJob" [(visible)]="showCancelJob" [modal]="true" [closable]="true">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="cancelledReason" class="p-col-12 cancel-text">Reason for cancelling this job?</label>
          <div class="p-col-12">
            <textarea id="cancelledReason" pInputTextarea [(ngModel)]="cancelledReason"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="cancelJob()" [style]="{'width': '100%'}"></p-button>
    </div>
  </div>

</p-dialog>
