<div *ngIf="!this.selectedCustomer">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="flex flex-column-reverse card-container">
        <div class="flex align-items-center justify-content-center metrics-card-text-padding text-align-right">
          <h1>Welcome to Vessel Blenders, Customer Administrator</h1>
          <h1>Please select a customer from above to get started</h1>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-card no-customer-background">
      </div>
    </div>

    </div>
</div>

<div *ngIf="pageDataReady">

  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-card quick-look-background">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center metrics-card-text-padding font-white">
            <h1 *ngIf="viewAssignment"><i>Quick Look: Last Updated {{ticker.createdOn | date: 'EEEE, MMMM d,y,h:mm a'}} (CST)</i></h1>
            <h1 *ngIf="!viewAssignment"><i>Quick Look: (No Active Jobs)</i></h1>
          </div>
          <div class="flex align-items-center justify-content-center  metrics-card-text-padding font-white">
            <h2 *ngIf="viewAssignment">Ship: {{ticker.shipName}}</h2>
            <h2 *ngIf="!viewAssignment">Ship: N/A</h2>
          </div>
          <div class="flex align-items-center justify-content-center  metrics-card-text-padding font-white">
            <h2 *ngIf="viewAssignment">Progress: {{ticker.percentLoaded}}%</h2>
            <h2 *ngIf="!viewAssignment">Progress: N/A</h2>
          </div>
          <div class="p-col-12 flex align-items-center justify-content-center metrics-card-button-padding">
            <p-button label="View Assignment" styleClass="p-button-raised p-button-lg" (onClick)="viewJobAssignment()" [disabled]="!viewAssignment"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">

    <div class="p-col-12 p-lg-4 p-xl-4">
      <div class="p-card metrics-card-background">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">
            <h1>Active Assignments</h1>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">
            <h2>{{homeScreenDetails.activeJobsCount}}</h2>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">&nbsp;</div>
          <div class="p-col-12 flex align-items-center justify-content-center metrics-card-button-padding">
            <p-button label="View" styleClass="p-button-raised p-button-outlined p-button-lg" (onClick)="viewActiveJobs()" [disabled]="homeScreenDetails.activeJobsCount === 0"></p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4 p-xl-4">
      <div class="p-card metrics-card-background">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">
            <h1>Completed Assignments</h1>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">
            <h2>{{homeScreenDetails.completedJobsCount}}</h2>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">&nbsp;</div>
          <div class="p-col-12 flex align-items-center justify-content-center metrics-card-button-padding">
            <p-button label="View" styleClass="p-button-raised p-button-outlined p-button-lg" (onClick)="viewCompletedJobs()" [disabled]="homeScreenDetails.completedJobsCount === 0"></p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4 p-xl-4">
      <div class="p-card metrics-card-background">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">
            <h1>Metrics</h1>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">
            <h2>(Key Performance Indicators)</h2>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-text-padding">&nbsp;</div>
          <div class="p-col-12 flex align-items-center justify-content-center metrics-card-button-padding">
            <p-button label="View" styleClass="p-button-raised p-button-outlined p-button-lg" (onClick)="viewKPIs()" [disabled]="homeScreenDetails.activeJobsCount === 0 && homeScreenDetails.completedJobsCount == 0"></p-button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="p-grid">
    <div class="p-col-12">

      <div class="p-card weather-background">

        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center md-padding font-white">
            <h1>Live Weather Update</h1>
          </div>
          <div class="flex align-items-center justify-content-center metrics-card-button-padding font-white">
            <p-button label="View Weather" styleClass="p-button-raised p-button-lg" (onClick)="openWeatherDlg()"></p-button>
          </div>
        </div>

      </div>

    </div>
  </div>

  <p-dialog header="Weather Summary" [(visible)]="weatherDlg" [modal]="true" [draggable]="false" [resizable]="false">
    <p-grid>
      <div class="p-col-12">
        <div class="p-card rcorners border-color-blue">
          <div class="flex flex-column card-container">
            <div class="flex align-items-center justify-content-center text-padding">
              <h3>Date: {{todaysDate | date: 'EEEE, MMMM d,y'}}</h3>
            </div>
            <div class="flex align-items-center justify-content-center text-padding">
              <h3>Location: Houston, TX</h3>
            </div>
            <div class="flex align-items-center justify-content-center text-padding">
              <h3>Latitude: {{weatherInfo.latitude | number: '1.3-3'}}, Longitude: {{weatherInfo.longitude | number: '1.3-3'}}</h3>
            </div>
            <div class="flex align-items-center justify-content-center text-padding">
              <p-table #WeatherDt [value]="weatherInfo.weatherComponents"
                       styleClass="p-datatable-striped"
                       [columns]="weatherCols"
                       [rows]="6"
                       [tableStyle]="{'min-height': '25rem','max-height': '25rem'}"
                       [responsive]="true"
                       [paginator]="true"
                       [showCurrentPageReport]="true"
                       currentPageReportTemplate="{first} to {last} of {totalRecords} records"
                       [rowHover]="true">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns | colDisplayFilter">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                        <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                        <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-weatherdata let-columns="columns">
                  <tr>
                    <td *ngFor="let col of columns">
                      <span class="p-column-title">{{ col.header }}</span>
                      <span *ngIf="col.header === 'TIME'">{{weatherdata.time}}</span>
                      <span *ngIf="col.header === 'TEMP 2M (°F)'">{{weatherdata.temp2m | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'REL HUMIDITY 2M (%)'">{{weatherdata.relHumidity2m | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'APPARENT TEMP (°F)'">{{weatherdata.apparentTemp | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'PRECIP (IN)'">{{weatherdata.precipitation | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'RAIN (IN)'">{{weatherdata.rain | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'SHOWERS (IN)'">{{weatherdata.showers | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'WEATHER CODE'">
                        <img *ngIf="weatherdata.weatherCode === 0" alt="Sunny" title="Sunny" src="assets/images/weather/sunny.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 1 || weatherdata.weatherCode === 2" alt="Partly Cloudy" title="Partly Cloudy" src="assets/images/weather/partlycloudy.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 3" alt="Cloudy" title="Cloudy" src="assets/images/weather/cloudy.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 45 || weatherdata.weatherCode === 48" alt="Foggy" title="Foggy" src="assets/images/weather/foggy.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 51 || weatherdata.weatherCode === 53 || weatherdata.weatherCode === 55"
                             alt="Rainy" title="Rainy" src="assets/images/weather/rainy.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}"
                             height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 61 || weatherdata.weatherCode === 63 || weatherdata.weatherCode === 65"
                             alt="Rainy" title="Rainy" src="assets/images/weather/rainy.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}"
                             height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 80 || weatherdata.weatherCode === 81 || weatherdata.weatherCode === 82"
                             alt="Rainy" title="Rainy" src="assets/images/weather/rainy.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}"
                             height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 56 || weatherdata.weatherCode === 57" alt="Sleet Rain" title="Sleet Rain" src="assets/images/weather/sleetrain.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 66 || weatherdata.weatherCode === 57" alt="Sleet Rain" title="Sleet Rain" src="assets/images/weather/sleetrain.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 85 || weatherdata.weatherCode === 86" alt="Sleet Rain" title="Sleet Rain" src="assets/images/weather/sleetrain.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 71 || weatherdata.weatherCode === 73" alt="Snow" title="Snow" src="assets/images/weather/snow.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 75 || weatherdata.weatherCode === 77" alt="Snow" title="Snow" src="assets/images/weather/snow.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}" height="24" width="24" class="vertical-align-middle" />
                        <img *ngIf="weatherdata.weatherCode === 51 || weatherdata.weatherCode === 53 || weatherdata.weatherCode === 55"
                             alt="Storm" title="Storm" src="assets/images/weather/storm.PNG" [style]="{'margin-top':'10px', 'margin-left':'10px'}"
                             height="24" width="24" class="vertical-align-middle" />
                      </span>
                      <span *ngIf="col.header === 'WIND SPEED 10M (MPH)'">{{weatherdata.windSpeed10m | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'WIND DIR 10M (°)'">{{weatherdata.windDirection10m | number: '1.2-2'}}</span>
                      <span *ngIf="col.header === 'WIND GUSTS 10M (MPH)'">{{weatherdata.windGusts10m | number: '1.2-2'}}</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </p-grid>
  </p-dialog>

</div>
