<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="originSwitch" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Origin Switch (h:m)</label>
                <div class="p-col-12 p-md-10">
                    <div class="p-field p-grid">
                        <div class="p-col-5">
                            <p-inputNumber id="originSwitch" [(ngModel)]="originSwitchHours" placeholder="hours"></p-inputNumber>
                        </div>
                        <div class="p-col-2 p-text-center bold-label colon-adjust">
                            :
                        </div>
                        <div class="p-col-5">
                            <p-inputNumber [(ngModel)]="originSwitchMinutes" placeholder="minutes"></p-inputNumber>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="rate" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Rate (Bbls/hr):</label>
                <div class="p-col-12 p-md-10">
                    <p-inputNumber id="originSwitch"  [(ngModel)]="rate"></p-inputNumber>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="stripping" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Stripping (h:m)</label>
                <div class="p-col-12 p-md-10">
                    <div class="p-field p-grid">
                        <div class="p-col-5">
                            <p-inputNumber id="stripping"  [(ngModel)]="strippingHours" placeholder="hours"></p-inputNumber>
                        </div>
                        <div class="p-col-2 p-text-center bold-label colon-adjust">
                            :
                        </div>
                        <div class="p-col-5">
                            <p-inputNumber [(ngModel)]="strippingMinutes" placeholder="minutes"></p-inputNumber>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>
