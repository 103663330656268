<div *ngIf="pageDataReady">

  <div class="p-grid">

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card general-metrics border-color-pistacchio background-color-pistacchio">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Average Time on Dock</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>(hh:mm)</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h1>{{kpiFirstReport.avgTimeOnDock.hours | number: '2.0-0'}}:{{kpiFirstReport.avgTimeOnDock.minutes | number: '2.0-0'}}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card  general-metrics border-color-coral background-color-coral">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Average Delays</h2>
            <h2>(hh:mm)</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h1>{{kpiFirstReport.avgDelays.hours | number: '2.0-0'}}:{{kpiFirstReport.avgDelays.minutes | number: '2.0-0'}}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card  general-metrics border-color-blue background-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Average Pumping Rate</h2>
            <h2>(bbls/hour)</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h1>{{kpiFirstReport.avgPumpingRate | number: '1.2-2'}}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card  general-metrics border-color-purple background-color-purple">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Average Assignments</h2>
            <h2>(Per Month)</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h1>{{kpiFirstReport.avgMonthlyAssignments}}</h1>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12 p-lg-6 p-xl-6">
      <div class="p-card chart-metrics border-color-lightgray background-color-lightgray">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h1 class="center-alignment">Operational Delays</h1>
          </div>
          <div class="flex align-items-center justify-content-center">
            <p-chart type="pie" [data]="chartData" [options]="chartOptions"></p-chart>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2 class="center-alignment">(Avg. hours per vessel)</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-6 p-xl-6">

      <div class="p-grid">

        <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">

          <div class="p-card terminal-metrics border-color-offwhite background-color-offwhite">
            <div class="flex flex-column card-container">
              <div class="flex align-items-center justify-content-center text-padding">
                <h1>Tank Name: {{testTermCard1.tankName}}</h1>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Average Rate</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h3>{{testTermCard1.avgPumpingRate | number: '1.2-2'}}</h3>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Terminal</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <p-chip label="{{testTermCard1.terminalName}}" styleClass="p-mr-2 completed-chip"></p-chip>
              </div>
            </div>
          </div>

        </div>

        <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">

          <div class="p-card terminal-metrics border-color-offwhite background-color-offwhite">
            <div class="flex flex-column card-container">
              <div class="flex align-items-center justify-content-center text-padding">
                <h1>Tank Name: {{testTermCard2.tankName}}</h1>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Average Rate</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h3>{{testTermCard2.avgPumpingRate | number: '1.2-2'}}</h3>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Terminal</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <p-chip label="{{testTermCard2.terminalName}}" styleClass="p-mr-2 completed-chip"></p-chip>
              </div>
            </div>
          </div>

        </div>

        <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">

          <div class="p-card terminal-metrics border-color-offwhite background-color-offwhite">
            <div class="flex flex-column card-container">
              <div class="flex align-items-center justify-content-center text-padding">
                <h1>Tank Name: {{testTermCard3.tankName}}</h1>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Average Rate</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h3>{{testTermCard3.avgPumpingRate | number: '1.2-2'}}</h3>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Terminal</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <p-chip label="{{testTermCard3.terminalName}}" styleClass="p-mr-2 completed-chip"></p-chip>
              </div>
            </div>
          </div>

        </div>

        <div class="p-col-12 p-md-6 p-lg-6 p-xl-6">

          <div class="p-card terminal-metrics border-color-offwhite background-color-offwhite">
            <div class="flex flex-column card-container">
              <div class="flex align-items-center justify-content-center text-padding">
                <h1>Tank Name: {{testTermCard4.tankName}}</h1>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Average Rate</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h3>{{testTermCard4.avgPumpingRate | number: '1.2-2'}}</h3>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <h2>Terminal</h2>
              </div>
              <div class="flex align-items-center justify-content-center text-padding">
                <p-chip label="{{testTermCard4.terminalName}}" styleClass="p-mr-2 completed-chip"></p-chip>
              </div>
            </div>
          </div>

        </div>

        <div class="p-col-12">
          <div class="flex card-container">
            <div class="center-alignment">
              <span class="p-buttonset">
                <p-button label="Prev" icon="pi pi-arrow-left" iconPos="left" (onClick)="termPrev()" [disabled]="termCardPrev"></p-button>
                <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="termNext()" [disabled]="termCardNext"></p-button>
              </span>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12">

      <div class="flex card-container">
        <div class="flex align-items-center justify-content-center text-padding center-alignment">
          <p-button style="{'margin-bottom':'2rem'}" label="View & Print KPIs" (onClick)="downloadKPIFirstReport()" pTooltip="View & Print KPIs"></p-button>
        </div>
      </div>

    </div>


  </div>

</div>
