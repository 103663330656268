import { AuditableEntity } from "../auditable-entity";

export class TimeTrackingEvent extends AuditableEntity {
    name: string;
    category: string;
    description: string;
    requiresDuration: boolean = true;
    requiresTankAssignment: boolean = false;
    isSystemTrigger: boolean = false;

    constructor() {
        super();
    }
}
