import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-superintendent-list-status',
  templateUrl: './superintendent-list-status.component.html',
  styleUrls: ['./superintendent-list-status.component.scss']
})
export class SuperintendentListStatusComponent implements OnInit {
  @Input() users: User[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  callDisplayName(user: User): string {
    return User.fromUserProperties(user).displayName;
  }
}
