import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ClientCompany, Contact } from 'src/app/models';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

  @Input() client: ClientCompany = new ClientCompany();
  @Input() contacts: Contact[] = [];
  @Output() clientUpdated = new EventEmitter<ClientCompany>();
  @Output() resetClient = new EventEmitter();
  
  contactOptions: SelectItem[] = [];
  showContactDialog = false;
  
  constructor() { }

  ngOnInit(): void {
    this.contactOptions = this.buildContactOptions();
  }

  buildContactOptions(): SelectItem[] {
    return this.contacts.sort((a,b) => a.displayName > b.displayName ?  1 :  -1).map(c => { return { 'label': c.displayName + ' (' + c.email + ')', value: c }; });
  }

  save() {
    this.clientUpdated.emit(this.client);
  }

  reset() {
    this.resetClient.emit();
  }

  contactAdded(contact: Contact) {
    this.contactOptions.push({ label: contact.displayName + ' (' + contact.email + ')', value: contact });
    this.contactOptions = this.contactOptions.sort((a,b) => a.label > b.label ?  1 :  -1);
    this.client.contacts.push(contact);
    this.client.contacts = [...this.client.contacts];  //force reference to array to change to trigger ngModel on multiSelect to see a change
    this.showContactDialog = false;
  }

}
