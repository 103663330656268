export enum UserRoles {
    admin = 'admin',
    customer = 'customer',
    superCustomer = 'superCustomer',
    newCustomer = 'newCustomer',
    superNewCustomer = 'superNewCustomer',
    superAdmin = 'superAdmin',
    superintendent = 'superintendent',
    terminal = 'terminal'
}
