import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bou } from 'src/app/models';

@Component({
  selector: 'app-job-bou-shore-barge-reporting',
  templateUrl: './job-bou-shore-barge-reporting.component.html',
  styleUrls: ['./job-bou-shore-barge-reporting.component.scss']
})
export class JobBouShoreBargeReportingComponent implements OnInit {

  @Input() bou: Bou;
  @Output() shoreBargeReportingUpdated = new EventEmitter();
  @Output() selectedEstimation = new EventEmitter<number>();
  @Output() selectedActual = new EventEmitter<number>();

  estimatedCols: any[] = [];
  actualCols: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.estimatedCols = this.buildEstimatedCols();
    this.actualCols = this.buildActualCols();
  }

  buildEstimatedCols(): any[] {
    return [
      { name: 'actions', width: '100px' },      
      { name: 'originSwitch', width: '100px' },      
      { name: 'tankDescription', width: '100px' },
      { name: 'start', width: '150px' },
      { name: 'rate', width: '100px' },
      { name: 'stripping', width: '100px' },
      { name: 'duration', width: '100px' },
      { name: 'completion', width: '150px' },
      { name: 'nominated', width: '100px' }
    ];
  }

  buildActualCols(): any[] {
    return [
      { name: 'actions', width: '100px' },
      { name: 'start', width: '150px' },
      { name: 'rate', width: '100px' },
      { name: 'duration', width: '100px' },
      { name: 'vapor', width: '100px' },
      { name: 'weather', width: '100px' },
      { name: 'lineDisplacement', width: '100px' },
      { name: 'slowRate', width: '100px' },
      { name: 'powerOutage', width: '100px' },
      { name: 'terminalReason', width: '100px' },
      { name: 'otherDelay', width: '100px' },
      { name: 'tankSwap', width: '100px' },
      { name: 'completion', width: '150px' },
      { name: 'delivered', width: '100px' },
      { name: 'variance', width: '100px' },
      { name: 'comments', width: '225px' },
      { name: 'completed', width: '100px' }
    ];
  }

  useNominatedQuantitiesChanged() {
    this.shoreBargeReportingUpdated.emit();
  }

  editEstimation(step: number) {
    this.selectedEstimation.emit(step);
  }

  editActual(step: number) {
    this.selectedActual.emit(step);
  }
}
