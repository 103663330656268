<div class="p-grid">
    <div class="p-md-6 p-col-12">
        <p><b>Selected VDP:</b> {{selectedVdpText}}</p>
    </div>
    <div class="p-col-12"><h3>VDPs</h3></div>
    <div class="p-col-12">
        <p-table
            [value]="tableData"
            [columns]="cols"
            [rows]="5"
            [responsive]="true"
            [paginator]="true"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[5,10,25,50]"
            [rowHover]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                            <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                            <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vdp>
                <tr>
                    <td>{{vdp.parcelNumber}}</td>
                    <td>{{vdp.product}}</td>
                    <td>
                        <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-eye" (click)="selectVdp(vdp.id)" pTooltip="View this VDP"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>