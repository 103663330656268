import { AuditableEntity, IAuditableEntity } from "../auditable-entity";

export interface IContact extends IAuditableEntity {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export class Contact extends AuditableEntity implements IContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;

    get displayName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    // In order to make sure getter works a new Contact() must be
    // created
    static fromJson(contact: IContact): Contact {
        const newContact = new Contact();
        if (contact) {
            newContact.id = contact.id;
            newContact.createdOn = contact.createdOn;
            newContact.createdBy = contact.createdBy;
            newContact.modifiedOn = contact.modifiedOn;
            newContact.modifiedBy = contact.modifiedBy;
            newContact.active = contact.active;
            newContact.firstName = contact.firstName;
            newContact.lastName = contact.lastName;
            newContact.email = contact.email;
            newContact.phone = contact.phone;
        }

        return newContact;
    }

    constructor() {
        super();
    }
}