import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClientCompany, Contact, Dock, InspectionCompany, Port, Terminal, TimeTrackingEvent, Tank, Ship, Product } from "src/app/models";
import { SystemConfiguration } from "src/app/models/system-config/system-configuration";

@Injectable({
  providedIn: 'root'
})
export class SystemConfigurationService {

  private apiUrl = '/api';

  constructor(
    private httpClient: HttpClient) { }

  getSystemConfiguration(): Observable<SystemConfiguration> {
    return this.httpClient.get<SystemConfiguration>(this.apiUrl + '/config');
  }

  upsertPort(port: Port): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/port/' + port.id, port);
  }

  upsertTerminal(terminal: Terminal): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/terminal/' + terminal.id, terminal);
  }

  upsertDock(dock: Dock): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/dock/' + dock.id, dock);
  }

  upsertClientCompany(clientCompany: ClientCompany): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/client/' + clientCompany.id, clientCompany);
  }

  upsertInspectionCompany(inspectionCompany: InspectionCompany): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/inspectionCompany/' + inspectionCompany.id, inspectionCompany);
  }

  upsertContact(contact: Contact): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/contact/' + contact.id, contact);
  }

  upsertTimeTrackingEvent(timeTrackingEvent: TimeTrackingEvent): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/timeTrackingEvent/' + timeTrackingEvent.id, timeTrackingEvent);
  }

  upsertTank(tank: Tank): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/tank/' + tank.id, tank);
  }

  upsertShip(ship: Ship): Observable<any> {
    console.log("Upsert Ship");
    return this.httpClient.put(this.apiUrl + '/ship/' + ship.id, ship);
  }

  upsertCargo(cargo: Product): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/product/' + cargo.id, cargo);
  }

  upsertProduct(product: Product): Observable<any> {
    return this.httpClient.put(this.apiUrl + '/product/' + product.id, product);
  }
}
