import { Ship, Terminal, TimeSpan, User } from 'src/app/models';
import { Feedback } from './feedback';

export class CompletedJobInfo {
    jobId: string;
    jobNumber: string;
    terminal: Terminal;
    ship: Ship;
    superintendents: User[] = [];
    lastUpdateDate: Date;
    status: string;
    nominatedVolume: number;
	  downtime: TimeSpan;
    berthingDate: Date;
	  allFastTime: Date;
    completionDate: Date;
    survey: boolean;

    constructor() {
    }
}
