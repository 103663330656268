<div *ngIf="bou" class="p-grid">
    <div class="p-md-6 p-col-12">
        <div class="p-grid">
            <div class="p-col-12 p-text-center">Current Status</div>
            <div class="p-md-6 p-col-12">
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="obq" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">O.B.Q.:</label>
                        <div class="p-col-12 p-md-6">
                            <p-inputNumber id="obq" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [(ngModel)]="bou.obq" (change)="updateBou()"></p-inputNumber>
                        </div>
                    </div>
                    <div *ngIf="!bou.isCompleted" class="p-field p-grid">
                        <label for="etcNotCompleted" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">E.T.C.:</label>
                        <div class="p-col-12 p-md-6">
                          <label id="etcNotCompleted">{{bou.etcDate | date: 'MMM d, yyyy, HH:mm'}}</label>
                        </div>
                    </div>
                    <div *ngIf="bou.isCompleted" class="p-field p-grid">
                        <label for="etcCompleted" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">E.T.C.:</label>
                        <div class="p-col-12 p-md-6">
                            <label id="etcCompleted">Completed</label>
                        </div>
                    </div>
                    <div *ngIf="!bou.isCompleted" class="p-field p-grid">
                        <label for="loadingFrom" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Loading From:</label>
                        <div class="p-col-12 p-md-6">
                            <p-dropdown id="loadingFrom" [options]="tankOptions" [(ngModel)]="bou.loadingFromTankId" appendTo="body" placeholder="Select Tank" (onChange)="updateBou()"></p-dropdown>
                        </div>
                    </div>
                    <div *ngIf="!bou.isCompleted" class="p-field p-grid">
                        <label for="etcStep" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">E.T.C. This Step:</label>
                        <div class="p-col-12 p-md-6">
                            <label id="etcStep">{{bou.etcThisStep | date: 'MMM d, yyyy, HH:mm'}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-md-6 p-col-12">
                <div class="p-fluid">
                    <div class="p-col-12"></div>
                    <div class="p-field p-grid">
                        <label for="currentRate" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Current Rate:</label>
                        <div class="p-col-12 p-md-6">
                            <input id="currentRate" type="number" pInputText [(ngModel)]="bou.currentRate">
                        </div>
                    </div>
                </div>
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="timeAtDock" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Time at Dock:</label>
                        <div class="p-col-12 p-md-6">
                            <label id="timeAtDock">{{bou.timeAtDockHours ? bou.timeAtDockHours : 0}}:{{bou.timeAtDockMinutes ? bou.timeAtDockMinutes : 0}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="chartReady" class="p-col-12">
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="chart" class="p-col-12 p-mb-3 p-md-3 p-mb-md-0 p-text-right">Percent Loaded:</label>
                        <div class="p-col-12 p-md-9">
                            <!-- Style attributes to make the chart responsive.  Set width to 99% since the chart would
                            bleed slightly outside the prime ng card when at 100%-->
                            <plotly-plot
                                id="chart"
                                *ngIf="chartReady"
                                [data]="chart.data"
                                [layout]="chart.layout"
                                [useResizeHandler]="true"
                                [style]="{ position: 'relative', width: '99%', height: '50%' }">
                            </plotly-plot>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    <div class="p-md-3 p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="firstLine" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">First Line:</label>
                <div class="p-col-12 p-md-6">
                    <label id="firstLine">{{bou.firstLine | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="allFast" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">All Fast:</label>
                <div class="p-col-12 p-md-6">
                    <label id="allFast">{{bou.allFast | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="gangwaySecure" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Gangway Secure:</label>
                <div class="p-col-12 p-md-6">
                    <label id="gangwaySecure">{{bou.gangwaySecure | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="keyMeeting" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Key Meeting Completed:</label>
                <div class="p-col-12 p-md-6">
                    <label id="keyMeeting">{{bou.keyMeetingCompleted | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="vesselTanksInspection" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Vessel Tanks Inspection Completed:</label>
                <div class="p-col-12 p-md-6">
                    <label id="vesselTanksInspection">{{bou.vesselTanksInspectionCompleted | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="arrivalCalculations" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Arrival Calculations:</label>
                <div class="p-col-12 p-md-6">
                    <label id="arrivalCalculations">{{bou.arrivalCalculationsCompleted | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="armsConnected" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Cargo & Vapor Arms/Hoses Connected:</label>
                <div class="p-col-12 p-md-6">
                    <label id="armsConnected">{{bou.cargoVaporArmsHosesConnected | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-3 p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="commencedLoading" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Commenced Loading:</label>
                <div class="p-col-12 p-md-6">
                    <label id="commencedLoading">{{bou.loadingCommenced | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="completedLoading" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Completed Loading:</label>
                <div class="p-col-12 p-md-6">
                    <label id="completedLoading">{{bou.loadingCompleted | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="ullaging" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Completed Ullaging:</label>
                <div class="p-col-12 p-md-6">
                    <label id="ullaging">{{bou.ullagingCompleted | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="cargoCalculations" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Completed Cargo Calculations:</label>
                <div class="p-col-12 p-md-6">
                    <label id="cargoCalculations">{{bou.cargoCalcualationsCompleted | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="armsDisconnected" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Arms/Hoses Disconnected:</label>
                <div class="p-col-12 p-md-6">
                    <label id="armsDisconnected">{{bou.armsHosesDisconnected | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="pilotSet" class="p-col-12 p-mb-6 p-md-6 p-mb-md-0">Pilot Set:</label>
                <div class="p-col-12 p-md-6">
                    <label id="pilotSet">{{bou.pilotSet | date: 'MMM d, yyyy, HH:mm'}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
