import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { merge, of, Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { google } from 'google-maps';
import { Terminal, Port, Contact } from 'src/app/models';

@Component({
  selector: 'app-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.scss']
})
export class TerminalListComponent implements OnInit, OnDestroy {

  demoLoadTimeSeconds: number = 1.5;
  cols: any[] = [];
  skeletonRows: any[] = [];
  terminals: TerminalListItem[] = [];
  map: any;
  mapOptions: any = {};
  mapOverlays: any[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedTerminal: Terminal;
  infoWindow: any;
  showEditDialog = false;
  allPorts: Port[] = [];
  allContacts: Contact[] = [];

  constructor(
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.cols = this.buildCols();
    this.skeletonRows = this.buildSkeletonRows();
    this.mapOptions = this.buildMapOptions();
    this.listenForPortsStateChanges();
    this.listenForContactsStateChanges();
    this.listenForTerminalStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForTerminalStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTerminals))
      .subscribe(results => { 
        this.terminals = [...results].map(t => TerminalListItem.fromTerminal(t));
        this.terminals.forEach(t => {
          t.portName = t.port?.name
        });
        this.mapOverlays = this.buildMarkers(this.terminals);
        if (this.mapOverlays && this.mapOverlays.length) {
          this.setMapOverlays
        }
        this.loaded = true;
      })
    );
  }

  listenForPortsStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getPortLocations))
      .subscribe(results => { 
        this.allPorts = [...results];
        this.terminals.forEach(t => {
          t.portName = t.port?.name
        });
      })
    );
  }

  listenForContactsStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getContacts))
      .subscribe(results => { 
        this.allContacts = [...results].map(c => Contact.fromJson(c));
      })
    );
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'portName', header: 'Port', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'lat', header: 'Latitude', display: false, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'long', header: 'Longitude', display: false, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'address', header: 'Address', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'contacts', header: 'Contacts', display: false, sortable: true, searchable: false, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  buildMapOptions(): any {
    return {
      center: { lat: 29.78036, lng: -95.30648 },
      zoom: 12
    }
  }

  buildMarkers(terminals: Terminal[]): any[] {
    return terminals.map(t => new google.maps.Marker({
      position: { lat: t.lat, lng: t.long },
      title: t.name
      // label: t.name
    }));
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addTerminal() {
    this.selectedTerminal = new Terminal();
    this.showEditDialog = true;
  }

  openTerminal(termainal: Terminal) {
   this.selectedTerminal = cloneDeep(termainal);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateTerminal(terminal: Terminal) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Terminal: ' + terminal.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveTerminal = { ...terminal, active: false }
        this.store.dispatch(appActions.setTerminal({ data: deactiveTerminal }));
      }
    });
  }

  activateTerminal(terminal: Terminal) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Terminal: ' + terminal.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeTerminal = { ...terminal, active: true }
        this.store.dispatch(appActions.setTerminal({ data: activeTerminal }));
      }
    });
  }

  setMap(event: any) {
    this.map = event.map;
    this.infoWindow = new google.maps.InfoWindow();
    if (this.mapOverlays && this.mapOverlays.length) {
      this.setMapOverlays(); 
    }
  }

  setMapOverlays() {
    let bounds = new google.maps.LatLngBounds();
    this.mapOverlays.forEach(marker => {
      bounds.extend(marker.getPosition());
    });
    setTimeout(()=> { // wait a bit after map loaded to give animated effect when setting bounds
      this.map.fitBounds(bounds);
    }, 1000);
  }
  
  handleOverlayClick(event: any) {
    const isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
      const title = event.overlay.getTitle();
      const terminal = this.terminals.find(t => t.name === title);
      const content = '<div class="p-grid>"' +
      '<div class="p-col-12">' +
      '<h3>' + title + '</h3>' +
      '</div' +
      '<div class="p-col-12">' +
      '<p><b>Port/Location :</b> ' + terminal.port?.name + '</p>' +
      '<p><b>Address :</b> ' + terminal.address + '</p>' +
      '</div>';
      this.infoWindow.setContent(content);
      this.infoWindow.open(event.map, event.overlay);
    }
  }
  
  rowClicked() {
    if (this.selectedTerminal) {
      this.map.setCenter({ lat: this.selectedTerminal.lat, lng: this.selectedTerminal.long });
      this.map.setZoom(14);
      const content = '<div class="p-grid>"' +
      '<div class="p-col-12">' +
      '<h3>' + this.selectedTerminal.name + '</h3>' +
      '</div' +
      '<div class="p-col-12">' +
      '<p><b>Port/Location :</b> ' + this.selectedTerminal.port?.name + '</p>' +
      '<p><b>Address :</b> ' + this.selectedTerminal.address + '</p>' +
      '</div>';
      this.infoWindow.setContent(content);
      this.infoWindow.open(this.map, this.mapOverlays.find(o => o.title === this.selectedTerminal.name));
    }    
  }

  updateTerminal(terminal: Terminal) {
    this.store.dispatch(appActions.setTerminal({ data: terminal }));
    this.showEditDialog = false;
  }

  async resetSelectedTerminal() {
    // this.terminals is already subscribed to latest state of terminals
    // reset selected terminal to state
    this.selectedTerminal = cloneDeep(this.terminals.find(t => t.id === this.selectedTerminal.id));
  }
}

class TerminalListItem extends Terminal {
  portName: string;
  constructor() {
    super();
  }

  static fromTerminal(terminal: Terminal, portName: string = ''): TerminalListItem {
    const terminalListItem = new TerminalListItem();
    terminalListItem.id = terminal.id;
    terminalListItem.active = terminal.active;
    terminalListItem.name = terminal.name;
    terminalListItem.lat = terminal.lat;
    terminalListItem.long = terminal.long;
    terminalListItem.address = terminal.address;
    terminalListItem.contacts = terminal.contacts;
    terminalListItem.port = terminal.port;
    terminalListItem.portName = portName;
    return terminalListItem;
  }
}
