import { AuditableEntity } from "../auditable-entity";
import { Ship, Terminal } from 'src/app/models';

export class JobPerformanceReport extends AuditableEntity {
  jobId: string;
  status: string;
  ship: Ship;
  terminal: Terminal;
  cargoCommenced: Date;
  estimatedCompletion: Date;
  completionTime: Date;
  netPumpingRate: number;
  grossPumpingRate: number;
  totalDowntime: number;
  totalDowntimeText: string;
  jobTimings: TimingInfo[];
  averageTimingsByClient: TimingInfo[];
  averageTimingsForAll: TimingInfo[];
  tankPerformances: TankPerformance[];
  loadingInfos: LoadingInfo[];
  blpStep: number;
}

export class TankPerformance {
  tankId: string;
  tankDescription: string;
  tankTimings: TimingInfo[];
  downtimeTimings: DownTimeGrouping[];
  chartData: any;
}

export class TimingInfo {
  description: string;
  explanation: string;
  duration: number;
  displayValue: boolean;
  durationText: string;
}

export class LoadingInfo {
  description: string;
  duration: number;
  durationText: string;
  bbls: number;
  bblsPerHour: number;
}

export class TimingComparisonInfo {
  description: string;
  explanation: string;
  duration: number;
  durationText: string;
  averageDuration: number;
  averageDurationText: string;
  averageDurationAll: number;
  averageDurationAllText: string;
  displayValue: boolean;
}

export class DownTimeGrouping {
  grouping: string;
  groupingDurationText: string;
  groupingDuration: number;
  details: DownTimeDetails[];
}

export class DownTimeDetails {
  delayType: string;
  delayDurationText: string;
  delayDuration: number;
}
