import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { first, tap } from "rxjs/operators";
import { AppConfig } from "../models/app-config";

@Injectable()
export class AppConfigService {
    constructor(private injector: Injector) {}

    public load(): Promise<AppConfig> {
        let backend = this.injector.get(HttpBackend);
        let httpClient = new HttpClient(backend);
        return httpClient.get<AppConfig>('api/appconfig')
                .pipe(
                    first(),
                    tap((c) => {AppConfig.GLOBAL_CONFIG = c;})
                ).toPromise();
    }
}