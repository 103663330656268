import { Injectable } from "@angular/core";
import { AppMessageService } from "./app-message.service";

@Injectable({
    providedIn: 'root'
})
export class CalculationReferenceService {
    readonly tempShift = 0.01374979547;
    readonly baseTemp = 60.0068749;

    constructor(private appMessageService: AppMessageService) {}

    getAstmTable11Wcf(api: number): number {
        //ASTM Table 11: To get the WCF to convert Barrels at 60 Deg F to Long Ton in the air.
        return parseFloat((Math.round(0.0375 * ((589.943 / (api + 131.5)) - 0.0050789) * Math.pow(10, 5) + 0.5) / Math.pow(10, 5)).toFixed(5));
    }

    getAstmTable13Wcf(api: number): number {
        //ASTM Table 13: To get the WCF to convert Barrels at 60 Deg F to Metric Ton in the air.
        return parseFloat((Math.round(0.042 * ((535.1911 / (api + 131.5)) - 0.0046189) * Math.pow(10, 5) + 0.5) / Math.pow(10, 5)).toFixed(5));
    }

    getAstmT6aVcf(api: number, tempF: number, pressure: number): number {
        //ASTM Table 6A: To get the VCF given an API at 60 degrees F and a given observed temperature
        //Dim TEMP As Double
        //TEMP = temperatureFahrenheit
        //Dim p As Double
        //p = pressure

        if (api < -10 || api > 100) {
            this.appMessageService.errorMessage('API is out of range: -10 to 100', api);
            return 0;
        }
        if (tempF < -58 || tempF > 302) {
            this.appMessageService.errorMessage('Temperature is out of range: -58 to 302', tempF);
            return 0;
        }
        if (pressure < 0 || pressure > 1500) {
            this.appMessageService.errorMessage('Pressure out of range: 0 to 1500', pressure);
            return 0;
        }
        
        //calculate Density kg/cu m
        const density = ((141.5) / (api + 131.5)) * 999.016;

        //STEP 2
        //2.1 convert to ITPS 68 Temp
        const tempITPS68 = this.convertFahrenheitToIPTS68(tempF);

        const a = (this.tempShift / 2) * ((((341.0957 / density)) * (1 / density)));
        const b = ((2 * 341.0957) + density) / (341.0957 + (density * density));

        const top = Math.exp(a * (1 + (0.8 * a))) - 1;
        const bottom = 1 + (a * (1 + (1.6 * a)) * b);
        const pITPS68 = density * (1 + (top / bottom));

        const thermal = (((341.0957 / pITPS68)) * (1 / pITPS68));

        //calculate temp difference
        const tempdiff = tempITPS68 - this.baseTemp;

        //calculate Coefficient of thermal expansion
        const ctl = Math.exp(-thermal * tempdiff * (1 + (0.8 * thermal * (tempdiff + this.tempShift))));

        //calculate Scaled Compressibility factor FP
        const fp = Math.exp(-1.9947 + 0.00013427 * tempITPS68 + ((793920 + 2326 * tempITPS68) / Math.pow(pITPS68, 2)));

        //calculate Correction due to pressure
        const cpl = 1 / (1 - Math.pow(10, -5) * fp * pressure);

        //calculate Volume Correction Factor
        let vcf = ctl * cpl;
        
        //perform rounding 11.1.6.1
        //vcf = this.roundIt(vcf, 0.00001);

        return vcf;
    }

    getAstmT6bVcf(api: number, tempF: number, pressure: number = 0): number {
        if (api < -10 || api > 100) {
            this.appMessageService.errorMessage('API is out of range: -10 to 100', api);
            return 0;
        }
        if (tempF < -58 || tempF > 302) {
            this.appMessageService.errorMessage('Temperature is out of range: -58 to 302', tempF);
            return 0;
        }
        if (pressure < 0 || pressure > 1500) {
            this.appMessageService.errorMessage('Pressure out of range: 0 to 1500', pressure);
            return 0;
        }
        
        //calculate Density kg/cu m
        const density = ((141.5) / (api + 131.5)) * 999.016;

        //STEP 2
        //2.1 convert to ITPS 68 Temp
        const tempITPS68 = this.convertFahrenheitToIPTS68(tempF);

        let K0: number;
        let K1: number; 
        let k2: number;
        if (838.3127 <= density && density <= 1163.5) {
            //Fuel Oils
            K0 = 103.872;
            K1 = 0.2701;
            k2 = 0;
        }
        if (787.5195 <= density && density < 838.3127) {
            //Jet Fuels
            K0 = 330.301;
            K1 = 0;
            k2 = 0;
        }
        if (770.352 <= density && density < 787.5195) {
            //Transition zone
            K0 = 1489.067;
            K1 = 0;
            k2 = -0.0018684;
        }
        if (610.6 <= density && density < 770.352) {
            K0 = 192.4571;
            K1 = 0.2438;
            k2 = 0;
        }
        if (density < 610.6) {
            this.appMessageService.errorMessage(`Can't calculate K coefficients from API due to density below 610.6`, density);
            return 0;
        }

        const a = (this.tempShift / 2) * ((((K0 / density) + K1) * (1 / density)) + k2);
        const b = ((2 * K0) + (K1 * density)) / (K0 + ((K1 + (k2 * density)) * density));

        const top = Math.exp(a * (1 + (0.8 * a))) - 1;
        const bot = 1 + (a * (1 + (1.6 * a)) * b);
        const pITPS68 = density * (1 + (top / bot));

        const thermal = (((K0 / pITPS68) + K1) * (1 / pITPS68)) + k2;

        //calculate temp difference
        const tempdiff = tempITPS68 - this.baseTemp;

        //calculate Coefficient of thermal expansion
        const ctl = Math.exp(-1 * thermal * tempdiff * (1 + (0.8 * thermal * (tempdiff + this.tempShift))));

        //calculate Scaled Compressibility factor FP
        const fp = Math.exp(-1.9947 + 0.00013427 * tempITPS68 + ((793920 + 2326 * tempITPS68) / Math.pow(pITPS68, 2)));

        //calculate Correction due to pressure
        const cpl = 1 / (1 - Math.pow(10, -5) * fp * pressure);

        //calculate Volume Correction Factor
        let vcf = ctl * cpl;
        
        //perform rounding 11.1.6.1
        // vcf = this.roundIt(vcf, 0.00001);

        return vcf;

    }

    getAstmT6cVcf(tempF: number): number {
        if (tempF < -58 || tempF > 302) {
            this.appMessageService.errorMessage('Temperature is out of range: -58 to 302', tempF);
            return 0;
        }

        const api = 13.4;
        const pressure = 1; // Vlad confirmed that 1 should be used for pressure in this calculation
        
        //calculate Density kg/cu m
        const Rho60 = ((141.5) / (api + 131.5)) * 999.016;

        //STEP 2
        //2.1 convert to ITPS 68 Temp
        const tempITPS68 = this.convertFahrenheitToIPTS68(tempF);

        const a = (this.tempShift / 2) * ((((0 / Rho60) + 0.34878) * (1 / Rho60)));
        const B = (0.34878 * Rho60) / (341.0957 + (Rho60 * Rho60));

        const top = Math.exp(a * (1 + (0.8 * a))) - 1;
        const bot = 1 + (a * (1 + (1.6 * a)) * B);
        const pITPS68 = Rho60 * (1 + (top / bot));

        const thermal = 0.000789;

        //calculate temp difference
        const tempdiff = tempITPS68 - this.baseTemp;

        //calculate Coefficient of thermal expansion
        const ctl = Math.exp(-(thermal * tempdiff) * (1 + (0.8 * thermal * (tempdiff + this.tempShift))));

        //calculate Scaled Compressibility factor FP
        const fp = Math.exp(-1.9947 + 0.00013427 * tempITPS68 + ((793920 + 2326 * tempITPS68) / Math.pow(pITPS68, 2)))

        //calculate Correction due to pressure
        const cpl = 1 / (1 - Math.pow(10, -5) * fp * pressure);

        //calculate Volume Correction Factor
        let vcf = ctl * cpl;
        
        //perform rounding 11.1.6.1
        //vcf = this.roundIt(vcf, 0.00001);

        return vcf;
    }

    convertFahrenheitToIPTS68(tempF: number): number {
        //first convert to celsius
        const tempC = (tempF - 32) / 1.8

        const t = tempC / 630

        //perform series
        const a1 = (7.438081 + (-3.536296 * t)) * t
        const a2 = (-1.871251 + a1) * t
        const a3 = (-4.089591 + a2) * t
        const a4 = (1.269056 + a3) * t
        const a5 = (1.08076 + a4) * t
        const a6 = (-0.267408 + a5) * t
        const a7 = (-0.148759 + a6) * t

        let ipts = tempC - a7 //deltat
        //convert back to Fahrenheit
        let fahr = (1.8 * ipts) + 32

        return fahr;
    }

    roundIt(d: number, factor: number): number {
        const y = Math.abs(d) / factor;

        const i = Math.floor(this.vbaRound(y, 0));

        const x = ((d < 0) ? -1 : 1) * factor * i;

        return x;
    }

    vbaRound(value: number, decimals: number, roundingOption: number = 1): number {
        if (decimals < 0) {
            return 0;
        }
        
        const placesFactor = Math.pow(10, decimals);
        
        let roundingFactor: number;
        switch(roundingOption) {
            case 1: {//Round to Nearest 
                roundingFactor = 0.5;
                break;
            }
            case 2: {//Round UP
                roundingFactor = 1;
                break;
            }
            case 3: {//Round DOWN
                roundingFactor = 0;
                break;
            }
            default:
                //message?
        }
        
        return Math.round(value * placesFactor + roundingFactor) / placesFactor
    }

    sumProduct(a: number[], b: number[]): number {
        let sumProduct = 0;
        for(let i=0; i<a.length; i++) {
            sumProduct += a && b ? (a[i] * b[i]) : 0;
        }
        return sumProduct;
    }

    handleNaN(val: any): number {
        if (!val) {
            return 0;
        }
        return isNaN(val) ? null : (val ?? 0);
    }

}