import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JobReport } from 'src/app/models/reports/job-report';
import { JobPerformanceReport, TimingInfo, LoadingInfo } from 'src/app/models/reports/job-performance';
import { TerminalPerformanceReport } from 'src/app/models/reports/terminal-report';
import { TerminalTrendReport } from 'src/app/models/reports/terminal-trend-report';
import { TerminalComparisonReport } from 'src/app/models/reports/terminal-comparison-report';
import { Terminal } from 'src/app/models';

@Injectable({
    providedIn: 'root'
})
export class JobReportService {

    private apiUrl = '/api/jobReport';

    constructor(private httpClient: HttpClient) {}

    getJobReport(customerId: string): Observable<JobReport> {
        return this.httpClient.get<JobReport>(this.apiUrl + '/summary/' + encodeURIComponent(customerId));
    }

    getJobPerformanceReport(jobId: string): Observable<JobPerformanceReport> {
        return this.httpClient.get<JobPerformanceReport>(this.apiUrl + '/jobperformance/' + encodeURIComponent(jobId));
    }

    getTerminalPerformanceReport(customerId: string, terminalId): Observable<TerminalPerformanceReport> {
        return this.httpClient.get<TerminalPerformanceReport>(this.apiUrl + '/terminalperformance/' + encodeURIComponent(customerId) + '/' 
            + encodeURIComponent(terminalId));
    }

    getTerminalTrendReport(customerId: string, terminalId: string, step: number): Observable<TerminalTrendReport> {
        return this.httpClient.get<TerminalTrendReport>(this.apiUrl + '/terminaltrend/' + encodeURIComponent(customerId) + '/' 
            + encodeURIComponent(terminalId) + '/' + step);
    }

    getTerminalComparisonReport(customerId: string): Observable<TerminalComparisonReport> {
        return this.httpClient.get<TerminalComparisonReport>(this.apiUrl + '/terminalcomparison/' + encodeURIComponent(customerId));
    }

    getJobTerminals(customerId: string): Observable<Terminal[]> {
        return this.httpClient.get<Terminal[]>(this.apiUrl + '/jobterminals/' + encodeURIComponent(customerId));
    }

    convertTimingDuration(timings: TimingInfo[]) {
        if (!timings) return;
        timings.forEach(t => t.durationText = this.convertDuration(t.duration));
    }

    convertLoadingDuration(loadings: LoadingInfo[]) {
        if (!loadings) return;
        loadings.forEach(l => l.durationText = this.convertDuration(l.duration));
    }

    convertDuration(duration: number): string {
        if (duration){
            var minutes = Math.floor(duration);
            var hours = Math.floor(minutes / 60);
            var minutes = minutes % 60;
            return hours + 'h ' + minutes + 'm ';
        }
        return 'N/A';
    }
}