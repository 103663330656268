<div class="p-grid" style="margin: 10px;">
    <div class="p-col-12">
        <p-tabView [(activeIndex)]="activeIndex1">
            <p-tabPanel headerStyleClass="tabHeader">
                <ng-template pTemplate = "header">
                    <i class="pi pi-inbox imageMargin"></i>
                    <span>Active Jobs</span>
                </ng-template>
                <p-table #activeJobsDt [value]="report.activeJobSummaries"
                    styleClass="p-datatable-striped"
                    [columns]="activeJobCols"
                    [rows]="50"
                    [responsive]="true"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowHover]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                                    <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                                    <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-jobSummary>
                      <tr>
                        <td>
                          <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-play"
                                  (click)="viewActiveJob(jobSummary.jobId)" pTooltip="View Job Report"></button>
                        </td>
                        <td>
                          <ng-template [ngIf]="jobSummary.loadingStatus == 1"><p-button styleClass="p-button-rounded p-button-danger" pTooltip="Loading Suspended"></p-button></ng-template>
                          <ng-template [ngIf]="jobSummary.loadingStatus == 0"><p-button styleClass="p-button-rounded p-button-success" pTooltip="Loading Ongoing"></p-button></ng-template>
                        </td>
                        <td>{{jobSummary.ship?.name}}</td>
                        <td>{{jobSummary.nominatedVolume | number: '1.0':'en-US'}}</td>
                        <td>{{jobSummary.terminal?.name}}</td>
                        <td>{{jobSummary.berthingDate | date: 'M/d/yyyy HH:mm'}}</td>
                        <!-- <td>{{jobSummary.cargoCommenced | date: 'M/d/yyyy HH:mm'}}</td> -->
                        <!-- <td>{{jobSummary.estimatedCompletion | date: 'M/d/yyyy HH:mm'}}</td> -->
                        <td>{{jobSummary.downtimeText}}</td>
                        <td>
                          <a href="javascript:void(0)" (click)="viewSuperindentendsInfo(jobSummary)">
                            <!-- {{jobSummary.superintendentNames}} -->
                            <app-superintendent-list-status [users]="jobSummary.superintendents"></app-superintendent-list-status>
                          </a>
                        </td>
                        <td>
                          <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-chart-bar"
                                  (click)="downloadBou(jobSummary)" pTooltip="Download BOU Report" [attr.disabled]="!jobSummary.hasBou ? 'disabled' : null"></button>
                        </td>
                        <td>
                          <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-chart-line"
                                  (click)="downloadBlp(jobSummary)" pTooltip="Download BLP Report"
                                  [attr.disabled]="jobSummary.blpId != null ? null : 'disabled'"></button>
                        </td>
                        <!-- <td>{{jobSummary.modifiedOn | date: 'M/d/yyyy HH:mm'}}</td> -->
                      </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel headerStyleClass="tabHeader">
                <ng-template pTemplate = "header">
                    <i class="pi pi-calendar imageMargin"></i>
                    <span>Completed Jobs</span>
                </ng-template>
                <p-table #completedJobsDt [value]="report.completedJobSummaries"
                    styleClass="p-datatable-striped"
                    [columns]="completedJobCols"
                    [rows]="50"
                    [responsive]="true"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowHover]="true"
                    [globalFilterFields]="completedFilterFields">
                    <ng-template pTemplate="caption">
                        <div class="search-bar">
                            <span class="p-input-icon-left p-text-left p-d-none p-d-lg-inline-flex full-width">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="completedJobsDt.filterGlobal($event.target.value, 'contains')" placeholder="Job Search" [style.width]="'100%'"/>
                            </span>
                            <span class="p-input-icon-left p-text-left p-d-md-none full-width">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="completedJobsDt.filterGlobal($event.target.value, 'contains')" placeholder="Job Search" [style.width]="'100%'"/>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" field="col.searchField"></p-sortIcon>
                                    <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                                    <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-jobSummary>
                        <tr>
                            <td>
                                <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-play" 
                                    (click)="viewCompletedJob(jobSummary.jobId)" pTooltip="View Job Report"></button>
                            </td>
                            <td>{{jobSummary.ship?.name}}</td>
                            <td>{{jobSummary.nominatedVolume | number: '1.0':'en-US'}}</td>
                            <td>{{jobSummary.terminal?.name}}</td>
                            <td>{{jobSummary.berthingDate | date: 'M/d/yyyy HH:mm'}}</td>
                            <!-- <td>{{jobSummary.cargoCommenced | date: 'M/d/yyyy HH:mm'}}</td>-->
                            <!-- <td>{{jobSummary.completionTime | date: 'M/d/yyyy HH:mm'}}</td>-->
                            <td>{{jobSummary.downtimeText}}</td>
                            <td>
                                <a href="javascript:void(0)" (click)="viewSuperindentendsInfo(jobSummary)">
                                    <!-- {{jobSummary.superintendentNames}} -->
                                    <app-superintendent-list-status [users]="jobSummary.superintendents"></app-superintendent-list-status>
                                </a>
                            </td>
                            <td>
                                <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-chart-bar" 
                                    (click)="downloadBou(jobSummary)" pTooltip="Download BOU Report" [attr.disabled]="!jobSummary.hasBou ? 'disabled' : null"></button>
                            </td>
                            <td>
                                <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-chart-line" 
                                    (click)="downloadBlp(jobSummary)" pTooltip="Download BLP Report" 
                                    [attr.disabled]="jobSummary.blpId != null ? null : 'disabled'"></button>
                            </td>
                            <td>
                                <p-button styleClass="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-table"
                                    (click)="downloadTimeTrackingXls(jobSummary)" pTooltip="Download Time Tracking (XLS)"></p-button>
                                <p-button styleClass="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-file-pdf"
                                    (click)="downloadTimeTrackingPdf(jobSummary)" pTooltip="Download Time Tracking (PDF)"></p-button>
                            </td>
                            <!-- <td>{{jobSummary.modifiedOn | date: 'M/d/yyyy HH:mm'}}</td>-->
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog *ngIf="showSuperintendents" [(visible)]="showSuperintendents" [style]="{width: '50vw'}" [modal]="true" [closable]="true">
    <div>
        <div style="text-align: center;"><h2>Superintendents</h2></div>
        <p-table [value]="superintendents" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone #</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-superintendent>
                <tr>
                    <td><span *ngIf="superintendent.isClockIn" pTooltip="Available" class="pi pi-sun" style="font-size: 0.7rem; margin: 2px"></span>
                        <span *ngIf="!superintendent.isClockIn" pTooltip="Away" class="pi pi-moon" style="font-size: 0.7rem; margin: 2px"></span>
                        {{superintendent.firstName}} {{superintendent.lastName}}</td>
                    <td><a [href]="'mailto:' + superintendent.email">{{superintendent.email}}</a></td>
                    <td><a [href]="'tel:' + superintendent.phone">{{superintendent.phone}}</a></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
