import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AstmTableOptions, BfrMeasurementTypes, BfrTank, Tank } from 'src/app/models';

@Component({
  selector: 'app-job-bfr-tank-detail',
  templateUrl: './job-bfr-tank-detail.component.html',
  styleUrls: ['./job-bfr-tank-detail.component.scss']
})
export class JobBfrTankDetailComponent implements OnInit {

  @Input() tank: BfrTank;
  @Input() shoreTank: Tank;
  @Output() cancelled = new EventEmitter();
  @Output() saved = new EventEmitter();

  astmTableOptions: SelectItem[] = [];

  constructor() { }

  ngOnInit(): void {
    this.astmTableOptions = this.buildAstmTableOptions();
  }

  buildAstmTableOptions(): SelectItem[] {
    const options: SelectItem[] = [];
    Object.keys(AstmTableOptions).forEach(k => {
        options.push({ label: AstmTableOptions[k], value: AstmTableOptions[k] })
    });
    return options;
  }

  cancel() {
    this.cancelled.emit();
  }

  save() {
    this.saved.emit();
  }

  get openIndex(): number {
    return this.tank.bfrTankLineItems.findIndex(l => l.measurementType === BfrMeasurementTypes.open);
  }

  get closeIndex(): number {
    return this.tank.bfrTankLineItems.findIndex(l => l.measurementType === BfrMeasurementTypes.close);
  }
}
