export class LoadingPlanItem {
    done: boolean;
    stepNumber: number;
    tankName: string;
    step: string;
    nominatedQty: number;
    deliveredQty: number;
    variance: number;

    constructor() {
    }
}
