import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { TimeTrackingEventCategories } from 'src/app/models/system-config/time-tracking-event-categories';
import { TimeTrackingEvent } from 'src/app/models';
import { SystemTriggerEventIds } from 'src/app/models/system-config/system-trigger-event-ids';

@Component({
  selector: 'app-time-tracking-list',
  templateUrl: './time-tracking-list.component.html',
  styleUrls: ['./time-tracking-list.component.scss']
})
export class TimeTrackingListComponent implements OnInit, OnDestroy {

  cols: any[] = [];
  skeletonRows: any[] = [];
  timeTrackingEvents: TimeTrackingEvent[] = [];
  categories: SelectItem[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedEvent: TimeTrackingEvent;
  showEditDialog = false;
  
  constructor(
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.categories = this.buildCategories();
    this.listenForTimeTrackingEventChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForTimeTrackingEventChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTimeTrackingEvents))
        .subscribe(results => {
          this.timeTrackingEvents = [...results];
          this.loaded = true;
        })
    );
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'category', header: 'Category', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'flags', header: 'Flags', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  buildCategories(): SelectItem[] {
    return Object.keys(TimeTrackingEventCategories).map(k => {
      return { label: TimeTrackingEventCategories[k], value: TimeTrackingEventCategories[k] }
    })
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addTimeTrackingEvent() {
    this.selectedEvent = new TimeTrackingEvent();
    this.showEditDialog = true;
  }

  openTimeTrackingEvent(timeTrackingEvent: TimeTrackingEvent) {
   this.selectedEvent = cloneDeep(timeTrackingEvent);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateTimeTrackingEvent(timeTrackingEvent: TimeTrackingEvent) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Time Tracking Event: ' + timeTrackingEvent.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveTimeTrackingEvent = { ...timeTrackingEvent, active: false };
        this.store.dispatch(appActions.setTimeTrackingEvent({ data: deactiveTimeTrackingEvent }));
      }
    });
  }

  activateTimeTrackingEvent(demoTimeTrackingEvent: TimeTrackingEvent) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Time Tracking Event: ' + demoTimeTrackingEvent.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeTimeTrackingEvent = { ...demoTimeTrackingEvent, active: true };
        this.store.dispatch(appActions.setTimeTrackingEvent({ data: activeTimeTrackingEvent }));
      }
    });
  }

  updateTimeTrackingEvent(timeTrackingEvent: TimeTrackingEvent) {
    this.store.dispatch(appActions.setTimeTrackingEvent({ data: timeTrackingEvent }));
    this.showEditDialog = false;
  }

  async resetSelectedTimeTrackingEvent() {
    // this.ports is already subscribed to latest state of TimeTrackingEvents
    // reset selected TimeTrackingEvent to state
    this.selectedEvent = cloneDeep(this.timeTrackingEvents.find(t => t.id === this.selectedEvent.id));
  }

  isSystemTrigger(id: string): boolean {
    let isTrigger = false;
    Object.keys(SystemTriggerEventIds).forEach(k => {
      if (SystemTriggerEventIds[k] === (id.toUpperCase())) {
        isTrigger = true;
      }
    });

    return isTrigger;
  }

}
