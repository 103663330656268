import { TimeSpan } from '../timespan';
import { KpiFirstReportDelayInfo } from './kpi-first-report-delay';
import { KpiFirstReportTerminalInfo } from './kpi-first-report-terminal';

export class KpiFirstReport {
    avgTimeOnDock: TimeSpan;
    avgDelays: TimeSpan;
    avgPumpingRate: number;
    avgMonthlyAssignments: number;
    delays: KpiFirstReportDelayInfo[] = [];
    terminalStats: KpiFirstReportTerminalInfo[] = [];

    constructor() {
    }
}
