import { Pipe, PipeTransform } from "@angular/core";
import { VdpShoreTank } from "../models";

@Pipe({
    name: 'vdpShoreTanksFilterAndOrderPipe'
})
export class VdpShoreTanksFilterAndOrderPipe implements PipeTransform {
    transform(items: VdpShoreTank[]): any[] {
        // Order by step and remove any tanks that do not have volume (no gsv)
        return items.sort((a,b) => a.step > b.step ? 1 : -1).filter(i => i.gsv && i.gsv !== 0);
    }
}