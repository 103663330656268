import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ClientCompany } from 'src/app/models/system-config/client-company';

@Injectable({
    providedIn: 'root'
})
export class DataSharingService {
    customer: ClientCompany = new ClientCompany();
    selectedCustomer: BehaviorSubject<ClientCompany>;

    constructor() {
        this.selectedCustomer = new BehaviorSubject(this.customer);
    }

    setCustomer(value: ClientCompany){
        if (this.customer.id == value.id) return;

        this.customer = value;
        this.selectedCustomer.next(value);
    }
}