export class BlpColumnDefinition {
    name: string;
    lineText1: string;
    lineText2: string;
    lineText3: string;
    lineText4: string;
    lineText5: string;
    showLineIcon2: boolean = false;
    class1: string = BlpColumnStyleClasses.empty;
    class2: string = BlpColumnStyleClasses.empty;
    class3: string = BlpColumnStyleClasses.empty;
    class4: string = BlpColumnStyleClasses.empty;
    class5: string = BlpColumnStyleClasses.empty;
    allowOverflow1: boolean = false;
    allowOverflow2: boolean = false;
    allowOverflow3: boolean = false;
    allowOverflow4: boolean = false;
    allowOverflow5: boolean = false;
    showOnMobile: boolean = false;
    width: string = '100px';
}

export enum BlpColumnStyleClasses {
    empty = 'blp-header-empty',
    stepHeader = 'blp-header-step',
    lineAdjustemnetHeader = 'blp-header-line-adjustement',
    parcelRed = 'blp-header-parcel-red',
    parcelIndigo = 'blp-header-parcel-indigo',
    parcelBlack = 'blp-header-parcel-black',
    greyedOut = 'blp-header-greyed-out',
    existingCargo = 'blp-header-existing-cargo'
}