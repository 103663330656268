<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="stepNumber" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Step Number</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="step" [options]="stepOptions"[(ngModel)]="override.step" appendTo="body" placeholder="Select Step"></p-dropdown> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="parcel" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Parcel</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="parcel" [options]="parcelOptions"[(ngModel)]="override.parcelNumber" appendTo="body" placeholder="Select Parcel"></p-dropdown> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="value" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Override Value</label>
                <div class="p-col-12 p-md-10">
                    <input id="value" type="number" pInputText [(ngModel)]="override.value"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="reason" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Reason</label>
                <div class="p-col-12 p-md-10">
                    <input id="reason" type="text" pInputText [(ngModel)]="override.reason"> 
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>
