<div *ngIf="bou" class="p-grid">
    <div class="p-md-6 p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="vessel" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Vessel:</label>
                <div class="p-col-12 p-md-10">
                    <label id="vessel">{{bou.vessel}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="date" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Last Update:</label>
                <div class="p-col-12 p-md-10">
                    <label id="date">{{bou.modifiedOn | date : 'MMM d, yyyy, HH:mm' : '-1000' }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="bouType" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Report Type:</label>
                <div class="p-col-12 p-md-10">
                    <label id="bouType" class="bou-type">{{bou.bouType}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="attending" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Attending:</label>
                <div class="p-col-12 p-md-10">
                    <label id="attending">{{bou.attendingText}}</label>
                </div>
            </div>
        </div>
    </div>
</div>