import * as moment from 'moment';
import { AuditableEntity } from "../../auditable-entity";

export class BouActualLineItem extends AuditableEntity {
    bouId: string;
    tankId: string;
    tankDescription: string;
    startDate: Date;
    rate: number = 0;
    durationHours: number = 0;
    durationMinutes: number = 0;
    vaporHours: number = 0;
    vaporMinutes: number = 0;
    weatherHours: number = 0;
    weatherMinutes: number = 0;
    lineDisplacementHours: number = 0;
    lineDisplacementMinutes: number = 0;
    slowRateHours: number = 0;
    slowRateMinutes: number = 0;
    powerOutageHours: number = 0;
    powerOutageMinutes: number = 0;
    terminalReasonHours: number = 0;
    terminalReasonMinutes: number = 0;
    otherDelayHours: number = 0;
    otherDelayMinutes: number = 0;
    tankSwapHours: number = 0;
    tankSwapMinutes: number = 0;
    completionDate: Date;
    delivered: number = 0;
    variance: number = 0;
    comments: string;
    isCompleted: boolean;
    isPush: boolean;
    blpStep: number;

    constructor() {
        super();
    }

    get duration(): moment.Duration {
        return moment.duration({
            hours: this.durationHours ? this.durationHours : 0,
            minutes: this.durationMinutes ? this.durationMinutes : 0
        });
    }

    get vapor(): moment.Duration {
        return moment.duration({
            hours: this.vaporHours ? this.vaporHours : 0,
            minutes: this.vaporMinutes ? this.vaporMinutes : 0
        });
    }

    get weather(): moment.Duration {
        return moment.duration({
            hours: this.weatherHours ? this.weatherHours : 0,
            minutes: this.weatherMinutes ? this.weatherMinutes : 0
        });
    }

    get lineDisplacement(): moment.Duration {
        return moment.duration({
            hours: this.lineDisplacementHours ? this.lineDisplacementHours : 0,
            minutes: this.lineDisplacementMinutes ? this.lineDisplacementMinutes : 0
        });
    }

    get slowRate(): moment.Duration {
        return moment.duration({
            hours: this.slowRateHours ? this.slowRateHours : 0,
            minutes: this.slowRateMinutes ? this.slowRateMinutes : 0
        });
    }

    get powerOutage(): moment.Duration {
        return moment.duration({
            hours: this.powerOutageHours ? this.powerOutageHours : 0,
            minutes: this.powerOutageMinutes ? this.powerOutageMinutes : 0
        });
    }

    get terminalReason(): moment.Duration {
        return moment.duration({
            hours: this.terminalReasonHours ? this.terminalReasonHours : 0,
            minutes: this.terminalReasonMinutes ? this.terminalReasonMinutes : 0
        });
    }

    get otherDelay(): moment.Duration {
        return moment.duration({
            hours: this.otherDelayHours ? this.otherDelayHours : 0,
            minutes: this.otherDelayMinutes ? this.otherDelayMinutes : 0
        });
    }

    get tankSwap(): moment.Duration {
        return moment.duration({
            hours: this.tankSwapHours ? this.tankSwapHours : 0,
            minutes: this.tankSwapMinutes ? this.tankSwapMinutes : 0
        });
    }

    get totalDelays(): moment.Duration {
        return this.vapor
                .add(this.weather)
                .add(this.lineDisplacement)
                .add(this.slowRate)
                .add(this.powerOutage)
                .add(this.terminalReason)
                .add(this.otherDelay)
                .add(this.tankSwap);
    }

    static fromJson(bouActualLineItem: BouActualLineItem): BouActualLineItem {
        const newLineItem : BouActualLineItem = new BouActualLineItem();

        newLineItem.id = bouActualLineItem.id;
        newLineItem.bouId = bouActualLineItem.bouId;
        newLineItem.tankId = bouActualLineItem.tankId;
        newLineItem.tankDescription = bouActualLineItem.tankDescription;
        newLineItem.startDate = bouActualLineItem.startDate;
        newLineItem.rate = bouActualLineItem.rate;
        newLineItem.durationHours = bouActualLineItem.durationHours;
        newLineItem.durationMinutes = bouActualLineItem.durationMinutes;
        newLineItem.vaporHours = bouActualLineItem.vaporHours;
        newLineItem.vaporMinutes = bouActualLineItem.vaporMinutes;
        newLineItem.weatherHours = bouActualLineItem.weatherHours;
        newLineItem.weatherMinutes = bouActualLineItem.weatherMinutes;
        newLineItem.lineDisplacementHours = bouActualLineItem.lineDisplacementHours;
        newLineItem.lineDisplacementMinutes = bouActualLineItem.lineDisplacementMinutes;
        newLineItem.slowRateHours = bouActualLineItem.slowRateHours;
        newLineItem.slowRateMinutes = bouActualLineItem.slowRateMinutes;
        newLineItem.powerOutageHours = bouActualLineItem.powerOutageHours;
        newLineItem.powerOutageMinutes = bouActualLineItem.powerOutageMinutes;
        newLineItem.terminalReasonHours = bouActualLineItem.terminalReasonHours;
        newLineItem.terminalReasonMinutes = bouActualLineItem.terminalReasonMinutes;
        newLineItem.otherDelayHours = bouActualLineItem.otherDelayHours;
        newLineItem.otherDelayMinutes = bouActualLineItem.otherDelayMinutes;
        newLineItem.tankSwapHours = bouActualLineItem.tankSwapHours;
        newLineItem.tankSwapMinutes = bouActualLineItem.tankSwapMinutes;
        newLineItem.completionDate = bouActualLineItem.completionDate;
        newLineItem.delivered = bouActualLineItem.delivered;
        newLineItem.variance = bouActualLineItem.variance;
        newLineItem.comments = bouActualLineItem.comments;
        newLineItem.isCompleted = bouActualLineItem.isCompleted;
        newLineItem.isPush = bouActualLineItem.isPush;
        newLineItem.blpStep = bouActualLineItem.blpStep;

        return newLineItem;
    }
}