import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import * as moment from 'moment';
import { timer } from "rxjs";
import { first } from "rxjs/operators";
import { ApplicationInsightsService } from "./application-insights.service";

@Injectable({
    providedIn: 'root'
})
export class AppMessageService {
    
    private bannerKey = 'global-messages';
    private toastKey = 'global-toast-messages';
    private timerMilliseconds = 3000;

    constructor(
      private primengMessageService: MessageService,
      private applicationInsightsService: ApplicationInsightsService) {}

    errorMessage(summaryMessage: string, errorDetail: any, useToast: boolean = true, useSticky = true) {
        this.primengMessageService.clear();

        const messageKey: string = useToast ? this.toastKey : this.bannerKey;

        let errorAsString = JSON.stringify(errorDetail);

        // Capture DateTime and Tracking Id in message
        const dateString: string = moment().format('MM-DD-YYYY HH:mm');
        const trackingIdString: string = Math.random().toString(36).substring(7);
        errorAsString = this.truncateErrorAndSpacesMessage(errorAsString) + `\n Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
        let errorMessage: string = errorAsString;
        let errorMessageBeforeTruncation: string = errorMessage;

        if (errorDetail) {
            // Display user friendly error in message banner
            // If validation message it will be in inner error.Message property
            if (errorDetail.error && errorDetail.error.message) {
              errorMessageBeforeTruncation = errorDetail.error.message + `\n Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
              errorMessage = this.truncateErrorAndSpacesMessage(errorDetail.error.message) + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
            // } else if (errorDetail.error) {
            //   errorMessage = errorDetail.error + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
            } else if (errorDetail.error) {
              errorMessageBeforeTruncation = errorDetail.error + `\n Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
              errorMessage = this.truncateErrorAndSpacesMessage(errorDetail.error) + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
            } else if (errorDetail.message) {
              errorMessageBeforeTruncation = errorDetail.message + `\n Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
              errorMessage = this.truncateErrorAndSpacesMessage(errorDetail.message) + ` Date: ${dateString}, Error Message Id:: ${trackingIdString}`;
            }
        }
        
        this.primengMessageService.add({ key: messageKey, severity: 'error', summary: summaryMessage, detail: errorMessage, sticky: useSticky, closable: true });

        this.applicationInsightsService.trackExceptionMessage(errorMessageBeforeTruncation, trackingIdString);
    }

    // After a certain error detail size without space characters to wrap the text,
    // the primeng error toast breaks and cannot be closed.
    // This truncation is used to prevent it
    private truncateErrorAndSpacesMessage(message: string): string {
      if (message && message.length > 100) {
        message = message.substring(0, 100) + '...';
      }
      if (message && message.length) {
        if (message.length > 25) {
          const spaceBefore25 = message.slice(0, 24).indexOf(' ') > 0;
          if (!spaceBefore25) {
            message = message.slice(0, 24) + ' ' + message.slice(24);
          }
          if (message.length > 50) {
            const spaceBefore50 = message.slice(26, 49).indexOf(' ') > 0;
            if (!spaceBefore50) {
              message = message.slice(0, 49) + ' ' + message.slice(49);
            }
          }
        }
        
      }
      return message;
    }

    successMessage(summaryMessage: string, detailMessage: string, useToast: boolean = false): void {
        const messageKey: string = useToast ? this.toastKey : this.bannerKey;
        this.primengMessageService.clear();
        this.primengMessageService.add({ key: messageKey, severity: 'success', summary: summaryMessage, detail: detailMessage });
    
        // Auto Clear After Delay
        this.clearMessage(true);
    }
    
    infoMessage(summaryMessage: string, detailMessage: string, useToast: boolean = false): void {
        const messageKey: string = useToast ? this.toastKey : this.bannerKey;
        this.primengMessageService.clear();
        this.primengMessageService.add({ key: messageKey, severity: 'info', summary: summaryMessage, detail: detailMessage });
    
        // Auto Clear After Delay
        this.clearMessage(true);
    }
    
    warnMessage(summaryMessage: string, detailMessage: string, useToast: boolean = false, clearPrevMessages = true): void {
        const messageKey: string = useToast ? this.toastKey : this.bannerKey;
        if (clearPrevMessages) {
          this.primengMessageService.clear();
          this.primengMessageService.add({ key: messageKey, severity: 'warn', summary: summaryMessage, detail: detailMessage, sticky: true });
        } else {
          this.primengMessageService.add({ key: messageKey, severity: 'warn', summary: summaryMessage, detail: detailMessage, sticky: true });
        }
    
        // Do not auto clear warnings
    }
    
    errorNoUiMessage(error: string): void {
        console.error(error);
    
        this.applicationInsightsService.trackExceptionMessage(error, '');
    }
    
    clearMessage(onTimer = false): void {
        if (onTimer) {
          timer(this.timerMilliseconds).pipe(first())
            .subscribe(() => {
              this.primengMessageService.clear();
            });
        } else {
          this.primengMessageService.clear();
        }
    }
}