import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DowntimeInfoItem } from '../../models/customer/downtime-info-item';
import { ActiveJobDetails, JobStatuses, LoadingPlanItem, TimeTrackingReportFormats } from '../../models';
import { CustomerService } from '../../services/http-services/customer.service';
import { DownloadsService } from '../../services/http-services/downloads.service';


@Component({
  selector: 'app-customerjobsdetail',
  templateUrl: './jobs-detail.component.html',
  styleUrls: ['./jobs-detail.component.scss']
})
export class CustomerJobsDetailComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  jobDetail: ActiveJobDetails;
  loadingPlanCols: any[];
  loadingPlanSteps: LoadingPlanItem[];
  loadingStatus: string;
  currLoadingStep: number;
  totalLoadingSteps: number;
  downtimeSummaryCols: any[];
  downtimeSummaries: DowntimeInfoItem[];
  superIndex: number;
  superCount: number;
  superBack: boolean;
  superForward: boolean;
  superFullName: string;
  superPhone: string;
  superEmail: string;
  superProfilePic: string;
  shipName: string;
  lastUpdateDate: Date;

  constructor(
    private custService: CustomerService, 
    private downloadsService: DownloadsService,
    private route: ActivatedRoute,
    private store: Store<appState.State>,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.getActiveJobDetails();
    this.buildDowntimeColumns();
    this.buildLoadingPlansColumns();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  buildDowntimeColumns() {
    this.downtimeSummaryCols = [
      { field: 'category', header: 'Category', display: true },
      { field: 'type', header: 'Type', display: true },
      { field: 'duration', header: 'Duration', display: true }
    ];
  }

  buildLoadingPlansColumns() {
    this.loadingPlanCols = [
      { field: 'done', header: 'Done?', display: true },
      { field: 'step', header: 'Step', display: true },
      { field: 'nomVolume', header: 'Nominated', display: true },
      { field: 'loadedVolume', header: 'Loaded', display: true },
      { field: 'variance', header: 'Variance', display: true }
    ];
  }

  getActiveJobDetails() {
    this.route.params.subscribe(
      async (params) => {
        if (params && params['status']) {
          var active = params['status'] === JobStatuses.active.toLowerCase();
          if (params['id']) {
            this.store.dispatch(appActions.showSpinner());
            var data = await this.custService.getActiveJobDetail(params['id'])
              .toPromise()
              .catch(error => {
                this.store.dispatch(appActions.hideSpinner());
                this.appMessageService.errorMessage('Error getting Active Job Detail for Job Id: ' + params['id'], null);
                return null;
              });
            this.setActiveJobVariables(data);
            this.store.dispatch(appActions.hideSpinner());
          }
        } else {
          this.appMessageService.errorMessage('Missing job information', null);
        }
      }
    );
  }

  downloadBou() {
    if (this.jobDetail && this.jobDetail.jobId && this.jobDetail.jobNumber) {
      this.downloadsService.downloadBou(this.jobDetail.jobId, this.jobDetail.jobNumber);
    } else {
      this.appMessageService.warnMessage('Cannot download BOU', 'Job details are not known');
    }
  }

  downloadBlp() {
    if (this.jobDetail && this.jobDetail.jobId && this.jobDetail.jobNumber) {
      this.downloadsService.downloadLatestBlp(this.jobDetail.jobId, this.jobDetail.jobNumber);
    } else {
      this.appMessageService.warnMessage('Cannot download BLP', 'Job details are not known');
    }
  }

  downloadTimeTrackingPdf() {
    this.downloadsService.downloadTimeTracking(this.jobDetail.jobId, TimeTrackingReportFormats.PDF);
  }

  downloadBfr() {
    if (this.jobDetail && this.jobDetail.jobId && this.jobDetail.jobNumber) {
      this.downloadsService.downloadBfrAllTanks(this.jobDetail.jobId);
    } else {
      this.appMessageService.warnMessage('Cannot download BFR', 'Job details are not known');
    }
  }

  setActiveJobVariables(jobData: ActiveJobDetails) {
    this.jobDetail = jobData;
    this.loadingPlanSteps = this.jobDetail.loadingPlanItems;
    // Current Loading Step, Total Loading Steps and Loading Status
    this.totalLoadingSteps = this.loadingPlanSteps.length;
    this.currLoadingStep = 0;
    this.loadingPlanSteps.forEach((loadingPlanStep) => {
      loadingPlanStep.step = "Step " + loadingPlanStep.stepNumber.toString() + ": " + loadingPlanStep.tankName;
      console.log(loadingPlanStep.step);
      if (loadingPlanStep.done)
        this.currLoadingStep = loadingPlanStep.stepNumber;
    });
    if (this.currLoadingStep == 0)
      this.loadingStatus = "NOT STARTED";
    else if (this.currLoadingStep < this.totalLoadingSteps)
      this.loadingStatus = "LOADING";
    else
      this.loadingStatus = "COMPLETED";
    this.downtimeSummaries = this.jobDetail.downtimeInfoItems;
    this.shipName = this.jobDetail.ship ? this.jobDetail.ship.name : '';

    this.superCount = this.jobDetail.superintendents.length;
    if (this.superCount == 1) {
      this.superBack = true;
      this.superForward = true;
      this.superIndex = 0;
      this.superFullName = this.jobDetail.superintendents[this.superIndex].firstName + " " + this.jobDetail.superintendents[this.superIndex].lastName;
      if (this.jobDetail.superintendents[this.superIndex].phone == null)
        this.superPhone = "Not Available";
      else
        this.superPhone = this.jobDetail.superintendents[this.superIndex].phone;
      if (this.jobDetail.superintendents[this.superIndex].email == null)
        this.superEmail = "Not Available";
      else
        this.superEmail = this.jobDetail.superintendents[this.superIndex].email;
      this.superProfilePic = "../../../assets/images/users/" +
        this.jobDetail.superintendents[this.superIndex].firstName.replace(/\s/g, "").toLowerCase()
        + "-" + this.jobDetail.superintendents[this.superIndex].lastName.replace(/\s/g, "").toLowerCase()
        + ".PNG";
    }
    else if (this.superCount > 1) {
      this.superBack = true;
      this.superForward = false;
      this.superIndex = 0;
      this.superFullName = this.jobDetail.superintendents[this.superIndex].firstName + " " + this.jobDetail.superintendents[this.superIndex].lastName;
      if (this.jobDetail.superintendents[this.superIndex].phone == null)
        this.superPhone = "Not Available";
      else
        this.superPhone = this.jobDetail.superintendents[this.superIndex].phone;
      if (this.jobDetail.superintendents[this.superIndex].email == null)
        this.superEmail = "Not Available";
      else
        this.superEmail = this.jobDetail.superintendents[this.superIndex].email;
      this.superProfilePic = "../../../assets/images/users/" +
        this.jobDetail.superintendents[this.superIndex].firstName.toLowerCase()
        + "-" + this.jobDetail.superintendents[this.superIndex].lastName.toLowerCase()
        + ".PNG";
    }
    else {
      this.superBack = true;
      this.superForward = true;
      this.superFullName = "Not Available";
      this.superPhone = "Not Available";
      this.superEmail = "Not Available";
    }
    console.log(this.superProfilePic);
  }

  prevSuper() {
    this.superIndex -= 1;
    if (this.superIndex == 0) {
      this.superBack = true;
      this.superForward = false;
      this.superFullName = this.jobDetail.superintendents[this.superIndex].firstName + " " + this.jobDetail.superintendents[this.superIndex].lastName;
      if (this.jobDetail.superintendents[this.superIndex].phone == null)
        this.superPhone = "Not Available";
      else
        this.superPhone = this.jobDetail.superintendents[this.superIndex].phone;
      if (this.jobDetail.superintendents[this.superIndex].email == null)
        this.superEmail = "Not Available";
      else
        this.superEmail = this.jobDetail.superintendents[this.superIndex].email;
      this.superProfilePic = "../../../assets/images/users/" +
        this.jobDetail.superintendents[this.superIndex].firstName.replace(/\s/g, "").toLowerCase()
        + "-" + this.jobDetail.superintendents[this.superIndex].lastName.replace(/\s/g, "").toLowerCase()
        + ".PNG";
    }
  }

  nextSuper() {
    this.superIndex += 1;
    if (this.superIndex == (this.superCount - 1)) {
      this.superBack = false;
      this.superForward = true;
      this.superFullName = this.jobDetail.superintendents[this.superIndex].firstName + " " + this.jobDetail.superintendents[this.superIndex].lastName;
      if (this.jobDetail.superintendents[this.superIndex].phone == null)
        this.superPhone = "Not Available";
      else
        this.superPhone = this.jobDetail.superintendents[this.superIndex].phone;
      if (this.jobDetail.superintendents[this.superIndex].email == null)
        this.superEmail = "Not Available";
      else
        this.superEmail = this.jobDetail.superintendents[this.superIndex].email;
      this.superProfilePic = "../../../assets/images/users/" +
        this.jobDetail.superintendents[this.superIndex].firstName.replace(/\s/g, "").toLowerCase()
        + "-" + this.jobDetail.superintendents[this.superIndex].lastName.replace(/\s/g, "").toLowerCase()
        + ".PNG";
    }
  }
}
