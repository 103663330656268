import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Bfr, Bou, BouActualLineItem, BouEstimationLineItem, BouTypes, JobBlpRevision, JobOverview, TimeTrackingEventLog, User, Tank, BlpLineItem } from "../models";
import { SystemTriggerEventIds } from "../models/system-config/system-trigger-event-ids";
import * as appState from '../state';
import * as appActions from '../state/app.actions';

@Injectable({
    providedIn: 'root'
})
export class BouManagementService {
    constructor(private store: Store<appState.State>) {}

    createNewBou(jobId: string): Bou {
        const bou = new Bou();
        bou.jobId = jobId;
        return bou;
    }

    processBou(currentBou: Bou, jobOverview: JobOverview, latestBlp: JobBlpRevision, timeTrackingEventLogs: TimeTrackingEventLog[], bfr: Bfr, tanks: Tank[]): Bou {

        // For now always disabling UseNominations
        currentBou.useNominatedQuantities = false;

        if (!currentBou || currentBou.jobId !== jobOverview.jobId) {
            currentBou = this.createNewBou(jobOverview.jobId);
        }

        currentBou = this.setBouHeaderDetail(currentBou, jobOverview);

        const requiredTankIds: string[] = !latestBlp ? [] : latestBlp.lineItems?.map(l => l.tankId);
        const requiredTankIdsDistinct: string[] = requiredTankIds.filter((n, i) => requiredTankIds.indexOf(n) === i);
        const eventLogs = timeTrackingEventLogs?.filter(l => l.active);
        currentBou = this.setKeyTimes(currentBou, eventLogs, requiredTankIdsDistinct);

        currentBou = this.updateOriginLineItems(currentBou, latestBlp, eventLogs, bfr, tanks);

        currentBou = this.setObq(currentBou, latestBlp, eventLogs);

        currentBou = this.setShoreFigures(currentBou, eventLogs);

        currentBou = this.setVesselReporting(currentBou);

        return currentBou;
    }

    // Needs to be an observable to be used in mergeMap of app effects use
    silentlyProcessBou(): Observable<any> {
        return new Observable<any>(subscriber => {
            this.store.pipe(select(appState.getSelectedJob)).pipe(take(1)).subscribe(async job => {
                if (job) {
                    var tanks = await this.store.pipe(select(appState.getTanks)).pipe(take(1)).toPromise();
                    const bou = this.processBou(Bou.fromJson(job.bou), job.overview, job.latestBlp, job.timeTrackingEventLogs, job.bfr, tanks);
                    const setBouProperties = new appActions.SetJobActionProperties<Bou>();
                    setBouProperties.id = bou.id;
                    setBouProperties.jobId = job.id;
                    setBouProperties.data = bou;
                    setBouProperties.silent = true;
                    this.store.dispatch(appActions.setBou({ bou: setBouProperties }));
                }
                subscriber.complete();
            })
        });
    }

    buildBouStatusOptions(): SelectItem[] {
        return [
            { label: 'Vessel continues proportional distribution of cargo, according to plan. ', value: 'Vessel continues proportional distribution of cargo, according to plan. ' },
            { label: 'Arrival operations have completed, and awaiting terminal readiness to commence loading.', value: 'Arrival operations have completed, and awaiting terminal readiness to commence loading.' },
            { label: 'Vessel continues proportional distribution of cargo, according to plan. ', value: 'Vessel continues proportional distribution of cargo, according to plan. ' },
            { label: 'Cargo loading continues, but now at a reduced rate, for stripping.', value: 'Cargo loading continues, but now at a reduced rate, for stripping.' },
            { label: 'Cargo loading currently suspended, awaiting terminal readiness.', value: 'Cargo loading currently suspended, awaiting terminal readiness.' },
            { label: 'Cargo loading currently suspended, while terminal changes shore tanks.', value: 'Cargo loading currently suspended, while terminal changes shore tanks.' },
            { label: 'Cargo loading currently suspended, due to Vapor Unit issues.', value: 'Cargo loading currently suspended, due to Vapor Unit issues.' },
            { label: 'Cargo loading currently suspended, due to inclement weather.', value: 'Cargo loading currently suspended, due to inclement weather.' },
            { label: 'Bulk cargo operations have completed, awaiting final line push to the vessel.', value: 'Bulk cargo operations have completed, awaiting final line push to the vessel.' },
            { label: 'Cargo operations have completed, and departure ullaging and sampling are in progress.  We will revert with sailing figures soonest.', value: 'Cargo operations have completed, and departure ullaging and sampling are in progress.  We will revert with sailing figures soonest.' }
        ];
    }

    setBouHeaderDetail(currentBou: Bou, jobOverview: JobOverview): Bou {
        currentBou.bouDate = new Date();

        if (jobOverview) {
            currentBou.shipId = jobOverview.ship?.id;
            currentBou.vessel = jobOverview.ship?.name;
            currentBou.attendingText = jobOverview.superintendents ?
                jobOverview.superintendents.map(s => User.fromUserProperties(s).displayName + ' ' + (s.phone ? s.phone : '')).join(', ') :
                '';
            currentBou.attendingIds = jobOverview.superintendents ?
                jobOverview.superintendents.map(s => s.id).join(', ') :
                '';
            currentBou.bouType = this.getBouType(currentBou);
            // Report Contacts are now on Job Overview only
            // currentBou.reportContacts = jobOverview.reportContacts;
        }
        return currentBou;
    }

    setObq(currentBou: Bou, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[]): Bou {
        currentBou.obq = currentBou.vesselReportingReceived ? currentBou.vesselReportingReceived : currentBou.obq;
        if (!currentBou.obq) {
            currentBou.obq = 0;
        }
        currentBou.percentLoaded = this.setPercentLoaded(currentBou, blp);

        const estimatedLineItems =[...currentBou.estimationLineItems];
        if (estimatedLineItems && estimatedLineItems.length) {
            currentBou.etcDate = estimatedLineItems?.sort((a, b) => a.completionDate > b.completionDate ? -1 : 1)[0].completionDate;
        }

        if (currentBou.loadingFromTankId && estimatedLineItems && estimatedLineItems.length) {
            currentBou.etcThisStep = currentBou.estimationLineItems.find(l => l.tankId === currentBou.loadingFromTankId)?.completionDate;
        }

        if (currentBou.firstLine) {
            const firstLine = moment(currentBou.firstLine);
            // If last line is known, set as difference between last and first line
            // otherwise difference between now and first line
            const lastLineEvent = eventLogs.find(e => e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.iorReleased && e.active);
            if (lastLineEvent) {
                const lastLine = moment(lastLineEvent.startTime);
                currentBou.timeAtDockHours = this.getHoursPortionOfNumberOfHours(moment.duration(lastLine.diff(firstLine)).asHours());
                currentBou.timeAtDockMinutes = this.getMinutesPortionOfNumberOfHours(moment.duration(lastLine.diff(firstLine)).asHours());
            } else {
                const now = moment();
                currentBou.timeAtDockHours = this.getHoursPortionOfNumberOfHours(moment.duration(now.diff(firstLine)).asHours());
                currentBou.timeAtDockMinutes = this.getMinutesPortionOfNumberOfHours(moment.duration(now.diff(firstLine)).asHours());
            }
        } else {
            currentBou.timeAtDockHours = 0;
            currentBou.timeAtDockMinutes = 0;
        }

        return currentBou;
    }

    setKeyTimes(currentBou: Bou, eventLogs: TimeTrackingEventLog[], requiredTankIds: string[]): Bou {
        currentBou.firstLine = null;
        currentBou.allFast = null;
        currentBou.gangwaySecure = null;
        currentBou.keyMeetingCompleted = null;
        currentBou.vesselTanksInspectionCompleted = null;
        currentBou.arrivalCalculationsCompleted = null;
        currentBou.cargoVaporArmsHosesConnected = null;
        currentBou.loadingCommenced = null;
        currentBou.loadingCompleted = null;
        currentBou.ullagingCompleted = null;
        currentBou.cargoCalcualationsCompleted = null;
        currentBou.armsHosesDisconnected = null;
        currentBou.pilotSet = null;
        if (eventLogs && eventLogs.length) {
            eventLogs.forEach(l => {
                if (l.timeTrackingEventId.toUpperCase()) {
                    switch(l.timeTrackingEventId.toUpperCase().toUpperCase()) {
                        // TODO: Add more system event triggers
                        case SystemTriggerEventIds.firstLineCommencedMooring: {
                            // currentBou.firstLine = l.startTime ? l.startTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.allFast: {
                            // currentBou.allFast = l.startTime ? l.startTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.gangwaySecure: {
                            // currentBou.gangwaySecure = l.startTime ? l.startTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.keyMeeting: {
                            currentBou.keyMeetingCompleted = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.vesselTanksInspected: {
                            currentBou.vesselTanksInspectionCompleted = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.arrivalCalculations: {
                            currentBou.arrivalCalculationsCompleted = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.cargoVaporArmsHosesConnected: {
                            currentBou.cargoVaporArmsHosesConnected = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.finishedLoadingFromShortTanks: {
                            currentBou.loadingCommenced = this.getTankEventStartedDate(eventLogs, requiredTankIds, SystemTriggerEventIds.finishedLoadingFromShortTanks);
                            currentBou.loadingCompleted = this.getAllTankEventsCompleteDate(eventLogs, requiredTankIds, SystemTriggerEventIds.finishedLoadingFromShortTanks);
                            break;
                        }
                        case SystemTriggerEventIds.ullaging: {
                            // currentBou.ullagingCompleted = this.getAllTankEventsCompleteDate(eventLogs, requiredTankIds, SystemTriggerEventIds.ullaging);
                            currentBou.ullagingCompleted = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.cargoCalculations: {
                            currentBou.cargoCalcualationsCompleted = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.armsHosesDisconnected: {
                            currentBou.armsHosesDisconnected = l.endTime ? l.endTime : null;
                            break;
                        }
                        case SystemTriggerEventIds.pilotSet: {
                            // currentBou.pilotSet = l.startTime ? l.startTime : null; // Not a duration
                            break;
                        }
                        default:
                            break;
                    }
                }
            })
        }
        return currentBou;
    }

    updateOriginLineItems(currentBou: Bou, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[], bfr: Bfr, tanks: Tank[]): Bou {
        if (blp && blp.lineItems) {
            const steps = [...blp.lineItems].sort((a, b) => a.step > b.step ? 1 : -1).map(l => l.step);
            if (this.tanksChanged(currentBou, steps, blp.lineItems)) {
                if (this.areNewBlpTanks(currentBou, steps)) {
                    // Add new origin line items to both estimation and actual from Blp
                    steps
                        .filter(s => !currentBou.estimationLineItems.some(e => e.blpStep === s))
                        .forEach(s => {
                            // Process Actuals first
                            const newActualLineItem = this.createActualLineItem(currentBou, s, blp, eventLogs, bfr, tanks);
                            currentBou.actualLineItems.push(newActualLineItem);

                            const newEstimatedLineItem = this.createEstimateLineItem(currentBou, s, blp, eventLogs, tanks);
                            currentBou.estimationLineItems.push(newEstimatedLineItem);

                        })
                } else {
                    // Remove origins from estimation and actual lineitems
                    currentBou.estimationLineItems = currentBou.estimationLineItems.filter(e => steps.includes(e.blpStep));
                    currentBou.actualLineItems = currentBou.actualLineItems.filter(a => steps.includes(a.blpStep));
                }
            } else {
                // Recalculate values on existing line items
                // Process actuals first
                currentBou.estimationLineItems.sort((a, b) => a.blpStep > b.blpStep ? 1 : -1);
                currentBou.actualLineItems.sort((a, b) => a.blpStep > b.blpStep ? 1 : -1);

                if (currentBou.actualLineItems && currentBou.actualLineItems.length) {
                    currentBou.actualLineItems.forEach(l => {
                        l = this.processActualLineItem(currentBou, l.blpStep, blp, eventLogs, bfr, tanks);
                    })
                }

                if (currentBou.estimationLineItems && currentBou.estimationLineItems.length) {
                    currentBou.estimationLineItems.forEach(l => {
                        l = this.processEstimateLineItem(currentBou, l.blpStep, blp, eventLogs, tanks);
                    })
                }
            }

            currentBou.estimationLineItems.sort((a, b) => a.blpStep > b.blpStep ? 1 : -1);
            currentBou.actualLineItems.sort((a, b) => a.blpStep > b.blpStep ? 1 : -1);

            // Process totals
            const totalEstimatedDuration = this.setEstimatedDurationTotal(currentBou);
            currentBou.estimationDurationHoursTotal = totalEstimatedDuration.days()*24 + totalEstimatedDuration.hours();
            currentBou.estimationDurationMinutesTotal = totalEstimatedDuration.minutes();
            currentBou.estimationNominatedTotal = this.setEstimatedNominatedTotal(currentBou);

            const totalActualDuration = this.setActualDurationTotal(currentBou);
            currentBou.actualDurationHoursTotal = totalActualDuration.days()*24 + totalActualDuration.hours();
            currentBou.actualDurationMinutesTotal = totalActualDuration.minutes();
            const totalActualVapor = this.setActualVaporTotal(currentBou);
            currentBou.actualVaporDelayHoursTotal = totalActualVapor.days()*24 + totalActualVapor.hours();
            currentBou.actualVaporDelayMinutesTotal = totalActualVapor.minutes();
            const totalActualWeather = this.setActualWeatherTotal(currentBou);
            currentBou.actualWeatherDelayHoursTotal = totalActualWeather.days()*24 + totalActualWeather.hours();
            currentBou.actualWeatherDelayMinutesTotal = totalActualWeather.minutes();
            const totalActualLineDisplacement = this.setActualLineDisplacementTotal(currentBou);
            currentBou.actualLineDisplacementDelayHoursTotal = totalActualLineDisplacement.days()*24 + totalActualLineDisplacement.hours();
            currentBou.actualLineDisplacementDelayMinutesTotal = totalActualLineDisplacement.minutes();
            const totalActualSlowRate = this.setActualRateSlowdownTotal(currentBou);
            currentBou.actualRateSlowdownDelayHoursTotal = totalActualSlowRate.days()*24 + totalActualSlowRate.hours();
            currentBou.actualRateSlowdownDelayMinutesTotal = totalActualSlowRate.minutes();
            const totalActualPowerOutage = this.setActualPowerOutageTotal(currentBou);
            currentBou.actualPowerOutageDelayHoursTotal = totalActualPowerOutage.days()*24 + totalActualPowerOutage.hours();
            currentBou.actualPowerOutageDelayMinutesTotal = totalActualPowerOutage.minutes();
            const totalActualTerminalReason = this.setActualTerminalReasonTotal(currentBou);
            currentBou.actualTerminalReasonDelayHoursTotal = totalActualTerminalReason.days()*24 + totalActualTerminalReason.hours();
            currentBou.actualTerminalReasonDelayMinutesTotal = totalActualTerminalReason.minutes();
            const totalActualOtherDelay = this.setActualOtherDelayTotal(currentBou);
            currentBou.actualOtherDelayHoursTotal = totalActualOtherDelay.days()*24 + totalActualOtherDelay.hours();
            currentBou.actualOtherDelayMinutesTotal = totalActualOtherDelay.minutes();
            const totalActualTankSwap = this.setActualTankSwapTotal(currentBou);
            currentBou.actualTankSwapHoursTotal = totalActualTankSwap.days()*24 + totalActualTankSwap.hours();
            currentBou.actualTankSwapMinutesTotal = totalActualTankSwap.minutes();
            currentBou.actualDeliveredTotal = this.setActualDeliveredTotal(currentBou);
            currentBou.actualVarianceTotal = this.setActualVarianceTotal(currentBou);
        }

        return Bou.fromJson(currentBou);
    }

    setShoreFigures(currentBou: Bou, eventLogs: TimeTrackingEventLog[]): Bou {
        const grossDuration = this.setGrossLoadingTimeDuration(currentBou, eventLogs);
        currentBou.grossLoadingTimeHours = grossDuration.days()*24 + grossDuration.hours();
        currentBou.grossLoadingTimeMinutes = grossDuration.minutes();
        currentBou.grossLoadingTimeShoreFigures = this.setGrossLoadingShoreFigures(currentBou);
        currentBou.grossLoadingTimeRate = this.setGrossLoadingRate(currentBou);

        const netDuration = this.setNetLoadingTimeDuration(currentBou);
        currentBou.netLoadingTimeHours = netDuration.days()*24 + netDuration.hours();
        currentBou.netLoadingTimeMinutes = netDuration.minutes();
        currentBou.netLoadingTimeShoreFigures = this.setNetLoadingShoreFigures(currentBou);
        currentBou.netLoadingTimeRate = this.setNetLoadingRate(currentBou);

        return currentBou;
    }

    setVesselReporting(currentBou: Bou): Bou {
        currentBou.vesselReportingAdjusted = this.setVesselReportingAdjusted(currentBou);
        currentBou.vesselReportingDifference = this.setVesselReportingDifference(currentBou);
        currentBou.vesselReportingApparentDifferencePercent = this.setVesselReportingApparentDifferencePercent(currentBou);

        return currentBou;
    }

    private getBouType(currentBou: Bou): string {
        return currentBou.isCompleted ? BouTypes.vesselSailingFigures : BouTypes.loadingOperationsUpdate;
    }

    private setPercentLoaded(currentBou: Bou, blp: JobBlpRevision): number {
        // From Excel: IF(U2=TRUE,0.000001,IF(BOU!E14>BOU!K42,(BOU!E14-BLP!G17)*100/BOU!K42,BOU!E14*100/BOU!K42))
        // U2: IsError(BOU!E14/BOU!K42)
        // BOU!E14: O.B.Q.
        // BOU!K42: Estimated Nominations Total
        // BLP!G17: Volume in Vessel Tank before BLP steps begin
        //          In new blp => any positive line adjustement in step 1

        let percentLoaded = 0;

        if (currentBou.obq && currentBou.estimationNominatedTotal) {
            let blpAdjustment = 0;
            if (blp && blp.lineItems) {
                const step1 = blp.lineItems.find(l => l.step === 1);
                blpAdjustment = step1 && (step1.lineAdjustment > 0) ? step1.lineAdjustment : 0;
            }
            percentLoaded = Math.round((currentBou.obq - blpAdjustment)/currentBou.estimationNominatedTotal * 100);
            if (percentLoaded > 100) {
                percentLoaded = 100;
            }
        }

        return percentLoaded;
    }

    private getTankEventStartedDate(eventLogs: TimeTrackingEventLog[], requiredTankIds: string[], targetEventId: string): Date {
        let anyTankStartedDate: Date = null;
        if (targetEventId && eventLogs && eventLogs.length && requiredTankIds && requiredTankIds.length) {
            const targetEvents = eventLogs.filter(e => e.timeTrackingEventId.toUpperCase() === targetEventId && requiredTankIds.includes(e.assignedTankId) && e.startTime);
            if (targetEvents && targetEvents.length) {
                anyTankStartedDate = targetEvents.sort((a, b) => a.startTime > b.startTime ? 1 : -1)[0].startTime;
            }
        }

        return anyTankStartedDate;
    }

    private getAllTankEventsCompleteDate(eventLogs: TimeTrackingEventLog[], requiredTankIds: string[], targetEventId: string): Date {
        let allTanksCompleteDate: Date = null;
        if (targetEventId && eventLogs && eventLogs.length && requiredTankIds && requiredTankIds.length) {
            const targetEvents = eventLogs.filter(e => e.timeTrackingEventId.toUpperCase() === targetEventId && requiredTankIds.includes(e.assignedTankId) && e.endTime && e.active);
            if (targetEvents && targetEvents.length) {
                //MAR 2022-01-06: Update validation to determine if a Job completed loading the tanks:
                // a) Get the Tank IDs from the latest BLP (must be distinct). Comes in parameter "requiredTankIds"
                // b) From Time Tracking (parameter "eventLogs"), filter the event types "Loading from ST" (parameter "targetEventId"). Then get the distinct Tank IDs
                // c) Compare a) and b)
                // Translation: If every Tank listed in the BLP has at least one "Loading from ST" event, then assume that the loading is finished, get the allTanksCompleteDate.
                const tanksInTargetEvents = targetEvents.map(e => e.assignedTankId);
                const tanksInTargetEventsDistinct = tanksInTargetEvents.filter((n, i) => tanksInTargetEvents.indexOf(n) === i);
                if (tanksInTargetEventsDistinct.length === requiredTankIds.length) {
                    allTanksCompleteDate = targetEvents.sort((a, b) => a.endTime > b.endTime ? -1 : 1)[0].endTime;
                }
            }
        }
        return allTanksCompleteDate;
    }

    private tanksChanged(currentBou: Bou, currentSteps: number[], blpLineItems: BlpLineItem[]): boolean {
        return this.areNewBlpTanks(currentBou, currentSteps) ||
               this.areRemovedBlpTanks(currentBou, currentSteps);
            //    this.haveBlpTanksSwapped(currentBou, blpLineItems);
    }

    private areNewBlpTanks(currentBou: Bou, currentSteps: number[]): boolean {
        let newTanksFound: boolean = false;
        if (currentSteps && currentSteps.length) {
            if (!currentBou.estimationLineItems || !currentBou.estimationLineItems.length) {
                return true;
            } else {
                // Check for added tanks to BLP
                currentSteps.forEach(s => {
                    // New tank not defined in BOU
                    if (!currentBou.estimationLineItems.some(e => e.blpStep === s)) {
                        newTanksFound = true;
                    }
                })
            }
        }
        return newTanksFound;
    }

    private areRemovedBlpTanks(currentBou: Bou, currentSteps: number[]): boolean {
        let tanksRemoved: boolean = false;
        if (currentBou.estimationLineItems && currentBou.estimationLineItems.length) {
            if (!currentSteps || !currentSteps.length) {
                return true;
            } else {
                // Check for tanks removed from BLP still in BFR
                currentBou.estimationLineItems.forEach(e => {
                    if (!currentSteps.some(s => s === e.blpStep)) {
                        tanksRemoved = true;
                    }
                })
            }
        }
        return tanksRemoved;
    }

    // private haveBlpTanksSwapped(currentBou: Bou, blpLineItems: BlpLineItem[]): boolean {
    //     let tanksSwapped = false;
    //     if (currentBou.estimationLineItems && currentBou.estimationLineItems.length && blpLineItems && blpLineItems.length) {
    //         currentBou.estimationLineItems.forEach(e => {
    //             const blpLineItem = blpLineItems.find(b => b.step === e.blpStep);
    //             if (blpLineItem && blpLineItem.tankId !== e.tankId) {
    //                 tanksSwapped = true;
    //             }
    //         })
    //     }
    //     return tanksSwapped;

    // }

    private createActualLineItem(currentBou: Bou, targetStep: number, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[], bfr: Bfr, tanks: Tank[]): BouActualLineItem {
        const lineItem: BouActualLineItem = new BouActualLineItem();
        lineItem.bouId = currentBou.id;
        lineItem.tankId = blp?.lineItems?.find(l => l.step === targetStep)?.tankId;
        var tank = tanks.find(t => t.id === lineItem.tankId);
        lineItem.tankDescription = tank?.name;

        lineItem.blpStep = targetStep;

        lineItem.startDate = this.setActualStartDate(lineItem.tankId, eventLogs, targetStep);
        lineItem.completionDate = this.setActualCompletedDate(lineItem.tankId, eventLogs, targetStep);
        lineItem.isPush = this.setActualIsPush(targetStep, blp);
        lineItem.isCompleted = this.setActualIsCompleted(lineItem.tankId, eventLogs, targetStep);

        // Barrels Calcs (requires isPush and isCompleted already set)
        lineItem.delivered = this.setActualDelivered(lineItem, targetStep, currentBou.useNominatedQuantities, bfr);
        lineItem.variance = this.setActualVariance(lineItem, targetStep, bfr);

        let scopeEvents: TimeTrackingEventLog[];
        if (targetStep === 1) {
            scopeEvents = eventLogs.filter(e =>
                e.assignedTankId === lineItem.tankId &&
                e.blpStepNumber === targetStep &&
                e.startTime >= lineItem.startDate);
            if (!scopeEvents)
            {
                scopeEvents = [];
            }
        }
        else {
            scopeEvents = eventLogs
        }
        //Delays
        const vaporDuration = this.setActualVaporDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.vaporHours = vaporDuration.days()*24 + vaporDuration.hours();
        lineItem.vaporMinutes = vaporDuration.minutes();
        const weatherDuration = this.setActualWeatherDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.weatherHours = weatherDuration.days()*24 + weatherDuration.hours();
        lineItem.weatherMinutes = weatherDuration.minutes();
        const lineDisplacementDuration = this.setActualLineDisplacementDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.lineDisplacementHours = lineDisplacementDuration.days()*24 + lineDisplacementDuration.hours();
        lineItem.lineDisplacementMinutes = lineDisplacementDuration.minutes();
        const rateSlowdownDuration = this.setActualRateSlowdownDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.slowRateHours = rateSlowdownDuration.days()*24 + rateSlowdownDuration.hours();
        lineItem.slowRateMinutes = rateSlowdownDuration.minutes();
        const powerOutageDuration = this.setActualPowerOutageDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.powerOutageHours = powerOutageDuration.days()*24 + powerOutageDuration.hours();
        lineItem.powerOutageMinutes = powerOutageDuration.minutes();
        const terminalReasonDuration = this.setActualTerminalReasonDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.terminalReasonHours = terminalReasonDuration.days()*24 + terminalReasonDuration.hours();
        lineItem.terminalReasonMinutes = terminalReasonDuration.minutes();
        const otherDuration = this.setActualOtherDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.otherDelayHours = otherDuration.days()*24 + otherDuration.hours();
        lineItem.otherDelayMinutes = otherDuration.minutes();
        const tankSwapDuration = this.setActualTankSwapDuration(lineItem.tankId, scopeEvents, targetStep);
        lineItem.tankSwapHours = tankSwapDuration.days()*24 + tankSwapDuration.hours();
        lineItem.tankSwapMinutes = tankSwapDuration.minutes();
        const duration = this.setActualDuration(lineItem);  //must set all delays first
        lineItem.durationHours = duration.days()*24 + duration.hours();
        lineItem.durationMinutes = duration.minutes();

        lineItem.rate = this.setActualRate(lineItem, targetStep, blp);  // Must be done after delivered and duration

        return lineItem;
    }

    private processActualLineItem(currentBou: Bou, targetStep: number, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[], bfr: Bfr, tanks: Tank[]): BouActualLineItem {
        const currentLineItem = currentBou.actualLineItems?.find(l => l.blpStep === targetStep)

        currentLineItem.blpStep = targetStep;
        currentLineItem.tankId = blp?.lineItems?.find(l => l.step === targetStep)?.tankId;
        currentLineItem.tankDescription = tanks.find(t => t.id === currentLineItem.tankId)?.name;
        currentLineItem.startDate = this.setActualStartDate(currentLineItem.tankId, eventLogs, targetStep);
        currentLineItem.completionDate = this.setActualCompletedDate(currentLineItem.tankId, eventLogs, targetStep);
        currentLineItem.isPush = this.setActualIsPush(targetStep, blp);
        currentLineItem.isCompleted = this.setActualIsCompleted(currentLineItem.tankId, eventLogs, targetStep);

        // Barrels Calcs (requires isPush and isCompleted already set)
        currentLineItem.delivered = this.setActualDelivered(currentLineItem, targetStep, currentBou.useNominatedQuantities, bfr);
        currentLineItem.variance = this.setActualVariance(currentLineItem, targetStep, bfr);

        //Delays
        this.CalculateDelays(targetStep, eventLogs, currentLineItem);

        currentLineItem.rate = this.setActualRate(currentLineItem, targetStep, blp); // Must be done after delivered and duration
        return currentLineItem;
    }

    private CalculateDelays(targetStep: number, eventLogs: TimeTrackingEventLog[], currentLineItem: BouActualLineItem, useStartDate: boolean = false) {
        let scopeEvents: TimeTrackingEventLog[];
        if (targetStep === 1 || useStartDate) {
            scopeEvents = eventLogs.filter(e => e.assignedTankId === currentLineItem.tankId &&
                e.blpStepNumber === targetStep &&
                e.startTime >= currentLineItem.startDate);
            if (!scopeEvents) {
                scopeEvents = [];
            }
        }
        else {
            scopeEvents = eventLogs;
        }
        const vaporDuration = this.setActualVaporDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.vaporHours = vaporDuration.days() * 24 + vaporDuration.hours();
        currentLineItem.vaporMinutes = vaporDuration.minutes();
        const weatherDuration = this.setActualWeatherDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.weatherHours = weatherDuration.days() * 24 + weatherDuration.hours();
        currentLineItem.weatherMinutes = weatherDuration.minutes();
        const lineDisplacementDuration = this.setActualLineDisplacementDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.lineDisplacementHours = lineDisplacementDuration.days() * 24 + lineDisplacementDuration.hours();
        currentLineItem.lineDisplacementMinutes = lineDisplacementDuration.minutes();
        const rateSlowdownDuration = this.setActualRateSlowdownDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.slowRateHours = rateSlowdownDuration.days() * 24 + rateSlowdownDuration.hours();
        currentLineItem.slowRateMinutes = rateSlowdownDuration.minutes();
        const powerOutageDuration = this.setActualPowerOutageDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.powerOutageHours = powerOutageDuration.days() * 24 + powerOutageDuration.hours();
        currentLineItem.powerOutageMinutes = powerOutageDuration.minutes();
        const terminalReasonDuration = this.setActualTerminalReasonDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.terminalReasonHours = terminalReasonDuration.days() * 24 + terminalReasonDuration.hours();
        currentLineItem.terminalReasonMinutes = terminalReasonDuration.minutes();
        const otherDuration = this.setActualOtherDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.otherDelayHours = otherDuration.days() * 24 + otherDuration.hours();
        currentLineItem.otherDelayMinutes = otherDuration.minutes();
        const tankSwapDuration = this.setActualTankSwapDuration(currentLineItem.tankId, scopeEvents, targetStep);
        currentLineItem.tankSwapHours = tankSwapDuration.days() * 24 + tankSwapDuration.hours();
        currentLineItem.tankSwapMinutes = tankSwapDuration.minutes();
        const duration = this.setActualDuration(currentLineItem); //must set all delays first
        currentLineItem.durationHours = duration.days() * 24 + duration.hours();
        currentLineItem.durationMinutes = duration.minutes();
    }

    private createEstimateLineItem(currentBou: Bou, targetBlpStep: number, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[], tanks: Tank[]): BouEstimationLineItem {
        const lineItem: BouEstimationLineItem = new BouEstimationLineItem();
        lineItem.bouId = currentBou.id;
        lineItem.tankId = blp?.lineItems?.find(l => l.step === targetBlpStep)?.tankId;
        lineItem.blpStep = targetBlpStep;
        var tank = tanks.find(t => t.id === lineItem.tankId);
        lineItem.tankDescription = tank?.name;

        lineItem.startDate = this.setEstimatedLineItemStartTime(currentBou, lineItem, eventLogs, targetBlpStep);

        // Rate && Stripping are inputs

        // nominated must be processed before duration
        const tankLineItem = blp.lineItems.find(l => l.step === targetBlpStep);
        if (tankLineItem) {
            if (tankLineItem.nominatedQuantities?.some(t => t.isPush)) {
                lineItem.isPush = true;
            }
            else {
                lineItem.isPush = false;
            }
            lineItem.nominated = this.setEstimatedLineItemNominated(currentBou, targetBlpStep, blp);
        }
        const duration = this.setEstimatedLineItemDuration(currentBou, targetBlpStep, blp, eventLogs);
        lineItem.durationHours = duration.days()*24 + duration.hours();
        lineItem.durationMinutes = duration.minutes();
        lineItem.completionDate = this.setEstimatedLineItemCompletionTime(lineItem, currentBou, targetBlpStep, eventLogs);

        return lineItem;
    }

    private processEstimateLineItem(currentBou: Bou, targetBlpStep: number, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[], tanks: Tank[]): BouEstimationLineItem {
        const currentLineItem = currentBou.estimationLineItems?.find(l => l.blpStep === targetBlpStep);
        currentLineItem.blpStep = targetBlpStep;
        currentLineItem.tankId = blp.lineItems.find(l => l.step === targetBlpStep)?.tankId;
        currentLineItem.tankDescription = tanks.find(t => t.id === currentLineItem.tankId)?.name;
        currentLineItem.startDate = this.setEstimatedLineItemStartTime(currentBou, currentLineItem, eventLogs, targetBlpStep);

        // nominated must be processed before duration
        // Determine if estimated line was a push before attempting to calculate
        const tankLineItem = blp.lineItems.find(l => l.step === targetBlpStep);
        if (tankLineItem) {
            if (tankLineItem.nominatedQuantities?.some(t => t.isPush)) {
                currentLineItem.isPush = true;
            }
            else {
                currentLineItem.isPush = false;
            }
            currentLineItem.nominated = this.setEstimatedLineItemNominated(currentBou, targetBlpStep, blp);
        }
        const duration = this.setEstimatedLineItemDuration(currentBou, targetBlpStep, blp, eventLogs);
        currentLineItem.durationHours = duration.days()*24 + duration.hours();
        currentLineItem.durationMinutes = duration.minutes();
        currentLineItem.completionDate = this.setEstimatedLineItemCompletionTime(currentLineItem, currentBou, targetBlpStep, eventLogs);

        return currentLineItem;
    }

    private setEstimatedLineItemStartTime(currentBou: Bou, currentLineItem: BouEstimationLineItem, eventLogs: TimeTrackingEventLog[], blpStep: number): Date {
        // Per 8/19/21 simplified logic instead of using Excel formula based on conversations with VB:
        // If No Loading Event for the tank line item and no previous line item to add tank switch time to
        //   Then date is blank  (Therefore all blank until first tank has a loading event)
        // If Loading event for the row exists, use that actual time
        // Else If previous tank row's time exists add current tank switch time to previous estimated start

        let startTime: Date = null;

        const loadingEvent = eventLogs.find(l =>
            l.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks &&
            l.assignedTankId === currentLineItem.tankId &&
            l.blpStepNumber === blpStep &&
            l.active);

        if (loadingEvent) {
            if (loadingEvent.startTime) {
                startTime = loadingEvent.startTime;
            } else {
                startTime = this.estimateStartFromOriginSwitch(currentBou, currentLineItem);
            }
        } else {
            startTime = this.estimateStartFromOriginSwitch(currentBou, currentLineItem);
        }

        return startTime;
    }

    private estimateStartFromOriginSwitch(currentBou: Bou, currentLineItem: BouEstimationLineItem): Date {
        if (currentLineItem.blpStep === 1) {
            return null;
        }

        const previousStep = currentBou?.estimationLineItems?.find(e => e.blpStep === (currentLineItem.blpStep - 1));
        if (previousStep && previousStep.completionDate) {
            const previousStart = moment(previousStep.completionDate);
            const originDuration = moment.duration(0);
            if (currentLineItem.originSwitchHours || currentLineItem.originSwitchMinutes) {
                originDuration.add({ hours: currentLineItem.originSwitchHours, minutes: currentLineItem.originSwitchMinutes });
            }
            return previousStart.add(originDuration).toDate();
        }

        return null;
    }

    private setEstimatedLineItemDuration(currentBou: Bou, targetBlpStep: number, blp: JobBlpRevision, eventLogs: TimeTrackingEventLog[]): moment.Duration {
        // Duration
        // From Excel: IF(OR(C27="",F27="",G27=""),"",IF(K27="push",((BLP!W18/F27)/24)+G27,((K27/F27)/24)+G27))
        //   - C27: Tank/Origin Id
        //   - F27: Rate
        //   - G27: Stripping
        //   - K27: Nominated
        //   - Blp!W18: BLP Tank's Total Adjusted for Tank

        const currentLineItem = currentBou?.estimationLineItems?.find(e => e.blpStep === targetBlpStep);
        if (currentLineItem) {
            const loadingEvent = eventLogs.find(l =>
                l.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks &&
                l.assignedTankId === currentLineItem.tankId &&
                l.blpStepNumber === targetBlpStep &&
                l.endTime &&
                l.active);

            if (loadingEvent) {
                // use actual time
                const endTime = moment(loadingEvent.endTime);
                const startTime = moment(loadingEvent.startTime);
                return moment.duration(endTime.diff(startTime));
            }

            if (currentLineItem.isPush && blp && currentLineItem.rate) { //Is a Push line
                if (targetBlpStep) {
                    const blpTotal = blp.stepTotals.find(t => t.parcelOrStepNumber === targetBlpStep)?.value;
                    const hours = this.getHoursPortionOfNumberOfHours(blpTotal / currentLineItem.rate);
                    const minutes = this.getMinutesPortionOfNumberOfHours(blpTotal / currentLineItem.rate);
                    const strippingDuration = moment.duration({ hours: currentLineItem.strippingHours, minutes: currentLineItem.strippingMinutes });
                    return strippingDuration.add({ hours: hours, minutes: minutes });
                }
            } else if (currentLineItem.rate) {
                const hours = this.getHoursPortionOfNumberOfHours(currentLineItem.nominated /currentLineItem.rate);
                const minutes = this.getMinutesPortionOfNumberOfHours(currentLineItem.nominated /currentLineItem.rate);
                const strippingDuration = moment.duration({ hours: currentLineItem.strippingHours, minutes: currentLineItem.strippingMinutes });
                const durationWithoutDelays = strippingDuration.add({ hours: hours, minutes: minutes });
                // MAR 2021-11-22: The calculated duration must include the actual line item delays.
                // const currentActualLineItem = currentBou?.actualLineItems?.find(e => e.blpStep === targetBlpStep && e.tankId === currentLineItem.tankId);
                // ...
                // MAR 2022-02-17: The previous calculation was wrong because [actual] includes the 'Tank Swap' event,
                //  which usually occurs before the 'Loading from ST' event.
                // Fixed by creating function that calculate the delays for the actual lines and re-do the delay
                //  calculation (for the [estimated] line), but this time only takes events that happen after the
                //  'Loading from ST' startDate.
                let calculatedLine = new BouActualLineItem();
                calculatedLine.tankId = currentLineItem.tankId;
                calculatedLine.startDate = currentLineItem.startDate;
                this.CalculateDelays(targetBlpStep, eventLogs, calculatedLine, true);
                const durationWithDelays = durationWithoutDelays.add(calculatedLine.totalDelays);
                return durationWithDelays;
            }
        }

        return moment.duration(0);
    }

    private setEstimatedLineItemCompletionTime(currentEstimatedLineItem: BouEstimationLineItem, currentBou: Bou, targetBlpStep: number, eventLogs: TimeTrackingEventLog[]): Date {
        // From Excel: IF(OR(C27="",F27="",G27=""),"",E27+H27+Q27+R27+S27)
        //   - C27: Tank/Origin Id
        //   - F27: Estimated Rate
        //   - G27: Estimated Stripping duration
        //   - E27: Estimated Start Time
        //   - H27: Estimated Duration duration
        //   - Q27 - S27: Acutal Delay durations (columns can change)

        // Need to pass estimated line item in instead of getting from the BOU
        // when this a new line item it is not yet added to the BOU.
        const currentActualLineItem = currentBou?.actualLineItems?.find(e => e.blpStep === targetBlpStep);
        if (!currentActualLineItem) {
            return null;
        }
        // If loading event for this tank row has end date, use it otherwise estimate it
        const loadingEvent = eventLogs.find(l =>
            l.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks &&
            l.assignedTankId === currentActualLineItem.tankId &&
            l.blpStepNumber === targetBlpStep &&
            l.active);
        if (loadingEvent && loadingEvent.endTime) {
            return loadingEvent.endTime;
        }

        if (currentEstimatedLineItem && currentEstimatedLineItem.startDate) {
            const startDate = moment(currentEstimatedLineItem.startDate);
            return startDate
                    //.add(BouEstimationLineItem.fromJson(currentEstimatedLineItem).stripping) //MAR 2021-10-28:  Removed stripping time because it is already rolled in the item duration.
                    .add(BouEstimationLineItem.fromJson(currentEstimatedLineItem).duration)
                    // MAR 2021-11-17: Removed the actual line item delays
                    // If we want to display the end date including the delays, the delays have to be included in the estimated duration calculation. See method "setEstimatedLineItemDuration"
                    // Old line:    .add(BouActualLineItem.fromJson(currentActualLineItem).totalDelays)
                    .toDate();
        }

        return null;
    }

    private setEstimatedLineItemNominated(currentBou: Bou, targetBlpStep: number, blp: JobBlpRevision): number {
        // From Excel: IF($AG$27=TRUE,BLP!M18,BLP!W18)
        //   - AG27: Use Nominated Quantities?
        //   - BLP!M18: BLP Tank's Hidden Cell:
        //       * IF(OR(G18="push",I18="push",J18="push",K18="push",L18="push",H18="push"),"Push",SUM(G18:L18))
        //       * G18 - L18: Parcel Nominations
        //   - Blp!W18: BLP Tank's Total Adjusted for Tank
        let tankNomTotal = 0;
        if (currentBou && currentBou.useNominatedQuantities && blp) {
            const tankLineItem = blp.lineItems.find(l => l.step === targetBlpStep);
            if (tankLineItem) {
                tankLineItem.nominatedQuantities?.forEach(n => tankNomTotal += n.value);
            }
        } else if (currentBou && !currentBou.useNominatedQuantities && blp) {
            const blpStep = blp.lineItems.find(l => l.step === targetBlpStep)?.step;
                if (blpStep) {
                    tankNomTotal = blp.stepTotals.find(t => t.parcelOrStepNumber === blpStep)?.value;
                }
        }
        return tankNomTotal;
    }

    private setEstimatedDurationTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.estimationLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.durationHours, minutes: l.durationMinutes}))
        );
        return total;
    }

    private setEstimatedNominatedTotal(currentBou: Bou): number {
        let total = 0;
        currentBou?.estimationLineItems?.forEach(l =>
            total += l.nominated ? l.nominated : 0 // MAR 2021-10-28: Removed the "Ignore Push lines" (l.isPush)
        );
        return total;
    }

    private setActualStartDate(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): Date {
        const loadingEvent = events.find(l =>
            l.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks &&
            l.assignedTankId === targetedTankId &&
            l.blpStepNumber === blpStep &&
            l.active);

        return loadingEvent ? loadingEvent.startTime : null;
    }

    private setActualCompletedDate(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): Date {
        const loadingEvent = events.find(l =>
            l.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks &&
            l.assignedTankId === targetedTankId &&
            l.blpStepNumber === blpStep &&
            l.active);

        return loadingEvent ? loadingEvent.endTime : null;
    }

    private setActualRate(currentActualLineItem: BouActualLineItem, targetedStep: number, blp: JobBlpRevision): number {
        // From Excel: IF(V27="","",IF(X27="Push", (BLP!N18/P27)/24, (X27/P27)/24))
        // - V27: Current line Completion Time
        // - X27: Current line Delivered
        // - BLP!N18: Current line BlP line adjustment
        // - P27: Current line Duration as hours
        // Delivered and duration must be already calculated before this method is called!!!
        let rate = 0;
        if (!(currentActualLineItem.completionDate && targetedStep && blp && blp.lineItems)) {
            return 0;
        }

        if (!(currentActualLineItem.durationHours || currentActualLineItem.durationMinutes)) {
            return 0;
        }

        const blpStep = blp.lineItems.find(s => s.step === targetedStep);
        if (blpStep.nominatedQuantities.some(n => n.isPush)) {
            rate = blpStep.lineAdjustment /
                moment.duration({ hours: currentActualLineItem.durationHours, minutes: currentActualLineItem.durationMinutes }).asHours();
        } else {
            rate = currentActualLineItem.delivered /
                moment.duration({ hours: currentActualLineItem.durationHours, minutes: currentActualLineItem.durationMinutes }).asHours();
        }
        return isNaN(rate) ? 0 : rate;
    }

    private setActualDuration(currentActualLineItem: BouActualLineItem): moment.Duration {
        // From Excel: IF(V27="","",(V27-N27-Q27-R27-S27-T27))
        // - V27: Current line item Completion time
        // - N27: Current line item Start time
        // - Q27-T27: All Delay times (excluding Tank Swap)
        // All Delays, Start, and Completion time must calculated before this method is called!!!
        if (!currentActualLineItem.startDate || !currentActualLineItem.completionDate) {
            return moment.duration(0);
        }
        const completion = moment(currentActualLineItem.completionDate);
        const start = moment(currentActualLineItem.startDate);
        currentActualLineItem = BouActualLineItem.fromJson(currentActualLineItem);
        return moment.duration(completion.diff(start))
            .subtract(currentActualLineItem.vapor)
            .subtract(currentActualLineItem.weather)
            .subtract(currentActualLineItem.lineDisplacement)
            .subtract(currentActualLineItem.slowRate)
            .subtract(currentActualLineItem.powerOutage)
            .subtract(currentActualLineItem.terminalReason)
            .subtract(currentActualLineItem.otherDelay);
    }

    private setActualVaporDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.vaporDelayTerminal &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualWeatherDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.weatherDelay &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualLineDisplacementDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.lineDisplacementDelay &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualRateSlowdownDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.rateSlowdownDelay &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualPowerOutageDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.powerOutageDelay &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualTerminalReasonDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.terminalReasonDelay &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualOtherDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.otherDelayTerminal &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualTankSwapDuration(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): moment.Duration {
        const duration = moment.duration(0);
        if (targetedTankId && events && events.length) {
            const foundEvents = events.filter(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.tankSwapDelayTerminal &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep);
            if (foundEvents && foundEvents.length) {
                foundEvents.forEach(e => {
                    duration.add(TimeTrackingEventLog.fromJson(e).duration);
                })
            }
        }
        return duration;
    }

    private setActualDelivered(currentActualLineItem: BouActualLineItem, targetedStep: number, useNomintations: boolean, bfr: Bfr): number {
        // From Excel: IF(AF27=FALSE,0,IF($AG$27=TRUE, IF(K27="push","Push",BFR!AG15),BFR!S15))
        // - AF27: Is current line item complete
        // - AG27: Use Nominations?
        // - K27: Estimated Nominated (only used to know if current line is push or not)
        // - BFR!AG15: Current line BFR Adjustd By Lines
        // - BFR!S15: Current line BFR NSV Barrels total
        // IsCompleted and IsPush must be calculated before calling this method!!!
        let delivered = 0;
        if (currentActualLineItem.isCompleted) {
            if (useNomintations) {
                // if (!currentActualLineItem.isPush) {
                //     const targetBfrTank = bfr?.bfrTanks?.find(t => t.stepNumber === targetedStep);
                //     if (targetBfrTank) {
                //         delivered = targetBfrTank.adjustedLines;
                //     }
                // }
                // Now including push rows
                const targetBfrTank = bfr?.bfrTanks?.find(t => t.stepNumber === targetedStep);
                if (targetBfrTank) {
                    delivered = targetBfrTank.adjustedLines ? targetBfrTank.adjustedLines : 0;
                }
            }  else {
                const targetBfrTank = bfr?.bfrTanks?.find(t => t.stepNumber === targetedStep);
                if (targetBfrTank) {
                    delivered = targetBfrTank.nsvBarrelsTotal ? targetBfrTank.nsvBarrelsTotal : 0;
                }
            }
        }
        return delivered;
    }

    private setActualVariance(currentActualLineItem: BouActualLineItem, targetedStep: number, bfr: Bfr): number {
        // From Excel: IF(AF27=FALSE,0,IF(X27="push","",BFR!AH15))
        // - AF27: Is current line completed
        // - X27: Current line delivered (only used to know if it is a push)
        // - BFR!AH15: current line item's bfr variance
        // IsCompleted and IsPush must already be calculated
        let variance = 0;
        //if (!currentActualLineItem.isPush && currentActualLineItem.isCompleted) {
        if (currentActualLineItem.isCompleted) {
            const targetBfrTank = bfr?.bfrTanks?.find(t => t.stepNumber === targetedStep);
                    if (targetBfrTank) {
                        variance = targetBfrTank.variance;
                    }
        }
        return variance;
    }

    private setActualIsCompleted(targetedTankId: string, events: TimeTrackingEventLog[], blpStep: number): boolean {
        if (events && events.length) {
            return events.some(e =>
                e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks &&
                e.assignedTankId === targetedTankId &&
                e.blpStepNumber === blpStep &&
                e.endTime)
        }
        return false;
    }

    private setActualIsPush(targetedStep: number, blp: JobBlpRevision): boolean {
        if (targetedStep && blp && blp.lineItems) {
            const blpStep = blp.lineItems?.find(l => l.step === targetedStep);
            return blpStep.nominatedQuantities.some(n => n.isPush);
        }
        return false;
    }

    private setActualVaporTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.vaporHours, minutes: l.vaporMinutes }))
        );
        return total;
    }

    private setActualDurationTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.durationHours, minutes: l.durationMinutes }))
        );
        return total;
    }

    private setActualWeatherTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.weatherHours, minutes: l.weatherMinutes }))
        );
        return total;
    }

    private setActualLineDisplacementTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.lineDisplacementHours, minutes: l.lineDisplacementMinutes }))
        );
        return total;
    }

    private setActualRateSlowdownTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.slowRateHours, minutes: l.slowRateMinutes }))
        );
        return total;
    }

    private setActualPowerOutageTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.powerOutageHours, minutes: l.powerOutageMinutes }))
        );
        return total;
    }

    private setActualTerminalReasonTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.terminalReasonHours, minutes: l.terminalReasonMinutes }))
        );
        return total;
    }

    private setActualOtherDelayTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.otherDelayHours, minutes: l.otherDelayMinutes }))
        );
        return total;
    }

    private setActualTankSwapTotal(currentBou: Bou): moment.Duration {
        let total = moment.duration(0);
        currentBou?.actualLineItems?.forEach(l =>
            total = total.add(moment.duration({ hours: l.tankSwapHours, minutes: l.tankSwapMinutes }))
        );
        return total;
    }

    private setActualDeliveredTotal(currentBou: Bou): number {
        let total = 0;
        currentBou?.actualLineItems?.forEach(l =>
            //total += l.isPush ? 0 : l.delivered // Ignore push rows
            //Push rows now included
            total += l.delivered ? l.delivered : 0
        );
        return total;
    }

    private setActualVarianceTotal(currentBou: Bou): number {
        let total = 0;
        currentBou?.actualLineItems?.forEach(l =>
            total += l.isPush ? 0 : l.variance // Ingore push rows
        );
        return total;
    }

    private setGrossLoadingTimeDuration(currentBou: Bou, eventLogs: TimeTrackingEventLog[]): moment.Duration {
        // From Excel: X14-x13
        // - X14: Completed Loading Key Time
        // - X13: Commenced Loading Key Time
        // 10/4/21 Updated from Excel's logic to not only wait to all loading
        // is completed but to use what loading events are available to get a
        // the current values
        if (currentBou.loadingCommenced && currentBou.loadingCompleted) {
            const completed = moment(currentBou.loadingCompleted);
            const commenced = moment(currentBou.loadingCommenced);
            return moment.duration(completed.diff(commenced));
        }

        const completedTanks = eventLogs.filter(e => e.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks && e.endTime);
        if (completedTanks && completedTanks.length) {
            const firstStart = completedTanks.find(e => e.blpStepNumber === 1)?.startTime;
            const lastFinish = completedTanks.sort((a, b) => a.endTime > b.endTime ? 1 : -1)[0]?.endTime;
            if (firstStart && lastFinish) {
                return moment.duration(moment(lastFinish).diff(moment(firstStart)));
            }
        }

        return moment.duration(0);
    }

    private setNetLoadingTimeDuration(currentBou: Bou): moment.Duration {
        // From Excel: U45-U42-S42-R42-Q42+U27-T42
        // - U45 - Gross Loading Time
        // - Q42 - U42: Each Delay columns total
        // Actuals and Gross Loading time must already be set before calling this method!!!
        if (currentBou.grossLoadingTimeRate && currentBou.grossLoadingTimeRate >= 0) {
            const netLoading = moment.duration({ hours: currentBou?.grossLoadingTimeHours, minutes: currentBou?.grossLoadingTimeMinutes });
            netLoading.subtract(moment.duration({ hours: currentBou?.actualVaporDelayHoursTotal, minutes: currentBou.actualVaporDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualWeatherDelayHoursTotal, minutes: currentBou.actualWeatherDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualLineDisplacementDelayHoursTotal, minutes: currentBou.actualLineDisplacementDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualRateSlowdownDelayHoursTotal, minutes: currentBou.actualRateSlowdownDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualPowerOutageDelayHoursTotal, minutes: currentBou.actualPowerOutageDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualTerminalReasonDelayHoursTotal, minutes: currentBou.actualTerminalReasonDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualOtherDelayHoursTotal, minutes: currentBou.actualOtherDelayMinutesTotal }));
            netLoading.subtract(moment.duration({ hours: currentBou?.actualTankSwapHoursTotal, minutes: currentBou.actualTankSwapMinutesTotal }));
            return netLoading;
        }
        return moment.duration({hours: 0, minutes: 0});
    }

    private setGrossLoadingShoreFigures(currentBou: Bou): number {
        // From Excel: X42
        // - X42: Acutal Delivered Total
        return currentBou.actualDeliveredTotal;
    }

    private setNetLoadingShoreFigures(currentBou: Bou): number {
        // From Excel: V45
        // - V45: Gross Loading Shore Figures
        return currentBou.grossLoadingTimeShoreFigures;
    }

    private setGrossLoadingRate(currentBou: Bou): number {
        // From Excel: (V45/U45)/24
        // - V45: Gross Loading Shore Figures
        // - U45: Gross Loading Time
        const grossLoadingTimeHours = moment.duration({ hours: currentBou.grossLoadingTimeHours, minutes: currentBou.grossLoadingTimeMinutes }).asHours();
        if (!grossLoadingTimeHours || grossLoadingTimeHours == 0) {
            return 0;
        }
        const rate = currentBou.grossLoadingTimeShoreFigures / grossLoadingTimeHours;
        return !rate || isNaN(rate) ? 0 : rate;
    }

    private setNetLoadingRate(currentBou: Bou): number {
        // From Excel: (V46/U46)/24
        // - V46: Net Loading Shore Figures
        // - U46: Net Loading Time
        const netLoadingTimeHours = moment.duration({ hours: currentBou.netLoadingTimeHours, minutes: currentBou.netLoadingTimeMinutes }).asHours();
        if (!netLoadingTimeHours || netLoadingTimeHours == 0) {
            return 0;
        }
        const rate = currentBou.netLoadingTimeShoreFigures / netLoadingTimeHours;
        return !rate || isNaN(rate) ? 0 : rate;
    }

    private setVesselReportingAdjusted(currentBou: Bou): number {
        // From Excel: IF(N51="","", N51/P51)
        // - N51: Vessel Reporting Received (input)
        // - P51: Vessel Reporting V.E.F. (input)
        return currentBou.vesselReportingReceived && currentBou.vesselReportingVef ? currentBou.vesselReportingReceived / currentBou.vesselReportingVef : 0;
    }

    private setVesselReportingDifference(currentBou: Bou): number {
        // From Excel: IF(N51="","Pending", S51-X42)
        // - N51: Vessel Reporting Received
        // - S51: Vessel Reporting Adjusted
        // - X42: Actual Delivered Total
        return currentBou.vesselReportingAdjusted && currentBou.actualDeliveredTotal ? currentBou.vesselReportingAdjusted - currentBou.actualDeliveredTotal : 0;
    }

    private setVesselReportingApparentDifferencePercent(currentBou: Bou): number {
        // From Excel: ABS(X42-(N51/P51))/X42
        // - N51: Vessel Reporting Received
        // - P51: Vessel Reporting VEF
        // - X42: Actual Delivered Total
        //return currentBou.vesselReportingReceived && currentBou.vesselReportingVef && currentBou.actualDeliveredTotal ?
        //    Math.abs(currentBou.actualDeliveredTotal - (currentBou.vesselReportingReceived/currentBou.vesselReportingVef))/currentBou.actualDeliveredTotal : 0;

        // MAR 2021-11-12: New calculation
        if (!currentBou.vesselReportingReceived) {
            return 0;
        }
        return Math.abs(currentBou.vesselReportingDifference / currentBou.vesselReportingReceived) * 100;
    }

    private getHoursPortionOfNumberOfHours(decimalValueNumOfHours: number): number {
        return Math.floor(decimalValueNumOfHours);
    }

    private getMinutesPortionOfNumberOfHours(decimalValueNumOfHours: number): number {
        return Math.round((decimalValueNumOfHours % 1) * 60);
    }
}
