import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TimeTrackingEvent, Tank, BlpStepTank, TimeTrackingReportFormats } from 'src/app/models';
import { JobReportService } from 'src/app/services/http-services/job.report.service';
import { TimeTrackingEventLog } from 'src/app/models/job/time-tracking/time-tracking-event-log';
import { TimeTrackingEventLogTableData } from 'src/app/models/job/time-tracking/time-tracking-event-log-table-data';
import { StatusManagementService } from 'src/app/services/status-management.service';
import { TimeTrackingManagementService } from 'src/app/services/time-tracking-management.service';
import { DataSharingService } from '../../../services/data-sharing.service';
import { DownloadsService } from '../../../services/http-services/downloads.service';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-job-time-tracking',
  templateUrl: './job-time-tracking.component.html',
  styleUrls: ['./job-time-tracking.component.scss']
})
export class JobTimeTrackingComponent implements OnInit, OnDestroy {

  @Input() shoreTanks: Tank[] = [];
  @Input() vesselTanks: Tank[] = [];

  ready: boolean = true;
  tankIds: string[] = [];
  jobShoreTanks: Tank[] = [];
  timeTrackingEvents: TimeTrackingEvent[] = [];
  timeTrackingEventLogs: TimeTrackingEventLog[] = [];
  timeTrackingEventLogTableData: TimeTrackingEventLogTableData[] = [];
  cols: any[] = [];
  subscriptions: Subscription[] = [];
  selectedLog: TimeTrackingEventLog;
  showEditDialog: boolean = false;
  tankToStepMappings: BlpStepTank[] = [];
  cstTimeZoneAbbrev: string;
  
  @ViewChild('timetrackingDt') table: Table;
  
  constructor(
    private store: Store<appState.State>,
    private timeTrackingManagementService: TimeTrackingManagementService,
    private StatusManagementService: StatusManagementService,
    private confirmationService: ConfirmationService,
    private downloadsService: DownloadsService,
    private datePipe: DatePipe
    ) { }

  ngOnInit(): void {
    this.cstTimeZoneAbbrev = HelperService.getCentralTimezone();
    this.listenForTankIdChanges();
    this.listenForTimeTrackingEventChanges();
    this.listenForTimeTrackingEventLogChanges();
    this.cols = this.timeTrackingManagementService.buildTableCols();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForTankIdChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getJobShoreTankIds)).subscribe(
        results => {
          this.tankIds = [...results];
          if (this.tankIds)
            this.jobShoreTanks = this.shoreTanks.filter(t => this.tankIds.includes(t.id));
        }
      )
    );

    this.subscriptions.push(
      this.store.pipe(select(appState.getJobAllTanksWithStepNumbers)).subscribe(
        results => {
          if (results) {
            this.tankToStepMappings = [...results];
          }
        }
      )
    );
  }

  listenForTimeTrackingEventChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTimeTrackingEvents)).subscribe(
        results => {
          this.timeTrackingEvents = results;
        }
      )
    );
  }

  listenForTimeTrackingEventLogChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJobTimeTrackingEventLogs)).subscribe(
        results => {
          this.timeTrackingEventLogs = results ? [...results.map(r => TimeTrackingEventLog.fromJson(r))] : [];
          this.timeTrackingEventLogTableData = [];
          results.map(r => {
            const tableData = new TimeTrackingEventLogTableData(TimeTrackingEventLog.fromJson(r));
            tableData.timeTrackingEventName = this.timeTrackingEvents.find(e => e.id === r.timeTrackingEventId)?.name;
            tableData.isDuration = this.timeTrackingEvents.find(e => e.id === r.timeTrackingEventId)?.requiresDuration;
            tableData.requiresTank = this.timeTrackingEvents.find(e => e.id === r.timeTrackingEventId)?.requiresTankAssignment;
            tableData.assignedTankId = r.assignedTankId;
            tableData.assignedTankName = this.shoreTanks.find(t => t.id === r.assignedTankId)?.name;
            
            // Using DatePipe to set values instead of in template so formatted values can be used in the table export
            tableData.startTime = r.startTime ? this.datePipe.transform(r.startTime, 'M/d/yyyy, HH:mm') : '';
            tableData.endTime = r.endTime ? this.datePipe.transform(r.endTime, 'M/d/yyyy, HH:mm') : '';

            this.timeTrackingEventLogTableData.push(tableData);
          })
          this.timeTrackingEventLogTableData = this.timeTrackingEventLogTableData.sort((a, b) => a.startTimeDate > b.startTimeDate ? 1 : -1);
          if (this.table) {
            this.table.reset();
          }
        }
      )
    );
  }

  async addLog() {
    this.selectedLog = new TimeTrackingEventLog();
    this.selectedLog.jobId = await this.store.select(appState.getSelectedJobId).pipe(take(1)).toPromise();
    this.showEditDialog = true;
  }

  openLog(id: string) {
    this.selectedLog = this.timeTrackingEventLogs.find(t => t.id === id);
    this.showEditDialog = true;
  }

  async saveEventLog(logSaved: TimeTrackingEventLog): Promise<void> {
    const setTimeTrackingEventLogProperties = new appActions.SetJobActionProperties<TimeTrackingEventLog>();
    setTimeTrackingEventLogProperties.id = logSaved.id;
    setTimeTrackingEventLogProperties.jobId = logSaved.jobId;
    setTimeTrackingEventLogProperties.data = logSaved;

    this.store.dispatch(appActions.setJobTimeTrackingEventLog({ log: setTimeTrackingEventLogProperties }));

    await this.StatusManagementService.processTimeTrackingEventLog(logSaved);
    this.close();
  }

  close() {
    this.selectedLog = null;
    this.showEditDialog = false;
  }

  deleteLog(id: string) {
    const targetLog = this.timeTrackingEventLogs.find(l => l.id === id);
    const targetEvent = this.timeTrackingEvents.find(e => e.id === targetLog.timeTrackingEventId);
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to remove Logged Event: ' + targetEvent?.name,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        targetLog.active = false;
        const deleteEventProperties = new appActions.SetJobActionProperties<TimeTrackingEventLog>();
        deleteEventProperties.id = targetLog.id;
        deleteEventProperties.jobId = targetLog.jobId;
        deleteEventProperties.data = targetLog;
        this.store.dispatch(appActions.setJobTimeTrackingEventLog({ log: deleteEventProperties }));
        await this.StatusManagementService.processTimeTrackingEventLog(targetLog);
      }
    });
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable);
  }

  async downloadTimeTrackingXls() {
    this.selectedLog = new TimeTrackingEventLog();
    this.selectedLog.jobId = await this.store.select(appState.getSelectedJobId).pipe(take(1)).toPromise();
    this.downloadsService.downloadTimeTracking(this.selectedLog.jobId, TimeTrackingReportFormats.XLS);
    
  }

  async downloadTimeTrackingPdf() {
    this.selectedLog = new TimeTrackingEventLog();
    this.selectedLog.jobId = await this.store.select(appState.getSelectedJobId).pipe(take(1)).toPromise();
    this.downloadsService.downloadTimeTracking(this.selectedLog.jobId, TimeTrackingReportFormats.PDF);

  }
}


