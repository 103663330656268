import { AuditableEntity } from "../auditable-entity";
import { Tank} from "./tank";

export class Ship extends AuditableEntity {
    name: string;
    imoNo: number;
    tanks: Tank[] = [];

    constructor() {
        super();
    }
}