import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ship } from 'src/app/models/system-config/ship';

@Component({
  selector: 'app-ship-detail',
  templateUrl: './ship-detail.component.html',
  styleUrls: ['./ship-detail.component.scss']
})
export class ShipDetailComponent implements OnInit {

  @Input() ship: Ship;
  @Output() shipUpdated = new EventEmitter<Ship>();
  @Output() resetShip = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.shipUpdated.emit(this.ship);
  }

  reset() {
    this.resetShip.emit();
  }

}
