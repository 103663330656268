import { Ship, Terminal, TimeSpan, User } from 'src/app/models';

export class ActiveJobInfo {
    jobId: string;
    jobNumber: string;
    terminal: Terminal;
    ship: Ship;
    superintendents: User[] = [];
    lastUpdateDate: Date;
    status: string;
    percentLoaded: number;
    loadingStalled: boolean;
    loadingStatus: number;
    nominatedVolume: number;
    loadedVolume: number;
	loadingTime: TimeSpan;
	downtime: TimeSpan;
    berthingDate: Date;
	allFastTime: Date;
    estimatedCompletion: Date;
    superintendentNames: string;

    constructor() {
    }
}
