import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BlpLineItem, BlpParcel } from 'src/app/models';
import { BlpOverride } from 'src/app/models/job/blp/blp-override';

@Component({
  selector: 'app-job-blp-overrides-detail',
  templateUrl: './job-blp-overrides-detail.component.html',
  styleUrls: ['./job-blp-overrides-detail.component.scss']
})
export class JobBlpOverridesDetailComponent implements OnInit {

  @Input() override: BlpOverride;
  @Input() steps: BlpLineItem[] = [];
  @Input() parcels: BlpParcel[] = [];
  @Output() overrideSaved = new EventEmitter();
  @Output() overrideCanceled = new EventEmitter();
  
  stepOptions: SelectItem[] = [];
  parcelOptions: SelectItem[] = [];

  constructor() { }

  ngOnInit(): void {
    this.stepOptions = this.buildStepOptions(this.steps);
    this.parcelOptions = this.buildParcelOptions(this.parcels);
  }
  
  buildStepOptions(steps: BlpLineItem[]): SelectItem[] {
    if (!steps) {
      return [];
    }

    return steps.map(s => {
      return {
        label: s.step + '-' + s.tankLocation + '-' + s.tankId,
        value: s.step
      };
    })
    .sort((a, b) => a.value > b.value ? 1 : -1);
  }

  buildParcelOptions(parcels: BlpParcel[]): SelectItem[] {
    if (!parcels) {
      return [];
    }

    return parcels.map(p => {
      return {
        label: p.parcelNumber + '-' + p.product + '-' + p.vesselTank,
        value: p.parcelNumber
      };
    })
    .sort((a, b) => a.value > b.value ? 1 : -1);
  }

  save() {
    this.overrideSaved.emit();
  }

  cancel() {
    this.overrideCanceled.emit();
  }

}
