import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Port } from 'src/app/models';

@Component({
  selector: 'app-port-detail',
  templateUrl: './port-detail.component.html',
  styleUrls: ['./port-detail.component.scss']
})
export class PortDetailComponent implements OnInit {

  @Input() port: Port;
  @Output() portUpdated = new EventEmitter<Port>();
  @Output() resetPort = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.portUpdated.emit(this.port);
  }

  reset() {
    this.resetPort.emit();
  }

}
