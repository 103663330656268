import { AuditableEntity } from "../../auditable-entity";
import { BfrTankLineItem } from "./bfr-tank-line-item";

export class BfrTank extends AuditableEntity {
    bfrId: string;
    tankId: string;
    stepNumber: number;
    ullageFt: string; // Used for open CRT Ullage    
    closeCrtUllageFt: string;
    tovUsBarrelsTotal: number;
    gsvUsBarrelsTotal: number;
    nsvBarrelsTotal: number;
    expectedDelivery: number;
    nominatedQuantity: number;
    adjustedLines: number;
    variance: number;
    lineCapacity: number;
    strappedApi: number;
    barrelsPerDegreeApi: number;
    bfrTankLineItems: BfrTankLineItem[] = [];

    constructor() {
        super();
    }

    static fromJson(bfrTank: BfrTank): BfrTank {
        const newBfrTank: BfrTank = new BfrTank();
        
        newBfrTank.id = bfrTank.id;
        newBfrTank.active = bfrTank.active;
        newBfrTank.createdOn = bfrTank.createdOn;
        newBfrTank.createdBy = bfrTank.createdBy;
        newBfrTank.modifiedOn = bfrTank.modifiedOn;
        newBfrTank.modifiedBy = bfrTank.modifiedBy;
        newBfrTank.bfrId = bfrTank.bfrId;
        newBfrTank.ullageFt = bfrTank.ullageFt;
        newBfrTank.closeCrtUllageFt = bfrTank.closeCrtUllageFt;
        newBfrTank.gsvUsBarrelsTotal = bfrTank.gsvUsBarrelsTotal;
        newBfrTank.nsvBarrelsTotal = bfrTank.nsvBarrelsTotal;
        newBfrTank.expectedDelivery = bfrTank.expectedDelivery;
        newBfrTank.nominatedQuantity = bfrTank.nominatedQuantity;
        newBfrTank.adjustedLines = bfrTank.adjustedLines;
        newBfrTank.variance = bfrTank.variance;
        newBfrTank.lineCapacity = bfrTank.lineCapacity;
        newBfrTank.strappedApi = bfrTank.strappedApi;
        newBfrTank.barrelsPerDegreeApi = bfrTank.barrelsPerDegreeApi;
        newBfrTank.bfrTankLineItems = bfrTank.bfrTankLineItems;

        return newBfrTank;
    }
}
