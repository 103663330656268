<div class="p-grid" class="page-font">
    <div class="p-col-12">
        <h2>Terminal Comparison</h2>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div *ngFor="let categoryTiming of report.categoryTimings" class="p-col-12 p-md-6 p-lg-4">
                <p-card [style]="{'font-weight': 'bold'}">
                    <div>
                        <p-chart type="bar" [data]="categoryTiming.chartData" [options]="chartsOptions[categoryTiming.category]" 
                        (onDataSelect)="selectData($event, categoryTiming.category)"></p-chart>
                    </div>
                    <ng-template pTemplate="footer">
                        <p>{{categoryTiming.category}}</p>
                        <p>(Past {{period}} Days)</p>
                    </ng-template>
                </p-card>
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
                <p-card [style]="{'font-weight': 'bold'}">
                    <div>
                        <p-chart type="bar" [data]="report.downtimeByReasonChartData" [options]="chartsOptions['Average Downtime By Reasons']" 
                        (onDataSelect)="selectDowntimeData($event)"></p-chart>
                    </div>
                    <ng-template pTemplate="footer">
                        <p>Average Downtime By Reasons</p>
                        <p>(Past {{period}} Days)</p>
                    </ng-template>
                </p-card>
            </div>
        </div>
    </div>
</div>
<p-dialog *ngIf="showDataInfo" [(visible)]="showDataInfo" [style]="{width: '50vw'}" [modal]="true" [closable]="true">
    <div>
        <div style="text-align: center;"><h2>{{dataCaption}}</h2></div>
        <p-table [value]="dataInfos" styleClass="p-datatable-striped" [rows]="5" [responsive]="true" [paginator]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Ship</th>
                    <th>Terminal</th>
                    <th>Cargo Commenced</th>
                    <th>Completion Time</th>
                    <th>Timing/Rate</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dataInfo>
                <tr>
                    <td>{{dataInfo.ship?.name}}</td>
                    <td>{{dataInfo.terminal?.name}}</td>
                    <td>{{dataInfo.cargoCommenced | date: 'M/d/yyyy HH:mm'}}</td>
                    <td>{{dataInfo.completionTime | date: 'M/d/yyyy HH:mm'}}</td>
                    <td>{{dataInfo.displayValue ? (dataInfo.duration | number : '1.0-0') : dataInfo.durationText}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
<p-dialog *ngIf="showDowntimeDataInfo" [(visible)]="showDowntimeDataInfo" [style]="{width: '40vw'}" [modal]="true" [closable]="true">
    <div>
        <div style="text-align: center;"><h2>{{dataCaption}}</h2></div>
        <p-table [value]="downtimeDataInfos" styleClass="p-datatable-striped" [rows]="5" [responsive]="true" [paginator]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Reason</th>
                    <th>Time</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-downtimeDataInfo>
                <tr>
                    <td>{{downtimeDataInfo.description}}</td>
                    <td>{{downtimeDataInfo.durationText}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
