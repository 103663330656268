import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Dock, Terminal } from 'src/app/models';

@Component({
  selector: 'app-docks-list',
  templateUrl: './docks-list.component.html',
  styleUrls: ['./docks-list.component.scss']
})
export class DocksListComponent implements OnInit, OnDestroy {
  cols: any[] = [];
  skeletonRows: any[] = [];
  docks: DockListItem[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedDock: Dock;
  showEditDialog = false;
  allTerminals: Terminal[] = [];

  constructor(
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForTerminalsChanges();
    this.listenForDockChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForTerminalsChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTerminals))
      .subscribe(results => { 
        this.allTerminals = [...results];
        this.docks.forEach(t => {
          t.terminal = t.terminal;
        });
      })
    );
  }

  listenForDockChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getDocks))
        .subscribe(results => {
          this.docks = [...results].map(d => DockListItem.fromDock(d));
          this.docks.forEach(d => {
            d.terminalName = d.terminal?.name
          });
          this.loaded = true;
        })
    );
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'terminalName', header: 'Terminal', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addDock() {
    this.selectedDock = new Dock();
    this.showEditDialog = true;
  }

  openDock(dock: Dock) {
   this.selectedDock = cloneDeep(dock);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateDock(dock: Dock) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Dock: ' + dock.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveDock = { ...dock, active: false };
        this.store.dispatch(appActions.setDock({ data: deactiveDock }));
      }
    });
  }

  activateDock(dock: Dock) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Dock: ' + dock.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeDock = { ...dock, active: true };
        this.store.dispatch(appActions.setDock({ data: activeDock }));
      }
    });
  }

  updateDock(port: Dock) {
    this.store.dispatch(appActions.setDock({ data: port }));
    this.showEditDialog = false;
  }

  async resetSelectedDock() {
    // this.ports is already subscribed to latest state of Docks
    // reset selected Dock to state
    this.selectedDock = cloneDeep(this.docks.find(t => t.id === this.selectedDock.id));
  }
}

class DockListItem extends Dock {
  terminalName: string;
  constructor() {
    super();
  }

  static fromDock(dock: Dock, terminalName: string = ''): DockListItem {
    const dockListItem = new DockListItem();
    dockListItem.id = dock.id;
    dockListItem.active = dock.active;
    dockListItem.name = dock.name;
    dockListItem.terminal = dock.terminal;
    dockListItem.terminalName = terminalName;
    return dockListItem;
  }
}