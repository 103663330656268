import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { Subscription } from 'rxjs';
import * as cloneDeep from 'lodash.clonedeep';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { take } from 'rxjs/operators';
import { Contact, Job, JobOverview, JobStatuses, Port, Terminal, Ship, User, VesselOnSpecTypes, Product } from 'src/app/models';
import { StatusManagementService } from 'src/app/services/status-management.service';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'app-job-overview',
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.scss']
})
export class JobOverviewComponent implements OnInit, OnDestroy {

  @Output() jobOverviewShipChanged = new EventEmitter<Ship>();
  @Output() jobOverviewCargoChanged = new EventEmitter<Product>();
  @Output() jobOverviewTerminalChanged = new EventEmitter<string>();

  jobOverview: JobOverview = new JobOverview();
  isNew: boolean = false;
  isJobLoading: boolean = true;
  subscriptions: Subscription[] = [];

  shipOptions: SelectItem[] = [];
  cargoOptions: SelectItem[] = [];
  clientOptions: SelectItem[] = [];
  portOptions: SelectItem[] = [];
  terminalOptions: SelectItem[] = [];
  dockOptions: SelectItem[] = [];
  superintendentOptions: SelectItem[] = [];
  inspectionCompanyOptions: SelectItem[] = [];
  jobStatuses = JobStatuses;
  superintendentTooltip: string;
  reportContactOptions: SelectItem[] = [];
  reportHourOptions: SelectItem[] = [];
  selectedReportHours: string[] = [];
  ready = false;
  selectedCargoId: string;
  selectedClientId: string;
  selectedPortId: string;
  selectedTerminalId: string;
  selectedDockId: string;
  selectedInspectionCompanyId: string;
  selectedSuperintendents: string[] = [];
  selectedReportContacts: string[] = [];
  showCancelJob: boolean = false;
  cancelledReason: string;
  isVesselOnSpecOptions: SelectItem[] = [];
  vesselOnSpecTypes = VesselOnSpecTypes;
  IsInjection: boolean = false;

  constructor(
    private store: Store<appState.State>,
    private statusManagementService: StatusManagementService,
    private confirmationService: ConfirmationService,
    private actions$: Actions,
    private router: Router,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.listenToJobChanges();
    this.listenForConfigurationLoaded();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForConfigurationLoaded() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getConfigurationLoaded)).subscribe(async (loaded) => {
        if (loaded) {
          await this.buildOptions(this.jobOverview);
        }
      })
    );
  }

  async buildOptions(localJobOverview: JobOverview): Promise<void> {
    let ships = [...await this.store.pipe(select(appState.getShips)).pipe(take(1)).toPromise()];
    ships = this.isNew ? ships.filter(i => i.active) : ships;
    this.shipOptions = ships.sort((a, b) => a.name > b.name ? 1 : -1).map(s => { return { label: s.name + ' ' + s.imoNo, value: s }; });

    let cargos = [...await this.store.pipe(select(appState.getProducts)).pipe(take(1)).toPromise()];
    cargos = cargos.filter(i => i.active);
    this.cargoOptions = cargos.sort((a, b) => a.name > b.name ? 1 : -1).map(c => { return { label: c.name, value: c }; });

    let clients = [...await this.store.pipe(select(appState.getClients)).pipe(take(1)).toPromise()];
    clients = this.isNew ? clients.filter(i => i.active) : clients;
    this.clientOptions = clients.sort((a, b) => a.name > b.name ? 1 : -1).map(i => { return { label: i.name, value: i.id }; });

    let ports = [...await this.store.pipe(select(appState.getPortLocations)).pipe(take(1)).toPromise()];
    ports = this.isNew ? ports.filter(i => i.active) : ports;
    this.portOptions = ports.sort((a, b) => a.name > b.name ? 1 : -1).map(i => { return { label: i.name, value: i.id }; });

    this.terminalOptions = await this.buildAndFilterTerminalOptions(localJobOverview.port?.id);

    this.dockOptions = await this.buildAndFilterDockOptions(localJobOverview.terminal?.id);

    const foundSuperintendents = await this.store.pipe(select(appState.getSuperintendents)).pipe(take(1)).toPromise();
    let superintendents = foundSuperintendents && foundSuperintendents.length ? [...foundSuperintendents] : [];
    superintendents = this.isNew ? superintendents.filter(i => i.active) : superintendents;
    this.superintendentOptions = superintendents.sort((a, b) => a.displayName > b.displayName ? 1 : -1).map(i => { return { label: i.displayName, value: i.id }; });

    const foundInspectionCompanies = await this.store.pipe(select(appState.getInspectionCompanies)).pipe(take(1)).toPromise();
    let inspectionCompanies = foundInspectionCompanies && foundInspectionCompanies.length ? [...foundInspectionCompanies] : [];
    inspectionCompanies = this.isNew ? inspectionCompanies.filter(i => i.active) : inspectionCompanies;
    this.inspectionCompanyOptions = inspectionCompanies.sort((a, b) => a.name > b.name ? 1 : -1).map(i => { return { label: i.name, value: i.id }; });

    this.reportHourOptions = [];
    for (let i: number = 1; i <= 24; i++) {
      this.reportHourOptions.push({ label: i.toString(), value: i.toString() });
    }

    this.isVesselOnSpecOptions = [
      { label: VesselOnSpecTypes.yes, value: VesselOnSpecTypes.yes },
      { label: VesselOnSpecTypes.na, value: VesselOnSpecTypes.na },
      { label: VesselOnSpecTypes.no, value: VesselOnSpecTypes.no }
    ];

    await this.updateReportContactOptions();

    this.ready = true;
  }

  async buildAndFilterTerminalOptions(portId: string): Promise<SelectItem[]> {
    let terminals = [...await this.store.pipe(select(appState.getTerminals)).pipe(take(1)).toPromise()];
    terminals = this.isNew ? terminals.filter(i => i.active) : terminals;
    const filteredTerminals = portId ? terminals.filter(o => o.port && o.port.id === portId) : terminals;
    return filteredTerminals.sort((a, b) => a.name > b.name ? 1 : -1).map(t => { return { label: t.name, value: t.id }; })
  }

  async buildAndFilterDockOptions(terminalId: string): Promise<SelectItem[]> {
    let docks = [...await this.store.pipe(select(appState.getDocks)).pipe(take(1)).toPromise()];
    docks = this.isNew ? docks.filter(i => i.active) : docks;
    const filteredDocks = terminalId ? docks.filter(o => o.terminal && o.terminal.id === terminalId) : docks;
    return filteredDocks.sort((a, b) => a.name > b.name ? 1 : -1).map(t => { return { label: t.name, value: t.id }; })
  }

  listenToJobChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJob)).subscribe(async (jobState) => {
        let localJobOverview = (cloneDeep(jobState) as Job)?.overview;

        if (localJobOverview.ship) {
          this.jobOverviewShipChanged.emit(localJobOverview.ship);
        }
        if (localJobOverview.cargoProduct) {
          this.jobOverviewCargoChanged.emit(localJobOverview.cargoProduct);
        }
        if (localJobOverview.terminal) {
          this.jobOverviewTerminalChanged.emit(localJobOverview.terminal.id);
        }

        this.selectedReportHours = localJobOverview.reportHours?.split(',');
        // For calendar control
        localJobOverview.date = localJobOverview.date ? new Date(localJobOverview.date) : null;
        this.selectedCargoId = localJobOverview.cargoProduct?.id;
        this.selectedClientId = localJobOverview.client?.id;
        this.selectedPortId = localJobOverview.port?.id;
        this.selectedTerminalId = localJobOverview.terminal?.id;
        this.selectedDockId = localJobOverview.dock?.id;
        this.selectedInspectionCompanyId = localJobOverview.inspectionCompany?.id;
        this.selectedSuperintendents = localJobOverview.superintendents.map(s => s.id);
        this.selectedReportContacts = localJobOverview.reportContacts.map(c => c.id);
        this.IsInjection = localJobOverview.injection === 'Y' ? true : false;
        if (this.ready) {
          await this.buildOptions(localJobOverview);
        }
        this.jobOverview = localJobOverview;
        await this.updateReportContactOptions();
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(appState.getIsJobNew)).subscribe(async (isNew) => {
        this.isNew = isNew;
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(appState.getIsJobLoading)).subscribe(async (isLoading) => {
        this.isJobLoading = isLoading;
      })
    );
  }

  reportHoursChanged() {
    this.jobOverview.reportHours = this.selectedReportHours?.join(',');
  }

  save() {
    const validationErrors = this.validationForm(this.jobOverview);
    if (validationErrors) {
      this.appMessageService.warnMessage('Overview Validation Errors:', validationErrors);
    } else {
      this.jobOverview.injection = this.IsInjection ? 'Y' : 'N';
      // See if any status changes from overview updates
      this.jobOverview = this.statusManagementService.updateJobStatusOnOverviewChanges(this.jobOverview);

      const setOverviewProperties = new appActions.SetJobActionProperties<JobOverview>();
      setOverviewProperties.id = this.jobOverview.id;
      setOverviewProperties.jobId = this.jobOverview.jobId;
      setOverviewProperties.jobNumber = this.jobOverview.jobNumber;
      setOverviewProperties.data = this.jobOverview;
      this.store.dispatch(appActions.setJobOverview({ overview: setOverviewProperties }));
    }
  }

  async reset() {
    const initialJob = await this.store.pipe(select(appState.getSelectedJob), take(1)).toPromise();
    this.jobOverview = cloneDeep(initialJob).overview;
  }

  return() {
    this.router.navigate(['/jobs']);
  }

  listenForSuccessfulSave() {
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(appActions.jobSavedSuccessfully))
        .subscribe(() => this.router.navigate(['/jobs']))
    );
  }

  // TODO: Refactor to use reactive forms and inline validation
  validationForm(overview: JobOverview): string {
    const errors: string[] = [];

    if (!overview.ship) errors.push('Ship is required');
    if (!overview.cargoProduct) errors.push('Cargo is required'); 
    if (!overview.port) errors.push('Port is required');
    if (!overview.terminal) errors.push('Terminal is required');
    if (!overview.dock) errors.push('Dock is required');
    if (!overview.superintendents || overview.superintendents.length === 0) errors.push('At least one Superintendent is required');
    if (!overview.date) errors.push('Date is required');

    return errors.join(', ');
  }

  async onShipChanged(ship: Ship) {
    this.jobOverviewShipChanged.emit(ship);
  }

  async onCargoChanged(cargo: Product) {
    this.jobOverviewCargoChanged.emit(cargo);
  }

  async onClientChanged(clientId: string) {
    const clients = await this.store.pipe(select(appState.getClients)).pipe(take(1)).toPromise();
    this.jobOverview.client = clients.find(c => c.id === clientId);
    await this.updateReportContactOptions();
  }

  async onPortChanged(portId: string) {
    this.terminalOptions = await this.buildAndFilterTerminalOptions(portId);
    const ports = await this.store.pipe(select(appState.getPortLocations)).pipe(take(1)).toPromise();
    this.jobOverview.port = ports.find(p => p.id === portId);
  }

  async onTerminalChanged(terminalId: string) {
    this.dockOptions = await this.buildAndFilterDockOptions(terminalId);
    const terminals = await this.store.pipe(select(appState.getTerminals)).pipe(take(1)).toPromise();
    this.jobOverview.terminal = terminals.find(t => t.id === terminalId);
    await this.updateReportContactOptions();
    this.jobOverviewTerminalChanged.emit(terminalId);
  }

  async onDockChanged(dockId: string) {
    const docks = await this.store.pipe(select(appState.getDocks)).pipe(take(1)).toPromise();
    this.jobOverview.dock = docks.find(c => c.id === dockId);
  }

  async onInspCompanyChanged(inspCompId: string) {
    const inspCompanies = await this.store.pipe(select(appState.getInspectionCompanies)).pipe(take(1)).toPromise();
    this.jobOverview.inspectionCompany = inspCompanies.find(i => i.id === inspCompId);
    await this.updateReportContactOptions();
  }

  async onSuperintendentsChanged(superintendentIds: string[] = []) {
    const superintendents = await this.store.pipe(select(appState.getSuperintendents)).pipe(take(1)).toPromise();
    const supersToAdd: User[] = [];
    superintendentIds.forEach(id => { supersToAdd.push(superintendents.find(s => s.id === id)) });
    this.jobOverview.superintendents = [...supersToAdd];
    await this.updateReportContactOptions();
  }

  async onReportContactsChanged(reportContactIds: string[] = []) {
    const contacts = await this.store.pipe(select(appState.getContacts)).pipe(take(1)).toPromise();
    const contactsToAdd: Contact[] = [];
    reportContactIds.forEach(id => { contactsToAdd.push(contacts.find(s => s.id === id)) });
    this.jobOverview.reportContacts = [...contactsToAdd];
  }

  async updateReportContactOptions() {
    const contactOptions: SelectItem[] = [];
    const allContacts = await this.store.pipe(select(appState.getContacts)).pipe(take(1)).toPromise();
    if (allContacts) {
      if (this.jobOverview?.terminal) {
        let terminals = [...await this.store.pipe(select(appState.getTerminals)).pipe(take(1)).toPromise()];
        if (terminals) {
          terminals = this.isNew ? terminals.filter(i => i.active) : terminals;
          const terminalContacts = terminals.find(t => t.id === this.jobOverview.terminal?.id)?.contacts;
          terminalContacts?.map(c => {
            const contact = Contact.fromJson(allContacts.find(contact => contact.id === c.id));
            if (contact) {
              contactOptions.push({ label: contact.displayName + ' (' + contact.email + ')', value: c.id })
            }
          });
        }
      }

      if (this.jobOverview?.client) {
        let clients = [...await this.store.pipe(select(appState.getClients)).pipe(take(1)).toPromise()];
        if (clients) {
          clients = this.isNew ? clients.filter(i => i.active) : clients;
          const clientContacts = clients.find(c => c.id === this.jobOverview.client?.id)?.contacts;
          clientContacts?.map(c => {
            const contact = Contact.fromJson(allContacts.find(contact => contact.id === c.id));
            if (contact) {
              contactOptions.push({ label: contact.displayName + ' (' + contact.email + ')', value: c.id })
            }
          });
        }
      }

      if (this.jobOverview?.inspectionCompany) {
        let inspCompanies = [...await this.store.pipe(select(appState.getInspectionCompanies)).pipe(take(1)).toPromise()];
        if (inspCompanies) {
          inspCompanies = this.isNew ? inspCompanies.filter(i => i.active) : inspCompanies;
          const inspCompanyContacts = inspCompanies.find(c => c.id === this.jobOverview.inspectionCompany?.id)?.contacts;
          inspCompanyContacts?.map(c => {
            const contact = Contact.fromJson(allContacts.find(contact => contact.id === c.id));
            if (contact) {
              contactOptions.push({ label: contact.displayName + ' (' + contact.email + ')', value: c.id })
            }
          });
        }
      }

      this.reportContactOptions = contactOptions.sort((a, b) => a.label > b.label ? 1 : 1);
    }
  }

  confirmCancelJob() {
    this.confirmationService.confirm({
      key: 'confirmation',
      header: 'Confirm Job Cancellation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to cancel Job Number: ' + this.jobOverview.jobNumber + '?',
      accept: () => {
        this.showCancelJob = true;
      },
      reject: () => { }
    });
  }

  async cancelJob() {
    this.jobOverview.isCancelled = true;
    this.jobOverview.cancelledReason = this.cancelledReason;
    this.jobOverview.cancelledOn = new Date();
    const currentUser = await this.store.pipe(select(appState.getCurrentUser)).pipe(take(1)).toPromise();
    this.jobOverview.cancelledBy = currentUser.id;

    this.save();

    this.showCancelJob = false;
    this.cancelledReason = null;
  }

  confirmUnCancelJob() {
    this.confirmationService.confirm({
      key: 'confirmation',
      header: 'Confirm Job Reactivation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to reactivate Job Number: ' + this.jobOverview.jobNumber + '?',
      accept: async () => {
        await this.unCancelJob();
      },
      reject: () => { }
    });
  }

  async unCancelJob(): Promise<void> {
    this.jobOverview.isCancelled = false;
    this.jobOverview.cancelledReason = null;
    this.jobOverview.cancelledOn = null;
    this.jobOverview.cancelledBy = null;

    this.jobOverview.status = await this.statusManagementService.getStatusWhenReturningFromUnCancel(this.jobOverview);

    this.save();
  }

  get showNewJobActions(): boolean {
    return this.isNew &&
      !this.isJobLoading;
  }

  get showNormalActions(): boolean {
    return !this.isNew &&
      !this.isJobLoading &&
      this.jobOverview.status !== JobStatuses.cancelled &&
      this.jobOverview.status !== JobStatuses.complete &&
      this.jobOverview.status !== JobStatuses.closed;
  }

  get showCancelledActions(): boolean {
    return !this.isNew &&
      !this.isJobLoading &&
      this.jobOverview.status === JobStatuses.cancelled;
  }

  get showCompletedActions(): boolean {
    return !this.isNew &&
      !this.isJobLoading &&
      this.jobOverview.status === JobStatuses.complete;
  }

  get showClosedActions(): boolean {
    return !this.isNew &&
      (this.jobOverview.status === JobStatuses.complete ||
        this.jobOverview.status === JobStatuses.closed);
  }

}
