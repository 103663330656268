import { TimeTrackingEventLog } from './time-tracking-event-log';

export class TimeTrackingEventLogTableData {
    id: string;
    jobId: string;
    timeTrackingEventName: string;
    requiresTank: boolean = false;
    assignedTankId?: string;
    assignedTankName?: string;
    notes?: string;
    isDuration: boolean = false;
    startTime: string;
    startTimeDate: Date;
    endTimeDate?: Date;

    // For Duration events
    endTime?: string;
    duration?: string;

    constructor(log: TimeTrackingEventLog, eventName: string = null, requiresTank: boolean = false, assignedTankId: string = null, assignedTankName: string = null, isDuration: boolean = false) {
        this.id = log.id;
        this.jobId = log.jobId;
        this.timeTrackingEventName = eventName;
        this.requiresTank = requiresTank;
        this.assignedTankId = assignedTankId
        this.assignedTankName = assignedTankName;
        this.notes = log.notes;
        this.isDuration = isDuration;
        this.startTimeDate = log.startTime;
        this.endTimeDate = log.endTime;
        const hourPart = log.duration.days() * 24 + log.duration.hours();
        this.duration = ("00" + hourPart).slice(-2) + ':' + ("00" + log.duration.minutes()).slice(-2);
    }
}