import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { TerminalListComponent } from './system-config/terminals/terminal-list/terminal-list.component';
import { JobDetailComponent } from './job/job-detail/job-detail.component';
import { JobBoardComponent } from './job/job-board/job-board.component';
import { JobListComponent } from './job/job-list/job-list.component';
import { HomeComponent } from './home/home.component';
import { PortsListComponent } from './system-config/ports/ports-list/ports-list.component';
import { UserListComponent } from './system-config/users/user-list/user-list.component';
import { ClientListComponent } from './system-config/client-companies/client-list/client-list.component';
import { DocksListComponent } from './system-config/docks/docks-list/docks-list.component';
import { InspectionCompaniesListComponent } from './system-config/inspection-companies/inspection-companies-list/inspection-companies-list.component';
import { TimeTrackingListComponent } from './system-config/time-tracking/time-tracking-list/time-tracking-list.component';
import { ShipListComponent } from './system-config/ships/ship-list/ship-list.component';
import { TankListComponent } from './system-config/tanks/tank-list/tank-list.component';
import { DashboardComponent } from './reports/dashboard/dashboard.component';
import { JobPerformanceComponent } from './reports/job-performance/job-performance.component';
import { CustomerJobPerformanceComponent } from './reports/customer-job-performance/customer-job-performance.component';
import { JobTrendsComponent } from './reports/job-trends/job-trends.component';
import { TerminalComparisonComponent } from './reports/terminal-comparison/terminal-comparison.component';
import { TimingCategoryListComponent } from './system-config/timing-categories/timing-category-list/timing-category-list.component';
import { ProductListComponent } from './system-config/products/product-list/product-list.component';
import { CustomerMainComponent } from './customer/main/main.component';
import { CustomerJobsListComponent } from './customer/jobs-list/jobs-list.component';
import { CustomerJobsDetailComponent } from './customer/jobs-detail/jobs-detail.component';
import { KpiComponent } from './customer/kpi/kpi.component';
import { CustomerFeedbackComponent } from './customer/feedback/feedback.component';

const routes: Routes = [
  { path: '', children: [
    { path: 'jobs/job-board', component: JobBoardComponent, pathMatch: 'full' },
    { path: 'jobs', component: JobListComponent, pathMatch: 'full' },
    { path: 'jobs/:id', component: JobDetailComponent, pathMatch: 'full' },
    { path: 'config/ships', component: ShipListComponent, pathMatch: 'full' },
    { path: 'config/terminals', component: TerminalListComponent, pathMatch: 'full' },
    { path: 'config/ports', component: PortsListComponent, pathMatch: 'full' },
    { path: 'config/docks', component: DocksListComponent, pathMatch: 'full' },
    { path: 'config/tanks', component: TankListComponent, pathMatch: 'full' },
    { path: 'config/clients', component: ClientListComponent, pathMatch: 'full' },
    { path: 'config/inspcompanies', component: InspectionCompaniesListComponent, pathMatch: 'full' },
    { path: 'config/events', component: TimeTrackingListComponent, pathMatch: 'full' },
    { path: 'config/timingcategories', component: TimingCategoryListComponent, pathMatch: 'full'},
    { path: 'config/users', component: UserListComponent, pathMatch: 'full'},
    { path: 'error', component: HomeComponent }, // Needed for hash routing
    { path: 'state', component: HomeComponent }, // Needed for hash routing
    { path: 'code', component: HomeComponent }, // Needed for hash routing
    { path: '', component: HomeComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard] },
    { path: 'report/jobperformance/:status/:id', component: JobPerformanceComponent },
    { path: 'report/customer', component: CustomerJobPerformanceComponent },
    { path: 'report/trend', component: JobTrendsComponent },
    { path: 'report/terminalcomparison', component: TerminalComparisonComponent },
    { path: 'config/products', component: ProductListComponent, pathMatch: 'full' },
    { path: 'customer/main', component: CustomerMainComponent },
    { path: 'customer/jobs-list/:status', component: CustomerJobsListComponent },
    { path: 'customer/jobs-detail/:status/:id', component: CustomerJobsDetailComponent },
    { path: 'customer/kpi', component: KpiComponent },
    { path: 'customer/feedback/:id/:action', component: CustomerFeedbackComponent }
  ]}
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
