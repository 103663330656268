<p-blockUI [target]="section" [blocked]="blocked">
    <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>

<app-blockable-div #section [class]="'p-grid'">

  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-md-10 p-col-12">
        <app-job-bfr-header [bfrHeader]="bfrHeader"></app-job-bfr-header>
      </div>
      <div class="p-md-2 p-col-12">
        <p-button label="Download" [disabled]="blocked" styleClass="p-button-help" icon="pi pi-download" class="p-button-secondary" (onClick)="openPopUp()" pTooltip="Download the BFR" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>

  </div>

  <p-divider align="left">
    <div class="p-d-inline-flex p-ai-center bfr-divider-content">
      Details
    </div>
  </p-divider>

  <p-dialog header="Tank Selection" [(visible)]="showPopUp" [modal]="true">
    <div *ngIf="showPopUp">
      <!--Comment Use this if you want to have the tanks options as a dropdown>
  <p-multiSelect [options]="tankOptions" [(ngModel)]="selectedTanks" appendTo="body" placeholder="Select Required Tanks"></p-multiSelect>
  <Comment-->
      
      <div *ngFor="let option of tankOptions; index as i">
        <div class="p-col-6 p-md-10 p-grid">
          <br />
          <p-checkbox name="{{option}}" label="{{option}}" value="option" [value]="option" [(ngModel)]="selectedTanks"></p-checkbox>
        </div>
      </div>
    </div>
      
    <div *ngIf="showPopUp" class="popup">
      <p-button label="OK" class="p-button-secondary" (onClick)="onTanksChanged()" [style]="{'width': '100%'}"></p-button>
    </div>
  </p-dialog>

  <div class="p-col-12">
    <p-tabView>
      <p-tabPanel header="Table">
        <div class="p-grid">
          <div *ngIf="bfr && bfr.bfrTanks" class="p-col-12 p-d-none p-d-md-inline">
            <p-table [value]="tableData"
                     [columns]="largeTableCols"
                     [responsive]="false"
                     [scrollable]="true"
                     [scrollHeight]="'42vh'"
                     [style]="{width: '100%'}">
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [style]="{'width':col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns | colDisplayFilter"
                      [ngClass]="{
                                            'bfr-header': col.header && !col.isTotalColumn,
                                            'bfr-header-total': col.isTotalColumn,
                                            'bfr-header-emtpy': !col.header
                                        }">{{col.header}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td *ngIf="row.isTankDefRow"><button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editTank(row.step)" pTooltip="Edit this step"></button></td>
                  <td *ngIf="!row.isTankDefRow"></td>
                  <td>{{row.strappedVsBarrelsPerApiText}}</td>
                  <td [ngClass]="{'bfr-api': row.isTankDefRow || row.tankOrMeasurementType === 'Open'}">{{row.strappedVsBarrelsPerApiValue}}</td>
                  <td>{{row.step}}</td>
                  <td class="bfr-bold" [ngClass]="{'bfr-tank-id':row.isTankDefRow}">{{row.tankOrMeasurementType}}</td>
                  <td>{{row.ullageFt}}</td>
                  <td [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.tovUsBarrels | number: '1.1-2'}}</td>
                  <td>{{row.freeGauge}}</td>
                  <td>{{row.waterBarrels}}</td>
                  <td>{{row.tankTemp}}</td>
                  <td>{{row.ambientTemp}}</td>
                  <td>{{row.shellFactor | number: '1.1-5'}}</td>
                  <td>{{row.govUsBarrels | number: '1.1-2'}}</td>
                  <td>{{row.roofCorrection | number: '1.1-2'}}</td>
                  <td>{{row.astmTableOption}}</td>
                  <td>{{row.api}}</td>
                  <td>{{row.astmVcf | number: '1.1-5'}}</td>
                  <td [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.gsvUsBarrels | number: '1.1-2'}}</td>
                  <td>{{row.swPercent}}</td>
                  <td [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.nsvBarrels | number: '1.1-2'}}</td>
                  <td [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.expectedDelivery}}</td>
                  <td *ngIf="row.nominatedQuantity >= 0" [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.nominatedQuantity}}</td>
                  <td *ngIf="row.nominatedQuantity < 0" [ngClass]="{'bfr-bold':row.isTotalRow}">PUSH</td>
                  <td [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.adjustedLines | number: '1.1-2'}}</td>
                  <td [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.variance | number: '1.1-2'}}</td>
                  <td *ngIf="row.lineCapacity < 0" class="debted-number" [ngClass]="{'bfr-bold':row.isTotalRow}">{{'(' + -row.lineCapacity + ')'}}</td>
                  <td *ngIf="row.lineCapacity >= 0" [ngClass]="{'bfr-bold':row.isTotalRow}">{{row.lineCapacity}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer" let-columns>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="bfr-bold">{{bfr.gsvUsBarrelsTotal | number: '1.1-2'}}</td>
                  <td></td>
                  <td class="bfr-bold">{{bfr.nsvBarrelsTotal | number: '1.1-2'}}</td>
                  <td class="bfr-bold">{{bfr.expectedDeliveryTotal | number: '1.1-2'}}</td>
                  <td></td>
                  <td></td>
                  <td class="bfr-bold">{{bfr.varianceTotal | number: '1.1-2'}}</td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="p-col-12 p-d-md-none">
            <p-table [value]="tableData"
                     [columns]="mobileTableCols"
                     [responsive]="true"
                     [style]="{width: '100%'}"
                     styleClass="p-datatable-striped">
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td>Step: {{row.step}}</td>
                  <td>Id: {{row.tankId}}</td>
                  <td>G.S.V. US Barrels: {{row.gsvUsBarrels}}</td>
                  <td>N.S.V. Barrels: {{row.nsvBarrels}}</td>
                  <td>
                    <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editStep(row.step)" pTooltip="Edit this step"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Summary">
        <div class="p-grid">
          <div class="p-col-12">
            <app-job-bfr-summary [bfr]="bfr"></app-job-bfr-summary>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-md-6 p-col-12">
        <p-button label="Reset" [disabled]="blocked" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-6 p-col-12">
        <p-button label="Save" [disabled]="blocked" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
  </div>
</app-blockable-div>

<p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
       <app-job-bfr-tank-detail
        [tank]="selectedBfrTank"
        [shoreTank]="shoreTank"
        (saved)="tankSaved()"
        (cancelled)="editCancelled()"
       ></app-job-bfr-tank-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-bfr-tank-detail
        [tank]="selectedBfrTank"
        [shoreTank]="shoreTank"
        (saved)="tankSaved()"
        (cancelled)="editCancelled()"
       ></app-job-bfr-tank-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
