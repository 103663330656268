import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { Job, JobOverview, JobStatuses, TimeTrackingEvent } from "../models";
import { TimeTrackingEventLog } from "../models/job/time-tracking/time-tracking-event-log";
import { SystemTriggerEventIds } from "../models/system-config/system-trigger-event-ids";
import * as appState from '../state';
import * as appActions from '../state/app.actions';

@Injectable({
    providedIn: 'root'
})
export class StatusManagementService {
    constructor(private store: Store<appState.State>) {}

    updateJobStatusOnOverviewChanges(overview: JobOverview): JobOverview {
        if (overview) {
            // For all statuses
            if (overview.isCancelled && overview.status !== JobStatuses.cancelled) {
                overview.status = JobStatuses.cancelled;
            } else if (overview.hasFiguresBeenConfirmed && overview.isVesselOnSpec) {
                // Figures are confirmed and any answer for isVesselOnSpec given then closed
                overview.status = JobStatuses.closed;
            } else {
                // rules on overview changes for each individual status
                switch (overview.status) {
                    case JobStatuses.unconfirmed:
                        if (overview.isConfirmed) {
                            overview.status = JobStatuses.incoming;
                        }
                        break;
                    case JobStatuses.incoming:
                        if (!overview.isConfirmed) {
                            overview.status = JobStatuses.unconfirmed;
                        }
                        break;
                    case JobStatuses.active:
                        break;
                    case JobStatuses.complete:
                        break;
                    case JobStatuses.cancelled:
                        // If is cancelled was removed
                        if (!overview.isCancelled) {
                            overview.status = JobStatuses.active;
                        }
                        break;
                    case JobStatuses.closed: {
                        // If figures confirmed removed or Is Vessel On Spec removed
                        if (!overview.hasFiguresBeenConfirmed || !overview.isVesselOnSpec) {
                            overview.status = JobStatuses.complete;
                        }
                    }
                    default:
                        break;
                }
            }
        } 
        return overview;
    }


    async processTimeTrackingEventLog(timeTrackingEventLog: TimeTrackingEventLog): Promise<void> {
        const currentJobState = await this.store.pipe(select(appState.getSelectedJob)).pipe(take(1)).toPromise();
        
        if (currentJobState && timeTrackingEventLog) {
            const events = await this.store.pipe(select(appState.getTimeTrackingEvents)).pipe(take(1)).toPromise();
            const targetEvent = events.find(e => e.id === timeTrackingEventLog.timeTrackingEventId);
            if (targetEvent) {
                let requiresJobUpdate = false;
                let overview = {...currentJobState.overview };
                switch(targetEvent.id.toUpperCase()) {
                    case SystemTriggerEventIds.firstLineCommencedMooring:
                        if ((overview.status === JobStatuses.unconfirmed || overview.status === JobStatuses.incoming) &&
                            timeTrackingEventLog.active) {
                            overview = {
                                ...currentJobState.overview,
                                status: JobStatuses.active 
                            };
                            requiresJobUpdate = true;
                        } else if (overview.status === JobStatuses.active && !timeTrackingEventLog.active) {
                            overview = {
                                ...currentJobState.overview,
                                status: currentJobState.overview.isConfirmed ? JobStatuses.incoming : JobStatuses.unconfirmed
                            };
                            requiresJobUpdate = true;
                        }
                        break;
                    case SystemTriggerEventIds.finishedLoadingFromShortTanks:
                        // If not in complete state, move to complete if all tanks have finished loading
                        // If currently completed but events are updated to remove end time or deactivated,
                        // move status back to active
                        const allTanksLoaded = await this.confirmAllTanksCompleted(timeTrackingEventLog);
                        if (allTanksLoaded &&
                            (currentJobState.overview.status === JobStatuses.unconfirmed ||
                             currentJobState.overview.status === JobStatuses.incoming ||
                             currentJobState.overview.status === JobStatuses.active)) {
                            overview = {
                                ...currentJobState.overview,
                                isLoadingComplete: true,
                                status: JobStatuses.complete 
                            };
                            requiresJobUpdate = true;
                        } else if (!allTanksLoaded && currentJobState.overview.status === JobStatuses.complete) {
                            overview = {
                                ...currentJobState.overview,
                                isLoadingComplete: true,
                                status: JobStatuses.active 
                            };
                            requiresJobUpdate = true;
                        }
                        break;
                    default:
                        break;
                }
                

                if (requiresJobUpdate) { 
                    this.store.dispatch(appActions.setJobOverview({ overview:  {
                        id: currentJobState.overview.id,
                        jobId: currentJobState.id,
                        jobNumber: currentJobState.jobNumber,
                        data: overview,
                        silent: true
                    } }));
                }
                else { 
                    this.store.dispatch(appActions.jobSavedSuccessfully({ jobSection: 'Time Tracking' }))
                }
            }
            
        }
    }

    async getStatusWhenReturningFromUnCancel(jobOverview: JobOverview): Promise<string> {
        const timeTrackingEventLogs = await this.store.pipe(select(appState.getSelectedJobTimeTrackingEventLogs)).pipe(take(1)).toPromise();
        if (timeTrackingEventLogs) {
            if (await this.confirmAllTanksCompleted()) {
                return JobStatuses.complete;
            }

            if (timeTrackingEventLogs.some(t => t.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.firstLineCommencedMooring)) {
                return JobStatuses.active;
            }
        }
        return jobOverview.isConfirmed ? JobStatuses.incoming : JobStatuses.unconfirmed;
    }

    private async confirmAllTanksCompleted(incomingEventLog: TimeTrackingEventLog = null): Promise<boolean> {
        const blpTanks = await this.store.pipe(select(appState.getJobShoreTankIds)).pipe(take(1)).toPromise();
        if (blpTanks && blpTanks.length) {
            const timeTrackingEventLogs = await this.store.pipe(select(appState.getSelectedJobTimeTrackingEventLogs)).pipe(take(1)).toPromise();
            let finishLoadingEventLogs = timeTrackingEventLogs?.filter(l => l.timeTrackingEventId.toUpperCase() === SystemTriggerEventIds.finishedLoadingFromShortTanks && l.endTime && l.active);
            
            if (incomingEventLog) {
                // remove instance of found events that is the same of as the incoming event log as the incoming event
                // as updates may not have been applied to state yet
                finishLoadingEventLogs = finishLoadingEventLogs.filter(l => l.id !== incomingEventLog.id);
                
                // if the incoming event is finished, add to list
                if (incomingEventLog.endTime && incomingEventLog.active) {
                    finishLoadingEventLogs.push(incomingEventLog);
                }
            }
            let allTanksLoaded = true;
            blpTanks.forEach(t => {
                const tankLoaded = finishLoadingEventLogs.find(l => l.assignedTankId === t);
                if (!tankLoaded) {
                    allTanksLoaded = false;
                }
            });
            return allTanksLoaded;
        }    
        
        return false;
    }
}
