import { Terminal } from 'src/app/models';
import { TimingInfo } from 'src/app/models/reports/job-performance';
import { JobTrendInfo } from 'src/app/models/reports/terminal-trend-report';
import { ClientCompany } from 'src/app/models/system-config/client-company';

export class TerminalComparisonReport {
    customer: ClientCompany;
    categoryTimings: CategoryTiming[];
    downtimeByReasonsTimings: TerminalTimingInfo[];
    downtimeByReasonChartData: any;
}

export class TerminalTimingInfo {
    terminal: Terminal;
    duration: number;
    durationText: string;
    categoryTimings: TimingInfo[];
}

export class CategoryTiming {
    category: string;
    terminalTimings: TerminalTiming[];
    chartData: any;
}

export class TerminalTiming {
    terminal: Terminal;
    jobInfos: JobTrendInfo[];
    duration: number;
    durationText: string;
}

export class DowntimeReasonInfo {
    reason: string;
    timings: TerminalTiming[];
}