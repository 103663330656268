import { Component, Input, OnInit } from '@angular/core';
import { Bfr } from 'src/app/models';

@Component({
  selector: 'app-job-bfr-summary',
  templateUrl: './job-bfr-summary.component.html',
  styleUrls: ['./job-bfr-summary.component.scss']
})
export class JobBfrSummaryComponent implements OnInit {

  @Input() bfr: Bfr;

  dummyRowData: any[] = [1];
  
  constructor() { }

  ngOnInit(): void {
  }

}
