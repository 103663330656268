import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlpLineItemMeasurement } from 'src/app/models';

@Component({
  selector: 'app-job-blp-measurement',
  templateUrl: './job-blp-measurement.component.html',
  styleUrls: ['./job-blp-measurement.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobBlpMeasurementComponent),
    multi: true
  }]
})
export class JobBlpMeasurementComponent implements OnInit, ControlValueAccessor {

  @Input() showPushOption: boolean = false;
  
  _measurement: BlpLineItemMeasurement;
  get measurement(): BlpLineItemMeasurement {
    return this._measurement;
  }
  set measurement(val: BlpLineItemMeasurement) {
    this._measurement = val;
    this.onChange(val);
  }

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(obj: BlpLineItemMeasurement): void {
    if (obj) {
      this.measurement = obj;
    }
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
