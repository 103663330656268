import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ClientCompany, HomeDetails, QuickLook, User, UserRoles } from "../../models";
import { take } from 'rxjs/operators';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { CustomerService } from 'src/app/services/http-services/customer.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { Subscription } from 'rxjs';
import { Feedback } from '../../models/customer/feedback';
import { JobService } from '../../services/http-services/job.service';


@Component({
  selector: 'app-customerfeedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnInit, OnDestroy {
  pageDataReady: boolean = false;
  createMode: boolean = false;
  satRatingLevel: number;
  satComments: string;
  qcRatingLevel: number;
  qcComments: string;
  generalComments: string;
  subscriptions: Subscription[] = [];
  feedback: Feedback = new Feedback();
  jobId: string;

  constructor(
    private store: Store<appState.State>,
    private jobService: JobService,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.initializeVariables();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initializeVariables() {
    this.jobId = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.paramMap.get('action') == 'create') {
      this.pageDataReady = true;
      this.createMode = true;
    }
    if (this.route.snapshot.paramMap.get('action') == 'view') {
      this.route.params.subscribe(
        async (params) => {
          this.store.dispatch(appActions.showSpinner());
          var data = await this.customerService.getFeedback(this.jobId)
            .toPromise()
            .catch(error => {
              this.store.dispatch(appActions.hideSpinner());
              this.appMessageService.errorMessage('Error getting Job Feedback', null);
              return null;
            });
          this.createMode = false;
          this.feedback = data;
          this.pageDataReady = true;
          this.store.dispatch(appActions.hideSpinner());
      });
    }
  }

  save() {
    this.feedback.jobId = this.jobId;

    this.store.dispatch(appActions.showSpinner());
    var data = this.jobService.upsertFeedback(this.jobId, this.feedback)
      .toPromise()
      .catch(error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error creating Job Feedback', null);
        return null;
      });
    this.createMode = false;
    this.store.dispatch(appActions.hideSpinner());
    this.appMessageService.successMessage('Job Feedback Saved', '', true);
    this.router.navigate(['customer/feedback/' + this.jobId + '/view']);
  }

  reset() {
    this.satRatingLevel = 0;
    this.satComments = '';
    this.qcRatingLevel = 0;
    this.qcComments = '';
    this.generalComments = '';
  }

  back() {
    this.router.navigate(['customer/jobs-list/complete']);
  }
}
