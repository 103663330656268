<p-menubar [model]="items">
  <ng-template pTemplate="start">
    <img src="assets/images/vessel-blenders-logo-excel.png" height="40" class="p-mr-2">
  </ng-template>
  <ng-template pTemplate="end">
    <p-dropdown *ngIf="showCustomerSelection" [options]="customerOptions" [(ngModel)]="selectedCustomer" appendTo="body"
      (onChange)="customerChanged()" placeholder="Select Customer"></p-dropdown>
    <button pButton type="button" icon="pi pi-user" class="p-button-rounded p-button-primary align-avatar p-d-none p-d-sm-inline-flex" (click)="usermenu1.toggle($event)"></button>
    <p-menu #usermenu1 [popup]="true" [model]="userItems" [style]="{width: userMenuWidth }"></p-menu>
  </ng-template>
</p-menubar>
<p-messages key="global-messages"></p-messages>
<p-toast key="global-toast-messages"></p-toast>
<div class="router-bg">
  <router-outlet></router-outlet>
</div>
<div *ngIf="showSpinner" class="global-spinner-background">
  <p-progressSpinner [style]="{width: '25vw', height: '25vw'}" strokeWidth="2" class="global-spinner"></p-progressSpinner>
</div>
<p-confirmDialog key="confirmation" [style]="{width: '50vw'}"></p-confirmDialog>
<p-dialog *ngIf="showProfileDialog" [(visible)]="showProfileDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="true">
  <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
      <app-user-profile [user]="currentUser" (userProfileUpdated)="userProfileUpdated($event)"></app-user-profile>
      <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
  </p-scrollPanel>
</p-dialog>


