import { Terminal } from 'src/app/models';
import { TimingInfo } from 'src/app/models/reports/job-performance';

export class TerminalPerformanceReport {
    terminal: Terminal;
    tankSwitch: number;
    tankSwitchText: string;
    netPumpingRate: number;
    grossPumpingRate: number;
    totalDowntime: number;
    totalDowntimeText: string;
    jobTimings: TimingInfo[];
    jobTimingsAll: TimingInfo[];
    downtimeAverages: TimingInfo[];
}
