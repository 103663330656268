import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { BlpStepTank, Tank, TimeTrackingEvent } from 'src/app/models';
import { TimeTrackingEventLog } from 'src/app/models/job/time-tracking/time-tracking-event-log';
import { TimeTrackingManagementService } from 'src/app/services/time-tracking-management.service';

@Component({
  selector: 'app-job-time-tracking-detail',
  templateUrl: './job-time-tracking-detail.component.html',
  styleUrls: ['./job-time-tracking-detail.component.scss']
})
export class JobTimeTrackingDetailComponent implements OnInit, OnChanges {

  @Input() log: TimeTrackingEventLog;
  @Input() shoreTanks: Tank[] = [];
  @Input() availableEvents: TimeTrackingEvent[] = [];
  @Input() tankToStepMappings: BlpStepTank[] = [];

  @Output() logSaved = new EventEmitter<TimeTrackingEventLog>();
  @Output() cancelled = new EventEmitter();

  categoryOptions: SelectItem[] = [];
  eventOptions: SelectItemGroup[] = [];
  tankOptions: SelectItem[] = [];
  blpStepOptions: SelectItem[] = [];
  requiresDuration: boolean = false;
  requiresTank: boolean = false;

  startingTime: Date;
  endingTime: Date;

  constructor(private timeTrackingManagementService: TimeTrackingManagementService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.categoryOptions = this.timeTrackingManagementService.buildCategoryOptions();
    this.eventOptions = this.timeTrackingManagementService.buildTimeTrackingEventGroupedOptions(this.availableEvents);
    this.tankOptions = this.timeTrackingManagementService.buildTankOptions(this.shoreTanks);
    this.updateRequirements(this.log.timeTrackingEventId);
    if(this.log && this.log.startTime) {
      this.startingTime = new Date(this.log.startTime);
    }
    if(this.log && this.log.endTime) {
      this.endingTime = new Date(this.log.endTime);
    }
    if (this.requiresTank) {
      this.updateBlpStepSelection();
    }
  }

  save() {
    if (this.startingTime) {
      this.log.startTime = new Date(this.startingTime);
    }
    if (this.endingTime) {
      this.log.endTime = new Date(this.endingTime);
    }

    this.logSaved.emit(this.log);
  }

  cancel() {
    this.cancelled.emit();
  }

  updateEventsFromCategories(category: string) {
    this.eventOptions = this.timeTrackingManagementService.buildTimeTrackingEventGroupedOptions(this.availableEvents, category);
  }

  updateRequirements(eventId: string) {
    if (eventId) {
      const targetEvent = this.availableEvents.find(e => e.id === eventId);
      if (targetEvent) {
        this.requiresDuration = targetEvent.requiresDuration;
        this.requiresTank = targetEvent.requiresTankAssignment
      }
    }
  }

  updateBlpStepSelection() {
    this.blpStepOptions = [];
    if (this.log.assignedTankId) {
      const availableSteps = this.tankToStepMappings.filter(l => l.tankId === this.log.assignedTankId);
      if (availableSteps) {
        availableSteps.forEach(s => {
          this.blpStepOptions.push({ label: s.step.toString(), value: s.step });
        })
      }
    }
    if (this.blpStepOptions.length === 1) {
      this.log.blpStepNumber = this.blpStepOptions[0].value;
    }
  }

}
