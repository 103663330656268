import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Bou, JobBlpRevision, Tank } from 'src/app/models';

@Component({
  selector: 'app-job-bou-key-times',
  templateUrl: './job-bou-key-times.component.html',
  styleUrls: ['./job-bou-key-times.component.scss']
})
export class JobBouKeyTimesComponent implements OnInit, OnChanges {

  @Input() bou: Bou;
  @Input() blp: JobBlpRevision;
  @Input() shoreTanks: Tank[] = [];
  @Output() obqUpdated = new EventEmitter();

  tankOptions: SelectItem[] = [];
  chart: ChartObject = new ChartObject();
  chartReady: boolean = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.tankOptions = this.buildLoadingFromOptions(this.blp, this.shoreTanks);
    const chartObject = {...this.buildGaugeChart(this.bou) };
    if (chartObject?.data && chartObject.data.length && chartObject.data[0].value > 0) {
      this.chart.data = [...chartObject.data];
      this.chart.layout = {...chartObject.layout};
      this.chartReady = true;
    }
    this.cdr.detectChanges();
  }

  buildLoadingFromOptions(blp: JobBlpRevision, tanks: Tank[]): SelectItem[] {
    const items: SelectItem[] = [];
    if (blp && blp.lineItems) {
      const steps = [...blp.lineItems].sort((a, b) => a.step > b.step ? 1 : -1);
      steps.forEach(l => {
        var tank = tanks.find(t => t.id == l.tankId);
        items.push({ label: tank?.name, value: l.tankId });  
      })
    }
    
    return items;
  }

  buildGaugeChart(currentBou: Bou): ChartObject {
    return {
      data: [
        {
          value: currentBou.percentLoaded ? currentBou.percentLoaded : 0,
          type: 'indicator',
          mode: 'gauge+number',
          title: { text: "Percent Loaded" },
          gauge: { axis: { visible: false, range: [0, 100] } }
        }
      ],
      layout: {
        height: 125,
        margin: { t: 0, b: 0 } },
    }
  }

  updateBou() {
    this.obqUpdated.emit();
  }
}

export class ChartObject {
  data: any;
  layout: any;
}
