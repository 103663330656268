import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as cloneDeep from 'lodash.clonedeep';
import { User, UserProfile } from 'src/app/models/user';
import { UserService } from 'src/app/services/http-services/user.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input() user: User;
  @Output() userProfileUpdated = new EventEmitter<User>();
  userInEdit: User;
  resetPassword: boolean;
  password: string;
  minLength: number = 8;

  constructor(
    private userService: UserService,
    private appMessageService: AppMessageService
  ) { }

  ngOnInit(): void {
    this.userInEdit = cloneDeep(this.user);
  }

  reset(){
    this.userInEdit = cloneDeep(this.user);
    this.resetPassword = false;
    this.password = '';
  }

  save(){
    if (!this.userProfileValid())
      return;

    var userProfile = new UserProfile();
    userProfile.user = this.userInEdit;
    if (this.resetPassword){
      userProfile.resetPassword = true;
      userProfile.password = this.password.trim();
    }
    this.userService.updateUserProfile(userProfile).subscribe(
      result => {
        console.log(result);
        this.appMessageService.successMessage('User Profile Saved', '');
        this.userProfileUpdated.emit(this.userInEdit);
      },
      error => {
        this.appMessageService.errorMessage('Error saving User Profile', '');
        console.log(error);
      }
    )
  }

  userProfileValid(): boolean{
    var msgs = [];
    if (!this.userInEdit.lastName || this.userInEdit.lastName.trim() == '')
      msgs.push('Last Name is missing');
    if (!this.userInEdit.firstName || this.userInEdit.firstName.trim() == '')
      msgs.push('First Name is missing');
    if (this.resetPassword){
      if (!this.password || this.password.trim().length < this.minLength)
        msgs.push('Password must be at least 8 characters');
    }

    if (msgs.length > 0)
      this.appMessageService.errorMessage('Missing/Invalid user profile', msgs.join('\n'));

    return msgs.length == 0;
  }

}
