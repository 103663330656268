import { Injectable } from "@angular/core";
import { SelectItem, SelectItemGroup } from "primeng/api";
import { TimeTrackingEvent, TimeTrackingEventCategories, Tank } from "../models";

@Injectable({
    providedIn: 'root'
})
export class TimeTrackingManagementService {
    constructor() {}

    buildTankOptions(tanks: Tank[]): SelectItem[] {
        if (tanks && tanks.length) {
            return tanks.map(t => {
                return { label: t.name, value: t.id };
            }).sort((a, b) => a > b ? 1 : -1);
        }
        return [];
    }

    buildTimeTrackingEventOptions(timeTrackingEvents: TimeTrackingEvent[], category: string = null): SelectItem[] {
        const events = category ? timeTrackingEvents.filter(e => e.category === category) : timeTrackingEvents;
        if (events && events.length) {
            return events.filter(e => e.active).map(t => {
                return { label: t.name, value: t.id }
            }).sort((a, b) => a.label > b.label ? 1 : -1);
        }
        return [];
    }

    buildTimeTrackingEventGroupedOptions(timeTrackingEvents: TimeTrackingEvent[], category: string = null): SelectItemGroup[] {
        const groupedItems: SelectItemGroup[] = [];
        const activeEvents = timeTrackingEvents.filter(e => e.active);
        const events = category ? activeEvents.filter(e => e.category === category) : activeEvents;
        if (events && events.length) {
            const requiredEvents = events.filter(e => e.isSystemTrigger);
            if (requiredEvents && requiredEvents.length) {
                groupedItems.push({
                    label: 'System Events',
                    items: requiredEvents
                            .sort((a, b) => a.name > b.name ? 1 : -1)
                            .map(e => { return { label: e.name, value: e.id }; })
                });
                
            }
            const optionalEvents = events.filter(e => !e.isSystemTrigger);
            if (optionalEvents && optionalEvents.length) {
                groupedItems.push({
                    label: 'Optional Events',
                    items: optionalEvents
                            .sort((a, b) => a.name > b.name ? 1 : -1)
                            .map(e => { return { label: e.name, value: e.id }; })
                });
                
            }
        }
        return groupedItems;
    }

    buildCategoryOptions() {
        const categoryOptions: SelectItem[] = [];
        Object.keys(TimeTrackingEventCategories).forEach(k => {
            categoryOptions.push({ label: TimeTrackingEventCategories[k], value: TimeTrackingEventCategories[k] })
        });
        return categoryOptions;
    }

    buildTableCols(): any[] {
        return [
            { field: 'timeTrackingEventName', header: 'Event', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
            { field: 'assignedTankName', header: 'Tank Id', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
            { field: 'startTime', header: 'Started Time', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: true },
            { field: 'endTime', header: 'Stopped Time', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: true },
            { field: 'duration', header: 'Duration', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false },
            { field: 'notes', header: 'Notes', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false }
        ];
    }

    doesTimeTrackingEventRequireDuration(eventId: string, timeTrackingEvents: TimeTrackingEvent[]): boolean {
        if (timeTrackingEvents && timeTrackingEvents.length) {
            const targetEvent = timeTrackingEvents.find(t => t.id === eventId);
            if (targetEvent) {
                return targetEvent.requiresDuration;
            }
        }
        return false;
    }

    doesTimeTrackingEventRequireTankAssignment(eventId: string, timeTrackingEvents: TimeTrackingEvent[]): boolean {
        if (timeTrackingEvents && timeTrackingEvents.length) {
            const targetEvent = timeTrackingEvents.find(t => t.id === eventId);
            if (targetEvent) {
                return targetEvent.requiresTankAssignment;
            }
        }
        return false;
    }
}