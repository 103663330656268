import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Dock, Terminal } from 'src/app/models';

@Component({
  selector: 'app-dock-detail',
  templateUrl: './dock-detail.component.html',
  styleUrls: ['./dock-detail.component.scss']
})
export class DockDetailComponent implements OnInit {

  @Input() dock: Dock;
  @Input() terminals: Terminal[] = [];
  @Output() dockUpdated = new EventEmitter<Dock>();
  @Output() resetPort = new EventEmitter();

  terminalOptions: SelectItem[] = [];

  constructor() { }

  ngOnInit(): void {
    this.terminalOptions = this.buildTerminalOptions();
  }

  buildTerminalOptions(): SelectItem[] {
    return this.terminals.sort((a,b) => a.name > b.name ?  1 :  -1).map(p => { return { 'label': p.name, value: p }; });
  }

  save() {
    this.dockUpdated.emit(this.dock);
  }

  reset() {
    this.resetPort.emit();
  }
}
