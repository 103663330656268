import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User, UserProfile } from "src/app/models/user";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiUrl: string = '/api/user'

    constructor(private httpClient: HttpClient) {}

    upsertUser(user: User): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + user.id, user);
    }

    updateUserProfile(userProfile: UserProfile): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/userprofile/' + userProfile.user.id, userProfile);
    }

    getUserByEmail(email: string): Observable<User> {
        return this.httpClient.get<User>(this.apiUrl + '/getbyemail/' + encodeURIComponent(email));
    }

    resetPassword(email: string): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + '/resetpassword/' + encodeURIComponent(email));
    }
}