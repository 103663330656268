import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { Bfr, Bou, BouActualLineItem, BouEstimationLineItem, Job, JobBlpRevision, JobOverview, TimeTrackingEventLog, Tank } from 'src/app/models';
import * as cloneDeep from 'lodash.clonedeep';
import { BouManagementService } from 'src/app/services/bou-managment.service';
import { take } from 'rxjs/operators';
import { SelectItem } from 'primeng/api/selectitem';
import { DownloadsService } from 'src/app/services/http-services/downloads.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'app-job-bou',
  templateUrl: './job-bou.component.html',
  styleUrls: ['./job-bou.component.scss']
})
export class JobBouComponent implements OnInit, OnDestroy {

  @Input() shoreTanks: Tank[] = [];

  bou: Bou = new Bou();
  jobOverview: JobOverview;
  latestBlp: JobBlpRevision;
  eventLogs: TimeTrackingEventLog[] = [];
  bfr: Bfr;
  ready: boolean = true;
  blocked: boolean = true;
  subscriptions: Subscription[] = [];
  bouStatusOptions: SelectItem[] = [];
  showEditEstimationDialog: boolean = false;
  showEditActualDialog: boolean = false;
  showEditVesselReporting: boolean = false;
  selectedEstimationLineItem: BouEstimationLineItem;
  selectedActualLineItem: BouActualLineItem;
  @ViewChild('section') section: ElementRef;

  constructor(
    private store: Store<appState.State>,
    private cdr: ChangeDetectorRef,
    private bouManagementService: BouManagementService,
    private appMessageService: AppMessageService,
    private downloadsService: DownloadsService) { }

  ngOnInit(): void {
    this.listenForBlpSaved();
    this.listenForJob();
    this.bouStatusOptions = this.bouManagementService.buildBouStatusOptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForBlpSaved() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJobIsBlpBlocked)).subscribe(isBlocked => {
        this.blocked = isBlocked;
        this.cdr.detectChanges();
      })
    );
  }

  listenForJob() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJob)).subscribe(job => {
        this.buildBouFromState(job);
      })
    );
  }

  buildBouFromState(job: Job) {
    if (job) {
      this.jobOverview = job.overview;
      this.latestBlp = job.latestBlp;
      this.eventLogs = job.timeTrackingEventLogs;
      this.bfr = job.bfr;

      if (job.bou && job.bou.jobId === job.id) {
        this.bou = cloneDeep(job.bou);
      } else {
        this.bou = this.bouManagementService.createNewBou(job.id);
      }
      this.updateBou();
    }
  }

  editEstimation(step: number) {
    this.selectedEstimationLineItem = this.bou?.estimationLineItems?.find(l => l.blpStep === step);
    this.showEditEstimationDialog = true;
  }

  editEstimationCancelled() {
    this.showEditEstimationDialog = false;
    this.selectedEstimationLineItem = null;
  }

  saveEstimation() {
    this.updateBou();
    this.showEditEstimationDialog = false;
    this.selectedEstimationLineItem = null;
  }

  editActual(step: number) {
    this.selectedActualLineItem = this.bou?.actualLineItems?.find(l => l.blpStep === step);
    this.showEditActualDialog = true;
  }

  editActualCancelled() {
    this.showEditActualDialog = false;
    this.selectedActualLineItem = null;
  }

  saveActual() {
    this.updateBou();
    this.showEditActualDialog = false;
    this.selectedActualLineItem = null;
  }

  editVesselReporting() {
    this.showEditVesselReporting = true;
  }

  saveVesselReporting() {
    this.updateBou();
    this.showEditVesselReporting = false;
  }

  cancelVesselReporting() {
    this.showEditVesselReporting = false;
  }

  updateBou() {
    this.bou = this.bouManagementService.processBou(this.bou, this.jobOverview, this.latestBlp, this.eventLogs, this.bfr, this.shoreTanks);
  }

  save() {
    this.updateBou();
    const setBouProperties = new appActions.SetJobActionProperties<Bou>();
    setBouProperties.id = this.bou.id;
    setBouProperties.jobId = this.bou.jobId;
    setBouProperties.data = this.bou;
    this.store.dispatch(appActions.setBou({ bou: setBouProperties }));
  }

  async reset(): Promise<void> {
    const job = await this.store.pipe(select(appState.getSelectedJob)).pipe(take(1)).toPromise();
    this.buildBouFromState(job);
  }

  downloadBou() {
    if (this.jobOverview && this.jobOverview.jobId && this.jobOverview.jobNumber) {
      this.downloadsService.downloadBou(this.jobOverview.jobId, this.jobOverview.jobNumber);
    } else {
      this.appMessageService.warnMessage('Cannot download BOU', 'Job details are not known');
    }
  }

}
