
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_susi",
        forgotPassword: "b2c_1_reset"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://vesselblenderscdp.b2clogin.com/vesselblenderscdp.onmicrosoft.com/b2c_1_susi",
        },
        forgotPassword: {
            authority: "https://vesselblenderscdp.b2clogin.com/vesselblenderscdp.onmicrosoft.com/b2c_1_reset",
        }
    },
    authorityDomain: "vesselblenderscdp.b2clogin.com"
};

/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: {scopes: string[]; uri: string} = {
    scopes: ['https://vesselblenderscdp.onmicrosoft.com/api/Cdp.Use'],
    uri: '/api/'
};
