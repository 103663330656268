<div *ngIf="bfrHeader" class="p-grid">
    <div class="p-md-6 p-col-12">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="vessel" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Vessel:</label>
          <div class="p-col-12 p-md-10">
            <label id="vessel">{{bfrHeader.vessel}}</label>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="bouType" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Port:</label>
          <div class="p-col-12 p-md-10">
            <label id="bouType" class="bou-type">{{bfrHeader.port}}</label>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="attending" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Terminal:</label>
          <div class="p-col-12 p-md-10">
            <label id="attending">{{bfrHeader.terminal}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="p-md-6 p-col-12">
      <div class="p-field p-grid">
        <label for="date" class="p-col-10 p-mb-2 p-md-2 p-mb-md-0">Date:</label>
        <div class="p-col-12 p-md-12">
          <label id="date">{{bfrHeader.date}}</label>
        </div>
      </div>
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="date" class="p-col-10 p-mb-2 p-md-2 p-mb-md-0">Superintendents:</label>
          <div class="p-col-12 p-md-12">
            <label id="date">{{bfrHeader.superintendents}}</label>
          </div>
        </div>
      </div>
    </div>
</div>
