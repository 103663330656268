import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BlpLineItem, BlpParcel, JobOverview, Tank } from 'src/app/models';
import { BlpManagementService } from 'src/app/services/blp-management.service';

@Component({
  selector: 'app-job-blp-step',
  templateUrl: './job-blp-step.component.html',
  styleUrls: ['./job-blp-step.component.scss']
})
export class JobBlpStepComponent implements OnInit {

  @Input() step: BlpLineItem = new BlpLineItem();
  @Input() parcels: BlpParcel[] = [];
  @Input() isNewStep: boolean = false;
  @Input() overview: JobOverview;
  @Input() isExistingCargo: boolean = false;
  @Input() shoreTanks: Tank[] = [];
  @Output() stepEditCanceled = new EventEmitter();
  @Output() stepEditSaved = new EventEmitter();
  
  displayedParcels: BlpParcel[] = [];
  isPush: boolean = false;
  localStep = new BlpLineItem();
  parcelNumberOptions: SelectItem[] = [];
  tankOptions: SelectItem[] = [];
  subscriptions: Subscription[] = [];
  selectedTank: Tank;

  constructor(private blpManagementService: BlpManagementService) { }

  ngOnInit(): void {
    this.localStep.step = this.step.step;
    this.localStep.apiNumber = this.step.apiNumber;
    this.localStep.comment = this.step.comment;
    this.localStep.nominatedQuantities = [];
    this.step.nominatedQuantities.forEach(val => this.localStep.nominatedQuantities.push(Object.assign({}, val)));
    this.localStep.lineAdjustmentParcelNumber = this.step.lineAdjustmentParcelNumber;
    this.localStep.lineAdjustment = this.step.lineAdjustment;
    this.localStep.lineNumbers = this.step.lineNumbers;
    this.localStep.tankId = this.step.tankId;

    //To make sure parcels are listed in correct order of edit screen
    if (this.parcels) {
      this.displayedParcels = this.parcels.sort((a, b) => a.parcelNumber > b.parcelNumber ?  1 :  -1);
    }
    this.isPush = this.initializePush();
    this.parcelNumberOptions = this.buildParcelOptions();
    this.buildTankOptions();
    if (this.localStep.tankId)
      this.selectedTank = this.shoreTanks.find(t => t.id == this.step.tankId);
  }

  buildParcelOptions(): SelectItem[] {
    return this.displayedParcels.map(p => {
      return { label: 'Parcel ' + p.parcelNumber, value: p.parcelNumber };
    });
  }

  buildTankOptions() {
    this.tankOptions = this.shoreTanks.map(t => {
      if (t.location && t.location.length) {
        return { label: t.name + ' (' + t.location +')', value: t };
      } else {
        return { label: t.name, value: t };
      }
      
    });
    this.tankOptions.sort((a, b) => a.label > b.label ? 1 : -1);
  }

  initializePush(): boolean {
    if (this.step.nominatedQuantities && this.step.nominatedQuantities.length) {
      return this.step.nominatedQuantities.some(n => n.isPush);
    }
    return false;
  }

  isSaveDisabled(): boolean {
    if (this.localStep.tankId) {
      return false;
    }
    return true;
  }

  cancel() {
    this.stepEditCanceled.emit();
  }

  save() {
    this.step.step = this.localStep.step;
    this.step.apiNumber = this.localStep.apiNumber;
    this.step.comment = this.localStep.comment;
    this.step.nominatedQuantities = this.localStep.nominatedQuantities;
    this.step.lineAdjustmentParcelNumber = this.localStep.lineAdjustmentParcelNumber;
    this.step.lineAdjustment = this.localStep.lineAdjustment;
    this.step.lineNumbers = this.localStep.lineNumbers;
    this.step.tankId = this.localStep.tankId;
    if (this.selectedTank) {
      this.step.tankLocation = this.selectedTank.location ? this.selectedTank.location : this.selectedTank.terminal?.name;
    }

    // Handle setting if parcel was pushed or not
    if (this.isPush) {
      this.step.nominatedQuantities.forEach(n => {
        if (n.parcelNumber === this.step.lineAdjustmentParcelNumber) {
          n.isPush = true;
        } else {
          n.isPush = false;
        }
      })
    } else {
      this.step.nominatedQuantities.forEach(n => n.isPush = false);
    }
    this.stepEditSaved.emit();
  }

  onTankChanged() {
    this.localStep.tankId = this.selectedTank?.id;
  }

  isPushChanged() {
    // Clear any previously entered nominated values when Is Push is set to prevent them
    // from erroneously be used in column totals
    if (this.isPush && this.step && this.step.nominatedQuantities && this.step.nominatedQuantities.length) {
      this.step.nominatedQuantities.forEach(n => n.value = null);
    }
  }
}
