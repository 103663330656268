<div class="p-grid">
    <div class="p-col-12">
        <p-card header="Jobs">
            <div class="p-grid">
                <div class="p-md-3 p-col-12 p-jc-start p-text-center">
                    <div class="job-metrics-label job-metrics-label-font" >Incoming Jobs</div>
                    <div class="job-metrics-label job-metrics-value-font" >{{incomingCounts}}</div>
                </div>
                <div class="p-md-3 p-col-12 p-jc-start p-text-center">
                    <div class="job-metrics-label job-metrics-label-font">Active Jobs</div>
                    <div class="job-metrics-label job-metrics-value-font" >{{activeCounts}}</div>
                </div>
                <div class="p-md-3 p-col-12 p-jc-start p-text-center">
                    <div class="job-metrics-label job-metrics-label-font">Completed Jobs</div>
                    <div class="job-metrics-label job-metrics-value-font" >{{completedCounts}}</div>
                </div>
                <div class="p-md-3 p-col-12 p-jc-start p-text-center">
                    <div class="job-metrics-label job-metrics-label-font">Average Time</div>
                    <div class="job-metrics-label job-metrics-value-font" >{{avgTime.days}}d {{avgTime.hours}}h {{avgTime.minutes}}m</div>
                </div>
                <div *ngIf="!loaded" class="p-col-12">
                    <p-table
                        [value]="skeletonRows"
                        [columns]="jobSummaryCols"
                        [responsive]="true">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns | colDisplayFilter">{{col.header}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body">
                            <tr>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div *ngIf="loaded" class="p-col-12">
                    <p-table #jobsDt
                    [value]="jobSummaries"
                    [columns]="jobSummaryCols"
                    [rows]="50"
                    sortField="date"
                    [sortOrder]="-1"
                    [responsive]="true"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[5,10,25,50]"
                    [globalFilterFields]="globalFilterFields"
                    [rowHover]="true"
                    >
                    <ng-template pTemplate="caption">
                        <div class="p-grid">
                            <div class="p-md-10 p-col-12 p-jc-start">
                                <span class="p-input-icon-left p-text-left p-d-none p-d-lg-inline-flex full-width">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="jobsDt.filterGlobal($event.target.value, 'contains')" placeholder="Job Search" [style.width]="'40%'"/>
                                </span>
                                <span class="p-input-icon-left p-text-left p-d-md-none full-width">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="jobsDt.filterGlobal($event.target.value, 'contains')" placeholder="Job Search"[style.width]="'100%'"/>
                                </span>
                            </div>
                            <div class="p-md-2 p-col-12 p-jc-end">
                                <p-button label="Add" icon="pi pi-plus" class="p-button-success" (onClick)="addJob()" [style]="{'width': '100%'}"></p-button>
                            </div>
                        </div>                       
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                                    <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                                    <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.field" display="menu" class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-jobSummary>
                        <tr>
                            <td>{{jobSummary.jobNumberAsNumber}}</td>
                            <td>{{jobSummary.date | date: 'M/d/yyyy'}}</td>
                            <td>{{jobSummary.shipName}}</td>
                            <td>{{jobSummary.clientName}}</td>
                            <td>{{jobSummary.terminalName}}</td>
                            <td>
                                <app-superintendent-list-status [users]="jobSummary.superintendents"></app-superintendent-list-status>
                            </td>
                            <td>
                                <p-chip *ngIf="jobSummary.status === jobStatuses.unconfirmed" label="Unconfirmed" styleClass="p-mr-2 unconfirmed-chip"></p-chip>
                                <p-chip *ngIf="jobSummary.status === jobStatuses.incoming" label="Incoming" styleClass="p-mr-2 incoming-chip"></p-chip>
                                <p-chip *ngIf="jobSummary.status === jobStatuses.active" label="Active" styleClass="p-mr-2 active-chip"></p-chip>
                                <p-chip *ngIf="jobSummary.status === jobStatuses.complete" label="Completed" styleClass="p-mr-2 completed-chip"></p-chip>
                                <p-chip *ngIf="jobSummary.status === jobStatuses.closed" label="Closed" styleClass="p-mr-2 closed-chip"></p-chip>
                                <p-chip *ngIf="jobSummary.status === jobStatuses.cancelled" label="Cancelled" styleClass="p-mr-2 cancelled-chip"></p-chip>
                            </td>
                            <td>
                              <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="openJob(jobSummary.jobId)" pTooltip="Edit this Job"></button>
                              <button pButton type="button" class="p-button-help p-button-rounded p-button-outlined action-icons" icon="pi pi-chart-line" (click)="downloadBou(jobSummary.jobId, jobSummary.jobNumber)" pTooltip="View this Job's BOU"></button>
                              <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-copy" (click)="copyJob(jobSummary.jobId)" pTooltip="Copy this Job"></button>
                              <button pButton type="button" class="p-button-secondary p-button-rounded p-button-outlined action-icons" icon="pi pi-trash" (click)="deleteJob(jobSummary.jobId)" pTooltip="Delete this Job"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                </div>
            </div>
        </p-card>
    </div>
</div>
