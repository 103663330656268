import { BfrMeasurementTypes, BfrTankLineItem, Tank } from "../..";
import { BfrTank } from "./bfr-tank";

export class BfrTableData {
    strappedVsBarrelsPerApiText: string;
    strappedVsBarrelsPerApiValue: number;
    
    action: string = null;
    step: number;
    tankOrMeasurementType: string;
    isTankDefRow: boolean = false;

    ullageFt: string;
    tovUsBarrels: number;
    freeGauge: number;
    waterBarrels: number;
    tankTemp: number;
    ambientTemp: number;
    shellFactor: number;
    govUsBarrels: number;
    roofCorrection: number;
    astmTableOption: string;
    api: number;
    astmVcf: number;
    gsvUsBarrels: number;
    swPercent: number;
    nsvBarrels: number;

    expectedDelivery: number;
    nominatedQuantity: number;
    adjustedLines: number;
    variance: number;
    lineCapacity: number;

    isTotalRow: boolean = false;

    constructor() {}

    static rowOneFromBfrTank(bfrTank: BfrTank, tanks: Tank[]): BfrTableData {
        const newTableData: BfrTableData = new BfrTableData();

        newTableData.strappedVsBarrelsPerApiText = 'Strapped API';
        newTableData.strappedVsBarrelsPerApiValue = bfrTank.strappedApi;
        newTableData.step = bfrTank.stepNumber;
        var tank = tanks.find(t => t.id == bfrTank.tankId);
        newTableData.tankOrMeasurementType = tank?.name;
        newTableData.isTankDefRow = true;
        newTableData.ullageFt = bfrTank.ullageFt;

        return newTableData;
    }

    static rowTwoFromBfrTankLineItem(bfrTankLineItm: BfrTankLineItem, barrelsPerDegApi: number): BfrTableData {
        const newTableData: BfrTableData = new BfrTableData();

        newTableData.strappedVsBarrelsPerApiText = 'Barrels/Deg API';
        newTableData.strappedVsBarrelsPerApiValue = barrelsPerDegApi;
        newTableData.tankOrMeasurementType = BfrMeasurementTypes.open;
        newTableData.ullageFt = bfrTankLineItm.ullageFt;
        newTableData.tovUsBarrels = bfrTankLineItm.tovUsBarrels;
        newTableData.freeGauge = bfrTankLineItm.freeGauge;
        newTableData.waterBarrels = bfrTankLineItm.waterBarrels;
        newTableData.tankTemp = bfrTankLineItm.tankTemp;
        newTableData.ambientTemp = bfrTankLineItm.ambientTemp;
        newTableData.shellFactor = bfrTankLineItm.shellFactor;
        newTableData.govUsBarrels = bfrTankLineItm.govUsBarrels;
        newTableData.roofCorrection = bfrTankLineItm.roofCorrection;
        newTableData.astmTableOption = bfrTankLineItm.astmTableOption;
        newTableData.api = bfrTankLineItm.api;
        newTableData.astmVcf = bfrTankLineItm.astmVcf;
        newTableData.gsvUsBarrels = bfrTankLineItm.gsvUsBarrels;
        newTableData.swPercent = bfrTankLineItm.swPercent;
        newTableData.nsvBarrels = bfrTankLineItm.nsvBarrels;

        return newTableData;
    }

    static rowThreeFromBfrTankLineItem(bfrTankLineItm: BfrTankLineItem): BfrTableData {
        const newTableData: BfrTableData = new BfrTableData();

        newTableData.tankOrMeasurementType = BfrMeasurementTypes.close;
        newTableData.ullageFt = bfrTankLineItm.ullageFt;
        newTableData.tovUsBarrels = bfrTankLineItm.tovUsBarrels;
        newTableData.freeGauge = bfrTankLineItm.freeGauge;
        newTableData.waterBarrels = bfrTankLineItm.waterBarrels;
        newTableData.tankTemp = bfrTankLineItm.tankTemp;
        newTableData.ambientTemp = bfrTankLineItm.ambientTemp;
        newTableData.shellFactor = bfrTankLineItm.shellFactor;
        newTableData.govUsBarrels = bfrTankLineItm.govUsBarrels;
        newTableData.roofCorrection = bfrTankLineItm.roofCorrection;
        newTableData.astmTableOption = bfrTankLineItm.astmTableOption;
        newTableData.api = bfrTankLineItm.api;
        newTableData.astmVcf = bfrTankLineItm.astmVcf;
        newTableData.gsvUsBarrels = bfrTankLineItm.gsvUsBarrels;
        newTableData.swPercent = bfrTankLineItm.swPercent;
        newTableData.nsvBarrels = bfrTankLineItm.nsvBarrels;

        return newTableData;
    }

    static rowFourFromBfrTank(bfrTank: BfrTank): BfrTableData {
        const newTableData: BfrTableData = new BfrTableData();

        newTableData.ullageFt = bfrTank.closeCrtUllageFt;
        newTableData.tovUsBarrels = bfrTank.tovUsBarrelsTotal;
        newTableData.gsvUsBarrels = bfrTank.gsvUsBarrelsTotal;
        newTableData.nsvBarrels = bfrTank.nsvBarrelsTotal;

        newTableData.expectedDelivery = bfrTank.expectedDelivery;
        newTableData.nominatedQuantity = bfrTank.nominatedQuantity;
        newTableData.adjustedLines = bfrTank.adjustedLines;
        newTableData.variance = bfrTank.variance;
        newTableData.lineCapacity = bfrTank.lineCapacity;

        newTableData.isTotalRow = true;

        return newTableData;
    }
}