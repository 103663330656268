import { LoadingPlanItem, Ship, Terminal, User } from 'src/app/models';
import { TimeSpan } from '../timespan';
import { DowntimeInfoItem } from './downtime-info-item';

export class ActiveJobDetails {
    jobId: string;
    jobNumber: string;
    terminal: Terminal;
    ship: Ship;
    superintendents: User[] = [];
    status: string;
    loadingStatus: number;
    lastUpdateDate: Date;
    loadingPlanItems: LoadingPlanItem[] = [];
    downtimeInfoItems: DowntimeInfoItem[] = [];
	  allFastDate: Date;
    estimatedCompletionDate: Date;
    timeOnDock: TimeSpan;
    totalDowntime: TimeSpan;
    totalTankSwitch: TimeSpan;
    grossPumpingRate: number;

    constructor() {
    }
}
