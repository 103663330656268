import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bou } from 'src/app/models';

@Component({
  selector: 'app-job-bou-vessel-reporting-detail',
  templateUrl: './job-bou-vessel-reporting-detail.component.html',
  styleUrls: ['./job-bou-vessel-reporting-detail.component.scss']
})
export class JobBouVesselReportingDetailComponent implements OnInit {

  @Input() bou: Bou;
  @Output() saved = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  vesselReportingReceived: number;
  vesselReportingVef: number;

  constructor() { }

  ngOnInit(): void {
    this.vesselReportingReceived = this.bou.vesselReportingReceived;
    this.vesselReportingVef = this.bou.vesselReportingVef;
  }

  save() {
    this.bou.vesselReportingReceived = this.vesselReportingReceived ? this.vesselReportingReceived : 0;
    this.bou.vesselReportingVef = this.vesselReportingVef ? this.vesselReportingVef : 0;
    this.saved.emit();
  }

  cancel() {
    this.cancelled.emit();
  }

}
