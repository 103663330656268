<div class="p-col-12">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <i class="pi pi-filter"></i>
      <p-dropdown [options]="jobFilters" [(ngModel)]="selectedJobFilter" placeholder="Select Job Status" optionLabel="name" class="jobFilterMargin"></p-dropdown>
      <button pButton type="button" title="Previous month" icon="pi pi-chevron-left" class="p-button-text"></button>
      <button pButton type="button" title="Select month" label="June 2022" class="p-button-text"></button>
      <button pButton type="button" title="Next month" icon="pi pi-chevron-right" class="p-button-text"></button>
    </div>
    <div class="p-toolbar-group-right">
      <h3 class="boardMargin">28 Jobs</h3>
      <h3 class="boardMargin">16 Vessels</h3>
      <h3 class="boardMargin">2,209,000 bbls</h3>
      <h3 class="boardMargin">87 Days</h3>
      <button pButton type="button" title="Select month" label="New Job" icon="pi pi-plus"></button>
    </div>
  </p-toolbar>
</div>
<div>
  <p-dataView #dv [value]="jobs" [paginator]="true" [rows]="12" filterBy="vesselName"
      [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
      <ng-template pTemplate="header">
          <div class="header-flex">
              <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Date" (onChange)="onSortChange($event)" styleClass="mb-2 md:mb-0"></p-dropdown>
              <span class="p-input-icon-left mb-2 md:mb-0">
                  <i class="pi pi-search"></i>
                  <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
              </span>
              <i class="pi pi-question-circle"></i>
              <!-- <p-dataViewLayoutOptions></p-dataViewLayoutOptions> -->
          </div>
      </ng-template>
      <!-- <ng-template let-product pTemplate="listItem">
          <div class="col-12">
              <div class="product-list-item">
                  <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name"/>
                  <div class="product-list-detail">
                      <div class="product-name">{{product.name}}</div>
                      <div class="product-description">{{product.description}}</div>
                      <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
                      <i class="pi pi-tag product-terminal-icon"></i><span class="product-termial">{{product.terminal}}</span>
                  </div>
                  <div class="product-list-action">
                      <span class="product-price">${{product.price}}</span>
                      <p-button icon="pi pi-shopping-cart" label="Add to Cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button>
                      <span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
                  </div>
              </div>
          </div>
      </ng-template> -->
      <ng-template let-job pTemplate="gridItem">
          <div class="col-12 p-md-4 p-lg-3 p-xl-2">
              <div class="job-grid-item card">
                  <div class="job-grid-item-top">
                      <div>
                          <i class="pi pi-map-marker job-terminal-icon"></i>
                          <span class="job-terminal">{{job.terminal}}</span>
                      </div>
                      
                  </div>
                  <div class="job-grid-item-content">
                      <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name"/> -->
                      <div class="job-name">{{job.name}}</div>
                      <div [class]="'job-description status-' + job.status.toLowerCase()">{{job.statusDescription}}</div>
                      <!-- <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating> -->
                      <div class="job-progress-flex">
                        Progress
                        <p-progressBar [value]="job.pctComplete"></p-progressBar>
                      </div>
                      <div class="job-margin"><span class="job-badge">{{job.status}}</span></div>
                      <p-chart type="doughnut" [data]="job.chartData" [options]="chartOptions"></p-chart>
                      <div class="job-margin"><button pButton type="button" title="Select month" label="Job Detail" icon="pi pi-book"></button></div>
                  </div>
                  <div class="job-grid-item-bottom">
                      <!-- <span class="product-price">${{product.price}}</span>
                      <p-button icon="pi pi-shopping-cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button> -->
                      
                  </div>
              </div>
          </div>
      </ng-template>
  </p-dataView>
</div>