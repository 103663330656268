import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BouActualLineItem } from 'src/app/models';

@Component({
  selector: 'app-job-bou-actual-detail',
  templateUrl: './job-bou-actual-detail.component.html',
  styleUrls: ['./job-bou-actual-detail.component.scss']
})
export class JobBouActualDetailComponent implements OnInit {

  @Input() lineItem: BouActualLineItem;
  @Output() cancelled = new EventEmitter();
  @Output() saved = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cancel() {
    this.cancelled.emit();
  }

  save() {
    this.saved.emit();
  }

}
