import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { InspectionCompany, Contact } from 'src/app/models';

@Component({
  selector: 'app-inspection-company-detail',
  templateUrl: './inspection-company-detail.component.html',
  styleUrls: ['./inspection-company-detail.component.scss']
})
export class InspectionCompanyDetailComponent implements OnInit {

  @Input() inspectionCompany: InspectionCompany = new InspectionCompany();
  @Input() contacts: Contact[] = [];
  @Output() inspectionCompanyUpdated = new EventEmitter<InspectionCompany>();
  @Output() resetInspectionCompany = new EventEmitter();
  
  contactOptions: SelectItem[] = [];
  showContactDialog = false;
  
  constructor() { }

  ngOnInit(): void {
    this.contactOptions = this.buildContactOptions();
  }

  buildContactOptions(): SelectItem[] {
    return this.contacts.sort((a,b) => a.displayName > b.displayName ?  1 :  -1).map(c => { return { 'label': c.displayName + ' (' + c.email + ')', value: c }; });
  }

  save() {
    this.inspectionCompanyUpdated.emit(this.inspectionCompany);
  }

  reset() {
    this.resetInspectionCompany.emit();
  }

  contactAdded(contact: Contact) {
    this.contactOptions.push({ label: contact.displayName + ' (' + contact.email + ')', value: contact });
    this.contactOptions = this.contactOptions.sort((a,b) => a.label > b.label ?  1 :  -1);
    this.inspectionCompany.contacts.push(contact);
    this.inspectionCompany.contacts = [...this.inspectionCompany.contacts];  //force reference to array to change to trigger ngModel on multiSelect to see a change
    this.showContactDialog = false;
  }

}
