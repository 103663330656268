<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid">
      <div class="p-field p-grid">
        <label for="productName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Product Name</label>
        <div class="p-col-12 p-md-10">
          <input id="productName" type="text" pInputText [(ngModel)]="product.name">
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-md-6 p-col-12">
        <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-6 p-col-12">
        <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
  </div>
</div>
