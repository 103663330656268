import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { Product } from 'src/app/models/system-config/product';
import { ConfirmationService } from 'primeng/api';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {

  cols: any[] = [];
  skeletonRows: any[] = [];
  products: Product[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedProduct: Product;
  showEditDialog = false;

  constructor(
    private store: Store<appState.State>,
    private actions$: Actions,
    private confirmationService: ConfirmationService,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForProductChanges();
    this.listenForProductUpdated();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  buildSkeletonRows(): number[] {
    return [1, 2, 3, 4, 5];
  }

  buildCols(): any[] {
    return [
      { field: 'name', header: 'Id', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  listenForProductChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getProducts))
        .subscribe(results => {
          if (results)
            this.products = JSON.parse(JSON.stringify(results));
          this.loaded = true;
          })
    );
  }

  listenForProductUpdated() {
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(appActions.setProductSuccess))
        .subscribe(() => this.showEditDialog = false)
    );
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addProduct() {
    this.selectedProduct = new Product();
    this.showEditDialog = true;
  }

  editProduct(product: Product) {
    this.selectedProduct = cloneDeep(product);  // separate reference from state
    this.showEditDialog = true;
  }

  deactivateProduct(product: Product) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Product: ' + product.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeProduct = { ...product, active: false };
        this.store.dispatch(appActions.setProduct({ data: activeProduct }));
      }
    });
  }

  activateProduct(product: Product) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Product: ' + product.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const inactiveProduct = { ...product, active: true };
        this.store.dispatch(appActions.setProduct({ data: inactiveProduct }));
      }
    });
  }

  updateProduct(product: Product) {
    this.store.dispatch(appActions.setProduct({ data: product }));
  }

  async resetSelectedProduct() {
    // this.products is already subscribed to latest state of Products
    // reset selected Product to state
    this.selectedProduct = cloneDeep(this.products.find(t => t.id === this.selectedProduct.id));
  }
}
