import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { JobVdp, JobBlpRevision, VdpLineItem, Tank } from 'src/app/models';
import { VdpManagementService } from 'src/app/services/vdp-management.service';

@Component({
  selector: 'app-job-vdp',
  templateUrl: './job-vdp.component.html',
  styleUrls: ['./job-vdp.component.scss']
})
export class JobVdpComponent implements OnInit {

  @Input() shoreTanks: Tank[] = [];
  @Input() vesselTanks: Tank[] = [];
  jobVesselTanks: Tank[] = [];

  vdps: JobVdp[] = [];
  jobId: string;
  jobNumber: string;
  latestBlp: JobBlpRevision;
  isNew: boolean = false;
  // jobOverview: JobOverview = new JobOverview();
  subscriptions: Subscription[] = [];
  ready: boolean = false;
  selectedVdp: JobVdp;
  vdpText: string = 'VDP';

  constructor(
    private store: Store<appState.State>,
    private vdpManagementService: VdpManagementService) { }

  ngOnInit(): void {
    this.listenToJobChanges();
  }

  listenToJobChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJob)).subscribe(jobState => {
        this.vdps = cloneDeep(jobState.vdps);
        this.jobId = jobState.id;
        this.vdps = this.vdpManagementService.buildVdpFromBlp(jobState.latestBlp, this.vdps);
        this.jobNumber = jobState.jobNumber;
        //if (this.vdps && this.vdps.length > 0) this.selectedVdp = this.vdps[0];
        // this.jobOverview = jobState.overview;
        var jobVesselTankIds = [].concat.apply([], jobState.latestBlp?.parcels.map(p => p.vesselTank.split(',')));
        this.jobVesselTanks = this.vesselTanks.filter(t => jobVesselTankIds.includes(t.id));
        this.ready = true;
      })
    );
  }

  save(vdpSaved: JobVdp) {
    const setVdpProperties = new appActions.SetJobActionProperties<JobVdp>();
    setVdpProperties.id = vdpSaved.id;
    setVdpProperties.jobId = vdpSaved.jobId;
    setVdpProperties.jobNumber = this.jobNumber;
    let existingVdp = this.vdps.find(b => b.id === vdpSaved.id);
    if (existingVdp) {
      existingVdp = vdpSaved;
    } else {
      // we should be creating any needed vdps in the collection prior to getting here.
      //this.vdps.push(vdpSaved);
    }
    setVdpProperties.data = vdpSaved;
    this.store.dispatch(appActions.setJobVdp({ vdp: setVdpProperties }));
    //this.store.dispatch(appActions.setJobInVdpEditMode({ inVdpEditMode: false }));
  }

  async reset() {
    const initialJob = await this.store.pipe(select(appState.getSelectedJob), take(1)).toPromise();
    this.vdps = cloneDeep(initialJob).vdps;

    // Reset selected BLP to state's value if one exists matching Id, otherwise reset to new revision
    const foundVdp = this.vdps.find(b => b.id === this.selectedVdp.id);
    if (foundVdp) {
      this.selectedVdp = cloneDeep(foundVdp);
      this.selectedVdp.lineItems.forEach(i => {
        let foundItem = this.selectedVdp.lineItems.find(l => l.id === i.id);
        if (foundItem) foundItem = VdpLineItem.fromJson(i);
      });
      
    } else {
      // no concept for VDP
      // this.revisionStarted();
    }
  }

  // download(vdp: JobVdp) {
  //   this.store.dispatch(appActions.showSpinner());
  //   this.http.get(`/api/job/${this.jobId}/vdp/${vdp.id}/pdf`, { responseType: 'text' }).subscribe(uri => {
  //     // const options = {
  //     //   headers?: new HttpHeaders({
  //     //     'Access-Control-Allow-Origin':'*'
  //     //   }),
  //     //   responseType: 'blob'
  //     // };
  //     const httpOptions : any = {
  //       headers: new HttpHeaders({
  //         'Access-Control-Allow-Origin':'*'
  //       }),
  //       responseType: 'blob'
  //     };
  //     this.http.get(uri, httpOptions).subscribe(blob => {
  //       this.store.dispatch(appActions.hideSpinner());
  //       var file = new Blob([blob], { type: 'application/pdf' })
  //       const objUrl = window.URL.createObjectURL(file);
  //       window.open(objUrl, 'blp.pdf');
  //     });
  //   });
  // }

  // async revisionStarted(): Promise<void> {
  //   const currentUser = await this.store.pipe(select(appState.getCurrentUser)).pipe(take(1)).toPromise();
  //   const latestBlp = await this.store.pipe(select(appState.getSelectedJobLatestBlp)).pipe(take(1)).toPromise();    
    
  //   this.selectedVdp = this.blpManagementService.cloneBlp(this.jobId, currentUser, latestBlp);
  // }

  selectVdp(blpId: string) {
    if (blpId) {
      this.selectedVdp = this.vdps.find(r => r.id === blpId);
      if (this.selectedVdp) this.vdpText = 'VDP - ' + this.selectedVdp.product;
      // console.log('selectedVdp', this.selectedVdp);
      //this.store.dispatch(appActions.setJobInBlpEditMode({ inBlpEditMode: false }));
    }
  }

  get vdpSet(): boolean {
    if (this.selectedVdp) {
      return true;
    }
    return false;
  }

  get selectedVdpJson(): string {
    return JSON.stringify(this.selectedVdp);
  }

  get vdpHeaderText(): string {
    return 'VDP Header'; //****maybe put the parcel it is based on here*** (' + (this.selectedVdp.revisionNumber >= 0 ? 'Revision ' + this.selectedVdp.revisionNumber : 'New Revision') + ')';
  }

  private haveParcelsChanged(blp: JobBlpRevision, vdps: JobVdp[]): boolean {
    return blp.parcels.length !== vdps.length;
  }

  private haveStepsChanged(blp: JobBlpRevision, vdps: JobVdp[]): boolean {
    let mismatchFound = false;
    vdps.forEach(v => {
     if (this.hasSingleVdpShoreTanksChanged(blp, v)) {
       mismatchFound = true;
     }
    });
    return mismatchFound;
  }

  private hasSingleVdpShoreTanksChanged(blp: JobBlpRevision, vdp: JobVdp) {
    const targetShoreTanks = blp.allLineItems.filter(l => 
      l.isExistingCargo ||
      l.nominatedQuantities.some(n => n.value && n.parcelNumber === vdp.parcelNumber && !n.isPush)
    );
    const missingTargets = targetShoreTanks.some(t => !vdp.shoreTanks.some(vSt => vSt.tankId === t.tankId));
    const shoreTankToBeRemoved = vdp.shoreTanks.some(vst => !targetShoreTanks.some(t => t.tankId === vst.tankId));
    return missingTargets || shoreTankToBeRemoved;
  }
}
