import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as appActions from '../../../state/app.actions';
import * as appState from '../../../state';
import { AppMessageService } from 'src/app/services/app-message.service';
import { TimingCategory } from 'src/app/models/system-config/timing-category';
import { TimingCategoryService } from 'src/app/services/http-services/timingCategory.service';

@Component({
  selector: 'app-timing-category-list',
  templateUrl: './timing-category-list.component.html',
  styleUrls: ['./timing-category-list.component.scss']
})
export class TimingCategoryListComponent implements OnInit, OnDestroy {
  loaded = false;
  selectedTimingCategory: TimingCategory;
  showEditDialog: boolean = false;
  skeletonRows: any[] = [];
  //subscriptions: Subscription[] = [];
  //timingCategorySubscription: Subscription;
  timingCategories: TimingCategory[];

  constructor(
    private appMessageService: AppMessageService,
    //private store: Store<appState.State>,
    private timingCategoryService: TimingCategoryService
  ) { }

  ngOnInit(): void {
    //this.listenForTimingCategories();
    //this.store.dispatch(appActions.getTimingCategories())
    this.getTimingCategories();
  }

  ngOnDestroy() {
    // this.subscriptions.forEach(s => s.unsubscribe());
    //this.timingCategorySubscription.unsubscribe();
  }

  // listenForTimingCategories() {
  //   this.store.dispatch(appActions.getTimingCategories())
  //   this.timingCategorySubscription = this.store.pipe(select(appState.getTimingCategories))
  //     .subscribe(results => {
  //       this.timingCategories = [ ... results];
  //     });
  // }

  getTimingCategories() {
    this.timingCategoryService.getTimingCategories().subscribe(
      results => {
        this.timingCategories = results;
        this.loaded = true;
      },
      error => {
        this.appMessageService.errorMessage('Error Getting Timing Categories Information', error);
        this.timingCategories = [];
        this.loaded = true;
      }
    )
  }

  openTimingCategory(tCategory: TimingCategory) {
    this.selectedTimingCategory = { ... tCategory }
    this.showEditDialog = true;
  }

  reset() {
    let cat = this.timingCategories.find(c => c.id === this.selectedTimingCategory.id);
    this.selectedTimingCategory = { ... cat };
  }

  save(tCategory: TimingCategory) {
    this.timingCategoryService.upsertTimingCategory(tCategory).subscribe(
      _ => {
        this.appMessageService.successMessage('Timing Categories Saved', 'Successfully saved the Timing Category changes.');
        this.getTimingCategories();
      },
      error => {
        this.appMessageService.errorMessage('Error Getting Timing Categories Information', error);
      }
    );
  }

  updateTimingCategory(tCategory: TimingCategory) {
    //this.store.dispatch(appActions.setTimingCategory({ data: tCategory }))
    this.save(tCategory);
    this.showEditDialog = false;
  }
}
