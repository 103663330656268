import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User, UserRoles, ClientCompany } from 'src/app/models';
import { JobReportService } from 'src/app/services/http-services/job.report.service';
import { JobReportDataService } from 'src/app/services/job-report-data.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as appState from 'src/app/state';
import * as appActions from 'src/app/state/app.actions';
import { Terminal } from 'src/app/models';
import { TerminalTrendReport, TrendInfo, DowntimeTrendInfo, CategoryTrendInfo, JobTrendInfo, TrendTimingInfo } from 'src/app/models/reports/terminal-trend-report';
import { TimingInfo } from 'src/app/models/reports/job-performance';

@Component({
  selector: 'app-job-trends',
  templateUrl: './job-trends.component.html',
  styleUrls: ['./job-trends.component.scss']
})
export class JobTrendsComponent implements OnInit, OnDestroy {

  currentUser: User = new User();
  report: TerminalTrendReport;
  terminals: Terminal[] = [];
  selectedTerminal: Terminal;
  terminalOptions: SelectItem[] = [];
  subscriptions: Subscription[] = [];
  chartOptions: any;
  categoryTrendInfos: CategoryTrendInfo[];
  showDataInfo: boolean;
  showDowntimeDataInfo: boolean;
  dataCaption: string;
  dataInfos: JobTrendInfo[] = [];
  downtimeDataInfos: TimingInfo[] = [];
  chartsOptions: any;
  selectedCustomer: ClientCompany;

  constructor(
    private jobReportService: JobReportService,
    private jobReportDataService: JobReportDataService,
    private dataSharingService: DataSharingService,
    private appMessageService: AppMessageService, 
    private store: Store<appState.State>
  ) { }

  ngOnInit(): void {
    this.report = this.jobReportDataService.createBlankTerminalTrendReport();
    this.listenToStateChanges();
    this.configChartsOptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        if (user?.id && user.id == this.currentUser?.id) return;
        
        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.customer)) {
            this.getTerminals(this.currentUser.client);
            this.store.pipe(select(appState.getClients)).subscribe(results => {
              if (results){
                this.selectedCustomer = results.find(c => c.id == this.currentUser.client);
                if (this.selectedCustomer)
                  this.dataSharingService.setCustomer(this.selectedCustomer);
              }
            })
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin)){
            this.listentToCustomerChanges();
          }
        }
      })
    );
  }

  listentToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;
      
      if (!this.selectedCustomer || this.selectedCustomer.id != c.id){
        this.selectedCustomer = c;
        this.getTerminals(this.selectedCustomer.id);
      }
    });
  }

  getTerminals(customerId: string){
    this.jobReportService.getJobTerminals(customerId).subscribe(
      result => {
        this.terminals = result;
        this.terminalOptions = this.buildTerminalOptions();
      },
      error => {
        this.appMessageService.errorMessage('Error Getting Job Terminals for Customer', null);
      }
    );
  }

  buildTerminalOptions(): SelectItem[] {
    return this.terminals.sort((a,b) => a.name > b.name ?  1 :  -1).map(d => { return { 'label': d.port?.name + ': ' + d.name, value: d }; });
  }

  configChartsOptions() {
    this.chartsOptions = {};
    this.chartsOptions['Average Total Downtime Trend'] = this.configChartOptions('Downtime (hrs)');
    this.chartsOptions['Average Time On Dock Trend'] = this.configChartOptions('Time on Dock (hrs)');
    this.chartsOptions['Average Gross Pumping Rate Trend'] = this.configChartOptions('Rate (bbls/h)');
    this.chartsOptions['Average Net Pumping Rate Trend'] = this.configChartOptions('Rate (bbls/h)');
    this.chartsOptions['Average Pre-Cargo Time Trend'] = this.configChartOptions('Time (hrs)');
    this.chartsOptions['Average Downtime By Reasons'] = this.configChartOptions('Downtime (hrs)');
  }

  configChartOptions(yAxisLabel: string): any {
    var jobReportDataService = this.jobReportDataService;
    return {
      legend: { display: false },

      scales: {
        yAxes: [{ 
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: yAxisLabel
          }
        }],
        xAxes: [{ 
          position: 'bottom', 
          gridLines: {
            display:false
          }
        }],
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
            return jobReportDataService.convertDurationInHours(tooltipItem.value);
          }
        }
      }
    }
  }

  async terminalChanged() {
    this.store.dispatch(appActions.showSpinner());
    var report = await this.jobReportService.getTerminalTrendReport(this.selectedCustomer.id, this.selectedTerminal.id, 30)
      .toPromise()
      .catch(error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error getting Terminal KPI Trend Report', null);
        console.log(error);
        return null;
      });
    if (report) {
      this.report = report;
    }
    this.computeAdditionaInfo(this.report);
    this.store.dispatch(appActions.hideSpinner());
  }

  computeAdditionaInfo(report: TerminalTrendReport) {
    if (report.trendInfos) {
      report.trendInfos.forEach(t => {
        if (t.trendTimingInfos) {
          t.trendTimingInfos.forEach(i => {
            if (i.timingInfo.displayValue) {
              i.timingInfo.duration = Math.round(i.timingInfo.duration);
            } else {
              i.timingInfo.duration = Math.round(i.timingInfo.duration / 60);
            }
            i.timingInfo.durationText = this.jobReportDataService.convertDuration(i.timingInfo.duration)
            if (i.jobInfos)
              i.jobInfos.forEach(j => { 
                j.duration = Math.round(j.duration);
                j.durationText = this.jobReportDataService.convertDuration(j.duration);
              });
          })
        }
        this.configTrendChartData(t);
      });
      report.downtimeByReasonsTrends.forEach(d => {
        d.duration = d.duration / 60;
        d.durationText = this.jobReportDataService.convertDuration(d.duration)
        if (d.categoryTimings)
          d.categoryTimings.forEach(t => t.durationText = this.jobReportDataService.convertDuration(t.duration));
      });
      this.configDowntimeChartData(report.downtimeByReasonsTrends);
    }
  }

  configTrendChartData(trend: TrendInfo){
    trend.chartData = {
      labels: trend.trendTimingInfos.map(t => t.timingInfo.description),
      datasets: [
        {
          label: 'Past Days',
          backgroundColor: '#FFA726',
          data: trend.trendTimingInfos.map(t => t.timingInfo.duration)
        }
      ]
    }
  }

  configDowntimeChartData(downtimes: DowntimeTrendInfo[]){
    this.report.downtimeByReasonChartData = {
      labels: downtimes.map(d => d.category),
      datasets: [
        {
          label: 'Past Days',
          backgroundColor: '#FFA726',
          data: downtimes.map(t => t.duration)
        }
      ]
    }
  }

  selectData(event: any, title: string) {
    this.dataInfos = this.getJobTrendInfos(title, event.element._index);
    this.showDataInfo = true
  }

  selectDowntimeData(event: any) {
    var downtimeTrendInfo = this.report.downtimeByReasonsTrends[event.element._index];
    this.downtimeDataInfos = downtimeTrendInfo.categoryTimings;
    this.dataCaption = 'Past ' + downtimeTrendInfo.category + ' Days';
    this.showDowntimeDataInfo = true;
  }

  getJobTrendInfos(title: string, index: number): JobTrendInfo[] {
    let trendInfo: TrendInfo;
    let trendTimingInfo: TrendTimingInfo;
    if (title == 'Average Total Downtime Trend')
      trendInfo = this.report.trendInfos[2];
    else if (title == 'Average Time On Dock Trend')
      trendInfo = this.report.trendInfos[3];
    else if (title == 'Average Gross Pumping Rate Trend')
      trendInfo = this.report.trendInfos[4];
    else if (title == 'Average Net Pumping Rate Trend')
      trendInfo = this.report.trendInfos[1];
    else if (title == 'Average Pre-Cargo Time Trend')
      trendInfo = this.report.trendInfos[0];
    trendTimingInfo = trendInfo.trendTimingInfos[index];
    this.dataCaption = trendInfo.category + ' (Past ' + trendTimingInfo.timingInfo.description + ' Days)';
    return trendTimingInfo.jobInfos;
  }

}
