import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { BlpLineItem, BlpParcel } from 'src/app/models';
import { BlpOverride } from 'src/app/models/job/blp/blp-override';

@Component({
  selector: 'app-job-blp-overrides',
  templateUrl: './job-blp-overrides.component.html',
  styleUrls: ['./job-blp-overrides.component.scss']
})
export class JobBlpOverridesComponent implements OnInit {

  @Input() overrides: BlpOverride[] = [];
  @Input() steps: BlpLineItem[] = [];
  @Input() parcels: BlpParcel[] = [];
  @Output() overridesSaved = new EventEmitter();
  @Output() overridesCanceled = new EventEmitter();

  cols: any[] = [];


  showOverrideEdit: boolean = false;
  selectedOverride: BlpOverride;
  isNew: boolean = false;

  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.cols = this.buildCols();
    
  }

  buildCols(): any[] {
    return [
      { field: 'step', header: 'Step', sortable: true },
      { field: 'parcelNumber', header: 'Parcel', sortable: true },
      { field: 'value', header: 'Value', sortable: false },
      { field: 'reason', header: 'Reason', sortable: false },
      { field: 'actions', header: '', sortable: false }
    ];
  }

  addOverride() {
    this.selectedOverride = new BlpOverride();
    this.isNew = true;
    this.showOverrideEdit = true;
  }

  editOverride(overrideId: string) {
    this.selectedOverride = this.overrides.find(o => o.id === overrideId);
    this.showOverrideEdit = true;
  }

  onEditOverrideCanceled() {
    this.selectedOverride = null;
    this.isNew = false;
    this.showOverrideEdit = false;
  }

  deleteOverride(override: BlpOverride) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to remove override for Step Number: ' + override.step + ' and Parcel Number:' + override.parcelNumber,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.overrides = this.overrides.filter(o => o.id !== override.id);
        this.overridesSaved.emit();
      }
    });
  }

  onSaveOverride() {
    if (this.isNew) {
      this.overrides.push(this.selectedOverride);
    }
    this.overridesSaved.emit();
    this.showOverrideEdit = false;
  }

  close() {
    this.overridesCanceled.emit();
  }



}
