import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { Subscription } from 'rxjs';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DowntimeInfoItem } from '../../models/customer/downtime-info-item';
import { ActiveJobDetails, ClientCompany, JobStatuses, LoadingPlanItem, TimeTrackingReportFormats, User, UserRoles } from '../../models';
import { CustomerService } from '../../services/http-services/customer.service';
import { DownloadsService } from '../../services/http-services/downloads.service';
import { KpiFirstReport } from '../../models/customer/kpi-first-report';
import { DataSharingService } from '../../services/data-sharing.service';
import { KpiFirstReportDelayInfo } from '../../models/customer/kpi-first-report-delay';
import { KpiFirstReportTerminalInfo } from '../../models/customer/kpi-first-report-terminal';


@Component({
  selector: 'app-customerkpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit, OnDestroy {
  pageDataReady: boolean = false;
  subscriptions: Subscription[] = [];
  kpiFirstReport: KpiFirstReport;
  chartOptions: any;
  labels: any = ['Terminal', 'Vessel', 'Customer', 'Inspector'];
  data: any = [600, 100, 50, 80];
  chartData: any = {};
  clientId: string;
  currentUser: User = new User();
  selectedCustomer: ClientCompany;
  termCardPrev: boolean;
  termCardNext: boolean;
  termTotalCards: number;
  termCurrentIndex: number;
  testTermCard1: KpiFirstReportTerminalInfo = new KpiFirstReportTerminalInfo();
  testTermCard2: KpiFirstReportTerminalInfo = new KpiFirstReportTerminalInfo();
  testTermCard3: KpiFirstReportTerminalInfo = new KpiFirstReportTerminalInfo();
  testTermCard4: KpiFirstReportTerminalInfo = new KpiFirstReportTerminalInfo();
  showTermCard1: boolean;
  showTermCard2: boolean;
  showTermCard3: boolean;
  showTermCard4: boolean;

  constructor(
    private custService: CustomerService, 
    private confirmationService: ConfirmationService,
    private dataSharingService: DataSharingService,
    private downloadsService: DownloadsService,
    private route: ActivatedRoute,
    private store: Store<appState.State>,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.listenToStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        if (user?.id && user.id == this.currentUser?.id) return;

        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.newCustomer)) {
            this.getKpiFirstReport(user.client);
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin) || this.currentUser.role.includes(UserRoles.superNewCustomer)) {
            this.listenToCustomerChanges();
          }
        }
      })
    );
  }

  listenToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;

      if (!this.selectedCustomer || this.selectedCustomer.id != c.id) {
        this.selectedCustomer = c;
        this.getKpiFirstReport(this.selectedCustomer.id);
      }
    });
  }

  configChartOptions() {
    this.chartOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      }
    }
  }

  setKpiFirstReportVariables(kpiData: KpiFirstReport) {
    if (kpiData == null) {
      this.confirmationService.confirm({
        key: 'confirmation',
        message: 'There are no KPI Metrics available for this client',
        icon: 'pi pi-exclamation-triangle',
        header: 'ERROR',
        rejectVisible: false,
        accept: () => {
          this.kpiFirstReport = null;
          this.pageDataReady = false;
        }
      });
    }
    else {
      this.kpiFirstReport = kpiData;
      console.log(this.kpiFirstReport);
      this.configChartOptions();
      this.configChartData(this.kpiFirstReport.delays);
      this.configTerminalInfoCards(this.kpiFirstReport.terminalStats);
      this.pageDataReady = true;
    }
  }

  getKpiFirstReport(clientId: string) {
    this.store.dispatch(appActions.showSpinner());
    this.custService.getKpiFirstReport(clientId).subscribe(
      results => {
        this.setKpiFirstReportVariables(results);
        this.store.dispatch(appActions.hideSpinner());
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error Getting KPI First Report', error);
      }
    );
  }

  configChartData(kpiDowntime: KpiFirstReportDelayInfo[]) {
    this.chartData = {
      labels: kpiDowntime.map(d => d.category),
      datasets: [
        {
          backgroundColor: [
            "#42A5F5", //blue
            "#66BB6A", //green
            "#FFA726", //orange
            '#EC407A', //magenta
            '#AB47BC', //purple
            '#ffff00', //yellow
            '#804000', //brown
            '#408080', //teal
            '#400040', //dark purple
            '#8080C0'  //lavender
          ],
          label: kpiDowntime.map(d => d.category),
          data: kpiDowntime.map(d => d.duration)
        }
      ]
    }
  }

  processTerminalInfoCards() {
    // Card 1
    if (this.termCurrentIndex < this.termTotalCards) {
      this.testTermCard1 = this.kpiFirstReport.terminalStats[this.termCurrentIndex++];
      this.showTermCard1 = true;
    }
    else
      this.showTermCard1 = false;

    // Card 2
    if (this.termCurrentIndex < this.termTotalCards) {
      this.testTermCard2 = this.kpiFirstReport.terminalStats[this.termCurrentIndex++];
      this.showTermCard2 = true;
    }
    else {
      this.showTermCard2 = false;
      this.termCurrentIndex++;
    }

    // Card 3
    if (this.termCurrentIndex < this.termTotalCards) {
      this.testTermCard3 = this.kpiFirstReport.terminalStats[this.termCurrentIndex++];
      this.showTermCard3 = true;
    }
    else {
      this.showTermCard3 = false;
      this.termCurrentIndex++;
    }

    // Card 4
    if (this.termCurrentIndex < this.termTotalCards) {
      this.testTermCard4 = this.kpiFirstReport.terminalStats[this.termCurrentIndex++];
      this.showTermCard4 = true;
    }
    else {
      this.showTermCard4 = false;
      this.termCurrentIndex++;
    }

    if (this.termCurrentIndex <= 6)
      this.termCardPrev = true;
    else
      this.termCardPrev = false;

    if (this.termCurrentIndex < this.termTotalCards)
      this.termCardNext = false;
    else
      this.termCardNext = true;
  }

  configTerminalInfoCards(kpiTerminals: KpiFirstReportTerminalInfo[]) {
    this.termCurrentIndex = 0;
    this.termTotalCards = kpiTerminals.length;
    this.processTerminalInfoCards();
  }

  termPrev() {
    this.termCurrentIndex -= 8;
    this.processTerminalInfoCards();
  }

  termNext() {
    this.processTerminalInfoCards();
  }

  downloadKPIFirstReport() {
    this.downloadsService.downloadKPIFirstReport(this.selectedCustomer.id);
  }

}
