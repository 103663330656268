import { AuditableEntity } from "./auditable-entity";

export class User extends AuditableEntity  {
    title?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    role?: string;
    client?: string;
    terminals?: string;
    isClockIn: boolean = false;

    constructor() {
        super();
    }
    
    get displayName(): string {
        return (this.title && this.title.length) ?
            this.title + ' ' + this.firstName + ' ' + this.lastName :
            this.firstName + ' ' + this.lastName;
    }

    // Use to create a User object with getter
    // when binding from an object of user properties
    static fromUserProperties(user: User): User {
        if (user) {
            const newUser = new User();
            newUser.id = user.id;
            newUser.createdOn = user.createdOn;
            newUser.createdBy = user.createdBy;
            newUser.modifiedOn = user.modifiedOn;
            newUser.modifiedBy = user.modifiedBy;
            newUser.active = user.active;
            newUser.title = user.title;
            newUser.firstName = user.firstName;
            newUser.lastName = user.lastName;
            newUser.email = user.email;
            newUser.phone = user.phone;
            newUser.role = user.role;
            newUser.client = user.client;
            newUser.terminals = user.terminals;
            newUser.isClockIn = user.isClockIn;
            return newUser;
        }
        return null;
    }
}

export class UserProfile {
    user: User;
    resetPassword: boolean;
    password: string;
}
