import { Guid } from 'guid-typescript';

export interface IAuditableEntity {
    id: string;
    createdOn?: Date;
    createdBy?: string;
    modifiedOn?: Date;
    modifiedBy?: string;
    active: boolean;
}

export class AuditableEntity {
    id: string = Guid.create().toString();
    createdOn?: Date;
    createdBy?: string;
    modifiedOn?: Date;
    modifiedBy?: string;
    active: boolean = true;

    constructor() {}
}