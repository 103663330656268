<div *ngIf="measurement">
    <div class="p-grid">
        <div class="p-col-2">
            <p><b>Parcel {{measurement.parcelNumber}}</b></p>
        </div>
        <div class="p-col-10">
            <div class="p-fluid">
                <div *ngIf="showPushOption" class="p-field p-grid">
                    <label for="isPush" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Is Push?</label>
                    <div class="p-col-12 p-md-10">
                        <p-inputSwitch id="isPush" [(ngModel)]="measurement.isPush"></p-inputSwitch>
                    </div>
                </div>
                <div *ngIf="!showPushOption || !measurement.isPush" class="p-field p-grid">
                    <label for="quantity" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Quantity</label>
                    <div class="p-col-12 p-md-10">
                        <input id="quantity" type="number" pInputText [(ngModel)]="measurement.value"> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
