import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ClientCompany, HomeDetails, QuickLook, User, UserRoles } from "../../models";
import { take } from 'rxjs/operators';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { CustomerService } from 'src/app/services/http-services/customer.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { Subscription } from 'rxjs';
import { Weather } from '../../models/customer/weather';


@Component({
  selector: 'app-customermain',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class CustomerMainComponent implements OnInit, OnDestroy {
  pageDataReady: boolean = false;
  todaysDate: Date;
  weatherDlg: boolean = false;
  subscriptions: Subscription[] = [];
  homeScreenDetails: HomeDetails;
  ticker: QuickLook;
  weatherInfo: Weather;
  weatherCols: any[];
  viewAssignment: boolean = false;
  activeJobsCount: number = 0;
  completedJobsCount: number = 0;
  clientId: string;
  currentUser: User = new User();
  selectedCustomer: ClientCompany;
  weatherIcon: string;
  weatherTip: string;

  constructor(
    private store: Store<appState.State>,
    private dataSharingService: DataSharingService,
    private customerService: CustomerService,
    private router: Router,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.todaysDate = new Date();
    this.weatherIcon = "assets/images/images/sunny.PNG";
    this.weatherTip = "Sunny";
    this.buildWeatherColumns();
    this.listenToStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        if (user?.id && user.id == this.currentUser?.id) return;

        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.newCustomer)) {
            this.getHomeScreenDetails(user.client);
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin) || this.currentUser.role.includes(UserRoles.superNewCustomer)) {
            this.listenToCustomerChanges();
          }
        }
      })
    );
  }

  listenToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;

      if (!this.selectedCustomer || this.selectedCustomer.id != c.id) {
        this.selectedCustomer = c;
        this.getHomeScreenDetails(this.selectedCustomer.id);
      }
    });
  }

  getHomeScreenDetails(clientId: string) {
    this.store.dispatch(appActions.showSpinner());
    this.customerService.getHomeScreenDetails(clientId).subscribe(
      results => {
        if (results == null) {
          this.viewAssignment = false;
        }
        else {
          this.homeScreenDetails = results;
          if (this.homeScreenDetails.ticker == null) {
            this.ticker = null;
            this.viewAssignment = false;
          }
          else {
            this.ticker = this.homeScreenDetails.ticker;
            this.viewAssignment = true;
          }
          if (this.homeScreenDetails.weather == null) {
            this.weatherInfo = null;
          }
          else {
            this.weatherInfo = this.homeScreenDetails.weather;
          }
        }
        this.pageDataReady = true;
        this.store.dispatch(appActions.hideSpinner());
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error Getting Home Screen Details', error);
      }
    );
  }

  buildWeatherColumns() {
    this.weatherCols = [
      { field: 'time', header: 'TIME', display: true },
      { field: 'temp2m', header: 'TEMP 2M (°F)', display: true },
      { field: 'relHum2m', header: 'REL HUMIDITY 2M (%)', display: true },
      { field: 'appTemp', header: 'APPARENT TEMP (°F)', display: true },
      { field: 'precipitation', header: 'PRECIP (IN)', display: true },
      { field: 'rain', header: 'RAIN (IN)', display: true },
      { field: 'showers', header: 'SHOWERS (IN)', display: true },
      { field: 'weathercode', header: 'WEATHER CODE', display: true },
      { field: 'windspeed10m', header: 'WIND SPEED 10M (MPH)', display: true },
      { field: 'winddirection10m', header: 'WIND DIR 10M (°)', display: true },
      { field: 'windgusts10m', header: 'WIND GUSTS 10M (MPH)', display: true },
    ];
  }

  viewActiveJobs() {
    this.router.navigate(['customer/jobs-list/active']);
  }

  viewCompletedJobs() {
    this.router.navigate(['customer/jobs-list/complete']);
  }

  viewKPIs() {
    this.router.navigate(['customer/kpi']);
  }

  viewJobAssignment() {
    this.router.navigate(['customer/jobs-detail/active/' + this.ticker.jobId]);
  }

  openWeatherDlg() {
    this.weatherDlg = true;
  }
}
