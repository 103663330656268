<div class="p-grid">
    <div class="p-col-12">
        <p-button label="Add Override" icon="pi pi-plus" styleClass="p-button-primary" (onClick)="addOverride()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-col-12">
        <p-table #overridesDt
                    [value]="overrides"
                    [columns]="cols"
                    [rows]="5"
                    [responsive]="true"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[5,10,25,50]"
                    [rowHover]="true"
                    >
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-override>
                        <tr>
                            <td>{{override.step}}</td>
                            <td>{{override.parcelNumber}}</td>
                            <td>{{override.value}}</td>
                            <td>{{override.reason}}</td>
                            <td>
                                <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editOverride(override.id)" pTooltip="Edit this Override"></button>
                                <button pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-trash" (click)="deleteOverride(override)" pTooltip="Delete this Override"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
    </div>
    <div class="p-col-12">
        <p-button label="Close" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showOverrideEdit" [(visible)]="showOverrideEdit" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-overrides-detail
            [override]="selectedOverride"
            [steps]="steps"
            [parcels]="parcels"
            (overrideSaved)="onSaveOverride()"
            (overrideCanceled)="onEditOverrideCanceled()"></app-job-blp-overrides-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showOverrideEdit" [(visible)]="showOverrideEdit" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-overrides-detail
            [override]="selectedOverride"
            [steps]="steps"
            [parcels]="parcels"
            (overrideSaved)="onSaveOverride()"
            (overrideCanceled)="onEditOverrideCanceled()"></app-job-blp-overrides-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
