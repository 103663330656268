import { AuditableEntity } from "../../auditable-entity";
import { VdpLineItem } from "./vdp-line-item";
import { VdpShoreTank } from "./vdp-shore-tank";

export class JobVdp extends AuditableEntity {

    jobId: string;
    parcelNumber: number;
    product: string;
    shoreTanks: VdpShoreTank[] = [];
    lineItems: VdpLineItem[] = [];
    calculatedApi: number = 0;
    calculatedTemp: number = 0;
    calculatedGsv: number = 0;
    calculatedTov: number = 0;
    calculatedMt: number = 0;
    calculatedLt: number = 0;
    tovTotal: number = 0;
    mtTotal: number = 0;
    gsvTotal: number = 0;
    pctTotal: number = 0;
    storageEntry: string;

    constructor(jobId: string, parcelNumber: number, product: string) {
        super();
        this.jobId = jobId;
        this.parcelNumber = parcelNumber;
        this.product = product;
    }

    static fromJson(vdp: JobVdp): JobVdp {
        const newVdp: JobVdp = new JobVdp(vdp.jobId, vdp.parcelNumber, vdp.product);
        
        newVdp.id = vdp.id;
        newVdp.active = vdp.active;
        newVdp.createdOn = vdp.createdOn;
        newVdp.createdBy = vdp.createdBy;
        newVdp.modifiedOn = vdp.modifiedOn;
        newVdp.modifiedBy = vdp.modifiedBy;
        newVdp.storageEntry = vdp.storageEntry;
        newVdp.shoreTanks = vdp.shoreTanks;
        newVdp.lineItems = vdp.lineItems.map(i => VdpLineItem.fromJson(i));

        return newVdp;
    }
}

export enum VdpStorageEntryOptions {
    tov = 'TOV',
    gsv = 'GSV',
    mt = 'MT'
}