<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="client.name"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="contacts" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Contacts</label>
                <div class="p-col-12 p-md-10">
                    <div class="p-grid">
                        <div class="p-col-12 p-lg-9 p-md-8">
                            <p-multiSelect [options]="contactOptions" [(ngModel)]="client.contacts" appendTo="body" placeholder="Select Contacts"></p-multiSelect>
                        </div>
                        <div class="p-col-12 p-lg-3 p-md-4">
                            <p-button label="Contact" icon="pi pi-plus" styleClass="p-button-primary" (onClick)="showContactDialog = true" [style]="{'width': '100%'}"></p-button>
                        </div>
                        <div *ngIf="showContactDialog" class="p-col-12">
                            <p-panel header="New Contact" class="new-contact-panel">
                                <app-contact (contactUpdated)="contactAdded($event)" (close)="showContactDialog = false"></app-contact>
                            </p-panel>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>