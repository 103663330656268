import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TimingCategory } from "src/app/models/system-config/timing-category";

@Injectable({
  providedIn: 'root'
})
export class TimingCategoryService {
  private apiUrl = '/api/timingcategory/';

  constructor(
    private httpClient: HttpClient) {}

  getTimingCategories(): Observable<TimingCategory[]> {
    return this.httpClient.get<TimingCategory[]>(this.apiUrl);
  }

  upsertTimingCategory(timingCategory: TimingCategory): Observable<any> {
      return this.httpClient.post(this.apiUrl + '', timingCategory);
  }
}