import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User, UserRoles, ClientCompany } from 'src/app/models';
import { JobReportService } from 'src/app/services/http-services/job.report.service';
import { JobReportDataService } from 'src/app/services/job-report-data.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as appState from 'src/app/state';
import * as appActions from 'src/app/state/app.actions';
import { Terminal } from 'src/app/models';
import { TerminalPerformanceReport } from 'src/app/models/reports/terminal-report';
import { TimingInfo } from 'src/app/models/reports/job-performance';

@Component({
  selector: 'app-customer-job-performance',
  templateUrl: './customer-job-performance.component.html',
  styleUrls: ['./customer-job-performance.component.scss']
})
export class CustomerJobPerformanceComponent implements OnInit, OnDestroy {
  chartData: any;
  chartOptions: any;
  currentUser: User = new User();
  report: TerminalPerformanceReport;
  terminals: Terminal[] = [];
  selectedTerminal: Terminal;
  terminalOptions: SelectItem[] = [];
  subscriptions: Subscription[] = [];
  selectedCustomer: ClientCompany;
  timingInfos: TimingInfo[] = [];


  constructor(
    private jobReportService: JobReportService,
    private jobReportDataService: JobReportDataService,
    private dataSharingService: DataSharingService,
    private appMessageService: AppMessageService, 
    private store: Store<appState.State>
  ) { }

  ngOnInit(): void {
    this.report = this.jobReportDataService.createBlankTerminalPerformanceReport();
    this.listenToStateChanges();
    this.configChartOptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  configChartOptions() {
    var jobReportDataService = this.jobReportDataService;
    this.chartOptions = {
      legend: { position: 'top' },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return data.labels[tooltipItem.index] + ": " + jobReportDataService.convertDuration(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] * 60);
          }
        }
      }
    }
  }

  configDowntimeChartData(downtimeTimings: TimingInfo[]){
    this.chartData = {
      labels: downtimeTimings.map(t => t.description),
      datasets: [
        {
          backgroundColor: [
            "#42A5F5", //blue
            "#66BB6A", //green
            "#FFA726", //orange
            '#EC407A', //magenta
            '#AB47BC', //purple
            '#ffff00', //yellow
            '#804000', //brown
            '#408080', //teal
            '#400040', //dark purple
            '#8080C0'  //lavender
          ],
          label: downtimeTimings.map(t => t.description),
          data: downtimeTimings.map(t => t.duration)
        }
      ]
    }
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        if (user?.id && user.id == this.currentUser?.id) return;
        
        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.customer)) {
            this.getTerminals(this.currentUser.client);
            this.store.pipe(select(appState.getClients)).subscribe(results => {
              if (results){
                this.selectedCustomer = results.find(c => c.id == this.currentUser.client);
                if (this.selectedCustomer)
                  this.dataSharingService.setCustomer(this.selectedCustomer);
              }
            })
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin)){
            this.listentToCustomerChanges();
          }
        }
      })
    );
  }

  listentToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;
      
      if (!this.selectedCustomer || this.selectedCustomer.id != c.id){
        this.selectedCustomer = c;
        this.getTerminals(this.selectedCustomer.id);
      }
    });
  }

  getTerminals(customerId: string){
    this.jobReportService.getJobTerminals(customerId).subscribe(
      result => {
        this.terminals = result;
        this.terminalOptions = this.buildTerminalOptions();
      },
      error => {
        this.appMessageService.errorMessage('Error Getting Job Terminals for Customer', null);
      }
    );
  }

  buildTerminalOptions(): SelectItem[] {
    return this.terminals.sort((a,b) => a.name > b.name ?  1 :  -1).map(d => { return { 'label': d.port?.name + ': ' + d.name, value: d }; });
  }

  async terminalChanged() {
    this.store.dispatch(appActions.showSpinner());
    var report = await this.jobReportService.getTerminalPerformanceReport(this.selectedCustomer.id, this.selectedTerminal.id)
      .toPromise()
      .catch(error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error getting Terminal Performance Report', null);
        console.log(error);
        return null;
      });
    if (report) {
      this.report = report;
      this.report.netPumpingRate = Math.round(this.report.netPumpingRate);
      this.report.grossPumpingRate = Math.round(this.report.grossPumpingRate);
    }
    this.computeAdditionaInfo(this.report);
    this.configDowntimeChartData(this.report.downtimeAverages)
    this.store.dispatch(appActions.hideSpinner());
  }

  computeAdditionaInfo(report: TerminalPerformanceReport) {
      this.timingInfos = this.jobReportDataService.createTerminalTimingComparisonInfo(report);
      report.tankSwitchText = this.jobReportDataService.convertDuration(report.tankSwitch);
      report.totalDowntimeText = this.jobReportDataService.convertDuration(report.totalDowntime);
  }
}
