import { VcfCalculations } from "../../domain";
import { AuditableEntity } from "../../auditable-entity";

export class VdpShoreTank extends AuditableEntity {
    vdpId: string;
    step: number;
    tankId: string;
    apiNumber: number;
    gsv: number;
    temp: number;
    vcfCalculation: string;
    vcf: number = 0;
    tov: number = 0; //gsv/vcf
    pctWt: number = 0; // correctedWeight / totalCorrectedWeight
    pctApi: number = 0; // API * (GSV / totalGSV)
    correctedWeight: number = 0; //row15 =@Tables13(API)*GSV
    weightedApi: number = 0; //row16b pctWt * API
    weightedTemp: number = 0; //row17 pctWt * TEMP
    pctVolume: number = 0; //

    constructor(vdpId: string, step: number, tankId: string, apiNumber: number, gsv: number, temp: number = 0, vcfCalculation: string = VcfCalculations.t6b){
        super();
        this.vdpId = vdpId;
        this.step = step;
        this.tankId = tankId;
        this.apiNumber = apiNumber;
        this.gsv = gsv;
        this.temp = temp;
        this.vcfCalculation = vcfCalculation;
    }
}