<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="dock.name"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="port" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Terminal</label>
                <div class="p-col-12 p-md-10">
                     <p-dropdown [options]="terminalOptions" [(ngModel)]="dock.terminal" appendTo="body" placeholder="Select Terminal"></p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>
