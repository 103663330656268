<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="tankId" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Tank</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown [options]="tankOptions" appendTo="body" [(ngModel)]="vdpLineItem.tankId" placeholder="Select Vessel Tanks"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="storageEntry" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Stowage Entry Type</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown [options]="entryOptions" appendTo="body" [(ngModel)]="vdp.storageEntry" [disabled]="entryOptionDisabled()" ></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="value" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Value</label>
                <div class="p-col-12 p-md-10">
                    <p-inputNumber id="port" mode="decimal" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="1" [(ngModel)]="value"></p-inputNumber>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" [disabled]="!canSave" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
        <p-message *ngIf="!canSave" severity="error" text="All fields are required"></p-message>
    </div>
    <!-- <div *ngIf="!canSave" class="p-col-12">
        <p-message severity="error" text="All fields are required"></p-message>
    </div> -->
</div>
