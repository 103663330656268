import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Job, JobBlpRevision, JobOverview, TimeTrackingEventLog, Bfr, JobVdp, Bou } from "src/app/models";
import { Feedback } from "../../models/customer/feedback";

@Injectable({
    providedIn: 'root'
})
export class JobService {
    private apiUrl = '/api/job';

    constructor(private httpClient: HttpClient) {}

    getJobOverviews(): Observable<JobOverview[]> {
        return this.httpClient.get<JobOverview[]>(this.apiUrl + '/overview');
    }

    getJobById(id: string): Observable<Job> {
        return this.httpClient.get<Job>(this.apiUrl + '/' + id).pipe(map(j => Job.fromJson(j)));
    }

    deleteJobById(id: string): Observable<Job> {
        return this.httpClient.delete<Job>(this.apiUrl + '/' + id).pipe(map(j => Job.fromJson(j)));
    }

    upsertJob(jobId: string, job: Job): Observable<any> {
      return this.httpClient.put(this.apiUrl + '/' + jobId, Job.fromJson(job));
    }

    upsertOverview(jobId: string, overview: JobOverview): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + jobId + '/overview', JobOverview.fromJson(overview));
    }

    upsertBlpRevision(jobId: string, blpRevisions: JobBlpRevision): Observable<JobBlpRevision> {
        return this.httpClient.put<JobBlpRevision>(this.apiUrl + '/' + jobId + '/blp', blpRevisions);
    }

    upsertTimeTrackingEventLog(jobId: string, timeTrackingEventLog: TimeTrackingEventLog): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + jobId + '/timetracking', timeTrackingEventLog);
    }

    upsertBfr(jobId: string, bfr: Bfr): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + jobId + '/bfr', bfr);
    }

    upsertVdp(jobId: string, vdp: JobVdp): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + jobId + '/vdp', vdp);
    }

    upsertBou(jobId: string, bou: Bou): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + jobId + '/bou', bou);
    }

    upsertFeedback(jobId: string, feedback: Feedback): Observable<any> {
      return this.httpClient.put(this.apiUrl + '/' + jobId + '/feedback', feedback);
  }
}
