<div *ngIf="jobDetail !== undefined && jobDetail !== null">

  <div class="p-grid">

    <div class="p-col-12 p-lg-6 p-xl-6">
      <div class="p-card rcorners border-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{jobDetail.ship.name}}</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h3>Last Updated on {{jobDetail.lastUpdateDate | date: 'EEEE, MMMM d,y,h:mm a'}} (CST)</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card rcorners border-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h3>All Fast Time</h3>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{jobDetail.allFastDate | date: 'M/d/yyyy h:mm a'}} (CST)</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card rcorners border-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h3>Estimated Completion Time (ECT)</h3>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{jobDetail.estimatedCompletionDate | date: 'M/d/yyyy h:mm a'}} (CST)</h2>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12 p-lg-6 p-xl-6">
      <div class="p-card rcorners border-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Loading Status: [{{loadingStatus}}]</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2 *ngIf="loadingStatus === 'COMPLETED'">Step: {{totalLoadingSteps}} out of {{totalLoadingSteps}}</h2>
            <h2 *ngIf="loadingStatus !== 'COMPLETED'">Step: {{currLoadingStep + 1}} out of {{totalLoadingSteps}}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card rcorners border-color-blue background-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Time on Dock (hh:mm)</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{(jobDetail.timeOnDock.days * 24) + jobDetail.timeOnDock.hours | number: '2.0-0'}}:{{jobDetail.timeOnDock.minutes | number: '2.0-0'}}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card rcorners border-color-blue background-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Time of Delays (hh:mm)</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{jobDetail.totalDowntime.hours | number: '2.0-0'}}:{{jobDetail.totalDowntime.minutes | number: '2.0-0'}}</h2>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12 p-lg-6 p-xl-6">
      <div class="p-card rcorners border-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Loading Plan</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <p-table #loadingPlanDt [value]="loadingPlanSteps"
                     styleClass="p-datatable-striped"
                     [columns]="loadingPlanCols"
                     [rows]="3"
                     [tableStyle]="{'min-height': '15rem','max-height': '15rem'}"
                     [responsive]="true"
                     [paginator]="true"
                     [showCurrentPageReport]="true"
                     currentPageReportTemplate="{first} to {last} of {totalRecords} records"
                     [rowHover]="true">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns | colDisplayFilter">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{col.header}}
                      <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                      <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                      <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-seqno let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns">
                    <span class="p-column-title">{{ col.header }}</span>
                    <span *ngIf="col.header === 'Done?'"><p-checkbox [(ngModel)]="seqno.done" [binary]="true" [disabled]="true"></p-checkbox></span>
                    <span *ngIf="col.header === 'Step'">{{seqno.step}}</span>
                    <span *ngIf="col.header === 'Nominated'">{{seqno.nominatedQty | number: '1.2-2'}}</span>
                    <span *ngIf="col.header === 'Loaded'">{{seqno.deliveredQty | number: '1.2-2'}}</span>
                    <span *ngIf="col.header === 'Variance'">{{seqno.variance | number: '1.2-2'}}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-6 p-xl-6">
      <div class="p-card rcorners border-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Downtime Summary</h2>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <p-table #downtimeDt [value]="downtimeSummaries"
                     styleClass="p-datatable-gridlines"
                     [columns]="downtimeSummaryCols"
                     [rows]="3"
                     [tableStyle]="{'min-height': '15rem','max-height': '15rem'}"
                     [responsive]="true"
                     [paginator]="true"
                     [showCurrentPageReport]="true"
                     currentPageReportTemplate="{first} to {last} of {totalRecords} records"
                     [rowHover]="true">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns | colDisplayFilter">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{col.header}}
                      <p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
                      <p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                      <p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-summary let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns">
                    <span class="p-column-title">{{ col.header }}</span>
                    <span *ngIf="col.header === 'Category'">{{summary.category}}</span>
                    <span *ngIf="col.header === 'Type'">{{summary.name}}</span>
                    <span *ngIf="col.header === 'Duration'">{{summary.duration.hours}}:{{summary.duration.minutes}}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12 p-lg-6 p-xl-6">
      <div class="p-card rcorners border-color-blue">

        <div class="flex flex-column card-container">
          <img src="{{superProfilePic
                      }}" [style]="{'float':'right','padding-top':'2rem','padding-right':'2rem','height':'15srem','width':'10rem'}"/>
                      <div class="flex align-items-center justify-content-center text-padding">
                        <h2>Superintendent: {{superFullName}}</h2>
                      </div>
                      <div class="flex align-items-center justify-content-center text-padding super-contact-text">
                        <text>Phone: <a href="tel:{{superPhone}}">{{superPhone}}</a></text>
                      </div>
                      <div class="flex align-items-center justify-content-center text-padding super-contact-text">
                        <text>Email: <a href="mailto:{{superEmail}}">{{superEmail}}</a></text>
                      </div>
                      <div class="flex align-items-center justify-content-center button-padding">
                        <p-button icon="pi pi-backward" styleClass="p-button-secondary p-button-text p-button-rounded" (onClick)="prevSuper()" [disabled]="superBack"></p-button>
                        &emsp;
                        <p-button icon="pi pi-forward" styleClass="p-button-secondary p-button-text p-button-rounded" (onClick)="nextSuper()" [disabled]="superForward"></p-button>
                      </div>
        </div>

      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card rcorners border-color-blue background-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Total Tank Switch</h2>
            <h3>(hh:mm)</h3>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{jobDetail.totalTankSwitch.hours | number: '2.0-0'}}:{{jobDetail.totalTankSwitch.minutes | number: '2.0-0'}}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3 p-xl-3">
      <div class="p-card rcorners border-color-blue background-color-blue">
        <div class="flex flex-column card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>Gross Pumping Rate</h2>
            <h3>(bbls/hr)</h3>
          </div>
          <div class="flex align-items-center justify-content-center text-padding">
            <h2>{{jobDetail.grossPumpingRate | number: '1.2-2'}}</h2>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12">
      <div class="p-card rcorners border-color-blue">

        <div class="flex card-container">
          <div class="flex align-items-center justify-content-center text-padding">
            <h1 class="center-alignment"><i>View Reports</i></h1>
          </div>
            <div class="center-alignment">
              <span class="p-buttonset">
                <button class="report-button" (click)="downloadBou()" pTooltip="Download BOU">
                  <img src="../../../assets/icons/bou-report-icon.PNG" />
                </button>
                <button class="report-button" (click)="downloadBlp()" pTooltip="Download BLP">
                  <img src="../../../assets/icons/blp-report-icon.PNG" />
                </button>
                <button class="report-button" (click)="downloadTimeTrackingPdf()" pTooltip="Download Time Tracking (PDF)">
                  <img src="../../../assets/icons/ttr-report-icon-pdf.PNG" />
                </button>
                <button class="report-button" (click)="downloadBfr()" pTooltip="Download BFR">
                  <img src="../../../assets/icons/bfr-report-icon.PNG" />
                </button>
              </span>
            </div>
          </div>

        </div>
    </div>

 </div>

</div>
