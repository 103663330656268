<div class="p-grid">
    <div class="p-col-12">
        <div class="p-grid">
            <!-- <div class="p-md-6 p-col-12">
                <p-button label="Add Parcel" [disabled]="!isInBlpEditMode" icon="pi pi-plus" styleClass="p-button-primary" (onClick)="addNewParcel()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Add Step" [disabled]="!isInBlpEditMode" icon="pi pi-plus" styleClass="p-button-primary" (onClick)="addNewStep()" [style]="{'width': '100%'}"></p-button>
            </div> -->
            <div class="p-lg-4 p-md-6 p-d-none p-d-md-inline">
                <p-splitButton label="BLP Actions" icon="pi pi-cog" [disabled]="!isInBlpEditMode" [model]="actionButtonItems" styleClass="p-button-primary" [style]="{'width': '100%', 'height': '3em' }"></p-splitButton>
            </div>
            <div class="p-col-12 p-d-md-none">
                <p-splitButton label="BLP Actions" icon="pi pi-cog" [disabled]="!isInBlpEditMode" [model]="actionButtonItems" styleClass="p-button-primary" [style]="{'width': '100%', 'height': '3em'}"></p-splitButton>
            </div>
            <div #blpDetailGrid class="p-col-12 p-d-none p-d-md-inline">
                <p-table
                        [value]="tableData"
                        [columns]="largeScreenCols"
                        [responsive]="false"
                        [scrollable]="true"
                        [style]="{width: '100%'}"
                        styleClass="p-datatable-striped"
                        >
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style]="{'width':col.width}">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class1"
                                    [style.overflow-x]="col.allowOverflow1 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow1 ? 'nowrap' : 'inherit'"
                                >{{col.header1}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class2"
                                    [style.overflow-x]="col.allowOverflow2 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow2 ? 'nowrap' : 'inherit'"
                                >{{col.header2}}
                                <i *ngIf="col.showHeader2Icon && isInBlpEditMode" class="pi pi-pencil parcel-icons" (click)="editParcel(col.header2)" pTooltip="Edit this Parcel"></i>
                            </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class3"
                                    [style.overflow-x]="col.allowOverflow3 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow3 ? 'nowrap' : 'inherit'"
                                >{{col.header3}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class4"
                                    [style.overflow-x]="col.allowOverflow4 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow4 ? 'nowrap' : 'inherit'"
                                >{{col.header4}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class5"
                                    [style.overflow-x]="col.allowOverflow5 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow5 ? 'nowrap' : 'inherit'"
                                >{{col.header5}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row>
                            <tr>
                                <td>
                                    <button [disabled]="!isInBlpEditMode" pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editStep(row.step)" pTooltip="Edit this Step"></button>
                                    <button [disabled]="!isInBlpEditMode" pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-trash" (click)="deleteStep(row.step)" pTooltip="Delete this Step"></button>
                                </td> 
                                <td>{{row.step}}</td>
                                <td>{{row.tankLocation}}</td>
                                <td>{{row.tankName}}</td>
                                <td>{{row.apiNumber}}</td>
                                <td *ngFor="let parcel of blp.parcels; index as i" [ngClass]="{'blp-step-push': showPushClass(row.step, i)}">{{row['nomParcel' + (i+1).toString()]}}</td>
                                <td *ngIf="row.lineAdjValue < 0" class="debted-number">{{'(' + -row.lineAdjValue + ')'}}</td>
                                <td *ngIf="!row.lineAdjValue || row.lineAdjValue >= 0">{{row.lineAdjValue}}</td>
                                <td>{{row.lineAdjNumber}}</td>
                                <td *ngFor="let parcel of blp.parcels; index as i">{{row['adjParcel' + (i+1).toString()]}}</td>
                                <td *ngIf="blp.parcels && blp.parcels.length">{{row.total}}</td>
                                <td>{{row.comment}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" let-columns>
                            <tr>
                                <td></td> 
                                <td></td>
                                <td></td>
                                <td *ngIf="blp.parcels && blp.parcels.length" class="blp-step-push allow-overflow">Total Loaded:</td>
                                <td *ngIf="!(blp.parcels && blp.parcels.length)"></td>
                                <td *ngIf="blp.parcels && blp.parcels.length" class="blp-step-push allow-overflow"></td>
                                <td *ngIf="!(blp.parcels && blp.parcels.length)"></td>
                                <td *ngFor="let parcel of blp.parcels; index as i" class="blp-header-step">{{getNominationTotal(i+1)}}</td>
                                <td></td>
                                <td></td>
                                <td *ngFor="let parcel of blp.parcels; index as i" class="blp-header-step" [ngClass]="{'blp-unbalanced':getAdjustedQuantityTotalUnbalanced(i+1)}">{{getAdjustedTotal(i+1)}}</td>
                                <td *ngIf="blp.parcels && blp.parcels.length" class="blp-header-step" [ngClass]="{'blp-unbalanced':unbalancedTotal}">{{blp.allStepTotal}}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td> 
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td *ngFor="let parcel of blp.parcels; index as i"></td>
                                <td></td>
                                <td></td>
                                <td *ngFor="let parcel of blp.parcels; index as i"></td>
                                <td *ngIf="blp.parcels && blp.parcels.length"></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td> 
                                <td></td>
                                <td></td>
                                <td *ngIf="blp.parcels && blp.parcels.length" class="blp-step-push allow-overflow">Final Parcel API:</td>
                                <td *ngIf="!(blp.parcels && blp.parcels.length)"></td>
                                <td *ngIf="blp.parcels && blp.parcels.length" class="blp-step-push allow-overflow"></td>
                                <td *ngIf="!(blp.parcels && blp.parcels.length)"></td>
                                <td *ngFor="let parcel of blp.parcels; index as i" class="blp-final-api">{{getFinalApi(i +1) | number: '1.1-1'}}</td>
                                <td></td>
                                <td></td>
                                <td *ngFor="let parcel of blp.parcels; index as i"></td>
                                <td *ngIf="blp.parcels && blp.parcels.length"></td>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
            <div class="p-col-12 p-d-md-none">
                <p-table
                        [value]="tableData"
                        [columns]="mobileCols"
                        [responsive]="true"
                        [style]="{width: '100%'}"
                        styleClass="p-datatable-striped"
                        >
                        <ng-template pTemplate="body" let-row>
                            <tr>
                                <td>Step: {{row.step}}</td>
                                <td>Location: {{row.tankLocation}}</td>
                                <td>Id: {{row.tankName}}</td>
                                <td *ngIf="blp.parcels && blp.parcels.length">Total: {{row.total}}</td>
                                <td>
                                    <button [disabled]="!isInBlpEditMode" pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editStep(row.step)" pTooltip="Edit this Step"></button>
                                    <button [disabled]="!isInBlpEditMode" pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-trash" (click)="deleteStep(row.step)" pTooltip="Delete this Step"></button>
                                </td> 
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" [disabled]="!isInBlpEditMode" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" [disabled]="!isInBlpEditMode || unbalanced" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
                <p-message *ngIf="unbalanced" severity="error" text="BLP must be balanced before saving!" [style]="{'width': '100%'}"></p-message>
            </div>
        </div>
    </div>
</div>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showParcelEdit" [(visible)]="showParcelEdit" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-parcel
            [parcel]="selectedParcel"
            [vesselTanks]="vesselTanks"
            (parcelEditCanceled)="onParcelEditCanceled()"
            (parcelEditSaved)="onParcelEditSaved()"></app-job-blp-parcel>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showParcelEdit" [(visible)]="showParcelEdit" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-parcel
            [parcel]="selectedParcel"
            [vesselTanks]="vesselTanks"
            (parcelEditCanceled)="onParcelEditCanceled()"
            (parcelEditSaved)="onParcelEditSaved()"></app-job-blp-parcel>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showStepEdit" [(visible)]="showStepEdit" [style]="{ width: '50vw' }" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-step
            [step]="selectedStep"
            [isNewStep]="isNewStep"
            [parcels]="blp.parcels"
            [overview]="overview"
            [shoreTanks]="shoreTanks"
            [isExistingCargo]="isExistingCargoStep"
            (stepEditCanceled)="onStepEditCanceled()"
            (stepEditSaved)="onStepEditSaved()"></app-job-blp-step>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showStepEdit" [(visible)]="showStepEdit" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-step
            [step]="selectedStep"
            [isNewStep]="isNewStep"
            [parcels]="blp.parcels"
            [overview]="overview"
            [shoreTanks]="shoreTanks"
            [isExistingCargo]="isExistingCargoStep"
            (stepEditCanceled)="onStepEditCanceled()"
            (stepEditSaved)="onStepEditSaved()"></app-job-blp-step>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showOverridesEdit" [(visible)]="showOverridesEdit" [style]="{width: '50vw',height: '350px'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-overrides
            [overrides]="blp.adjustedQuantityOverrides"
            [steps]="blp.lineItems"
            [parcels]="blp.parcels"
            (overridesSaved)="onOverridesSaved()"
            (overridesCanceled)="onOverridesCanceled()"></app-job-blp-overrides>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showOverridesEdit" [(visible)]="showOverridesEdit" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-blp-overrides
            [overrides]="blp.adjustedQuantityOverrides"
            [steps]="blp.lineItems"
            [parcels]="blp.parcels"
            (overridesSaved)="onOverridesSaved()"
            (overridesCanceled)="onOverridesCanceled()"></app-job-blp-overrides>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
