import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { Port } from 'src/app/models';

@Component({
  selector: 'app-ports-list',
  templateUrl: './ports-list.component.html',
  styleUrls: ['./ports-list.component.scss']
})
export class PortsListComponent implements OnInit, OnDestroy {

  cols: any[] = [];
  skeletonRows: any[] = [];
  ports: Port[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedPort: Port;
  showEditDialog = false;
  
  constructor(
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForPortChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForPortChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getPortLocations))
        .subscribe(results => {
          this.ports = [...results];
          this.loaded = true;
        })
    );
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addPort() {
    this.selectedPort = new Port();
    this.showEditDialog = true;
  }

  openPort(port: Port) {
   this.selectedPort = cloneDeep(port);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivatePort(port: Port) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Port/Location: ' + port.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactivePort = { ...port, active: false };
        this.store.dispatch(appActions.setPortLocation({ data: deactivePort }));
      }
    });
  }

  activatePort(port: Port) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Port/Location: ' + port.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activePort = { ...port, active: true };
        this.store.dispatch(appActions.setPortLocation({ data: activePort }));
      }
    });
  }

  updatePort(port: Port) {
    this.store.dispatch(appActions.setPortLocation({ data: port }));
    this.showEditDialog = false;
  }

  async resetSelectedPort() {
    // this.ports is already subscribed to latest state of Ports
    // reset selected Port to state
    this.selectedPort = cloneDeep(this.ports.find(t => t.id === this.selectedPort.id));
  }

}


