import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { merge, Observable, of, interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as appState from '../state';
import * as appActions from '../state/app.actions';
import { User, UserRoles } from '../models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loginDisplay = false;
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<appState.State>,
    private actions$: Actions,
    private router: Router,
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe({
        next: (result: any) => {
          if (result?.payload?.account) {
            this.authService.instance.setActiveAccount(result.payload.account);
          }
        },
        error: (error) => console.log(error)
      });

    this.setLoginDisplay();

  }

  setLoginDisplay() {
    this.loginDisplay = true; // this.authService.instance.getAllAccounts().length > 0; --> check why this is returning a length of 0
    if (this.loginDisplay) {
      this.subscriptions.push(
        this.actions$
          .pipe(ofType(appActions.setCurrentUserFromTokenSuccess))
          .subscribe((payload) => {
            if (payload && payload.user && payload.user.role) {
              if ((payload.user.role.includes(UserRoles.customer) || payload.user.role.includes(UserRoles.superCustomer))
                && !payload.user.role.includes(UserRoles.admin) && !payload.user.role.includes(UserRoles.superAdmin)) {
                this.router.navigate(['dashboard']);
              }
              else if ((payload.user.role.includes(UserRoles.newCustomer) || payload.user.role.includes(UserRoles.superNewCustomer))
                && !payload.user.role.includes(UserRoles.admin) && !payload.user.role.includes(UserRoles.superAdmin)) {
                this.router.navigate(['customer/main']);
              }
              else {
                this.router.navigate(['jobs']);
              }
            }
          })
      );
    }
  }

}
