import { Action, createReducer, on } from "@ngrx/store";
import { State } from ".";
import { Port, Terminal, Dock, InspectionCompany, Contact, TimeTrackingEvent, ClientCompany, Job, UserRoles, JobVdp, Tank, Ship, Product } from "../models";
import { TimeTrackingEventLog } from "../models/job/time-tracking/time-tracking-event-log";
import { SystemConfiguration } from "../models/system-config/system-configuration";
import { TimingCategory } from "../models/system-config/timing-category";
import { User } from "../models/user";
import * as appActions from './app.actions';

const initialState: State = {
  currentUser: null,
  selectedJob: new Job(),
  isNewJob: false,
  loadingJob: false,
  inBlpEditMode: false,
  systemConfiguration: new SystemConfiguration(),
  configurationLoaded: false,
  showSpinner: false,
  showAppUpdateAvailable: false,
  timingCategories: []
}

const appReducer = createReducer(
  initialState,
  on(
    appActions.getTimingCategoriesSuccess,
    (state, result) => { state.timingCategories = result.data; return state; }
  ),
  on(
    appActions.setCurrentUserFromTokenSuccess,
    (state, { user }) => ({
      ...state,
      currentUser: user
    })
  ),
  on(
    appActions.showSpinner,
    (state) => ({
      ...state,
      showSpinner: true
    })
  ),
  on(
    appActions.hideSpinner,
    (state) => ({
      ...state,
      showSpinner: false
    })
  ),
  on(
    appActions.ShowUpdateAvailable,
    (state) => ({
      ...state,
      showAppUpdateAvailable: true
    })
  ),
  on(
    appActions.setJob,
    (state, { job }) => ({
      ...state,
      selectedJob: Job.fromJson(job),
      inBlpEditMode: false
    })
  ),
  on(
    appActions.setIsJobNew,
    (state, { isNew }) => ({
      ...state,
      isNewJob: isNew
    })
  ),
  on(
    appActions.setIsJobLoading,
    (state, { isLoading }) => ({
      ...state,
      loadingJob: isLoading
    })
  ),
  on(
    appActions.setJobInBlpEditMode,
    (state, { inBlpEditMode }) => ({
      ...state,
      inBlpEditMode: inBlpEditMode
    })
  ),
  on(
    appActions.setJobOverview,
    (state, { overview }) => ({
      ...state,
      selectedJob: Job.fromJson({
        ...state.selectedJob,
        overview: overview.data
      })
    })
  ),
  on(
    appActions.jobBlpSavedSuccessfully,
    (state, { blpRevision }) => ({
      ...state,
      selectedJob: Job.fromJson({
        ...state.selectedJob,
        blpRevisions: [...state.selectedJob.blpRevisions, blpRevision]
      })
    })
  ),
  on(
    appActions.jobTimeTrackingSavedSuccessfully,
    (state, { log }) => ({
      ...state,
      selectedJob: Job.fromJson({
        ...state.selectedJob,
        timeTrackingEventLogs: [...updateTimeTrackingEventLogs(state.selectedJob.timeTrackingEventLogs, log)]
      })
    })
  ),
  on(
    appActions.jobBfrSavedSuccessfully,
    (state, { bfr }) => ({
      ...state,
      selectedJob: Job.fromJson({
        ...state.selectedJob,
        bfr: bfr
      })
    })
  ),
  on(
    appActions.jobVdpSavedSuccessfully,
    (state, { vdp }) => ({
      ...state,
      selectedJob: Job.fromJson({
        ...state.selectedJob,
        vdps: updateJobVdps(state.selectedJob.vdps, vdp)
      })
    })
  ),
  on(
    appActions.jobBouSavedSuccessfully,
    (state, { bou }) => ({
      ...state,
      selectedJob: Job.fromJson({
        ...state.selectedJob,
        bou: bou
      })
    })
  ),
  on(
    appActions.setSystemConfiguration,
    (state, { configuration }) => ({
      ...state,
      systemConfiguration: { ...configuration, superintendents: configuration.users.filter(u => u.role && u.role.includes(UserRoles.superintendent)).map(u => User.fromUserProperties(u)) },
      configurationLoaded: true
    })
  ),
  on(
    appActions.setPortLocationSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, ports: updatePorts(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setShipSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, ships: updateShips(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setCargoSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, cargos: updateCargos(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setTerminalSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, terminals: updateTerminals(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setDockSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, docks: updateDocks(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setTankSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, tanks: updateTanks(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setClientCompanySuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, clients: updateClients(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setInspectionCompanySuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, inspectionCompanies: updateInspectionCompany(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setContactSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, contacts: updateContacts(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setUserSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, users: updateUsers(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setTimeTrackingEventSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, timeTrackingEvents: updateTimeTrackingEvent(state.systemConfiguration, data) }
    })
  ),
  on(
    appActions.setTimingCategorySuccess,
    (state, { data }) => ({
      ...state,
      timingCategories: { ...state.timingCategories, timingCategories: updateTimingCategory(state.timingCategories, data) }
    })
  ),
  on(
    appActions.setProductSuccess,
    (state, { data }) => ({
      ...state,
      systemConfiguration: { ...state.systemConfiguration, products: updateProducts(state.systemConfiguration, data) }
    })
  )
);

export function reducer(state: State = initialState, action: Action) {
  return appReducer(state, action);
}

function updatePorts(currentSystemConfiguration: SystemConfiguration, item: Port): Port[] {
  const exists = currentSystemConfiguration.ports.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.ports.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.ports, item];
  }
}

function updateShips(currentSystemConfiguration: SystemConfiguration, item: Ship): Ship[] {
  const exists = currentSystemConfiguration.ships.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.ships.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.ships, item];
  }
}

function updateCargos(currentSystemConfiguration: SystemConfiguration, item: Product): Product[] {
  const exists = currentSystemConfiguration.cargos.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.cargos.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.cargos, item];
  }
}

function updateTerminals(currentSystemConfiguration: SystemConfiguration, item: Terminal): Terminal[] {
  const exists = currentSystemConfiguration.terminals.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.terminals.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.terminals, item];
  }
}

function updateDocks(currentSystemConfiguration: SystemConfiguration, item: Dock): Dock[] {
  const exists = currentSystemConfiguration.docks.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.docks.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.docks, item];
  }
}

function updateTanks(currentSystemConfiguration: SystemConfiguration, item: Tank): Tank[] {
  const exists = currentSystemConfiguration.tanks.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.tanks.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.tanks, item];
  }
}

function updateClients(currentSystemConfiguration: SystemConfiguration, item: ClientCompany): ClientCompany[] {
  const exists = currentSystemConfiguration.clients.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.clients.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.clients, item];
  }
}

function updateInspectionCompany(currentSystemConfiguration: SystemConfiguration, item: InspectionCompany): InspectionCompany[] {
  const exists = currentSystemConfiguration.inspectionCompanies.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.inspectionCompanies.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.inspectionCompanies, item];
  }
}

function updateContacts(currentSystemConfiguration: SystemConfiguration, item: Contact): Contact[] {
  const exists = currentSystemConfiguration.contacts.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.contacts.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.contacts, item];
  }
}

function updateUsers(currentSystemConfiguration: SystemConfiguration, item: User): User[] {
  const exists = currentSystemConfiguration.users.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.users.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.users, item];
  }
}

function updateTimeTrackingEvent(currentSystemConfiguration: SystemConfiguration, item: TimeTrackingEvent): TimeTrackingEvent[] {
  const exists = currentSystemConfiguration.timeTrackingEvents.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.timeTrackingEvents.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.timeTrackingEvents, item];
  }
}

function updateTimeTrackingEventLogs(currentLogs: TimeTrackingEventLog[], log: TimeTrackingEventLog): TimeTrackingEventLog[] {
  const exists = currentLogs.find(l => l.id === log.id);
  if (exists) {
    return currentLogs.map(l => {
      return l.id === log.id ? log : l;
    });
  } else {
    return [...currentLogs, log];
  }
}

function updateTimingCategory(currentTimingCategories: TimingCategory[], item: TimingCategory): TimingCategory[] {
  const exists = currentTimingCategories.find(i => i.id === item.id);
  if (exists) {
    return currentTimingCategories.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentTimingCategories, item];
  }
}

function updateJobVdps(currentJobVdps: JobVdp[], vdp: JobVdp): JobVdp[] {
  const exists = currentJobVdps.find(l => l.id === vdp.id);
  if (exists) {
    return currentJobVdps.map(l => {
      return l.id === vdp.id ? vdp : l;
    });
  } else {
    return [...currentJobVdps, vdp];
  }
}

function updateProducts(currentSystemConfiguration: SystemConfiguration, item: Product): Product[] {
  const exists = currentSystemConfiguration.products.find(i => i.id === item.id);
  if (exists) {
    return currentSystemConfiguration.products.map(i => {
      return (i.id === item.id) ? item : i;
    });
  } else {
    return [...currentSystemConfiguration.products, item];
  }
}
