import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppConfig } from '../models/app-config';

@Injectable({
    providedIn: 'root'
  })
export class ApplicationInsightsService {
  private routerSubscription: Subscription;

//   private appInsights = new ApplicationInsights({
//     config: {
//       instrumentationKey: AppConfig.GLOBAL_CONFIG.applicationInsightsKey
//     }
//   });
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    // this.appInsights.loadAppInsights();
    //     this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
    //       const activatedComponent = this.getActivatedComponent(event.state.root);
    //       if (activatedComponent) {
    //         this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
    //       }
    //     });
  }

  initialize() {
    this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: AppConfig.GLOBAL_CONFIG.applicationInsightsKey
        }
    });
    this.appInsights.loadAppInsights();
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
    });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  trackExceptionMessage(message: string, trackingId: string = null) {
    const exception: IExceptionTelemetry = {
        id: trackingId ? trackingId : Math.random().toString(36).substring(7),
        exception: new Error(message)
      };
    this.appInsights.trackException(exception);
  }

  trackExceptionError(error: Error, trackingId: string = null) {
    const exception: IExceptionTelemetry = {
        id: trackingId ? trackingId : Math.random().toString(36).substring(7),
        exception: error
      };
    this.appInsights.trackException(exception);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
