import { LoadingPlanItem, Ship, Terminal, User } from 'src/app/models';
import { AuditableEntity } from '../auditable-entity';
import { TimeSpan } from '../timespan';
import { DowntimeInfoItem } from './downtime-info-item';

export class Feedback extends AuditableEntity {
    jobId: string;
    satisfaction: number;
    sat_Comments: string;
    qualcomm: number;
    qC_Comments: string;
    gen_Comments: string;

  constructor() {
    super();
  }
}
