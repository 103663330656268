import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { JobPerformanceReport, TankPerformance, TimingComparisonInfo, DownTimeGrouping } from 'src/app/models/reports/job-performance';
import { JobReportService } from 'src/app/services/http-services/job.report.service';
import { JobReportDataService } from 'src/app/services/job-report-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JobStatuses } from "src/app/models/job/job-statuses";

@Component({
  selector: 'app-job-performance',
  templateUrl: './job-performance.component.html',
  styleUrls: ['./job-performance.component.scss']
})
export class JobPerformanceComponent implements OnInit {

  header: string;
  isActive: boolean;
  report: JobPerformanceReport;
  selectedTankId: string;
  selectedTank: string;
  tankOptions: SelectItem[] = [];
  selectedTankPerformance: TankPerformance;
  timingComparisonInfos: TimingComparisonInfo[] = [];
  chartOptions: any;
  shipName: string;
  status: string;
  downTimeDetailsDlg: boolean;
  downTimeDetails: DownTimeGrouping;

  constructor(
    private jobReportService: JobReportService,
    private jobReportDataService: JobReportDataService,
    private route: ActivatedRoute,
    private store: Store<appState.State>,
    private appMessageService: AppMessageService
  ) { }

  ngOnInit(): void {
    this.selectedTankPerformance = new TankPerformance();
    this.selectedTankPerformance.tankTimings = [];
    this.selectedTankPerformance.downtimeTimings = [];
    this.configChartOptions();
    this.loadJobPerformanceReportFromRoute();
  }

  configChartOptions() {
    var jobReportDataService = this.jobReportDataService;
    this.chartOptions = {
      legend: { position: 'top' },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return data.labels[tooltipItem.index] + ": " + jobReportDataService.convertDuration(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] * 60);
          }
        }
      }
    }
  }

  loadJobPerformanceReportFromRoute() {
    this.route.params.subscribe(
      async (params) => {
        if (params && params['status']) {
          var active = params['status'] === JobStatuses.active.toLowerCase();
          this.report = this.jobReportDataService.createBlankJobPerformanceReport(active);
          this.selectedTankPerformance = this.jobReportDataService.createTankPerformance();
          this.downTimeDetails = this.jobReportDataService.createDownTimeGrouping();
          if (params['id']) {
            this.store.dispatch(appActions.showSpinner());
            var report = await this.jobReportService.getJobPerformanceReport(params['id'])
              .toPromise()
              .catch(error => {
                this.store.dispatch(appActions.hideSpinner());
                this.appMessageService.errorMessage('Error getting Job Report', null);
                console.log(error);
                return null;
              });
            if (report)
                this.report = report;
            this.computeAdditionaInfo(this.report);
            this.configTankOptions(this.report);
            this.store.dispatch(appActions.hideSpinner());
          }
        } else {
          this.appMessageService.errorMessage('Missing job information', null);
        }
      }
    );
  }

  configTankOptions(report: JobPerformanceReport) {
    this.tankOptions = [];
    if (report.tankPerformances) {
      report.tankPerformances.forEach(p => this.tankOptions.push({ label: p.tankDescription, value: p.tankId }));
      this.setDefaultTankOption();
    }
  }

  setDefaultTankOption() {
    if (this.tankOptions && this.tankOptions.length > 0) {
      var totalTank = this.tankOptions.find(t => t.label == 'All Tanks');
      if (totalTank)
        this.selectedTankId = totalTank.value;
      this.tanksChanged();
    }
  }

  computeAdditionaInfo(report: JobPerformanceReport) {
    this.isActive = report.status === JobStatuses.active || report.status === JobStatuses.complete;
    this.status = this.isActive ? 'Active' : 'Completed';

    this.shipName = report.ship ? report.ship.name : '';
    this.header = this.isActive ? 'Active Job Report: ' + this.shipName : 'Performance Report: ' + this.shipName;
    report.netPumpingRate = Math.round(report.netPumpingRate);
    report.grossPumpingRate = Math.round(report.grossPumpingRate);
    report.totalDowntimeText = this.jobReportDataService.convertDuration(report.totalDowntime);
    this.jobReportService.convertLoadingDuration(report.loadingInfos);
    this.timingComparisonInfos = this.jobReportDataService.createTimingComparisonInfo(report);
    if (report.jobTimings) {
      report.jobTimings.forEach(t => t.durationText = this.jobReportDataService.convertDuration(t.duration));
    }
  }

  tanksChanged() {
    var tank = this.tankOptions.find(t => t.value == this.selectedTankId);
    this.selectedTank = tank?.label;
    this.selectedTankPerformance = this.report.tankPerformances.find(p => p.tankId == this.selectedTankId);
    if (this.selectedTankPerformance && this.selectedTankPerformance.tankTimings) {
      this.selectedTankPerformance.tankTimings.forEach(t => t.durationText = this.jobReportDataService.convertDuration(t.duration));
      this.configTankPerformanceChartData(this.selectedTankPerformance);
    }
  }

  configTankPerformanceChartData(performance: TankPerformance) {
    performance.chartData = {
      labels: performance.downtimeTimings.map(t => t.grouping),
      datasets: [
        {
          backgroundColor: [
            "#42A5F5", //blue
            "#66BB6A", //green
            "#FFA726", //orange
            '#EC407A', //magenta
            '#AB47BC', //purple
            '#ffff00', //yellow
            '#804000', //brown
            '#408080', //teal
            '#400040', //dark purple
            '#8080C0'  //lavender
          ],
          label: performance.downtimeTimings.map(t => t.grouping),
          data: performance.downtimeTimings.map(t => t.groupingDuration)
        }
      ]
    }
  }

  displayDownTimeDetailsEvent(event) {
    this.downTimeDetailsDlg = true;
    this.setDownTimeDetailsText(this.selectedTankPerformance.chartData.labels[event.element._index]);
  }

  setDownTimeDetailsText(grouping: string) {
    for (let i = 0; i < this.selectedTankPerformance.downtimeTimings.length; i++) {
      if (this.selectedTankPerformance.downtimeTimings[i].grouping == grouping) {
        this.downTimeDetails = this.selectedTankPerformance.downtimeTimings[i];
        return;
      }
    }
  }
}
