import { Component, Input, OnInit } from '@angular/core';
import { BfrHeader } from 'src/app/models/job/bfr/bfr-header';

@Component({
  selector: 'app-job-bfr-header',
  templateUrl: './job-bfr-header.component.html',
  styleUrls: ['./job-bfr-header.component.scss']
})
export class JobBfrHeaderComponent implements OnInit {

  @Input() bfrHeader: BfrHeader;
  
  constructor() { }

  ngOnInit(): void {
  }

}
