import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { InspectionCompany, Contact } from 'src/app/models';


@Component({
  selector: 'app-inspection-companies-list',
  templateUrl: './inspection-companies-list.component.html',
  styleUrls: ['./inspection-companies-list.component.scss']
})
export class InspectionCompaniesListComponent implements OnInit, OnDestroy {

  cols: any[] = [];
  skeletonRows: any[] = [];
  inspectionCompanies: InspectionCompany[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedInspectionCompany: InspectionCompany;
  showEditDialog = false;
  allContacts: Contact[] = [];

  constructor(
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForInspectionCompanyChanges();
    this.listenForContactsStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForInspectionCompanyChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getInspectionCompanies))
        .subscribe(results => {
          this.inspectionCompanies = [...results];
          this.loaded = true;
        })
    );
  }

  listenForContactsStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getContacts))
      .subscribe(results => { 
        this.allContacts = [...results].map(c => Contact.fromJson(c));
      })
    );
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addInspectionCompany() {
    this.selectedInspectionCompany = new InspectionCompany();
    this.showEditDialog = true;
  }

  openInspectionCompany(inspectionCompany: InspectionCompany) {
   this.selectedInspectionCompany = cloneDeep(inspectionCompany);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateInspectionCompany(inspectionCompany: InspectionCompany) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Inspection Company: ' + inspectionCompany.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveInspectionCompany = { ...inspectionCompany, active: false };
        this.store.dispatch(appActions.setInspectionCompany({ data: deactiveInspectionCompany }));
      }
    });
  }

  activateInspectionCompany(inspectionCompany: InspectionCompany) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Inspection Company: ' + inspectionCompany.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeInspectionCompany = { ...inspectionCompany, active: true };
        this.store.dispatch(appActions.setInspectionCompany({ data: activeInspectionCompany }));
      }
    });
  }

  updateInspectionCompany(inspectionCompany: InspectionCompany) {
    this.store.dispatch(appActions.setInspectionCompany({ data: inspectionCompany }));
    this.showEditDialog = false;
  }

  async resetSelectedInspectionCompany() {
    // this.inspectionCompanies is already subscribed to latest state of InspectionCompanies
    // reset selected InspectionCompany to state
    this.selectedInspectionCompany = cloneDeep(this.inspectionCompanies.find(t => t.id === this.selectedInspectionCompany.id));
  }

}
