<div class="p-grid" class="page-font">
  <div class="p-col-12">
    <p-card>
      <div class="p-grid">
        <div class="p-col-6 p-md-6 p-lg-6" style="font-size: large;">
          <p><b>{{status}} Job Report:</b> {{report.ship?.name}}</p>
          <p><b>Terminal:</b> {{report.terminal?.name}}</p>
          <div *ngIf="isActive && report.blpStep > 0" class="p-col-12">
            <p>As of last completed tank through BLP step {{report.blpStep}}</p>
          </div>
        </div>
        <div class="p-col-6 p-md-6 p-lg-4">
          <div class="p-grid rcorners">
            <!-- style="height: inherit;" -->
            <div class="p-col-6">Cargo Commenced:</div>
            <div class="p-col-6">{{report.cargoCommenced | date: 'M/d/yyyy HH:mm'}}</div>
            <div *ngIf="isActive" class="p-col-6">Estimated Completion:</div>
            <div *ngIf="isActive" class="p-col-6">{{report.estimatedCompletion | date: 'M/d/yyyy HH:mm'}}</div>
            <div *ngIf="!isActive" class="p-col-6">Completion:</div>
            <div *ngIf="!isActive" class="p-col-6">{{report.completionTime | date: 'M/d/yyyy HH:mm'}}</div>
            <div class="p-col-6">Last Updated:</div>
            <div class="p-col-6">{{report.modifiedOn | date: 'M/d/yyyy HH:mm'}}</div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-lg-6" style="height: inherit">
        <p-card [style]="{'height': 'inherit'}">
          <div class="p-grid">
            <div class="p-col-12">
              <p style="font-size: large;"><b>Timings</b></p>
            </div>
            <p-table [value]="timingComparisonInfos" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th></th>
                  <th>{{status}} Job Timing {{report.ship?.name}}</th>
                  <th *ngIf="isActive">Average Timing of Active Jobs</th>
                  <th *ngIf="!isActive">Average Timing of Completed Jobs</th>
                  <th>Overall Customer Average</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-timingComparisonInfo>
                <tr>
                  <td>
                    <p-accordion>
                      <p-accordionTab>
                        <ng-template pTemplate="header">
                          {{timingComparisonInfo.description}}
                        </ng-template>
                        <ng-template pTemplate="content">
                          <p>{{timingComparisonInfo.explanation}}</p>
                        </ng-template>
                      </p-accordionTab>
                    </p-accordion>
                  </td>
                  <td>{{timingComparisonInfo.displayValue? (timingComparisonInfo.duration | number : '1.0-0') : timingComparisonInfo.durationText}}</td>
                  <td>{{timingComparisonInfo.displayValue? (timingComparisonInfo.averageDuration | number : '1.0-0') : timingComparisonInfo.averageDurationText}}</td>
                  <td>{{timingComparisonInfo.displayValue? (timingComparisonInfo.averageDurationAll | number : '1.0-0') : timingComparisonInfo.averageDurationAllText}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-card>
      </div>
      <div class="p-col-12 p-md-6 p-lg-6">
        <p-card>
          <div class="p-grid">
            <div class="p-col-12">
              <p style="font-size: large; display: inline-block;"><b>Delivery Rate Details</b></p>
              <p-dropdown [style]="{ 'width': '120px', 'float': 'right'}" [options]="tankOptions" [(ngModel)]="selectedTankId" appendTo="body"
                          placeholder="Select Tank" (onChange)="tanksChanged()"></p-dropdown>
            </div>
            <div class="p-col-12" style="text-align: center;">
              <div class="p-grid">
                <div *ngFor="let tankTiming of selectedTankPerformance.tankTimings" class="p-col-4">
                  <p-card [style]="{'font-weight': 'bold'}">
                    <div class="rcorners2 tank-det">
                      <span>{{tankTiming.displayValue ? (tankTiming.duration | number : '1.0-0') : tankTiming.durationText}}</span>
                    </div>
                    <ng-template pTemplate="footer">
                      {{tankTiming.description}}
                    </ng-template>
                  </p-card>
                </div>
                <div class="p-col-12" style="text-align: center;">
                  <p-card [style]="{'font-weight': 'bold'}">
                    <div style="background-color: white;">
                      <p-chart type="pie" [data]="selectedTankPerformance.chartData" (onDataSelect)="displayDownTimeDetailsEvent($event)" [options]="chartOptions"></p-chart>
                      <p-dialog header="DownTime Reason Details" [(visible)]="downTimeDetailsDlg" [modal]="true" [style]="{'text-align': 'left'}" [draggable]="false" [resizable]="false">
                        <b>Delay Grouping: </b>{{downTimeDetails.grouping}}<br />
                        <b>Delay Duration: </b>{{downTimeDetails.groupingDurationText}}<br /><br />
                        <b>Delay Details: </b><br />
                        <ul>
                          <li *ngFor="let delayDetail of downTimeDetails.details">
                            <b>Delay Type: </b>{{delayDetail.delayType}},&ensp;<b>Delay Duration: </b>{{delayDetail.delayDurationText}}<br />
                          </li>
                        </ul>
                      </p-dialog>
                    </div>
                    <ng-template pTemplate="footer">
                      Downtime Reasons (hours)
                    </ng-template>
                  </p-card>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>
