import { AuditableEntity } from "../../auditable-entity";
import * as moment from 'moment';

export class TimeTrackingEventLog extends AuditableEntity {

    jobId: string;
    timeTrackingEventId: string;
    isShipTank: boolean;
    assignedTankId?: string;
    blpStepNumber?: number;
    startTime: Date;
    endTime?: Date;
    notes?: string;

    // in milliseconds
    get duration(): moment.Duration {
        if (this.endTime) {
            const startMoment = moment(this.startTime);
            const endMoment = moment(this.endTime);
            return moment.duration(endMoment.diff(startMoment));
        }
        return moment.duration(0);
    }

    get durationFriendlyText(): string {
        return this.duration.humanize({ h:72, m: 360, s: 59});
    }

    static fromJson(log: TimeTrackingEventLog): TimeTrackingEventLog {
        const newLog = new TimeTrackingEventLog();
        newLog.id = log.id;
        newLog.active = log.active;
        newLog.createdOn = log.createdOn;
        newLog.createdBy = log.createdBy;
        newLog.modifiedOn = log.modifiedOn;
        newLog.modifiedBy = log.modifiedBy;
        newLog.jobId = log.jobId;
        newLog.timeTrackingEventId = log.timeTrackingEventId;
        newLog.assignedTankId = log.assignedTankId;
        newLog.blpStepNumber = log.blpStepNumber;
        newLog.startTime = log.startTime;
        newLog.endTime = log.endTime;
        newLog.notes = log.notes;
        return newLog;
    }
}
