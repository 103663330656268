import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-job-time-vdp',
  templateUrl: './job-time-vdp.component.html',
  styleUrls: ['./job-time-vdp.component.scss']
})
export class JobTimeVdpComponent implements OnInit, OnDestroy {

  ready: boolean = true;
  blocked: boolean = true;
  subscriptions: Subscription[] = [];
  @ViewChild('section') section: ElementRef;

  constructor(
    private store: Store<appState.State>,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.listenForBlpSaved();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForBlpSaved() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJobIsBlpBlocked)).subscribe(isBlocked => {
        this.blocked = isBlocked;
        this.cdr.detectChanges();
      })
    );
  }

  save() {}

  reset() {}

}
