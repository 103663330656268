import { AuditableEntity } from "../auditable-entity";
import { Contact } from "./contact";
import { Port } from "./port";

export class Terminal extends AuditableEntity {
    name: string;
    lat: number;
    long: number;
    address: string;
    contacts: Contact[] = [];
    port: Port; 

    constructor() {
        super();
    }
}