<div class="p-grid">
  <div class="p-col-12">
    <p-card header="Timing Categories">
      <div class="p-grid">
        <div *ngIf="!loaded" class="p-col-12">
          <p-table
              [value]="skeletonRows"
              [responsive]="true">
              <ng-template pTemplate="header">
                  <tr>
                      <th style="width:25%">Name</th>
                      <th style="width:55%">Explanation</th>
                      <th></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body">
                  <tr>
                      <td><p-skeleton></p-skeleton></td>
                      <td><p-skeleton></p-skeleton></td>
                      <td><p-skeleton></p-skeleton></td>
                  </tr>
              </ng-template>
          </p-table>
        </div>
        <div *ngIf="loaded" class="p-col-12">
          <p-table
          dataKey="id"
          [responsive]="true"
          [rowHover]="true"
          [value]="timingCategories">
            <ng-template pTemplate="header">
              <tr>
                  <th style="width:25%">Name</th>
                  <th style="width:55%">Explanation</th>
                  <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cat>
                <tr>
                  <td>{{cat.name}}</td>
                  <td>{{cat.explanation}}</td>
                  <td>
                    <p-button styleClass="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="openTimingCategory(cat)" pTooltip="Edit this Timing Category"></p-button>
                  </td>
                </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-card>
  </div>
</div>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="true">
  <app-timing-category-detail [cat]="selectedTimingCategory" (timingCategoryUpdated)="updateTimingCategory($event)" (resetTimingCategory)="reset()"></app-timing-category-detail>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '100%'}" [modal]="true" [closable]="true">
  <app-timing-category-detail [cat]="selectedTimingCategory" (timingCategoryUpdated)="updateTimingCategory($event)" (resetTimingCategory)="reset()"></app-timing-category-detail>
</p-dialog>
