import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Terminal, Port, Contact, Dock } from 'src/app/models';

@Component({
  selector: 'app-terminal-detail',
  templateUrl: './terminal-detail.component.html',
  styleUrls: ['./terminal-detail.component.scss']
})
export class TerminalDetailComponent implements OnInit {

  @Input() terminal: Terminal;
  @Input() ports: Port[] = [];
  @Input() contacts: Contact[] = [];
  @Input() docks: Dock[] = [];
  @Output() terminalUpdated = new EventEmitter<Terminal>();
  @Output() resetTerminal = new EventEmitter();

  portOptions: SelectItem[] = [];
  contactOptions: SelectItem[] = [];
  dockOptions: SelectItem[] = [];
  showContactDialog = false;

  constructor() { }

  ngOnInit(): void {
    this.portOptions = this.buildPortOptions();
    this.contactOptions = this.buildContactOptions();
    this.dockOptions = this.buildDockOptions();
  }

  buildPortOptions(): SelectItem[] {
    return this.ports.sort((a,b) => a.name > b.name ?  1 :  -1).map(p => { return { 'label': p.name, value: p }; });
  }

  buildContactOptions(): SelectItem[] {
    return this.contacts.sort((a,b) => a.displayName > b.displayName ?  1 :  -1).map(c => { return { 'label': c.displayName + ' (' + c.email + ')', value: c }; });
  }

  buildDockOptions(): SelectItem[] {
    return this.docks.sort((a,b) => a.name > b.name ?  1 :  -1).map(c => { return { 'label': c.name, value: c }; });
  }

  save() {
    this.terminalUpdated.emit(this.terminal);
  }

  reset() {
    this.resetTerminal.emit();
  }

  contactAdded(contact: Contact) {
    this.contactOptions.push({ label: contact.displayName + ' (' + contact.email + ')', value: contact });
    this.contactOptions = this.contactOptions.sort((a,b) => a.label > b.label ?  1 :  -1);
    this.terminal.contacts.push(contact);
    this.terminal.contacts = [...this.terminal.contacts];  //force reference to array to change to trigger ngModel on multiSelect to see a change
    this.showContactDialog = false;
  }

}
