<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="categories" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Category</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="categories" [options]="categoryOptions" appendTo="body" (onChange)="updateEventsFromCategories($event.value)" placeholder="Select Category" [showClear]="true"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="eventName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Event</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="eventName" [options]="eventOptions" [filter]="true" [group]="true" [(ngModel)]="log.timeTrackingEventId" appendTo="body" (onChange)="updateRequirements($event.value)"  placeholder="Select Event"></p-dropdown>
                </div>
            </div>
            <div *ngIf="requiresTank" class="p-field p-grid">
                <label for="tank" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Shore Tank</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="tank" [options]="tankOptions" [(ngModel)]="log.assignedTankId" appendTo="body" placeholder="Select Tank" [showClear]="true" (onChange)="updateBlpStepSelection()"></p-dropdown>
                </div>
            </div>
            <div *ngIf="requiresTank" class="p-field p-grid">
                <label for="tank" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">BLP Step Number</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="step" [options]="blpStepOptions" [(ngModel)]="log.blpStepNumber" appendTo="body" placeholder="Select BLP Step" [showClear]="true"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label *ngIf="!requiresDuration" for="startTime" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Time</label>
                <label *ngIf="requiresDuration" for="startTime" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Starting Time</label>
                <div class="p-col-12 p-md-10">
                    <p-calendar id="startTime" [(ngModel)]="startingTime" dateFormat="m/d/yy" [showTime]="true" [showIcon]="true" [showOnFocus]="false" appendTo="body"></p-calendar>
                </div>
            </div>
            <div *ngIf="requiresDuration" class="p-field p-grid">
                <label for="endTime" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Ending Time</label>
                <div class="p-col-12 p-md-10">
                    <p-calendar id="endTime" [(ngModel)]="endingTime" dateFormat="m/d/yy" [showTime]="true" [showIcon]="true" [showOnFocus]="false" appendTo="body"></p-calendar>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="vesselTank" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Remarks</label>
                <div class="p-col-12 p-md-10">
                    <textarea id="vesselTank" pInputTextarea [(ngModel)]="log.notes"></textarea> 
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>
