import { AuditableEntity } from "../../auditable-entity";
import { BfrTank } from "./bfr-tank";

export class Bfr extends AuditableEntity {
    jobId: string;

    // Totals
    gsvUsBarrelsTotal: number;
    nsvBarrelsTotal: number;
    expectedDeliveryTotal: number;
    varianceTotal: number;

    // T.C.V. Summary
    tcvGallons: number;
    tcvBarrels: number;
    tcvLongTons: number;
    tcvMetricTons: number;
    tcvKilograms: number;

    // G.S.V. Summary
    gsvGallons: number;
    gsvBarrels: number;
    gsvLongTons: number;
    gsvMetricTons: number;
    gsvKilograms: number;

    // UTI/MMC
    utiMmcGaugeTapeId: number;
    utiMmcGaugeThermoProbeId: number;

    // General
    averageTemp: number;
    averageApi: number;
    remarks: string;

    // Tanks
    bfrTanks: BfrTank[] = [];

    constructor() {
        super();
    }

    static fromJson(bfr: Bfr): Bfr {
        const newBfr: Bfr = new Bfr();

        newBfr.id = bfr.id;
        newBfr.active = bfr.active;
        newBfr.createdOn = bfr.createdOn;
        newBfr.createdBy = bfr.createdBy;
        newBfr.modifiedOn = bfr.modifiedOn;
        newBfr.modifiedBy = bfr.modifiedBy;
        newBfr.jobId = bfr.jobId;
        newBfr.gsvUsBarrelsTotal = bfr.gsvUsBarrelsTotal;
        newBfr.nsvBarrelsTotal = bfr.nsvBarrelsTotal;
        newBfr.expectedDeliveryTotal = bfr.expectedDeliveryTotal;
        newBfr.varianceTotal = bfr.varianceTotal;
        newBfr.tcvGallons = bfr.tcvGallons;
        newBfr.tcvBarrels = bfr.tcvBarrels;
        newBfr.tcvLongTons = bfr.tcvLongTons;
        newBfr.tcvMetricTons = bfr.tcvMetricTons;
        newBfr.tcvKilograms = bfr.tcvKilograms;
        newBfr.gsvGallons = bfr.gsvGallons;
        newBfr.gsvBarrels = bfr.gsvBarrels;
        newBfr.gsvLongTons = bfr.gsvLongTons;
        newBfr.gsvMetricTons = bfr.gsvMetricTons;
        newBfr.gsvKilograms = bfr.gsvKilograms;
        newBfr.utiMmcGaugeTapeId = bfr.utiMmcGaugeTapeId;
        newBfr.utiMmcGaugeThermoProbeId = bfr.utiMmcGaugeThermoProbeId;
        newBfr.averageTemp = bfr.averageTemp;
        newBfr.averageApi = bfr.averageApi;
        newBfr.remarks = bfr.remarks;
        newBfr.bfrTanks = bfr.bfrTanks;

        return newBfr;
    }
}