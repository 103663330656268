<div class="p-grid">
    <div class="p-col-12">
        <p-divider align="center" type="dashed">
            <b>Step {{tank.stepNumber}} : {{shoreTank.name}}</b>
        </p-divider>
    </div>
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="strappedApi" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Strapped API</label>
                <div class="p-col-12 p-md-10">
                    <input id="strappedApi" type="number" pInputText [(ngModel)]="tank.strappedApi"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="barrelsPerDegApi" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Barrels/deg API</label>
                <div class="p-col-12 p-md-10">
                    <input id="barrelsPerDegApi" type="number" pInputText [(ngModel)]="tank.barrelsPerDegreeApi"> 
                </div>
            </div>
            <p-divider align="center" type="dashed">
                <b> Open </b>
            </p-divider>
            <div class="p-field p-grid">
                <label for="tankUllage" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">CRT Ullage Ft</label>
                <div class="p-col-12 p-md-10">
                    <input id="tankUllage" type="text" pInputText [(ngModel)]="tank.ullageFt"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openUllage" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Actual Ullage Ft</label>
                <div class="p-col-12 p-md-10">
                    <input id="openUllage" type="text" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].ullageFt"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openTov" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">T.O.V. U.S. Barrels</label>
                <div class="p-col-12 p-md-10">
                    <input id="openTov" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].tovUsBarrels"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openFreeGauge" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Free Guage</label>
                <div class="p-col-12 p-md-10">
                    <input id="openFreeGauge" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].freeGauge"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openWater" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Water Barrels</label>
                <div class="p-col-12 p-md-10">
                    <input id="openWater" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].waterBarrels"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openTankTemp" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Tank Temperature</label>
                <div class="p-col-12 p-md-10">
                    <input id="openTankTemp" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].tankTemp"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openAmbientTemp" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Ambient Temperature</label>
                <div class="p-col-12 p-md-10">
                    <input id="openAmbientTemp" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].ambientTemp"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="astmTableOption" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">ASTM Table Option</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="astmTableOption" [options]="astmTableOptions" [(ngModel)]="tank.bfrTankLineItems[openIndex].astmTableOption" appendTo="body" placeholder="Select ASTM Table Option"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="openSw" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">S & W %</label>
                <div class="p-col-12 p-md-10">
                    <input id="openSw" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[openIndex].swPercent"> 
                </div>
            </div>
            <p-divider align="center" type="dashed">
                <b> Close </b>
            </p-divider>
            <div class="p-field p-grid">
                <label for="tankUllage" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">CRT Ullage Ft</label>
                <div class="p-col-12 p-md-10">
                    <input id="tankUllage" type="text" pInputText [(ngModel)]="tank.closeCrtUllageFt"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeUllage" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Actual Ullage Ft</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeUllage" type="text" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].ullageFt"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeTov" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">T.O.V. U.S. Barrels</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeTov" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].tovUsBarrels"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeFreeGauge" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Free Guage</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeFreeGauge" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].freeGauge"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeWater" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Water Barrels</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeWater" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].waterBarrels"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeTankTemp" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Tank Temperature</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeTankTemp" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].tankTemp"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeAmbientTemp" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Ambient Temperature</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeAmbientTemp" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].ambientTemp"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="astmTableOption" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">ASTM Table Option</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown id="astmTableOption" [options]="astmTableOptions" [(ngModel)]="tank.bfrTankLineItems[closeIndex].astmTableOption" appendTo="body" placeholder="Select ASTM Table Option"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="closeSw" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">S & W %</label>
                <div class="p-col-12 p-md-10">
                    <input id="closeSw" type="number" pInputText [(ngModel)]="tank.bfrTankLineItems[closeIndex].swPercent"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="zeroRoofCorrection" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Zero Roof Correction?</label>
                <div class="p-col-12 p-md-10">
                    <p-inputSwitch id="zeroRoofCorrection" [(ngModel)]="tank.bfrTankLineItems[closeIndex].zeroRoofCorrection"></p-inputSwitch>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>
