import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { AstmTableOptions, Bfr, BfrMeasurementTypes, BfrTank, BfrTankLineItem, BlpStepTank, JobBlpRevision, Tank } from "../models";
import { BfrTableData } from "../models/job/bfr/bfr-table-data";
import { AppMessageService } from "./app-message.service";
import { CalculationReferenceService } from "./calculation-reference.service";

@Injectable({
    providedIn: 'root'
})
export class BfrManagementService {
    constructor(
        private calcReferenceService: CalculationReferenceService,
        private appMessageService: AppMessageService) {}

    buildNewBfrFromTanks(currentTanks: BlpStepTank[], jobId: string): Bfr {
        const bfr: Bfr = new Bfr;
        bfr.jobId = jobId;
        bfr.bfrTanks = [];

        if (currentTanks && currentTanks.length) {
            currentTanks.forEach(t => {
                const newBfrTank = this.createNewBfrTank(bfr, t);
                bfr.bfrTanks.push(newBfrTank);
            });
        }
        
        return Bfr.fromJson(bfr);
    }

    private createNewBfrTank(currentBfr: Bfr, tank: BlpStepTank): BfrTank {
        const bfrTank: BfrTank = new BfrTank();
        bfrTank.bfrId = currentBfr.id;
        bfrTank.stepNumber = tank.step;
        bfrTank.tankId = tank.tankId;
        bfrTank.bfrTankLineItems = [];

        const openBfrTankLineItem: BfrTankLineItem = new BfrTankLineItem();
        openBfrTankLineItem.brfTankId = bfrTank.id;
        openBfrTankLineItem.measurementType = BfrMeasurementTypes.open;
        bfrTank.bfrTankLineItems.push(openBfrTankLineItem);

        const closeBfrTankLineItem: BfrTankLineItem = new BfrTankLineItem();
        closeBfrTankLineItem.brfTankId = bfrTank.id;
        closeBfrTankLineItem.measurementType = BfrMeasurementTypes.close;
        bfrTank.bfrTankLineItems.push(closeBfrTankLineItem);

        return bfrTank;
    }

    updateBfrFromTanks(currentBfr:Bfr, currentTanks: BlpStepTank[]): Bfr {
        if (this.tanksChanged(currentBfr, currentTanks)) {
            if (this.areNewBlpTanks(currentBfr, currentTanks)) {
                // Add new Bfr Tank from BlpStepTank
                currentTanks
                    .filter(t => !currentBfr.bfrTanks.some(bt => bt.stepNumber === t.step))
                    .forEach(t => {
                        const newBfrTank = this.createNewBfrTank(currentBfr, t);
                        currentBfr.bfrTanks.push(newBfrTank);
                    });
            } else {
                // Remove Bfr Tank(s) not in BLPStepTanks
                currentBfr.bfrTanks = currentBfr.bfrTanks.filter(bt => currentTanks.some(t => t.step === bt.stepNumber));
            }
        }

        currentBfr.bfrTanks?.sort((a, b) => a.stepNumber > b.stepNumber ? 1 : -1 );

        return Bfr.fromJson(currentBfr);
    }

    buildLargeTableCols(): any[] {
        return [
            { field: 'strappedVsBarrelsPerApiText', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'strappedVsBarrelsPerApiValue', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'action', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'step', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'tankOrMeasurementType', header: 'Tank', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'ullageFt', header: 'Ullage Ft', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'tovUsBarrels', header: 'T.O.V. U.S. Bbbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'freeGauge', header: 'Free Gauge', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'waterBarrels', header: 'Water Bbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'tankTemp', header: 'Tank Temp.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'ambientTemp', header: 'Amb. Temp.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'shellFactor', header: 'Shell Factor', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'govUsBarrels', header: 'G.O.V. U.S. Bbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'roofCorrection', header: 'Roof Corr\'n', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'astmTableOption', header: 'Table', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'api', header: 'API', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'astmVcf', header: 'ASTM V.C.F.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'gsvUsBarrels', header: 'G.S.V. U.S. Bbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'swPercent', header: 'S&W %', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'nsvBarrels', header: 'N.S.V. Bbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: false, width: '100px' },
            { field: 'expectedDelivery', header: 'Expected Delivery', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: true, width: '100px' },
            { field: 'nominatedQuantity', header: 'Nominated Quantity', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: true, width: '100px' },
            { field: 'adjustedLines', header: 'Adjusted Lines', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: true, width: '100px' },
            { field: 'variance', header: 'Variance Totals', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: true, width: '100px' },
            { field: 'lineCapacity', header: 'Line Capacity', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false, isTotalColumn: true, width: '100px' }
        ];
    }

    buildMobileTableCols(): any[] {
        return [
            { field: 'step', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false },
            { field: 'tankId', header: 'Tank', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false },
            { field: 'gsvUsBarrels', header: 'G.S.V. U.S. Bbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false },
            { field: 'nsvBarrels', header: 'N.S.V. Bbls.', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false },
            { field: '', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
        ];
    }

    buildTableData(bfr: Bfr, tanks: Tank[]): BfrTableData[] {
        const tableData: BfrTableData[] = [];

        if (bfr && bfr.bfrTanks && bfr.bfrTanks.length) {
            bfr.bfrTanks.forEach(tank => {
                // Build Tank Row 1
                tableData.push(BfrTableData.rowOneFromBfrTank(tank, tanks));
                const openRow = tank?.bfrTankLineItems.find(l => l.measurementType === BfrMeasurementTypes.open);
                tableData.push(BfrTableData.rowTwoFromBfrTankLineItem(openRow, tank.barrelsPerDegreeApi));
                const closeRow = tank?.bfrTankLineItems.find(l => l.measurementType === BfrMeasurementTypes.close);
                tableData.push(BfrTableData.rowThreeFromBfrTankLineItem(closeRow));
                tableData.push(BfrTableData.rowFourFromBfrTank(tank));

                // Make sure tank line items are sorted correctly (tankId, open, close, totals)
                tank.bfrTankLineItems.sort((a, b) => this.sortBrfTankLineItems(a, b));
            });
        }

        return tableData;
    }

    calculateBfr(currentBfr: Bfr, latestBlp: JobBlpRevision): Bfr {
        // For each tank
        // 1. Calculate line items
        // 2. Calculate Tank Totals
        // 3. Pull in data from BLP
        if (currentBfr && currentBfr.bfrTanks && currentBfr.bfrTanks.length) {

            currentBfr.bfrTanks.forEach(t => {
                var lineItem = latestBlp.lineItems.find(li => li.step == t.stepNumber);
                if (lineItem){
                    t.tankId = lineItem.tankId;
                }
                else {
                    return;
                }
                // Line Item calcs
                if (t.bfrTankLineItems && t.bfrTankLineItems.length) {
                    t.bfrTankLineItems.forEach(l => {
                        l.shellFactor = this.calcShellFactor(l.tankTemp, l.ambientTemp);
                        l.api = this.setApiFromBlp(t.stepNumber, latestBlp);
                        l.astmVcf = this.calcAstmVcf(l.astmTableOption, l.api, l.tankTemp);
                        l.roofCorrection = this.calcRoofCorrection(t.strappedApi, l.api, l.astmVcf, t.barrelsPerDegreeApi, l.tovUsBarrels, l.measurementType, l.zeroRoofCorrection);
                        l.govUsBarrels = this.calcGovUsBarrels(l.tovUsBarrels, l.waterBarrels, l.shellFactor, l.roofCorrection);
                        l.gsvUsBarrels = this.calcGsvUsBarrels(l.govUsBarrels, l.astmVcf);
                        l.nsvBarrels = this.calcNsvUsBarrels(l.gsvUsBarrels, l.swPercent);
                    });
                }

                //  Tank Totals
                t.tovUsBarrelsTotal = this.calcTovTankTotal(t);
                t.gsvUsBarrelsTotal = this.calcGsvTankTotal(t);
                t.nsvBarrelsTotal = this.calcNsvTankTotal(t);

                // Tank items from BLP
                t.expectedDelivery = this.setExpectedDeliveryFromBlp(t.stepNumber, latestBlp);
                t.nominatedQuantity = this.setNominatedQuantityFromBlp(t.stepNumber, latestBlp);
                t.adjustedLines = this.setAdjustedByLinesFromBlp(t.stepNumber, latestBlp, t.nsvBarrelsTotal);
                t.variance = this.calcVariance(t.nsvBarrelsTotal, t.expectedDelivery);
                t.lineCapacity = this.setLineCapacityFromBlp(t.stepNumber, latestBlp);
            });
        }

        // For BFR Summary
        currentBfr.gsvUsBarrelsTotal = this.calcGsvBarrelsSummary(currentBfr);
        currentBfr.nsvBarrelsTotal = this.calcNsvBarrelsSummary(currentBfr);
        currentBfr.expectedDeliveryTotal = this.calcExpectedDeliverySummary(currentBfr);
        currentBfr.varianceTotal = this.calcVarianceSummary(currentBfr);

        currentBfr.averageTemp = this.calcAvgTempSummary(currentBfr);
        currentBfr.averageApi = this.calcAvgApiSummary(currentBfr);

        currentBfr.tcvBarrels = this.calcTcvBarrelsSummary(currentBfr);
        currentBfr.tcvGallons = this.calcTcvGallonsSummary(currentBfr.tcvBarrels);
        currentBfr.tcvLongTons = this.calcTcvLongTonsSummary(currentBfr.averageTemp, currentBfr.tcvBarrels);
        currentBfr.tcvMetricTons = this.calcTcvMetricTonsSummary(currentBfr.averageTemp, currentBfr.tcvBarrels)
        currentBfr.tcvKilograms = this.calcTcvKilogramsSummary(currentBfr.tcvMetricTons);

        currentBfr.gsvBarrels = this.calcGsvBarrelsSummary(currentBfr);
        currentBfr.gsvGallons = this.calcGsvGallonsSummary(currentBfr.gsvBarrels);
        currentBfr.gsvLongTons = this.calcGsvLongTonsSummary(currentBfr.averageTemp, currentBfr.gsvBarrels);
        currentBfr.gsvMetricTons = this.calcGsvMetricTonsSummary(currentBfr.averageTemp, currentBfr.gsvBarrels)
        currentBfr.gsvKilograms = this.calcGsvKilogramsSummary(currentBfr.gsvMetricTons);
        
        return currentBfr;
    }

    private tanksChanged(currentBfr: Bfr, currentTanks: BlpStepTank[]): boolean {
        return this.areNewBlpTanks(currentBfr, currentTanks) || this.areRemovedBlpTanks(currentBfr, currentTanks);
    }

    private areNewBlpTanks(currentBfr: Bfr, currentTanks: BlpStepTank[]): boolean {
        let newTanksFound: boolean = false;
        if (currentTanks && currentTanks.length) {
            if (!currentBfr.bfrTanks || !currentBfr.bfrTanks.length) {
                return true;
            } else {
                // Check for added tanks to BLP
                currentTanks.forEach(t => {
                    // New tank not defined in BFR
                    if (!currentBfr.bfrTanks.some(bt => bt.stepNumber === t.step)) {
                        newTanksFound = true;
                    }
                })
            }
        }
        return newTanksFound;
    }

    private areRemovedBlpTanks(currentBfr: Bfr, currentTanks: BlpStepTank[]): boolean {
        let tanksRemoved: boolean = false;
        if (currentBfr.bfrTanks && currentBfr.bfrTanks.length) {
            if (!currentTanks || !currentTanks.length) {
                return true;
            } else {
                // Check for tanks removed from BLP still in BFR
                currentBfr.bfrTanks.forEach(bt => {
                    if (!currentTanks.some(t => t.step === bt.stepNumber)) {
                        tanksRemoved = true;
                    }
                })
            }
        }
        return tanksRemoved;
    }

    // Tank Line Item Values
    private calcShellFactor(tankTemp: number = 0, ambientTemp: number = 0): number {
        //From Excel:
        //ROUND(1+(((0.0000124)*(((7*I13+J13)/8)-60))+((0.00000000003844)*((((7*I13+J13)/8)-60 )^2))),5)
        //I13: Tank Temp
        //J13: Ambient Temp
        //Note: Rounding done in UI with decmial pipe
        return 1 +
        (
            ( (0.0000124) * (((7*tankTemp + ambientTemp)/8) - 60) ) +
            ( (0.00000000003844) * Math.pow(((7*tankTemp + ambientTemp)/8) - 60, 2))
        );
    }

    private calcGovUsBarrels(tovUsBarrels: number = 0, waterBarrels: number = 0, shellFactor: number = 0, roofCorrection:number = 0): number {
        //From Excel:
        //ROUND((((F13-H13)*K13)+M13),2)
        //F13: T.O.V. US Barrels
        //H13: Water Barrels
        //K13: Shell Factor
        //M13: Roof Correction
        //Note: Rounding done in UI with decmial pipe
        return (tovUsBarrels - waterBarrels)*shellFactor + roofCorrection;
    }

    private calcRoofCorrection(strappedApi: number = 0, api: number = 0, astmVcf: number = 1, barrelsPerDegApi: number = 0, tovBbls: number, measurementType: string, zeroRoofCorrection = false): number {
        //From Excel:
        //ROUND((B12-(ROUND((((131.5+O13)/P13)-131.5),1)))*B13,2)
        //B12: Strapped API (Fixed for whole tank)
        //O13: API
        //P13: ASTM VCF
        //B13: Barrles/deg API (Fixed for whole tank) 
        //Note: Rounding done in UI with decmial pipe

        //10/5/2021 Added two conditions to zero roof correction when on close lineItem
        // 1. If T.O.V. bbls is 0, then auto set to 0
        // 2. If new user control to zero roof correction is set, then auto set to 0
        if (measurementType === BfrMeasurementTypes.close && (tovBbls === 0 || zeroRoofCorrection)) {
            return 0;
        }

        if (astmVcf == 0) {
            this.appMessageService.warnMessage('ASTM VCF Cannot be 0', 'Causes divide by 0 in Roof Correction calculation');
            astmVcf = 1;
        }
        return (strappedApi - (this.calcReferenceService.vbaRound((((131.5 + api)/astmVcf) - 131.5),1)))*barrelsPerDegApi;
    }

    private calcAstmVcf(astmTableOption: string = AstmTableOptions.sixB, api: number = 0, tankTemp: number = 0): number {
        //From Excel:
        //IF(N13="6A",t6a(O13,I13,0),IF(N13="6B",T6B(O13,I13,0),IF(N13="6C",t6c(I13),0)))
        //N13: ASTM Table Option
        //O13: Api
        //I13: Tank Temp
        switch(astmTableOption) {
            case AstmTableOptions.sixA:
                return this.calcReferenceService.getAstmT6aVcf(api, tankTemp, 0);
            case AstmTableOptions.sixB:
                return this.calcReferenceService.getAstmT6bVcf(api, tankTemp, 0);
            case AstmTableOptions.sixC:
                return this.calcReferenceService.getAstmT6cVcf(tankTemp);
            default:
                this.appMessageService.warnMessage('ASTM Table Option is invalid', astmTableOption + ' is not a recogonized option to be used in ASTM VCF calculation');
                return 0;
        }
    }

    private calcGsvUsBarrels(govUsBarrels: number = 0, astmVcf: number = 0): number {
        //From Excel:
        //ROUND(((L13)*P13),2)
        //L13: G.O.V. U.S. Barrels
        //P13: ASTM V.C.F.
        //Note: Rounding done in UI with decmial pipe
        return govUsBarrels*astmVcf;
    }

    private calcNsvUsBarrels(gsvUsBarrels: number = 0, swPct: number = 0): number {
        //From Excel:
        //ROUND(Q13-(R13/100*Q13),2)
        //R13: S&W %
        //Q13: G.S.V. U.S. Barrels
        //Note: Rounding done in UI with decmial pipe
        return gsvUsBarrels-(swPct/100*gsvUsBarrels);
    }

    // Set values from BLP
    private setApiFromBlp(tankStepNumber: number, latestBlp: JobBlpRevision): number {
        if (tankStepNumber && latestBlp) {
            const foundStep = latestBlp.lineItems.find(l => l.step === tankStepNumber);
            if (foundStep) {
                return foundStep.apiNumber;
            }
        }
        return 0;
    }

    private setExpectedDeliveryFromBlp(tankStepNumber: number, latestBlp: JobBlpRevision): number {
        if (tankStepNumber && latestBlp) {
            const foundStep = latestBlp.stepTotals.find(l => l.parcelOrStepNumber === tankStepNumber);
            if (foundStep) {
                return foundStep.value;
            }
        }
        return 0;
    }

    private setNominatedQuantityFromBlp(tankStepNumber: number, latestBlp: JobBlpRevision): number {
        if (tankStepNumber && latestBlp) {
            const foundStep = latestBlp.lineItems.find(l => l.step === tankStepNumber);
            if (foundStep.nominatedQuantities.some(n => n.isPush)) {
                return -1;  // negative value to indicate push in UI
            } else {
                let total = 0;
                foundStep.nominatedQuantities.forEach(n => total += n.value);
                return total;
            }
        }
        return 0;
    }

    private setAdjustedByLinesFromBlp(tankStepNumber: number, latestBlp: JobBlpRevision, nsvBarrels: number = 0): number {
        //From Excel:
        //IF(AF15="push",S15,IF(AN15<0,S15+ABS(AN15),S15-AN15))
        //AF15: Nominated Quantity
        //S15: N.S.V. Barrels
        //AN15: Line Capacity
        if (tankStepNumber && latestBlp) {
            // negative value indicates step was a push
            if (this.setNominatedQuantityFromBlp(tankStepNumber, latestBlp) < 1) {
                return nsvBarrels;
            } else {
                const lineCapacity = this.setLineCapacityFromBlp(tankStepNumber, latestBlp)
                if (lineCapacity < 0) {
                    return nsvBarrels + Math.abs(lineCapacity)
                } else {
                    return nsvBarrels - lineCapacity;
                }
            } 
        }
        return 0;
    }

    private calcVariance(nsvBarrels: number, expectedDelivery: number): number {
        //From Excel:
        //S15-AE15
        //S15: N.S.V. Barrels
        //AE15: Expected Delivery
        return nsvBarrels - expectedDelivery;
    }

    private setLineCapacityFromBlp(tankStepNumber: number, latestBlp: JobBlpRevision): number {
        if (tankStepNumber && latestBlp) {
            const foundStep = latestBlp.lineItems.find(l => l.step === tankStepNumber);
            if (foundStep) {
                return foundStep.lineAdjustment ? foundStep.lineAdjustment : 0;
            }
        }
        return 0;
    }

    // Tank Totals
    private calcTovTankTotal(tank: BfrTank): number {
        const open = tank?.bfrTankLineItems?.find(l => l.measurementType === BfrMeasurementTypes.open)?.tovUsBarrels;
        const close = tank?.bfrTankLineItems?.find(l => l.measurementType === BfrMeasurementTypes.close)?.tovUsBarrels;
        
        if (isNaN(open) || isNaN(close)) {
            return 0;
        }

        return Math.abs(open - close);
    }

    private calcGsvTankTotal(tank: BfrTank): number {
        const open = tank?.bfrTankLineItems?.find(l => l.measurementType === BfrMeasurementTypes.open)?.gsvUsBarrels;
        const close = tank?.bfrTankLineItems?.find(l => l.measurementType === BfrMeasurementTypes.close)?.gsvUsBarrels;
        
        if (isNaN(open) || isNaN(close)) {
            return 0;
        }

        return Math.abs(open - close);
    }

    private calcNsvTankTotal(tank: BfrTank): number {
        const open = tank?.bfrTankLineItems?.find(l => l.measurementType === BfrMeasurementTypes.open)?.nsvBarrels;
        const close = tank?.bfrTankLineItems?.find(l => l.measurementType === BfrMeasurementTypes.close)?.nsvBarrels;
        
        if (isNaN(open) || isNaN(close)) {
            return 0;
        }

        return Math.abs(open - close);
    }

    // BFR Summary
    private calcTovBarrelsSummary(bfr: Bfr): number {
        let total = 0;
        bfr?.bfrTanks?.forEach(t => total += t.tovUsBarrelsTotal);
        return total;
    }

    private calcGsvBarrelsSummary(bfr: Bfr): number {
        //Total G.S.V. Barrels
        let total = 0;
        bfr?.bfrTanks?.forEach(t => total += t.gsvUsBarrelsTotal);
        return total;
    }

    private calcNsvBarrelsSummary(bfr: Bfr): number {
        let total = 0;
        bfr?.bfrTanks?.forEach(t => total += t.nsvBarrelsTotal);
        return total;
    }

    private calcExpectedDeliverySummary(bfr: Bfr): number {
        let total = 0;
        bfr?.bfrTanks?.forEach(t => total += t.expectedDelivery);
        return total;
    }

    private calcVarianceSummary(bfr: Bfr): number {
        let total = 0;
        bfr?.bfrTanks?.forEach(t => total += t.variance);
        return total;
    }

    private calcTcvGallonsSummary(tcvBarrelsSummary: number = 0): number {
        //From Excel:
        //ROUND(G76*42,0)
        //G76: T.C.V. Barrels
        //Note: Rounding done in UI with decmial pipe
        return tcvBarrelsSummary*42;
    }

    private calcTcvBarrelsSummary(bfr: Bfr): number {
        //Total G.S.V. Barrels
        let total = 0;
        bfr?.bfrTanks?.forEach(t => total += t.gsvUsBarrelsTotal);
        return total;
    }

    private calcTcvLongTonsSummary(avgTempSummary: number = 0, tcvBarrelsSummary: number): number {
        //From Excel:
        //@Tables11(J81)*G76
        //J81: Average Temp Summary
        //G76: T.C.V. Barrels Sumary total
        return this.calcReferenceService.getAstmTable11Wcf(avgTempSummary)*tcvBarrelsSummary;
    }

    private calcTcvMetricTonsSummary(avgTempSummary: number = 0, tcvBarrelsSummary: number): number {
        //From Excel:
        //@Tables13(J81)*G76
        //J81: Average Temp Summary
        //G76: T.C.V. Barrels Sumary total
        return this.calcReferenceService.getAstmTable13Wcf(avgTempSummary)*tcvBarrelsSummary;
    }

    private calcTcvKilogramsSummary(tcvMetricTonsSummary: number = 0): number {
        //From Excel:
        //ROUND(G78*1000,0)
        //G78: Metric Tons Summary
        //Note: Rounding done in UI with decmial pipe
        return tcvMetricTonsSummary * 1000;
    }

    private calcGsvGallonsSummary(tcvBarrelsSummary: number = 0): number {
        //From Excel:
        //ROUND(J76*42,0)
        //J76: G.S.V. Barrels
        //Note: Rounding done in UI with decmial pipe
        return tcvBarrelsSummary*42;
    }

    private calcGsvLongTonsSummary(avgTempSummary: number = 0, tcvBarrelsSummary: number): number {
        //From Excel:
        //@Tables11(J81)*J76
        //J81: Average Temp Summary
        //J76: G.S.V. Barrels Sumary total
        return this.calcReferenceService.getAstmTable11Wcf(avgTempSummary)*tcvBarrelsSummary;
    }

    private calcGsvMetricTonsSummary(avgTempSummary: number = 0, tcvBarrelsSummary: number): number {
        //From Excel:
        //@Tables13(J81)*J76
        //J81: Average Temp Summary
        //J76: G.S.V. Barrels Sumary total
        return this.calcReferenceService.getAstmTable13Wcf(avgTempSummary)*tcvBarrelsSummary;
    }

    private calcGsvKilogramsSummary(tcvMetricTonsSummary: number = 0): number {
        //From Excel:
        //ROUND(J78*1000,0)
        //J78: Metric Tons Summary
        //Note: Rounding done in UI with decmial pipe
        return tcvMetricTonsSummary * 1000;
    }

    private calcAvgApiSummary(bfr: Bfr): number {
        //From Excel:
        //SUM(AL12:AL71)/S72
        //AL12:AL71: N.S.V. Barrels Tank Total * API (that row) summed
        //S72: N.S.V. Barrels Total
        let total = 0;
        bfr?.bfrTanks?.forEach(t => {
            const close = t.bfrTankLineItems.find(l => l.measurementType === BfrMeasurementTypes.close); 
            total += (close && close.api && t.nsvBarrelsTotal) ? (t.nsvBarrelsTotal * close.api) : 0;
        });
        return total/bfr.nsvBarrelsTotal;
    }

    private calcAvgTempSummary(bfr: Bfr): number {
        //From Excel:
        //SUM(AK12:AK71)/S72
        //AK12:AK71: N.S.V. Barrels Tank Total * Tank Temp (that row) summed
        //S72: N.S.V. Barrels Total
        let total = 0;
        bfr?.bfrTanks?.forEach(t => {
            const close = t.bfrTankLineItems.find(l => l.measurementType === BfrMeasurementTypes.close); 
            total += (close && close.tankTemp && t.nsvBarrelsTotal) ? (t.nsvBarrelsTotal * close.tankTemp) : 0;
        });
        return total/bfr.nsvBarrelsTotal;
    }

    private sortBrfTankLineItems(a: BfrTankLineItem, b: BfrTankLineItem): number {
        switch (a.measurementType) {
            case BfrMeasurementTypes.open:
                // Only tank Id row is before
                return (b.measurementType !== BfrMeasurementTypes.close && b.measurementType.length) ? -1 : 1;
            case BfrMeasurementTypes.close:
                // Both Open and Tank Id are before (should be any row with length since this row is close already)
                return (b.measurementType.length) ? -1 : 1;
            case null:
                // If not set, then is total row and all rows ahead
                return -1;
            case '':
                // same as null case
            default:
                // if any other value then it is the tankId and should always be first
                return 1;
        }
    }
}