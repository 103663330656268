import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/http-services/customer.service';
import { ActiveJobInfo } from '../../models/customer/active-job-info';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as appState from 'src/app/state';
import * as appActions from 'src/app/state/app.actions';
import { JobStatuses } from '../../models/job/job-statuses';
import { ClientCompany, CompletedJobInfo, QuickLook, User, UserRoles } from '../../models';
import { DataSharingService } from '../../services/data-sharing.service';
import { DownloadsService } from '../../services/http-services/downloads.service';


@Component({
  selector: 'app-customerjobslist',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})
export class CustomerJobsListComponent implements OnInit, OnDestroy {
  isActive: boolean;
  pageDataReady: boolean = false;
  subscriptions: Subscription[] = [];
  ticker: QuickLook;
  activeCustomerJobs: ActiveJobInfo[];
  activeCustomerJobsCount: number;
  activeJobCols: any[];
  completedCustomerJobs: CompletedJobInfo[];
  completedCustomerJobsCount: number;
  completedJobCols: any[];
  currentUser: User = new User();
  selectedCustomer: ClientCompany;
  viewAssignment: boolean = false;

  constructor(
    private router: Router,
    private downloadsService: DownloadsService,
    private dataSharingService: DataSharingService,
    private custService: CustomerService,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService,
    private store: Store<appState.State>) { }

  ngOnInit(): void {
    this.listenToStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        if (user?.id && user.id == this.currentUser?.id) return;

        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.newCustomer)) {
            this.getJobsList(user.client);
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin) || this.currentUser.role.includes(UserRoles.superNewCustomer)) {
            this.listenToCustomerChanges();
          }
        }
      })
    );
  }

  listenToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;

      if (!this.selectedCustomer || this.selectedCustomer.id != c.id) {
        this.selectedCustomer = c;
        this.getJobsList(this.selectedCustomer.id);
      }
    });
  }

  buildJobActiveColumns() {
    this.activeJobCols = [
      { field: 'status', header: 'STATUS', display: true },
      { field: 'ship', header: 'SHIP', display: true },
      { field: 'loadingStatus', header: 'LOADING STATUS', display: true },
      { field: 'progress', header: 'PROGRESS (%)', display: true },
      { field: 'nominatedVolume', header: 'NOMINATED', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'loadedVolume', header: 'LOADED', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'loadingTime', header: 'LOADING TIME', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'totalDelay', header: 'DOWN TIME', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'allFast', header: 'ALL FAST', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'etcTime', header: 'ETC TIME', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'flagged', header: 'FLAGGED', display: true }
    ];
  }

  buildJobCompletedColumns() {
    this.completedJobCols = [
      { field: 'status', header: 'STATUS', display: true },
      { field: 'ship', header: 'SHIP', display: true },
      { field: 'jobNumber', header: 'JOB NUMBER', display: true },
      { field: 'nominatedVolume', header: 'NOMINATED', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'totalDelay', header: 'DOWN TIME', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'allFast', header: 'ALL FAST', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'completionDate', header: 'COMPLETION', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'feedback', header: 'FEEDBACK', display: true }
    ];
  }

  getJobsList(clientId: string) {
    this.route.params.subscribe(
      async (params) => {
        if (params && params['status']) {
          if (params['status'] == JobStatuses.active.toLowerCase()) {
            this.store.dispatch(appActions.showSpinner());
            this.isActive = true;
            var data = await this.custService.getActiveJobsList(clientId)
              .toPromise()
              .catch(error => {
                this.pageDataReady = true;
                this.store.dispatch(appActions.hideSpinner());
                this.appMessageService.errorMessage('Error getting Active Jobs List', null);
                return null;
              });
            this.activeCustomerJobs = data.activeJobs;
            this.activeCustomerJobsCount = this.activeCustomerJobs.length;
            this.ticker = data.ticker;
            if (this.ticker == null)
              this.viewAssignment = false;
            else
              this.viewAssignment = true;
            this.buildJobActiveColumns();
            this.pageDataReady = true;
            this.store.dispatch(appActions.hideSpinner());
          }
          else if (params['status'] == JobStatuses.complete.toLowerCase()) {
            this.store.dispatch(appActions.showSpinner());
            this.isActive = false;
            var data = await this.custService.getCompletedJobsList(clientId)
              .toPromise()
              .catch(error => {
                this.pageDataReady = true;
                this.store.dispatch(appActions.hideSpinner());
                this.appMessageService.errorMessage('Error getting Completed Jobs List', null);
                return null;
              });
            this.completedCustomerJobs = data.completedJobs;
            this.completedCustomerJobsCount = this.completedCustomerJobs.length;
            this.ticker = data.ticker;
            if (this.ticker == null)
              this.viewAssignment = false;
            else
              this.viewAssignment = true;
            this.buildJobCompletedColumns();
            this.pageDataReady = true;
            this.store.dispatch(appActions.hideSpinner());
          }
        } else {
          this.appMessageService.errorMessage('Missing Job Status', null);
        }
      }
    );
  }

  viewActiveJob(jobId: string) {
    this.router.navigate(['customer/jobs-detail/active/' + jobId]);
  }

  viewCompletedJob(jobId: string) {
    this.router.navigate(['customer/jobs-detail/completed/' + jobId]);
  }

  viewJobAssignment() {
    this.router.navigate(['customer/jobs-detail/active/' + this.ticker.jobId]);
  }

  downloadJobsList() {
    this.downloadsService.downloadJobsList(this.selectedCustomer.id, this.isActive);
  }

  createFeedback(jobId: string) {
    this.router.navigate(['customer/feedback/' + jobId + '/create']);
  }

  viewFeedback(jobId: string) {
    this.router.navigate(['customer/feedback/' + jobId + '/view']);
  }

}
