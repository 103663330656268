<div class="p-grid">
    <div class="p-md-6 p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="vessel" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Vessel</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="vessel" type="text" [disabled]="true" pInputText [ngModel]="overview.ship">  -->
                    <label id="vessel">{{overview.ship?.name}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="client" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Customer</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="client" type="text" [disabled]="true" pInputText [ngModel]="overview.client?.name">  -->
                    <label id="client">{{overview.client?.name}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="preparedBy" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Prepared By</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="preparedBy" type="text" [disabled]="true" pInputText [ngModel]="preparedBy">  -->
                    <label id="preparedBy">{{preparedBy}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="vesselAttendants" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Vessel Attendants</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="vesselAttendants" type="text" [disabled]="true" pInputText [ngModel]="vesselAttendants">  -->
                    <label id="vesselAttendants">{{vesselAttendants}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="revision" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Revision</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="vessel" type="text" [disabled]="true" pInputText [ngModel]="currentRevision">  -->
                    <label id="revision">{{currentRevision}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="dock" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Dock</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="client" type="text" [disabled]="true" pInputText [ngModel]="overview.dock?.name">  -->
                    <label id="dock">{{overview.dock?.name}}</label>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="terminal" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Terminal</label>
                <div class="p-col-12 p-md-10">
                    <!-- <input id="terminal" type="text" [disabled]="true" pInputText [ngModel]="overview.terminal?.name">  -->
                    <label id="terminal">{{overview.terminal?.name}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
