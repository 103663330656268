import * as moment from 'moment';
import { Contact } from '../..';
import { AuditableEntity } from "../../auditable-entity";
import { BouActualLineItem } from './bou-actual-line-item';
import { BouEstimationLineItem } from "./bou-estimation-line-item";

export class Bou extends AuditableEntity {
    jobId: string;

    shipId: string;
    vessel: string;
    attendingIds: string;
    attendingText: string;
    bouDate: Date;
    bouType: string;
    statusDetail: string;
    reportContacts: Contact[];

    // O.B.Q.
    obq: number = 0;
    etcDate: Date;
    loadingFromTankId: string;
    etcThisStep: Date;
    currentRate: number = 0;
    timeAtDockHours: number = 0;
    timeAtDockMinutes: number = 0;
    percentLoaded: number = 0;

    // Event Times
    firstLine: Date;
    allFast: Date;
    gangwaySecure: Date;
    keyMeetingCompleted: Date;
    vesselTanksInspectionCompleted: Date;
    arrivalCalculationsCompleted: Date;
    cargoVaporArmsHosesConnected: Date;
    loadingCommenced: Date;
    loadingCompleted: Date;
    ullagingCompleted: Date;
    cargoCalcualationsCompleted: Date;
    armsHosesDisconnected: Date;
    pilotSet: Date;

    //Shore Barge Reporting
    useNominatedQuantities: boolean = false;
    estimationLineItems: BouEstimationLineItem[] = [];
    estimationDurationHoursTotal: number = 0;
    estimationDurationMinutesTotal: number = 0;
    estimationNominatedTotal: number = 0;
    actualLineItems: BouActualLineItem[] = [];
    actualDurationHoursTotal: number = 0;
    actualDurationMinutesTotal: number = 0;
    actualVaporDelayHoursTotal: number = 0;
    actualVaporDelayMinutesTotal: number = 0;
    actualWeatherDelayHoursTotal: number = 0;
    actualWeatherDelayMinutesTotal: number = 0;
    actualLineDisplacementDelayHoursTotal: number = 0;
    actualLineDisplacementDelayMinutesTotal: number = 0;
    actualRateSlowdownDelayHoursTotal: number = 0;
    actualRateSlowdownDelayMinutesTotal: number = 0;
    actualPowerOutageDelayHoursTotal: number = 0;
    actualPowerOutageDelayMinutesTotal: number = 0;
    actualTerminalReasonDelayHoursTotal: number = 0;
    actualTerminalReasonDelayMinutesTotal: number = 0;
    actualOtherDelayHoursTotal: number = 0;
    actualOtherDelayMinutesTotal: number = 0;
    actualTankSwapHoursTotal: number = 0;
    actualTankSwapMinutesTotal: number = 0;
    actualDeliveredTotal: number = 0;
    actualVarianceTotal: number = 0;

    operationalNotes: string;

    grossLoadingTimeHours: number = 0;
    grossLoadingTimeMinutes: number = 0;
    grossLoadingTimeShoreFigures: number = 0;
    grossLoadingTimeRate: number = 0;
    netLoadingTimeHours: number = 0;
    netLoadingTimeMinutes: number = 0;
    netLoadingTimeShoreFigures: number = 0;
    netLoadingTimeRate: number = 0;

    vesselReportingReceived: number = 0;
    vesselReportingVef: number = 0;
    vesselReportingAdjusted: number = 0;
    vesselReportingDifference: number = 0;
    vesselReportingApparentDifferencePercent: number = 0;

    constructor() {
        super();
    }

    get isCompleted(): boolean {
        // return this.loadingCompleted instanceof Date && !isNaN(this.loadingCompleted.getTime());
        return this.loadingCompleted ? true : false;
    }

    get timeAtDock(): moment.Duration {
        return moment.duration({
            hours: this.timeAtDockHours ? this.timeAtDockHours : 0,
            minutes: this.timeAtDockMinutes ? this.timeAtDockMinutes : 0
        });
    }

    get estimationDuration(): moment.Duration {
        return moment.duration({
            hours: this.estimationDurationHoursTotal ? this.estimationDurationHoursTotal : 0,
            minutes: this.estimationDurationMinutesTotal ? this.estimationDurationMinutesTotal : 0
        });
    }

    get actualDuration(): moment.Duration {
        return moment.duration({
            hours: this.actualDurationHoursTotal ? this.actualDurationHoursTotal : 0,
            minutes: this.actualDurationMinutesTotal ? this.actualDurationMinutesTotal : 0
        });
    }

    get actualTankSwap(): moment.Duration {
        return moment.duration({
            hours: this.actualTankSwapHoursTotal ? this.actualTankSwapHoursTotal : 0,
            minutes: this.actualTankSwapMinutesTotal ? this.actualTankSwapMinutesTotal : 0
        });
    }

    get grossLoadingTime(): moment.Duration {
        return moment.duration({
            hours: this.grossLoadingTimeHours ? this.grossLoadingTimeHours : 0,
            minutes: this.grossLoadingTimeMinutes ? this.grossLoadingTimeMinutes : 0
        });
    }

    get netLoadingTime(): moment.Duration {
        return moment.duration({
            hours: this.netLoadingTimeHours ? this.netLoadingTimeHours : 0,
            minutes: this.netLoadingTimeMinutes ? this.netLoadingTimeMinutes : 0
        });
    }

    static fromJson(bou: Bou): Bou {
        const newBou: Bou = new Bou();

        newBou.id = bou.id;
        newBou.active = bou.active;
        newBou.createdBy = bou.createdBy;
        newBou.createdOn = bou.createdOn;
        newBou.modifiedBy = bou.modifiedBy;
        newBou.modifiedOn = bou.modifiedOn;

        newBou.jobId = bou.jobId;

        newBou.vessel = bou.vessel;
        newBou.attendingIds = bou.attendingIds;
        newBou.attendingText = bou.attendingText;
        newBou.bouDate = bou.bouDate;
        newBou.bouType = bou.bouType;
        newBou.statusDetail = bou.statusDetail;
        newBou.reportContacts = bou.reportContacts;

        newBou.obq = bou.obq ? bou.obq : 0;
        newBou.etcDate = bou.etcDate;
        newBou.loadingFromTankId = bou.loadingFromTankId;
        newBou.etcThisStep = bou.etcThisStep;
        newBou.currentRate = bou.currentRate;
        newBou.timeAtDockHours = bou.timeAtDockHours;
        newBou.timeAtDockMinutes = bou.timeAtDockMinutes;
        newBou.percentLoaded = bou.percentLoaded;

        newBou.firstLine = bou.firstLine;
        newBou.allFast = bou.allFast;
        newBou.gangwaySecure = bou.gangwaySecure;
        newBou.keyMeetingCompleted = bou.keyMeetingCompleted;
        newBou.vesselTanksInspectionCompleted = bou.vesselTanksInspectionCompleted;
        newBou.arrivalCalculationsCompleted = bou.arrivalCalculationsCompleted;
        newBou.cargoVaporArmsHosesConnected = bou.cargoVaporArmsHosesConnected;
        newBou.loadingCommenced = bou.loadingCommenced;
        newBou.loadingCompleted = bou.loadingCompleted;
        newBou.ullagingCompleted = bou.ullagingCompleted;
        newBou.cargoCalcualationsCompleted = bou.cargoCalcualationsCompleted;
        newBou.armsHosesDisconnected = bou.armsHosesDisconnected;
        newBou.pilotSet = bou.pilotSet;

        newBou.useNominatedQuantities = bou.useNominatedQuantities;
        newBou.estimationLineItems = bou.estimationLineItems.map(l => BouEstimationLineItem.fromJson(l));
        newBou.estimationDurationHoursTotal = bou.estimationDurationHoursTotal;
        newBou.estimationDurationMinutesTotal = bou.estimationDurationMinutesTotal;
        newBou.estimationNominatedTotal = bou.estimationNominatedTotal;

        newBou.actualLineItems = bou.actualLineItems.map(l => BouActualLineItem.fromJson(l));
        newBou.actualDurationHoursTotal = bou.actualDurationHoursTotal;
        newBou.actualDurationMinutesTotal = bou.actualDurationMinutesTotal;
        newBou.actualVaporDelayHoursTotal = bou.actualVaporDelayHoursTotal;
        newBou.actualVaporDelayMinutesTotal = bou.actualVaporDelayMinutesTotal;
        newBou.actualWeatherDelayHoursTotal = bou.actualWeatherDelayHoursTotal;
        newBou.actualWeatherDelayMinutesTotal = bou.actualWeatherDelayMinutesTotal;
        newBou.actualLineDisplacementDelayHoursTotal = bou.actualLineDisplacementDelayHoursTotal;
        newBou.actualLineDisplacementDelayMinutesTotal = bou.actualLineDisplacementDelayMinutesTotal;
        newBou.actualRateSlowdownDelayHoursTotal = bou.actualRateSlowdownDelayHoursTotal;
        newBou.actualRateSlowdownDelayMinutesTotal = bou.actualRateSlowdownDelayMinutesTotal;
        newBou.actualPowerOutageDelayHoursTotal  = bou.actualPowerOutageDelayHoursTotal;
        newBou.actualPowerOutageDelayMinutesTotal = bou.actualPowerOutageDelayMinutesTotal;
        newBou.actualTerminalReasonDelayHoursTotal = bou.actualTerminalReasonDelayHoursTotal;
        newBou.actualTerminalReasonDelayMinutesTotal = bou.actualTerminalReasonDelayMinutesTotal;
        newBou.actualOtherDelayHoursTotal = bou.actualOtherDelayHoursTotal;
        newBou.actualOtherDelayMinutesTotal = bou.actualOtherDelayMinutesTotal;
        newBou.actualTankSwapHoursTotal = bou.actualTankSwapHoursTotal;
        newBou.actualTankSwapMinutesTotal = bou.actualTankSwapMinutesTotal;
        newBou.actualDeliveredTotal = bou.actualDeliveredTotal;
        newBou.actualVarianceTotal = bou.actualVarianceTotal;

        newBou.operationalNotes = bou.operationalNotes;

        newBou.grossLoadingTimeHours = bou.grossLoadingTimeHours;
        newBou.grossLoadingTimeMinutes = bou.grossLoadingTimeMinutes;
        newBou.grossLoadingTimeShoreFigures = bou.grossLoadingTimeShoreFigures;
        newBou.grossLoadingTimeRate = bou.grossLoadingTimeRate;
        newBou.netLoadingTimeHours = bou.netLoadingTimeHours;
        newBou.netLoadingTimeMinutes = bou.netLoadingTimeMinutes;
        newBou.netLoadingTimeShoreFigures = bou.netLoadingTimeShoreFigures;
        newBou.netLoadingTimeRate = bou.netLoadingTimeRate;

        newBou.vesselReportingReceived = bou.vesselReportingReceived;
        newBou.vesselReportingVef = bou.vesselReportingVef;
        newBou.vesselReportingAdjusted = bou.vesselReportingAdjusted;
        newBou.vesselReportingDifference = bou.vesselReportingDifference;
        newBou.vesselReportingApparentDifferencePercent = bou.vesselReportingApparentDifferencePercent;

        return newBou;
    }
}