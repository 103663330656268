import { AuditableEntity } from "../../auditable-entity";

export class BfrTankLineItem extends AuditableEntity {
    brfTankId: string;
    measurementType: string;
    ullageFt: string;
    tovUsBarrels: number;
    freeGauge: number;
    waterBarrels: number;
    tankTemp: number;
    ambientTemp: number;
    shellFactor: number;
    govUsBarrels: number;
    roofCorrection: number;
    astmTableOption: string;
    api: number;
    astmVcf: number;
    gsvUsBarrels: number;
    swPercent: number;
    nsvBarrels: number;
    zeroRoofCorrection: boolean = false;

    constructor() {
        super();
    }

    static fromJson(bfrTankLineItem: BfrTankLineItem): BfrTankLineItem{
        const newBfrTankLineItem: BfrTankLineItem = new BfrTankLineItem();

        newBfrTankLineItem.id = bfrTankLineItem.id;
        newBfrTankLineItem.active = bfrTankLineItem.active;
        newBfrTankLineItem.createdOn = bfrTankLineItem.createdOn;
        newBfrTankLineItem.createdBy = bfrTankLineItem.createdBy;
        newBfrTankLineItem.modifiedOn = bfrTankLineItem.modifiedOn;
        newBfrTankLineItem.modifiedBy = bfrTankLineItem.modifiedBy;
        newBfrTankLineItem.brfTankId = bfrTankLineItem.brfTankId;
        newBfrTankLineItem.measurementType = bfrTankLineItem.measurementType;
        newBfrTankLineItem.ullageFt = bfrTankLineItem.ullageFt;
        newBfrTankLineItem.tovUsBarrels = bfrTankLineItem.tovUsBarrels;
        newBfrTankLineItem.freeGauge = bfrTankLineItem.freeGauge;
        newBfrTankLineItem.waterBarrels = bfrTankLineItem.waterBarrels;
        newBfrTankLineItem.tankTemp = bfrTankLineItem.tankTemp;
        newBfrTankLineItem.ambientTemp = bfrTankLineItem.ambientTemp;
        newBfrTankLineItem.shellFactor = bfrTankLineItem.shellFactor;
        newBfrTankLineItem.govUsBarrels = bfrTankLineItem.govUsBarrels;
        newBfrTankLineItem.roofCorrection = bfrTankLineItem.roofCorrection;
        newBfrTankLineItem.astmTableOption = bfrTankLineItem.astmTableOption;
        newBfrTankLineItem.api = bfrTankLineItem.api;
        newBfrTankLineItem.astmVcf = bfrTankLineItem.astmVcf;
        newBfrTankLineItem.gsvUsBarrels = bfrTankLineItem.gsvUsBarrels;
        newBfrTankLineItem.swPercent = bfrTankLineItem.swPercent;
        newBfrTankLineItem.nsvBarrels = bfrTankLineItem.nsvBarrels;
        newBfrTankLineItem.zeroRoofCorrection = bfrTankLineItem.zeroRoofCorrection;

        return newBfrTankLineItem;
    }

}
