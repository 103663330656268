import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';

interface JobFilter {
  name: string,
  code: string
}

interface Job {
  name: string,
  pctComplete: number,
  startDate: Date,
  status: string, //Active, Incoming, Complete, Cancelled, Unconfirmed, Closed
  statusDescription: string,
  terminal: string,
  chartData: PieChartData,
}

interface PieChartDataset {
  backgroundColor: string[],
  data: number[],
  //label: number[],
}

interface PieChartData {
  labels: string[],
  datasets: PieChartDataset[]
}

@Component({
  selector: 'app-job-board',
  templateUrl: './job-board.component.html',
  styleUrls: ['./job-board.component.scss']
})
export class JobBoardComponent implements OnInit {
  chartOptions: any;
  jobFilters: JobFilter[];
  jobs: Job[];
  selectedJobFilter: JobFilter;
  sortField: string;
  sortOptions: SelectItem[];
  sortOrder: number;

  //Demo: throwaway methods
  sampleTankNames: string[] = ["T-Mix", "100-15", "30-02", "125-01", "150-102", "150-17", "", "170-1", "167-11", "Barges"]
  sampleBackgroundColors: string[] = [
    //'#42A5F5', //blue
    //'#66BB6A', //green
    //'#FFA726', //orange
    //'#EC407A', //magenta
    //'#ffff00', //yellow
    //'#804000', //brown
    //'#AB47BC', //purple
    //'#408080', //teal
    '#dcdcdc',  //gainsboro
    //'#400040', //dark purple
    //'#8080C0',  //lavender
    '#D3D3D3', //lightgray
    '#C0C0C0', //silver
    '#A9A9A9', //darkgrey
    '#808080', //gray
    '#696969', //dimgray
    '#778899', //lightslategray 
    '#3F4F4F', //darkslategray 
    '#202020',
    '#000000', //black
  ];
  generateChartData(status: string): PieChartData {
    let tankNumber = this.generateRandomNumber(1, 5);
    let sampleData = [this.generateRandomNumber(2, 10)]; //pre-cargo
    if (this.jobIsNotUnassigned(status)) {
      for(var i = 0; i < tankNumber; i++) {
        sampleData.push(this.generateRandomNumber(2, 5)); //tanks
      }
      sampleData.push(this.generateRandomNumber(1, 6)); //post-cargo
    }
    let pieChartDataset: PieChartDataset = {backgroundColor: this.sampleBackgroundColors, data: sampleData};
    let sampleTanks = ["Pre-Cargo"];
    if (this.jobIsNotUnassigned(status)) {
        for(var i = 0; i < tankNumber; i++){
        sampleTanks.push(this.sampleTankNames[this.generateRandomNumber(0, tankNumber-1)]); //tank names
      }
      sampleTanks.push("Post-Cargo")
    }
    let pieChartData: PieChartData = {labels: sampleTanks, datasets: [pieChartDataset]};
    return pieChartData;
  }
  generateRandomNumber (min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; 
  }
  //end Demo

  jobIsNotUnassigned(status: string): boolean {
    if(status == "Incoming" || status == "Cancelled" || status == "Unconfirmed") {
      return false;
    }
    return true;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.chartOptions = {
      legend: { display: false },
    }
    this.jobFilters = [
      {name: 'All', code: 'All'},
      {name: 'Active', code: 'Active'},
      {name: 'Incoming', code: 'Incoming'},
      {name: 'Complete', code: 'Complete'},
      {name: 'Cancelled', code: 'Cancelled'},
      {name: 'Unconfirmed', code: 'Unconfirmed'},
      {name: 'Closed', code: 'Closed'},
    ];
    this.jobs = [
      { name: "MT Fidelity", pctComplete: 100, startDate: new Date(2022,6,1), status: "Complete", statusDescription: "Complete", terminal: "Kinder Morgan-Galena Park", chartData: this.generateChartData("Complete")},
      { name: "Maersk Matsuma", pctComplete: 28, startDate: new Date(2022,6,2), status: "Active", statusDescription: "Loading From ST 150-03", terminal: "ITC-Pasadena", chartData: this.generateChartData("Active")},
      { name: "Nordic Anne", pctComplete: 69, startDate: new Date(2022,6,3), status: "Active", statusDescription: "Loading From ST 150-01", terminal: "Kinder Morgan-Galena Park", chartData: this.generateChartData("Active")},
      { name: "Aligote", pctComplete: 16, startDate: new Date(2022,6,4), status: "Active", statusDescription: "Loading From ST 150-02", terminal: "Kinder Morgan-Galena Park", chartData: this.generateChartData("Active")},
      { name: "Ardmore Endurance", pctComplete: 0, startDate: new Date(2022,6,5), status: "Unconfirmed", statusDescription: "Unconfirmed", terminal: "ITC-Pasadena", chartData: this.generateChartData("Unconfirmed")},
      { name: "Green Sea", pctComplete: 0, startDate: new Date(2022,6,6), status: "Unconfirmed", statusDescription: "Unconfirmed", terminal: "Valero Tule Lake", chartData: this.generateChartData("Unconfirmed")},
      { name: "Stena Conquest", pctComplete: 100, startDate: new Date(2022,6,7), status: "Closed", statusDescription: "Complete", terminal: "Kinder Morgan-Galena Park", chartData: this.generateChartData("Closed")},
      { name: "Lafayette Bay", pctComplete: 0, startDate: new Date(2022,6,8), status: "Cancelled", statusDescription: "Cancelled", terminal: "Magellan", chartData: this.generateChartData("Cancelled")},
      { name: "Ardmore Endurance", pctComplete: 0, startDate: new Date(2022,6,9), status: "Incoming", statusDescription: "N/A", terminal: "Valero Tule Lake", chartData: this.generateChartData("Incoming")},
      { name: "Lafayette Bay", pctComplete: 0, startDate: new Date(2022,6,10), status: "Incoming", statusDescription: "N/A", terminal: "ITC-Pasadena", chartData: this.generateChartData("Incoming")},
      { name: "Hellas Calafia", pctComplete: 0, startDate: new Date(2022,6,11), status: "Incoming", statusDescription: "N/A", terminal: "ITC-Pasadena", chartData: this.generateChartData("Incoming")},
    ]
    this.selectedJobFilter = this.jobFilters[0];
    this.sortField = 'startDate';
    this.sortOptions = [
      {label: 'Date Recent to Old', value: '!startDate'},
      {label: 'Date Old to Recent', value: 'startDate'}
    ];
    this.sortOrder = 1;
  }

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
    }
    else {
        this.sortOrder = 1;
        this.sortField = value;
    }
  }
}
