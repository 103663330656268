import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { Subscription } from 'rxjs';
import { Job, JobSections, Tank, Ship, Product } from 'src/app/models';
import { JobService } from 'src/app/services/http-services/job.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  jobMenuLarge: MenuItem[] = [];
  jobMenuSmall: MenuItem[] = [];
  collapseMenu = false;
  selectedSection: string = 'Overview';
  subscriptions: Subscription[] = [];
  tanks: Tank[] = [];
  shoreTanks: Tank[] = [];
  vesselTanks: Tank[] = [];
  ship: Ship;
  cargoProduct: Product;
  terminalId: string;

  constructor(
    private jobService: JobService,
    private route: ActivatedRoute,
    private store: Store<appState.State>,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.store.dispatch(appActions.showSpinner());
    this.jobMenuLarge = this.buildJobDetailLargeMenu();
    this.jobMenuSmall = this.buildJobDetailSmallMenu();
    this.loadJobFromRoute();
    this.listenForBlpSaved();
  }

  ngAfterViewInit() {


  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadJobFromRoute() {
    this.store.dispatch(appActions.setIsJobLoading({ isLoading: true }));
    this.route.params.subscribe(
      async (params) => {
        let job: Job = new Job();
        if (params && params['id'] && params['id'] !== 'new') {
          job = await this.jobService.getJobById(params['id'])
            .toPromise()
            .catch(error => {
              this.appMessageService.errorMessage('Cannot get Job:', error);
              return null;
            });
          this.store.dispatch(appActions.setIsJobNew({ isNew: false }));
        } else {
          this.store.dispatch(appActions.setIsJobNew({ isNew: true }));
        }
        if (job.overview.ship) {
          this.shipChanged(job.overview.ship);
        }
        if (job.overview.cargoProduct) {
          this.cargoChanged(job.overview.cargoProduct);
        }
        if (job.overview.terminal) {
          this.terminalChanged(job.overview.terminal.id);
        }
        this.listenForTankChanged();
        this.store.dispatch(appActions.setJob({ job: job }));
        this.store.dispatch(appActions.setIsJobLoading({ isLoading: false }));
        this.store.dispatch(appActions.hideSpinner());
      }
    );
  }

  listenForBlpSaved() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJobIsBlpBlocked)).subscribe(isBlocked => {
        const blocked = isBlocked;
        this.jobMenuLarge = this.buildJobDetailLargeMenu(blocked);
        this.jobMenuSmall = this.buildJobDetailSmallMenu(blocked);
      })
    );
  }

  buildJobDetailLargeMenu(waitingOnBlp: boolean = false): MenuItem[] {
    return [
      {
        label: JobSections.overview,
        command: () => { this.selectedSection = JobSections.overview; }
      },
      {
        label: JobSections.blp,
        command: () => { this.selectedSection = JobSections.blp; }
      },
      {
        label: JobSections.timeTracking,
        command: () => { this.selectedSection = JobSections.timeTracking; }
      },
      {
        label: JobSections.vdp,
        icon: waitingOnBlp ? 'pi pi-lock' : null,
        command: () => { this.selectedSection = JobSections.vdp; }
      },
      {
        label: JobSections.bfr,
        icon: waitingOnBlp ? 'pi pi-lock' : null,
        command: () => { this.selectedSection = JobSections.bfr; }
      },
      {
        label: JobSections.bou,
        icon: waitingOnBlp ? 'pi pi-lock' : null,
        command: () => { this.selectedSection = JobSections.bou; }
      }
    ];
  }

  buildJobDetailSmallMenu(waitingOnBlp: boolean = false): MenuItem[] {
    return [
      {
        label: 'OV',
        command: () => { this.selectedSection = JobSections.overview; }
      },
      {
        label: 'BLP',
        command: () => { this.selectedSection = JobSections.blp; }
      },
      {
        label: 'TT',
        command: () => { this.selectedSection = JobSections.timeTracking; }
      },
      {
        label: 'VDP',
        icon: waitingOnBlp ? 'pi pi-lock' : null,
        command: () => { this.selectedSection = JobSections.vdp; }
      },
      {
        label: 'BFR',
        icon: waitingOnBlp ? 'pi pi-lock' : null,
        command: () => { this.selectedSection = JobSections.bfr; }
      },
      {
        label: 'BOU',
        icon: waitingOnBlp ? 'pi pi-lock' : null,
        command: () => { this.selectedSection = JobSections.bou; }
      }
    ];
  }

  collapseMenuClicked() {
    this.collapseMenu = !this.collapseMenu;
    this.store.dispatch(appActions.collapseMenuToggled());
  }

  listenToJobUpdates() {

  }

  listenForTankChanged() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTanks)).subscribe(async (results) => {
        if (results) {
          this.tanks = results;
          if (this.ship)
            this.vesselTanks = this.tanks.filter(t => t.ship?.id == this.ship.id);
          if (this.terminalId)
            this.shoreTanks = this.tanks.filter(t => t.terminal?.id == this.terminalId);
        }
      })
    );
  }

  shipChanged(ship: Ship) {
    this.ship = ship;
    this.vesselTanks = this.tanks.filter(t => t.ship?.id == ship.id);
  }

  cargoChanged(cargo: Product) {
    this.cargoProduct = cargo;
  }

  terminalChanged(terminalId: string) {
    this.terminalId = terminalId;
    this.shoreTanks = this.tanks.filter(t => t.terminal?.id == terminalId);
  }
}
