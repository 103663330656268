<p-blockUI [target]="section" [blocked]="blocked">
    <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>
<div #section *ngIf="ready" class="p-grid">
    <div class="p-col-12 p-text-center p-d-none p-d-md-inline-flex">
        <img src="assets/images/demo/VDP-Sample.png" height="350">
    </div>
    <div class="p-col-12 p-text-center p-d-md-none">
        <img src="assets/images/demo/VDP-Sample.png" width="100%">
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" [disabled]="blocked" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" [disabled]="blocked" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>