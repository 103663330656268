<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid">
      <div class="p-field p-grid">
        <label for="tankid" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Tank ID</label>
        <div class="p-col-12 p-md-10">
          <input id="tankid" type="text" pInputText [(ngModel)]="tank.name">
        </div>
      </div>
      <div *ngIf="tank.isShipTank === false" class="p-field p-grid">
        <label for="location" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Location</label>
        <div class="p-col-12 p-md-10">
          <input id="location" type="text" pInputText [(ngModel)]="tank.location">
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="tankType" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Tank Type</label>
        <div class="p-col-12 p-md-10">
          <p-selectButton [options]="tankTypes" [(ngModel)]="tank.isShipTank" optionLabel="label" optionValue="value"></p-selectButton>
        </div>
      </div>
      <div *ngIf="tank.isShipTank === true" class="p-field p-grid">
        <label for="ship" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Ship</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="shipOptions" [(ngModel)]="tank.ship" appendTo="body" placeholder="Select Ship"></p-dropdown>
        </div>
      </div>
      <div *ngIf="tank.isShipTank === false" class="p-field p-grid">
        <label for="terminal" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Terminal</label>
        <div class="p-col-12 p-md-10">
          <p-dropdown [options]="terminalOptions" [(ngModel)]="tank.terminal" appendTo="body" placeholder="Select Terminal"></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-md-6 p-col-12">
        <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
      </div>
      <div class="p-md-6 p-col-12">
        <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
      </div>
    </div>
  </div>
</div>
