<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Email</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="userInEdit.email" readonly> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">First Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="userInEdit.firstName"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Last Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="userInEdit.lastName"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="phone" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Phone</label>
                <div class="p-col-12 p-md-10">
                    <!-- Input Mask was removed since need for international support with multiple phone numbers
                        <p-inputMask id="phone" mask="(999) 999-9999" [(ngModel)]="user.phone" placeholder="(999) 999-9999"></p-inputMask>
                    -->
                    <input id="phone" type="text" pInputText [(ngModel)]="userInEdit.phone" placeholder="(999) 999-9999">
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="resetPassword" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Reset Password</label>
                <div class="p-col-12 p-md-10">
                    <!-- Input Mask was removed since need for international support with multiple phone numbers
                        <p-inputMask id="phone" mask="(999) 999-9999" [(ngModel)]="user.phone" placeholder="(999) 999-9999"></p-inputMask>
                    -->
                    <p-checkbox [(ngModel)]="resetPassword" binary="true" inputId="binary"></p-checkbox>
                </div>
            </div>
            <div *ngIf="resetPassword" class="p-field p-grid">
                <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">New Password</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="password"> 
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>
