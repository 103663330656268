export enum TimingDescriptions {
    NorPeriond = 'NOR Period',
    Mooring = 'Mooring',
    Gangway = 'Gangway',
    IorApproval = 'IOR Approval',
    PreCargo = 'Pre-Cargo',
    CargoPeriod = 'Cargo Period',
    PostCargo = 'Post Cargo',
    TotalTimeOnDock = 'Total Time On Dock',
    GrossPumpingRate = 'Gross Pumping Rate',
    GrossPumpingTime = 'Gross Pumping Time',
    NetPumpingRate = 'Net Pumping Rate',
    NetPumpingTime = 'Net Pumping Time',
    GrossLoadingTime = 'Gross Loading Time',
    NetLoadingTime = 'Net Loading Time',
    TankSwitch = 'Tank Switch',
    Downtime = 'Total Downtime'
}

export enum TrendDescriptions {
    AverageDowntime = 'Average Downtime Trend',
    AverageJobTimeOnDock = 'Average Job Time On Dock Trend',
    AverageGrossPumpingRate = 'Average Gross Pumping Rate Trend',
    AverageNetPumpingRate = 'Average Net Pumping Rate Trend',
    AveragePreCargoTime = 'Average Pre-Cargo Time Trend',
    AverageDowntimeByReasons = 'Average Downtime By Reasons'
}