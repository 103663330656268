import { Port, Terminal, Dock, ClientCompany, InspectionCompany, User, TimeTrackingEvent, Contact, Tank, Ship, Product } from "..";

export class SystemConfiguration {
  ports: Port[] = [];
  terminals: Terminal[] = [];
  docks: Dock[] = [];
  clients: ClientCompany[] = [];
  inspectionCompanies: InspectionCompany[] = [];
  superintendents: User[] = [];
  contacts: Contact[] = [];
  users: User[] = [];
  timeTrackingEvents: TimeTrackingEvent[] = [];
  ships: Ship[] = [];
  cargos: Product[] = [];
  tanks: Tank[] = [];
  products: Product[] = [];
}
