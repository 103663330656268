export enum SystemTriggerEventIds {
    firstLineCommencedMooring = 'C17CEDF1-BAEF-4665-AC4D-C35E24C68244',
    finishedLoadingFromShortTanks = 'A5B95A23-2FE8-4247-942B-F07B2E47E2CE',
    allFast = '94EDF006-3707-4070-AE1B-F86F474E84A3',
    gangwaySecure = '8B6C7C08-B2A0-47EB-9B24-E6BFE3B5FFF9',
    keyMeeting = '2DE8EB5A-3BCF-453B-88F0-968D4EFE4672',
    vesselTanksInspected = 'F983CF35-82E1-4E49-B6BE-50504C6904BD',
    arrivalCalculations = 'F65828BB-45EB-4095-AE25-9DAD354CB5BA',
    cargoVaporArmsHosesConnected = 'DFEA30C4-C657-4CD9-A41C-98884E4A1E78',
    ullaging = '66439ECE-E213-41A1-B66A-0CD525043A60',
    cargoCalculations = '63D4E872-AE3F-4E98-9350-3CD45634A5CE',
    armsHosesDisconnected = '890C33D4-5FDF-4431-9C86-3A45ED191CF7',
    pilotSet = '066FF026-D521-4686-B6E8-F08BC81BB0BA',
    vaporDelayTerminal = 'B46ECF45-076B-4382-A195-7A69D4B4659D',
    weatherDelay = 'ACCAC3B6-71CB-43CB-8A13-28C8398D47B6',
    lineDisplacementDelay = '0D92D146-E5AB-4E4D-9B2A-FA0C8C455FF5',
    rateSlowdownDelay = 'BE416860-AA72-4D0D-AFEF-E912C232962F',
    powerOutageDelay = '7518F4BA-9FFE-494B-95E1-944D446760AE',
    terminalReasonDelay = '6DF8453F-B7CD-4FC5-991D-247FCA535EA0',
    tankSwapDelayTerminal = 'E01BF4C9-3E26-4E69-837F-644EB809AF53',
    otherDelayTerminal = '25A7D657-0C7C-42F1-8487-19EE1A74D374',
    iorApproved = '7E7B4C04-5014-4675-9CD6-95BED30d381D',
    iorReleased = '1AFF5863-8EC8-415B-B1F1-910DD6AD4746',
    lastLine = '9496EF33-2672-44AF-B67F-D59B87CCBE04',
    awaitingLineApproval = 'ED56f7CA-9646-4638-B1DB-68C1E546C290',
    awaitingLinePush = '7B82E69B-CD24-4F5C-8D76-6FD5C15D0442',
    vaporIssuesVessel = '8074BF85-88D2-4965-9287-17EED4909A77',
    tankSwitchVessel = '6B3A1106-20DC-4538-89EF-426C1E2734FB'
}
