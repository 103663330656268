<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="firstName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">First Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="firstName" type="text" pInputText [(ngModel)]="contact.firstName"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="lastName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Last Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="lastName" type="text" pInputText [(ngModel)]="contact.lastName"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Email</label>
                <div class="p-col-12 p-md-10">
                    <input id="email" type="text" pInputText [(ngModel)]="contact.email"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="phone" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Phone</label>
                <div class="p-col-12 p-md-10">
                    <!-- Input Mask was removed since need for international support with multiple phone numbers
                        <p-inputMask id="phone" mask="(999) 999-9999" [(ngModel)]="contact.phone" placeholder="(999) 999-9999"></p-inputMask>
                    -->
                    <input id="phone" type="text" pInputText [(ngModel)]="contact.phone" placeholder="(999) 999-9999"> 
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>
