import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { JobBlpRevision, JobOverview, User } from 'src/app/models';
import * as appState from '../../../../state';

@Component({
  selector: 'app-job-blp-header',
  templateUrl: './job-blp-header.component.html',
  styleUrls: ['./job-blp-header.component.scss']
})
export class JobBlpHeaderComponent implements OnInit, OnDestroy {

  @Input() blp: JobBlpRevision = new JobBlpRevision();
  overview: JobOverview = new JobOverview();
  currentUser: User = new User();
  preparedByUser: User;
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<appState.State>,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.listenToStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJob)).subscribe(jobState => {
        this.overview = JobOverview.fromJson(jobState.overview);
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        this.currentUser = User.fromUserProperties(user);
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(appState.getUsers)).subscribe(users => {
        if (this.blp && this.blp.preparedById && users) {
          this.preparedByUser = User.fromUserProperties(users.find(u => u.id === this.blp.preparedById));
        }
      })
    );
  }

  get preparedBy(): string {
    return this.preparedByUser ?
      this.preparedByUser.displayName  + ' ' + (this.preparedByUser.phone ? this.preparedByUser.phone : '') :
      this.currentUser.displayName + ' ' + (this.currentUser.phone ? this.currentUser.phone : '');
  }

  get vesselAttendants(): string {
    return this.overview.superintendents?.map(s => { return User.fromUserProperties(s).displayName + ' ' + (s.phone ? s.phone : ''); }).join(', ');
  }

  get currentRevision(): string {
    return (this.blp.revisionNumber >= 0 ? this.blp.revisionNumber : 'New Revision') + ' (' + this.datePipe.transform(this.blp.revisionDate, 'MMM d, y, HH:mm') + ')';
  }
}
