import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BlpLineItem, BlpLineItemMeasurement, BlpParcel, JobBlpRevision, JobOverview, Tank } from 'src/app/models';
import { BlpManagementService } from 'src/app/services/blp-management.service';
import * as appState from '../../../../state';
import * as appActions from '../../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'app-job-blp-detail',
  templateUrl: './job-blp-detail.component.html',
  styleUrls: ['./job-blp-detail.component.scss']
})
export class JobBlpDetailComponent implements OnInit, OnChanges, OnDestroy {

  @Input() blpToEdit: JobBlpRevision = new JobBlpRevision();
  @Input() overview: JobOverview;
  @Input() shoreTanks: Tank[] = [];
  @Input() vesselTanks: Tank[] = [];
  @Output() blpSaved = new EventEmitter<JobBlpRevision>();
  @Output() blpReset = new EventEmitter();
  
  blp: JobBlpRevision = new JobBlpRevision();
  subscriptions: Subscription[] = [];
  isInBlpEditMode: boolean = false;

  actionButtonItems: MenuItem[] = [];

  showParcelEdit: boolean = false;
  selectedParcel: BlpParcel;
  isNewParcel: boolean = false;

  showStepEdit: boolean = false;
  selectedStep: BlpLineItem;
  isNewStep: boolean = false;
  stepNumberBeforeEdit: number;
  isExistingCargoStep: boolean = false;
  showOverridesEdit: boolean = false;

  largeScreenCols: any[] = [];
  mobileCols: any[] = [];

  tableData: any[] = [];

  constructor(
    private store: Store<appState.State>,
    private blpManagementService: BlpManagementService,
    private confirmationService: ConfirmationService,
    private actions$: Actions) { }

  ngOnInit(): void {
    this.actionButtonItems = this.buildActionButtonItems();
    this.listenForBlpEditMode();
    this.refreshColumnSettings();
    this.refreshTableData();
  }

  ngOnChanges() {
    this.blp = cloneDeep(this.blpToEdit);
    this.refreshColumnSettings();
    this.refreshTableData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  buildActionButtonItems(): MenuItem[] {
    return [
      {
        label: 'Add Parcel',
        icon: 'pi pi-plus',
        command: () => { this.addNewParcel(); }
      },
      {
        label: 'Add Step',
        icon: 'pi pi-plus',
        command: () => { this.addNewStep(); }
      },
      {
        label: 'Add Existing Cargo',
        icon: 'pi pi-plus',
        command: () => { this.addExistingCargo(); }
      },
      {
        label: 'Manage Overrides',
        icon: 'pi pi-pencil',
        command: () => { this.setOverrides(); }
      }
    ];
  }

  listenForBlpEditMode() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getInBlpEditMode)).subscribe(
        result => this.isInBlpEditMode = result
      )
    );
  }

  // Hack to prevent current BLP revision from being locked out
  // when menu collapse is toggled why editing a new revision
  listenToMenuCollapseToggled() {
    this.subscriptions.push(
      this.actions$.pipe(ofType(appActions.collapseMenuToggled)).subscribe(
        () => {
          if (this.isInBlpEditMode) {
           this.isInBlpEditMode = false;
           of(delay(500)).subscribe(() => this.isInBlpEditMode = true);
          }
        }
      )
    );
  }

  save() {
    this.blpSaved.emit(this.blp);
  }

  reset() {
    this.blpReset.emit();
  }

  // Parcel Management
  addNewParcel() {
    this.selectedParcel = new BlpParcel();
    this.isNewParcel = true;
    this.showParcelEdit = true;
  }

  editParcel(parcelNumber: string) {
    this.selectedParcel = this.blp.parcels.find(p => p.parcelNumber === parseInt(parcelNumber));
    this.showParcelEdit = true;
  }

  onParcelEditCanceled() {
    this.selectedParcel = null;
    this.isNewParcel = false;
    this.showParcelEdit = false;
  }

  onParcelEditSaved() {
    if (this.isNewParcel) {
      this.blp = this.blpManagementService.addParcel(this.blp, this.selectedParcel);
    }
    // else parcel already modified on blp

    this.calculateBlp();
    this.refreshColumnSettings();
    this.refreshTableData();
    
    this.selectedParcel = null;
    this.isNewParcel = false;
    this.showParcelEdit = false;
  }

  deleteParcel(parcelNumber: number) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to remove Parcel Number: ' + parcelNumber,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.blpManagementService.removeParcel(this.blp, parcelNumber);
        this.calculateBlp();
        this.refreshColumnSettings();
        this.refreshTableData();
      }
    });
  }

  // Step Management
  addNewStep() {
    this.selectedStep = this.blpManagementService.buildStep(this.blp);
    this.isNewStep = true;
    this.isExistingCargoStep = false;
    this.showStepEdit = true;
  }

  addExistingCargo() {
    const existingCargoStepAlreadyExists = this.blp.existingCargo !== null && this.blp.existingCargo !== undefined;
    if (existingCargoStepAlreadyExists) {
      this.stepNumberBeforeEdit = this.blp.existingCargo.step;
      this.isNewStep = false;
    } else {
      this.isNewStep = true;
    }
    this.selectedStep = this.blpManagementService.buildExistingCargoStep(this.blp);
    this.isExistingCargoStep = true;
    this.showStepEdit = true;
  }

  editStep(stepNumber: number) {
    this.stepNumberBeforeEdit = stepNumber;
    this.selectedStep = this.blp.allLineItems.find(s => s.step === stepNumber);
    this.showStepEdit = true;
  }

  onStepEditCanceled() {
    this.selectedStep = null;
    this.isNewStep = false;
    this.isExistingCargoStep = false;
    this.showStepEdit = false;
    this.stepNumberBeforeEdit = null;
  }

  onStepEditSaved() {
    // Handle inserting the step into the proper place
    if (this.isNewStep) {
      this.blp = this.blpManagementService.addStep(this.blp, this.selectedStep);
    } else if (this.selectedStep.step !== this.stepNumberBeforeEdit) {
      this.blp = this.blpManagementService.reorderSteps(this.blp, this.selectedStep, this.stepNumberBeforeEdit);
    }
    // else parcel already modified on blp

    this.calculateBlp();
    this.refreshTableData();
    if (this.selectedStep.isExistingCargo) {
      this.refreshColumnSettings();
    }

    this.selectedStep = null;
    this.showStepEdit = false;
    this.isNewStep = false;
    this.isExistingCargoStep = false;
  }

  deleteStep(stepNumber: number) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to remove Step Number: ' + stepNumber,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.blpManagementService.removeStep(this.blp, stepNumber);
        this.calculateBlp();
        this.refreshColumnSettings();
        this.refreshTableData();
      }
    });
  }

  setOverrides() {
    this.showOverridesEdit = true;
  }

  onOverridesCanceled() {
    this.showOverridesEdit = false;
  }

  onOverridesSaved() {
    this.calculateBlp();
    this.refreshTableData();
    // Leave overrides list dialog open until canceled/closed
    // this.showOverridesEdit = false;
  }

  calculateBlp() {
    this.blp = this.blpManagementService.calculateBlp(this.blp);
  }

  refreshColumnSettings() {
    this.largeScreenCols = this.buildLargeScreenCols(this.blp?.parcels, this.blp?.existingCargo);
    this.mobileCols = this.buildMobileCols(this.blp?.parcels, this.blp?.existingCargo);
  }

  refreshTableData() {
    this.tableData = this.blpManagementService.buildTableData(this.blp, this.shoreTanks);
  }

  buildLargeScreenCols(parcels: BlpParcel[], existingCargo: BlpLineItem = null): any[] {
    const cols: any[] = [];
    if (parcels) {
      const columnDefs = this.blpManagementService.buildBlpTableColumns(parcels, existingCargo);
      columnDefs.forEach(c => {
        cols.push({
          field: c.name,
          header1: c.lineText1,
          header2: c.lineText2,
          header3: c.lineText3,
          header4: c.lineText4,
          header5: c.lineText5,
          showHeader2Icon: c.showLineIcon2,
          class1: c.class1,
          class2: c.class2,
          class3: c.class3,
          class4: c.class4,
          class5: c.class5,
          width: c.width,
          allowOverflow1: c.allowOverflow1,
          allowOverflow2: c.allowOverflow2,
          allowOverflow3: c.allowOverflow3,
          allowOverflow4: c.allowOverflow4,
          allowOverflow5: c.allowOverflow5,
        });
      })
    }

    return cols;
  }

  buildMobileCols(parcels: BlpParcel[], existingCargo: BlpLineItem = null): any[] {
    const cols: any[] = [];
    if (parcels) {
      const columnDefs = this.blpManagementService.buildBlpTableColumns(parcels, existingCargo).filter(c => c.showOnMobile);
      columnDefs.forEach(c => {
        cols.push({
          field: c.name,
          header1: c.lineText1,
          header2: c.lineText2,
          header3: c.lineText3,
          header4: c.lineText4,
          header5: c.lineText5,
          class1: c.class1,
          class2: c.class2,
          class3: c.class3,
          class4: c.class4,
          class5: c.class5
        });
      })
    }

    return cols;
  }

  showPushClass(stepNumber: number, parcelIndex: number): boolean {
    const parcel = this.blp.parcels[parcelIndex];
    if (parcel) {
      const step = this.blp.allLineItems.find(l => l.step === stepNumber);
      if (step) {
        return step.nominatedQuantities.find(n => n.parcelNumber === (parcelIndex + 1))?.isPush;
      }
    }
    return false;
  }

  showDebtedClass(stepNumber: number, lineAdjustment: number) {
    if (stepNumber && lineAdjustment) {
      const lineItem = this.blp.allLineItems.find(l => l.step === stepNumber);
      return !this.blpManagementService.getPushParcelNumber(lineItem);
    }
  }

  getNominationTotal(parcelNumber: number): number {
    return this.blp.nominatedQuantityTotals.find(t => t.parcelOrStepNumber === parcelNumber)?.value;
  }

  getAdjustedTotal(parcelNumber: number): number {
    return this.blp.adjustedQuantityTotals.find(t => t.parcelOrStepNumber === parcelNumber)?.value;
  }

  getFinalApi(parcelNumber: number): number {
    return this.blp.parcelFinalApi.find(t => t.parcelOrStepNumber === parcelNumber)?.value;
  }

  getAdjustedQuantityTotalUnbalanced(parcelNumber: number) {
    const nomParcelTotal = this.blp.nominatedQuantityTotals.find(n => n.parcelOrStepNumber === parcelNumber);
    const adjustedParcelTotal = this.blp.adjustedQuantityTotals.find(a => a.parcelOrStepNumber === parcelNumber);
    return nomParcelTotal.value !== adjustedParcelTotal.value;
  }

  get unbalancedTotal(): boolean {
    let nomTotal: number = 0;
    this.blp.nominatedQuantityTotals.forEach(parcelNomTotal => {
      nomTotal += parcelNomTotal.value;
    });
    return nomTotal !== this.blp.allStepTotal;
  }

  get unbalanced(): boolean {
    let errorFound = false;
    this.blp.nominatedQuantityTotals.forEach(parcelNomTotal => {
      errorFound = this.getAdjustedQuantityTotalUnbalanced(parcelNomTotal.parcelOrStepNumber);
    });
    return errorFound || this.unbalancedTotal;
  }
}
