import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { SelectItem } from "primeng/api";
import { JobVdp, User, VdpLineItem, VdpLineItemMeasurement, VdpShoreTank, VcfCalculations, Tank, JobBlpRevision, BlpParcel } from "../models";
import { VdpStorageEntryOptions } from "../models/job/vdp/job-vdp";
import { VdpColumnDefinition, VdpColumnStyleClasses } from "../models/job/vdp/vdp-column-definition";
import { VesselSide } from "../models/job/vdp/vdp-line-item";
import { CalculationReferenceService } from "./calculation-reference.service";


@Injectable({
    providedIn: 'root'
})
export class VdpManagementService {
    constructor(private calculationService: CalculationReferenceService) {}

    buildVdpFromBlp(blp: JobBlpRevision, currentVdps: JobVdp[]): JobVdp[] {
        if (this.haveParcelsChanged(blp, currentVdps) ||
            this.haveStepsChanged(blp, currentVdps)) {
            blp.parcels.forEach(p => {
                let existingVdp = currentVdps.find(v => v.parcelNumber === p.parcelNumber);
                if (!existingVdp || this.hasSingleVdpShoreTanksChanged(blp, existingVdp)) {
                    let newVdp = existingVdp ? existingVdp : new JobVdp(blp.jobId, p.parcelNumber, p.product);
                    // VDP is special case where existing cargo line item is included (allLineItems vs lineItems)
                    blp.allLineItems.forEach(lineItem => {
                        const nominated = lineItem.nominatedQuantities.find(n => n.parcelNumber === p.parcelNumber);
                        if (nominated && (lineItem.isExistingCargo || !nominated.isPush)) {
                            const gsv = nominated.value;
                            if (gsv) {
                                const tankId = lineItem.isExistingCargo ? 'OBQ' : lineItem.tankId;
                                let shoreTank = newVdp.shoreTanks.find(s => s.tankId === tankId);
                                if (!shoreTank) {
                                shoreTank = new VdpShoreTank(
                                    newVdp.id,
                                    lineItem.step,
                                    tankId,
                                    lineItem.apiNumber,
                                    gsv);
                                    newVdp.shoreTanks.push(shoreTank);
                                } else {
                                // Update shore tank as the BLP could have changed
                                    shoreTank.step = lineItem.step;
                                    shoreTank.tankId = tankId;
                                    shoreTank.apiNumber = lineItem.apiNumber;
                                    shoreTank.gsv = gsv;
                                }
                            }
                        }
                    });
                    newVdp = this.manageVdpLineItemsFromVesselTanks(p, newVdp);
                    // Replace existing vdp with new vdp in case of changes otherwise add
                    if (existingVdp) {
                        existingVdp = newVdp;
                    } else {
                        currentVdps.push(newVdp);
                    }
                } else {
                    existingVdp = this.manageVdpLineItemsFromVesselTanks(p, existingVdp);
                }
            });
        }
        return currentVdps;
    }

    buildStorageEntryOptions(): SelectItem[] {
        return [
            
            { label: VdpStorageEntryOptions.tov, value: VdpStorageEntryOptions.tov },
            { label: VdpStorageEntryOptions.mt, value: VdpStorageEntryOptions.mt },
            { label: VdpStorageEntryOptions.gsv, value: VdpStorageEntryOptions.gsv }
        ];
    }

    buildVdpTableColumns(vdpShoreTanks: VdpShoreTank[] = [], storageEntry: string, shoreTanks: Tank[]): VdpColumnDefinition[] {
        const cols: VdpColumnDefinition[] = [];

        // Action Column
        const actionCol = new VdpColumnDefinition();
        actionCol.name = 'actions';
        actionCol.lineText9 = 'Actions';
        actionCol.class9 = VdpColumnStyleClasses.vesselTankHeader.valueOf();
        actionCol.showOnMobile = true;
        actionCol.width= '60px';
        cols.push(actionCol);

        const tankIdCol = new VdpColumnDefinition();
        tankIdCol.name = 'tankId';
        tankIdCol.lineText9 = 'COT';
        tankIdCol.class9 = VdpColumnStyleClasses.vesselTankHeader.valueOf();
        tankIdCol.showOnMobile = true;
        tankIdCol.width = '60px';
        cols.push(tankIdCol);

        const tovCol = new VdpColumnDefinition();
        tovCol.name = 'tov';
        tovCol.lineText9 = 'TOV';
        tovCol.class9 = (storageEntry === VdpStorageEntryOptions.tov) ? VdpColumnStyleClasses.vesselTankHighlightedHeader.valueOf() : VdpColumnStyleClasses.vesselTankHeader.valueOf();
        tovCol.width = '60px';
        cols.push(tovCol);

        const mtCol = new VdpColumnDefinition();
        mtCol.name = 'mt';
        mtCol.lineText9 = 'MT';
        mtCol.class9 = (storageEntry === VdpStorageEntryOptions.mt) ? VdpColumnStyleClasses.vesselTankHighlightedHeader.valueOf() : VdpColumnStyleClasses.vesselTankHeader.valueOf();
        mtCol.showOnMobile = true;
        mtCol.width = '60px';
        cols.push(mtCol);

        const gsvCol = new VdpColumnDefinition();
        gsvCol.name = 'gsv';
        gsvCol.lineText9 = 'GSV';
        gsvCol.class9 = (storageEntry === VdpStorageEntryOptions.gsv) ? VdpColumnStyleClasses.vesselTankHighlightedHeader.valueOf() : VdpColumnStyleClasses.vesselTankHeader.valueOf();
        gsvCol.showOnMobile = true;
        gsvCol.width = '60px';
        cols.push(gsvCol);        

        const pctCol = new VdpColumnDefinition();
        pctCol.name = 'pct';
        pctCol.lineText1 = 'Shore Tank #';
        pctCol.lineText2 = 'GSV';
        pctCol.lineText3 = 'API';
        pctCol.lineText4 = 'TEMP';
        pctCol.lineText5 = 'VCF';
        pctCol.lineText6 = 'TOV';
        pctCol.lineText7 = '% WT';
        pctCol.lineText9 = '%';
        pctCol.class9 = VdpColumnStyleClasses.vesselTankHeader.valueOf();
        pctCol.width = '60px';
        cols.push(pctCol);

        // Shore Tank Columns
        let maxStep = 0;
        let minStep = 0;
        vdpShoreTanks.forEach(t => {
            maxStep = Math.max(maxStep, t.step);
            minStep = Math.min(minStep, t.step);
        });
        for(let i = minStep; i <= maxStep; i++) {
            const shoreTankCol = new VdpColumnDefinition();
            shoreTankCol.step = i;
            const targetShoreTank = vdpShoreTanks.find(t => t.step === i);
            if (targetShoreTank) {
                var shoreTank = shoreTanks.find(t => t.id == targetShoreTank.tankId);
                shoreTankCol.lineText1 = shoreTank?.name;
                shoreTankCol.lineText2 = targetShoreTank.gsv.toString();
                shoreTankCol.lineText3 = targetShoreTank.apiNumber.toString();
                shoreTankCol.lineText4 = targetShoreTank.temp.toString();
                shoreTankCol.lineText5 = targetShoreTank.vcf.toFixed(4); 
                shoreTankCol.lineText6 = Math.round(targetShoreTank.tov).toString();
                shoreTankCol.lineText7 = (targetShoreTank.pctWt * 100).toFixed(2);
                shoreTankCol.lineText8 = "STOP # " + targetShoreTank.step.toString();
                shoreTankCol.lineText9 = "M3  G/Bbbls"; // made to be static in template with split9 property
                shoreTankCol.split9 = true;
                shoreTankCol.showLineIcon2 = true;
                shoreTankCol.class1 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class2 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class3 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class4 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class5 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class6 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class7 = VdpColumnStyleClasses.shoreTankHeader.valueOf();
                shoreTankCol.class8 = VdpColumnStyleClasses.stopHeader.valueOf();
                shoreTankCol.class9 = VdpColumnStyleClasses.stopHeader.valueOf();
                shoreTankCol.showInputs = (shoreTankCol.step > 0) || (targetShoreTank.tankId === 'OBQ');
                cols.push(shoreTankCol);
            }
        }

        return cols;
    }

    // Translate VDP Data model to flat table to display in table control
    buildTableData(vdp: JobVdp, tanks: Tank[]): any[] {
        const tableData: any[] = [];

        if (vdp && vdp.lineItems) {
            vdp.lineItems.forEach(l => {
                // this is only if TOV is the source value;
                //let mt = firstColValue*@T6B(CalculatedApi,CalculatedTemp,0)*@Tables13(CalculatedApi);
                // console.log('buildTableData lineItem', l);
                var tank = tanks.find(t => l.tankId == t.id);
                const tableLineItem: any = {
                    'tankInfo': tank.name,
                    'tankId': tank.id,
                    'tov': l.tov ? l.tov.toFixed(1) : '',
                    'mt': l.mt ? l.mt.toFixed(1) : '', 
                    'gsv': l.gsv ? l.gsv.toFixed(1) : '',
                    'pct': l.pct ? (l.pct * 100).toFixed(2) : ''
                };
                if (vdp.shoreTanks) {
                    let maxStep = 0;
                    let minStep = 0;
                    vdp.shoreTanks.forEach(t => {
                        maxStep = Math.max(maxStep, t.step);
                        minStep = Math.min(minStep, t.step);
                    });
                    // console.log('measurements', l.measurements);
                    if (l.measurements) {
                        for(let i = minStep; i <= maxStep; i++) {
                            const measurement = l.measurements.find(m => m.step === i);
                            if (measurement) {
                                tableLineItem['shoreTankSiVol' + i.toString()] = Math.round(measurement.value); 
                                tableLineItem['shoreTankMetricVol' + i.toString()] = Math.round(measurement.value / 6.28981); 
                            }
                        }
                    }
                }

                tableData.push(tableLineItem);
            });

            tableData.sort((a,b) => a.tankInfo > b.tankInfo ?  1 :  -1);
        }

        return tableData;
    }

    calculateVdp(vdp: JobVdp): JobVdp {
        let totalGsv = 0; // sum of gsv for all shore tanks
        let totalCorrectedWeight = 0; // sum of correctedWeight for all shore tanks
        
        vdp.calculatedApi = 0;
        vdp.calculatedTemp = 0;
        vdp.calculatedGsv = 0;
        vdp.calculatedTov = 0;
        vdp.calculatedMt = 0;
        vdp.calculatedLt = 0;

        for (let shoreTank of vdp.shoreTanks) {
            totalGsv += shoreTank.gsv;
            shoreTank.correctedWeight = this.calculationService.getAstmTable13Wcf(shoreTank.apiNumber) * shoreTank.gsv;
            totalCorrectedWeight += shoreTank.correctedWeight;
            if (!shoreTank.vcfCalculation) shoreTank.vcfCalculation = VcfCalculations.t6b;
            switch (shoreTank.vcfCalculation) {
                case VcfCalculations.t6a : {
                    shoreTank.vcf = this.calculationService.getAstmT6aVcf(shoreTank.apiNumber, shoreTank.temp, 0);
                    break;
                }
                case VcfCalculations.t6b : {
                    shoreTank.vcf = this.calculationService.getAstmT6bVcf(shoreTank.apiNumber, shoreTank.temp, 0);
                    break;
                }
                case VcfCalculations.t6c : {
                    shoreTank.vcf = this.calculationService.getAstmT6cVcf(shoreTank.temp);
                    break;
                }
            }
            shoreTank.tov = shoreTank.gsv / shoreTank.vcf;            
        }

        for (let shoreTank of vdp.shoreTanks) {
            shoreTank.pctWt = shoreTank.correctedWeight / totalCorrectedWeight; 
            shoreTank.weightedApi = shoreTank.pctWt * shoreTank.apiNumber; 
            shoreTank.weightedTemp = shoreTank.pctWt * shoreTank.temp; 
            shoreTank.pctApi = shoreTank.apiNumber * (shoreTank.gsv / totalGsv);
            vdp.calculatedApi += shoreTank.pctApi;
            vdp.calculatedTemp += shoreTank.weightedTemp;
            vdp.calculatedGsv += shoreTank.gsv;
            vdp.calculatedTov += shoreTank.tov;
        }
        
        vdp.calculatedMt = this.calculationService.getAstmTable13Wcf(vdp.calculatedApi) * vdp.calculatedGsv;
        vdp.calculatedLt = this.calculationService.getAstmTable11Wcf(vdp.calculatedApi) * vdp.calculatedGsv;

        let maxStep = 0;
        let minStep = 0;
        vdp.shoreTanks.forEach(t => {
            maxStep = Math.max(maxStep, t.step);
            minStep = Math.min(minStep, t.step);
        });
        for (let lineItem of vdp.lineItems) {
            switch (vdp.storageEntry) {
                case VdpStorageEntryOptions.tov: {
                    lineItem.mt = lineItem.tov * this.calculationService.getAstmT6bVcf(vdp.calculatedApi, vdp.calculatedTemp) * this.calculationService.getAstmTable13Wcf(vdp.calculatedApi);
                    lineItem.gsv = lineItem.tov * this.calculationService.getAstmT6bVcf(vdp.calculatedApi, vdp.calculatedTemp);
                    break;
                }
                case VdpStorageEntryOptions.mt: {
                    lineItem.tov = (lineItem.mt / this.calculationService.getAstmTable13Wcf(vdp.calculatedApi)) / this.calculationService.getAstmT6bVcf(vdp.calculatedApi, vdp.calculatedTemp);
                    lineItem.gsv = lineItem.tov * this.calculationService.getAstmT6bVcf(vdp.calculatedApi, vdp.calculatedTemp);
                    break;
                }
                case VdpStorageEntryOptions.gsv: {
                    lineItem.tov = lineItem.gsv / this.calculationService.getAstmT6bVcf(vdp.calculatedApi, vdp.calculatedTemp);
                    lineItem.mt = lineItem.tov * this.calculationService.getAstmT6bVcf(vdp.calculatedApi, vdp.calculatedTemp) * this.calculationService.getAstmTable13Wcf(vdp.calculatedApi);
                    break;
                }
                default: {
                    console.log('ERROR----------------Storage Entry----------------', vdp.storageEntry);
                }
            }
        }

        vdp.gsvTotal = 0;
        vdp.mtTotal = 0;
        vdp.tovTotal = 0;
        vdp.pctTotal = 0;
        vdp.lineItems.forEach(i => {
            vdp.gsvTotal += isNaN(i.gsv) ? 0 : i.gsv;
            vdp.mtTotal += isNaN(i.mt) ? 0 : i.mt;
            vdp.tovTotal += isNaN(i.tov) ? 0 : i.tov;
            
        });

        for (let lineItem of vdp.lineItems) {
            lineItem.pct = lineItem.tov / vdp.tovTotal;
            vdp.pctTotal += lineItem.pct * 100;

            lineItem.measurements = [];
            for (let i = minStep; i <= maxStep; i++) {
                const shoreTank = vdp.shoreTanks.find(t => t.step === i);
                if (shoreTank) {
                    const tankSiVol = new VdpLineItemMeasurement();
                    tankSiVol.step = i;
                    // TankSiVol = tov*pct and accumulate for each shore tank
                    if (tankSiVol.step > 1) {
                        const prevTankSiVols = lineItem.measurements.filter(m => m.step < tankSiVol.step);
                        if (prevTankSiVols) {
                            const lastTank = prevTankSiVols.sort((a,b) => a.step > b.step ? -1 : 1)[0];
                            tankSiVol.value = lastTank ? shoreTank.tov * lineItem.pct + lastTank.value : shoreTank.tov * lineItem.pct;
                        }
                    } else {
                        tankSiVol.value = shoreTank.tov * lineItem.pct;
                    }
                    lineItem.measurements.push(tankSiVol);
                }
            }
        }
        
        return vdp;
    }

    private haveParcelsChanged(blp: JobBlpRevision, vdps: JobVdp[]): boolean {
        if (blp && vdps) {
            return blp.parcels.length !== vdps.length;
        } else {
            return false;
        }
    }
    
    private haveStepsChanged(blp: JobBlpRevision, vdps: JobVdp[]): boolean {
        let mismatchFound = false;
        if (blp && vdps) {
            vdps.forEach(v => {
                if (this.hasSingleVdpShoreTanksChanged(blp, v)) {
                   mismatchFound = true;
                }
            });
            return mismatchFound;
        } else {
            return mismatchFound;
        }
    }
    
    private hasSingleVdpShoreTanksChanged(blp: JobBlpRevision, vdp: JobVdp) {
        const targetShoreTanks = blp.allLineItems.filter(l => 
          l.isExistingCargo ||
          l.nominatedQuantities.some(n => n.value && n.parcelNumber === vdp.parcelNumber && !n.isPush)
        );
        const missingTargets = targetShoreTanks.some(t => !vdp.shoreTanks.some(vSt => vSt.tankId === t.tankId));
        const shoreTankToBeRemoved = vdp.shoreTanks.some(vst => !targetShoreTanks.some(t => t.tankId === vst.tankId));
        return missingTargets || shoreTankToBeRemoved;
    }

    private manageVdpLineItemsFromVesselTanks(targetBlpParcel: BlpParcel, vdp: JobVdp): JobVdp {
        if (targetBlpParcel && vdp) {
            if (!vdp.lineItems) {
                vdp.lineItems = [];
            }
            vdp = this.removeVesselTanksIfNeeded(vdp, targetBlpParcel);
            vdp = this.addVesselTanksIfNeeded(vdp, targetBlpParcel);
        }
        return vdp;
    }

    private removeVesselTanksIfNeeded(vdp: JobVdp, blpParcel: BlpParcel): JobVdp {
        const vesselTanks:string[] = blpParcel.vesselTank ? blpParcel.vesselTank.split(',') : [];
        vdp.lineItems = vdp.lineItems.filter(l => vesselTanks.some(t => t === l.tankId));
        return vdp;
    }

    private addVesselTanksIfNeeded(vdp: JobVdp, blpParcel: BlpParcel): JobVdp {
        const vesselTanks:string[] = blpParcel.vesselTank ? blpParcel.vesselTank.split(',') : [];
        vesselTanks.forEach(t => {
            if (!vdp.lineItems.some(l => l.tankId === t)) {
                const newLineItem = new VdpLineItem();
                newLineItem.tankId = t;
                vdp.lineItems.push(newLineItem);
            }
        });
        return vdp;
    }

}
