import { AuditableEntity } from "../auditable-entity";
import { Terminal } from "./terminal";

export class Dock extends AuditableEntity {
    terminal: Terminal;
    name: string;

    constructor() {
        super();
    }
}