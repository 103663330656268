import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

// PrimeNg Resoures
import { DataViewModule } from 'primeng/dataview';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { SkeletonModule } from 'primeng/skeleton';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollTopModule } from 'primeng/scrolltop';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { GMapModule } from 'primeng/gmap';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { BlockUIModule } from 'primeng/blockui';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';

import { TerminalListComponent } from './system-config/terminals/terminal-list/terminal-list.component';
import { TerminalDetailComponent } from './system-config/terminals/terminal-detail/terminal-detail.component';
import { ContactComponent } from './system-config/contacts/contact.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ColDisplayFilter } from './pipes/column-display-filter.pipe';

import { JobListComponent } from './job/job-list/job-list.component';
import { JobDetailComponent } from './job/job-detail/job-detail.component';
import { JobOverviewComponent } from './job/job-detail/job-overview/job-overview.component';
import { JobBlpComponent } from './job/job-detail/job-blp/job-blp.component';
import { JobTimeTrackingComponent } from './job/job-detail/job-time-tracking/job-time-tracking.component';
import { JobTimeVdpComponent } from './job/job-detail/job-time-vdp/job-time-vdp.component';
import { JobBfrComponent } from './job/job-detail/job-bfr/job-bfr.component';
import { JobBouComponent } from './job/job-detail/job-bou/job-bou.component';
import { JobDetailSelectorComponent } from './job/job-detail/job-detail-selector/job-detail-selector.component';

// NgRx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducer } from './state/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './state/app.effects';

// MSAL
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { b2cPolicies, apiConfig } from './b2c-config';
import { HomeComponent } from './home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { PortsListComponent } from './system-config/ports/ports-list/ports-list.component';
import { PortDetailComponent } from './system-config/ports/port-detail/port-detail.component';
import { UserListComponent } from './system-config/users/user-list/user-list.component';
import { UserDetailComponent } from './system-config/users/user-detail/user-detail.component';
import { ClientListComponent } from './system-config/client-companies/client-list/client-list.component';
import { ClientDetailComponent } from './system-config/client-companies/client-detail/client-detail.component';
import { DocksListComponent } from './system-config/docks/docks-list/docks-list.component';
import { DockDetailComponent } from './system-config/docks/dock-detail/dock-detail.component';
import { InspectionCompaniesListComponent } from './system-config/inspection-companies/inspection-companies-list/inspection-companies-list.component';
import { InspectionCompanyDetailComponent } from './system-config/inspection-companies/inspection-company-detail/inspection-company-detail.component';
import { TimeTrackingListComponent } from './system-config/time-tracking/time-tracking-list/time-tracking-list.component';
import { TimeTrackingDetailComponent } from './system-config/time-tracking/time-tracking-detail/time-tracking-detail.component';
import { JobBlpRevisionManagerComponent } from './job/job-detail/job-blp/job-blp-revision-manager/job-blp-revision-manager.component';
import { JobBlpDetailComponent } from './job/job-detail/job-blp/job-blp-detail/job-blp-detail.component';
import { JobBlpHeaderComponent } from './job/job-detail/job-blp/job-blp-header/job-blp-header.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { JobBlpParcelComponent } from './job/job-detail/job-blp/job-blp-parcel/job-blp-parcel.component';
import { JobBlpStepComponent } from './job/job-detail/job-blp/job-blp-step/job-blp-step.component';
import { JobBlpMeasurementComponent } from './job/job-detail/job-blp/job-blp-measurement/job-blp-measurement.component';
import { JobBlpOverridesComponent } from './job/job-detail/job-blp/job-blp-overrides/job-blp-overrides.component';
import { JobBlpOverridesDetailComponent } from './job/job-detail/job-blp/job-blp-overrides/job-blp-overrides-detail/job-blp-overrides-detail.component';
import { JobTimeTrackingDetailComponent } from './job/job-detail/job-time-tracking/job-time-tracking-detail/job-time-tracking-detail.component';
import { JobVdpComponent } from './job/job-detail/job-vdp/job-vdp.component';
import { JobVdpVesselTankComponent } from './job/job-detail/job-vdp/job-vdp-vessel-tank/job-vdp-vessel-tank.component';
import { ParcelSortPipe } from './pipes/parcel-sort.pipe';
import { JobBfrTankDetailComponent } from './job/job-detail/job-bfr/job-bfr-tank-detail/job-bfr-tank-detail.component';
import { JobBfrSummaryComponent } from './job/job-detail/job-bfr/job-bfr-summary/job-bfr-summary.component';
import { JobBfrHeaderComponent } from './job/job-detail/job-bfr/job-bfr-header/job-bfr-header.component';
import { BlockableDivComponent } from './common/blockable-div/blockable-div.component';
import { JobVdpDetailComponent } from './job/job-detail/job-vdp/job-vdp-detail/job-vdp-detail.component';
import { JobVdpManagerComponent } from './job/job-detail/job-vdp/job-vdp-manager/job-vdp-manager.component';
import { VdpShoreTanksFilterAndOrderPipe } from './pipes/vdp-shoretanks-order-filter.pipe';
import { JobBouHeaderComponent } from './job/job-detail/job-bou/job-bou-header/job-bou-header.component';
import { JobBouKeyTimesComponent } from './job/job-detail/job-bou/job-bou-key-times/job-bou-key-times.component';
import { JobBouShoreBargeReportingComponent } from './job/job-detail/job-bou/job-bou-shore-barge-reporting/job-bou-shore-barge-reporting.component';
import { JobBouVesselReportingComponent } from './job/job-detail/job-bou/job-bou-vessel-reporting/job-bou-vessel-reporting.component';
import { JobBouEstimationDetailComponent } from './job/job-detail/job-bou/job-bou-estimation-detail/job-bou-estimation-detail.component';
import { JobBouActualDetailComponent } from './job/job-detail/job-bou/job-bou-actual-detail/job-bou-actual-detail.component';
import { JobBouVesselReportingDetailComponent } from './job/job-detail/job-bou/job-bou-vessel-reporting-detail/job-bou-vessel-reporting-detail.component';

// Configure Plotly Module to use imported PlotlyJs library
// PlotlyModule.plotlyjs = PlotlyJS;
// There is an issue using the library directly into the normal bundle and ES6 modules
// Instead load the library via CDN
// https://github.com/plotly/angular-plotly.js/issues/75
import { PlotlyViaCDNModule } from 'angular-plotly.js';
import { AppConfigService } from './services/app-config.service';
import { ApplicationInsightsService } from './services/application-insights.service';
import { TankListComponent } from './system-config/tanks/tank-list/tank-list.component';
import { TankDetailComponent } from './system-config/tanks/tank-detail/tank-detail.component';
import { ShipListComponent } from './system-config/ships/ship-list/ship-list.component';
import { ShipDetailComponent } from './system-config/ships/ship-detail/ship-detail.component';
import { DashboardComponent } from './reports/dashboard/dashboard.component';
import { JobPerformanceComponent } from './reports/job-performance/job-performance.component';
import { JobTrendsComponent } from './reports/job-trends/job-trends.component';
import { TerminalComparisonComponent } from './reports/terminal-comparison/terminal-comparison.component';
import { TimingCategoryDetailComponent } from './system-config/timing-categories/timing-category-detail/timing-category-detail.component';
import { TimingCategoryListComponent } from './system-config/timing-categories/timing-category-list/timing-category-list.component';
import { CustomerJobPerformanceComponent } from './reports/customer-job-performance/customer-job-performance.component';
import { SuperintendentListStatusComponent } from './common/superintendent-list-status/superintendent-list-status.component';
import { UserProfileComponent } from './system-config/users/user-profile/user-profile.component';
import { JobBoardComponent } from './job/job-board/job-board.component';
import { ProductListComponent } from './system-config/products/product-list/product-list.component';
import { ProductDetailComponent } from './system-config/products/product-detail/product-detail.component';
import { CustomerMainComponent } from './customer/main/main.component';
import { CustomerJobsListComponent } from './customer/jobs-list/jobs-list.component';
import { CustomerJobsDetailComponent } from './customer/jobs-detail/jobs-detail.component';
import { KpiComponent } from './customer/kpi/kpi.component';
import { CustomerFeedbackComponent } from './customer/feedback/feedback.component';

PlotlyViaCDNModule.setPlotlyVersion('latest');

export function loadConfigService(configService: AppConfigService): Function {
  return () => configService.load();
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message); <-- leave in code for debugging if necessary
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'ffd1d7a8-12cc-48d4-976b-22517028c874',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    // authRequest: {
    //   scopes: [...apiConfig.scopes],
    // },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ColDisplayFilter,
    TerminalListComponent,
    TerminalDetailComponent,
    ContactComponent,
    JobListComponent,
    JobDetailComponent,
    JobOverviewComponent,
    JobBlpComponent,
    JobTimeTrackingComponent,
    JobTimeVdpComponent,
    JobBfrComponent,
    JobBouComponent,
    JobDetailSelectorComponent,
    HomeComponent,
    PortsListComponent,
    PortDetailComponent,
    UserListComponent,
    UserDetailComponent,
    ClientListComponent,
    ClientDetailComponent,
    DocksListComponent,
    DockDetailComponent,
    InspectionCompaniesListComponent,
    InspectionCompanyDetailComponent,
    TimeTrackingListComponent,
    TimeTrackingDetailComponent,
    JobBlpRevisionManagerComponent,
    JobBlpDetailComponent,
    JobBlpHeaderComponent,
    JobBlpParcelComponent,
    JobBlpStepComponent,
    JobBlpMeasurementComponent,
    JobTimeTrackingDetailComponent,
    JobVdpComponent,
    JobVdpVesselTankComponent,
    ParcelSortPipe,
    JobBfrTankDetailComponent,
    JobBfrSummaryComponent,
    JobBfrHeaderComponent,
    BlockableDivComponent,
    JobVdpDetailComponent,
    JobVdpManagerComponent,
    JobBouHeaderComponent,
    JobBouKeyTimesComponent,
    JobBouShoreBargeReportingComponent,
    JobBouVesselReportingComponent,
    JobBouEstimationDetailComponent,
    JobBouActualDetailComponent,
    JobBouVesselReportingDetailComponent,
    VdpShoreTanksFilterAndOrderPipe,
    JobBlpOverridesComponent,
    JobBlpOverridesDetailComponent,
    TankListComponent,
    TankDetailComponent,
    ShipListComponent,
    ShipDetailComponent,
    DashboardComponent,
    JobPerformanceComponent,
    JobTrendsComponent,
    TerminalComparisonComponent,
    TimingCategoryDetailComponent,
    TimingCategoryListComponent,
    CustomerJobPerformanceComponent,
    SuperintendentListStatusComponent,
    UserProfileComponent,
    JobBoardComponent,
    ProductListComponent,
    ProductDetailComponent,
    CustomerMainComponent,
    CustomerJobsListComponent,
    CustomerJobsDetailComponent,
    KpiComponent,
    CustomerFeedbackComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MsalModule,

    // PrimeNg Resources
    DataViewModule,
    MenubarModule,
    InputTextModule,
    InputNumberModule,
    ScrollPanelModule,
    TableModule,
    ButtonModule,
    MenuModule,
    PanelModule,
    SkeletonModule,
    CardModule,
    ConfirmDialogModule,
    ScrollTopModule,
    TooltipModule,
    DropdownModule,
    CalendarModule,
    ChipModule,
    GMapModule,
    DialogModule,
    ProgressBarModule,
    MultiSelectModule,
    InputMaskModule,
    BlockUIModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    AccordionModule,
    DividerModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    TabViewModule,
    ToolbarModule,
    SplitButtonModule,
    SelectButtonModule,
    ChartModule,
    CheckboxModule,
    RadioButtonModule,

    // NgRx Resources
    StoreModule.forRoot({ app: reducer }),
    StoreDevtoolsModule.instrument({
      name: 'CDP NgRx DevTools',
      maxAge: 25,
      logOnly: environment.production // when in prod use logOnly mode
    }),
    EffectsModule.forRoot([AppEffects]),

    // PWA Resources
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately'  }),

    // Plotly
    PlotlyViaCDNModule
  ],
  providers: [
    AppConfigService,
    { provide: APP_INITIALIZER, useFactory: loadConfigService, deps: [AppConfigService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ConfirmationService,
    MessageService,
    DatePipe,
    DecimalPipe,
    ApplicationInsightsService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
