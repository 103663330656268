import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { merge, Observable, of, interval, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { Ship } from 'src/app/models/system-config/ship';
import { ConfirmationService } from 'primeng/api';
import * as cloneDeep from 'lodash.clonedeep';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-ship-list',
  templateUrl: './ship-list.component.html',
  styleUrls: ['./ship-list.component.scss']
})
export class ShipListComponent implements OnInit {

  cols: any[] = [];
  skeletonRows: any[] = [];
  ships: Ship[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedShip: Ship;
  infoWindow: any;
  showEditDialog = false;

  constructor(protected httpClient: HttpClient,
    private actions$: Actions,
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForShipStateChanges();
    this.listenForShipUpdated();
    this.subscriptions.push(interval(10000).subscribe(
      (val) => { this.store.dispatch(appActions.refreshSystemConfiguration()); }
    ))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4];
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'imoNo', header: 'IMO #', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Active', display: false, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  listenForShipStateChanges() {
    // TODO - get from state once we have a working model for populating state from API
    this.subscriptions.push(
      this.store.pipe(select(appState.getShips))
      .subscribe(results => { 
        if(results){
          this.ships = [...results];
        }
        this.loaded = true;
      })
    );
  }

  listenForShipUpdated(){
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(appActions.setShipSuccess))
        .subscribe(() => this.showEditDialog = false)
    );
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addShip() {
    this.selectedShip = new Ship();
    this.showEditDialog = true;
  }

  editShip(ship: Ship) {
   this.selectedShip = cloneDeep(ship);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateShip(ship: Ship) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Ship: ' + ship.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeShip: Ship = { ...ship, active: false };
        this.store.dispatch(appActions.setShip({ data: activeShip }));
      }
    });
  }

  activateShip(ship: Ship) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Ship: ' + ship.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const inactiveShip: Ship = { ...ship, active: true };
        this.store.dispatch(appActions.setShip({ data: inactiveShip }));
      }
    });
  }
  
  rowClicked() {
    if (this.selectedShip) {
      const content = '<div class="p-grid>"' +
      '<div class="p-col-12">' +
      '<h3>' + this.selectedShip.name + ' ' + this.selectedShip.imoNo + '</h3>' +
      '</div>';
      this.infoWindow.setContent(content);
    }    
  }

  updateShip(ship: Ship) {
    this.store.dispatch(appActions.setShip({ data: ship }));
    // this.showEditDialog = false;
  }

  async resetSelectedShip() {
    // this.ships is already subscribed to latest state of ships
    // reset selected ship to state
    this.selectedShip = cloneDeep(this.ships.find(s => s.id === this.selectedShip.id));
  }

}
