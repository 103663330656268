import * as moment from 'moment';
import { AuditableEntity } from "../../auditable-entity";

export class BouEstimationLineItem extends AuditableEntity {
    bouId: string;
    originSwitchHours: number = 0;
    originSwitchMinutes: number = 0;
    tankId: string;
    tankDescription: string;
    startDate: Date;
    rate: number;
    strippingHours: number = 0;
    strippingMinutes: number = 0; 
    durationHours: number = 0;
    durationMinutes: number = 0; 
    completionDate: Date;
    nominated: number = 0;
    isPush: boolean = false;
    blpStep: number;

    constructor() {
        super();
    }

    get originSwitch(): moment.Duration {
        return moment.duration({
            hours: this.originSwitchHours ? this.originSwitchHours : 0,
            minutes: this.originSwitchMinutes ? this.originSwitchMinutes : 0
        });
    }

    get stripping(): moment.Duration {
        return moment.duration({
            hours: this.strippingHours ? this.strippingHours : 0,
            minutes: this.strippingMinutes ? this.strippingMinutes : 0
        });
    }

    get duration(): moment.Duration {
        return moment.duration({
            hours: this.durationHours ? this.durationHours : 0,
            minutes: this.durationMinutes ? this.durationMinutes : 0
        });
    }

    static fromJson(bouEstimationLineItem: BouEstimationLineItem): BouEstimationLineItem {
        const newLineItem: BouEstimationLineItem = new BouEstimationLineItem();

        newLineItem.id = bouEstimationLineItem.id;
        newLineItem.active = bouEstimationLineItem.active;
        newLineItem.createdBy = bouEstimationLineItem.createdBy;
        newLineItem.createdOn = bouEstimationLineItem.createdOn;
        newLineItem.modifiedBy = bouEstimationLineItem.modifiedBy;
        newLineItem.modifiedOn = bouEstimationLineItem.modifiedOn;
        newLineItem.bouId = bouEstimationLineItem.bouId;
        newLineItem.originSwitchHours = bouEstimationLineItem.originSwitchHours;
        newLineItem.originSwitchMinutes = bouEstimationLineItem.originSwitchMinutes;
        newLineItem.tankId = bouEstimationLineItem.tankId;
        newLineItem.tankDescription = bouEstimationLineItem.tankDescription;
        newLineItem.startDate = bouEstimationLineItem.startDate;
        newLineItem.rate = bouEstimationLineItem.rate;
        newLineItem.strippingHours = bouEstimationLineItem.strippingHours;
        newLineItem.strippingMinutes = bouEstimationLineItem.strippingMinutes;
        newLineItem.durationHours = bouEstimationLineItem.durationHours;
        newLineItem.durationMinutes = bouEstimationLineItem.durationMinutes;
        newLineItem.completionDate = bouEstimationLineItem.completionDate;
        newLineItem.nominated = bouEstimationLineItem.nominated;
        newLineItem.isPush = bouEstimationLineItem.isPush;
        newLineItem.blpStep = bouEstimationLineItem.blpStep;

        return newLineItem;
    }
}