<div class="p-grid">
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <div class="p-field p-grid">
                    <label for="storageEntry" class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">Stowage Entry Type</label>
                    <div class="p-col-12 p-md-8">
                        <p-dropdown [options]="entryOptions" appendTo="body" [(ngModel)]="vdp.storageEntry" [disabled]="isEntryOptionsLocked" placeholder="Select Storage Entry Type"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-d-none p-d-md-inline">
                <p-table
                        [value]="tableData"
                        [columns]="largeScreenCols"
                        [responsive]="false"
                        [scrollable]="true"
                        [style]="{width: '100%'}"
                        styleClass="p-datatable-striped"
                        >
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style]="{'width':col.width}">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class1"
                                    [style.overflow-x]="col.allowOverflow1 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow1 ? 'nowrap' : 'inherit'"
                                >{{col.header1}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class2"
                                    [style.overflow-x]="col.allowOverflow2 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow2 ? 'nowrap' : 'inherit'"
                                >{{col.header2}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class3"
                                    [style.overflow-x]="col.allowOverflow3 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow3 ? 'nowrap' : 'inherit'"
                                >{{col.header3}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class4"
                                    [style.overflow-x]="col.allowOverflow4 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow4 ? 'nowrap' : 'inherit'"
                                >
                                    <div *ngIf="!col.showInputs">{{col.header4}}</div>
                                    <input *ngIf="col.showInputs && vdp" type="text" pInputText [ngModel]="getTempByStep(col.step)" (change)="onTempChanged(col.step, $event.target.value)" [style]="{'max-width':'4em'}">
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class5"
                                    [style.overflow-x]="col.allowOverflow5 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow5 ? 'nowrap' : 'inherit'"
                                >
                                    <div *ngIf="!col.showInputs">{{col.header5}}</div>
                                    <div *ngIf="col.showInputs" class="p-grid">
                                        <div class="p-col-6">
                                            <p-dropdown [options]="vcfOptions" appendTo="body" [ngModel]="getVcfFormulaByStep(col.step)" (onChange)="onVcfChanged(col.step, $event.value)" [style]="{'max-width':'3em'}"></p-dropdown>
                                        </div>
                                        <div  class="p-col-6">
                                            {{col.header5}}
                                        </div>
                                    </div>                                    
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class6"
                                    [style.overflow-x]="col.allowOverflow6 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow6 ? 'nowrap' : 'inherit'"
                                >{{col.header6}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class7"
                                    [style.overflow-x]="col.allowOverflow7 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow7 ? 'nowrap' : 'inherit'"
                                >{{col.header7}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class8"
                                    [style.overflow-x]="col.allowOverflow8 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow8 ? 'nowrap' : 'inherit'"
                                >{{col.header8}}</th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns"
                                    [ngClass]="col.class9"
                                    [style.overflow-x]="col.allowOverflow9 ? 'visible' : 'inherit'"
                                    [style.white-space]="col.allowOverflow9 ? 'nowrap' : 'inherit'"
                                >
                                    <div *ngIf="!col.split9">{{col.header9}}</div>
                                    <div *ngIf="col.split9 && (shoreTankColCount <= 4)" class="p-grid">
                                        <div class="p-col-6 p-text-center">M3</div>
                                        <div class="p-col-6 p-text-center">G/Bbbls</div>
                                    </div>
                                    <div *ngIf="col.split9 && (shoreTankColCount > 4)" class="p-grid">
                                        <div class="p-col-12">M3</div>
                                        <div class="p-col-12">G/Bbbls</div>
                                    </div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row>
                            <tr>
                                <td class="table1-data">
                                    <button [disabled]="!isInVdpEditMode" pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editVesselTank(row.tankId)" pTooltip="Edit this Vessel Tank"></button>
                                    <button [disabled]="!isInVdpEditMode" pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-trash" (click)="deleteVesselTank(row.tankId)" pTooltip="Delete this Vessel Tank"></button>
                                </td> 
                                <td class="table1-data">{{row.tankInfo}}</td>
                                <td class="table1-data">{{row.tov | number: '1.1-1'}}</td>
                                <td class="table1-data">{{row.mt | number: '1.1-1'}}</td>
                                <td class="table1-data">{{row.gsv | number: '1.1-1'}}</td>
                                <td class="table1-data">{{row.pct | number: '1.1-1'}}</td>
                                <td *ngFor="let shoreTank of vdp.shoreTanks | vdpShoreTanksFilterAndOrderPipe; index as i">
                                    <!-- <div>{{row['shoreTankMetricVol' + (i+1).toString()]}}</div><div>{{row['shoreTankSiVol' + (i+1).toString()]}}</div> -->
                                    <div *ngIf="shoreTankColCount <= 4" class="p-grid p-col-12">
                                        <div class="p-col-6">
                                            {{row['shoreTankMetricVol' + (shoreTank.step).toString()] | number: '1.1-1'}}
                                        </div>
                                        <div class="p-col-6">
                                            {{row['shoreTankSiVol' + (shoreTank.step).toString()] | number: '1.1-1'}}
                                        </div>
                                    </div>
                                    <div *ngIf="shoreTankColCount > 4" class="p-grid">
                                        <div class="p-col-12">
                                            {{row['shoreTankMetricVol' + (shoreTank.step).toString()] | number: '1.1-1'}}
                                        </div>
                                        <div class="p-col-12">
                                            {{row['shoreTankSiVol' + (shoreTank.step).toString()] | number: '1.1-1'}}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" let-columns>
                            <tr>
                                <td></td>
                                <td class="allow-overflow">Totals:</td>
                                <td>{{vdp.tovTotal | number: '1.1-1'}}</td>
                                <td>{{vdp.mtTotal | number: '1.1-1'}}</td>
                                <td>{{vdp.gsvTotal | number: '1.1-1'}}</td>
                                <td>{{vdp.pctTotal | number: '1.1-1'}}</td>
                                <td *ngFor="let shoreTank of vdp.shoreTanks; index as i">
                                    <!-- <div>{{getShoreTankMetricTotal(i+1)}}</div><div>{{getShoreTankSiTotal(i+1)}}</div> -->
                                    <div *ngIf="shoreTankColCount <= 4" class="p-grid p-col-12">
                                        <div class="p-col-6">
                                            {{getShoreTankMetricTotal(shoreTank.step) | number: '1.1-1'}}
                                        </div>
                                        <div class="p-col-6">
                                            {{getShoreTankSiTotal(shoreTank.step) | number: '1.1-1'}}
                                        </div>
                                    </div>
                                    <div *ngIf="shoreTankColCount > 4" class="p-grid">
                                        <div class="p-col-12">
                                            {{getShoreTankMetricTotal(shoreTank.step) | number: '1.1-1'}}
                                        </div>
                                        <div class="p-col-12">
                                            {{getShoreTankSiTotal(shoreTank.step) | number: '1.1-1'}}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
            <div class="p-md-2 p-col-12 vdp-header-shore">
                Calculated API
            </div>
            <div class="p-md-2 p-col-12 vdp-header-shore">
                Calculated Temp
            </div>
            <div class="p-md-2 p-col-12 vdp-header-shore">
                Total GSV
            </div>
            <div class="p-md-2 p-col-12 vdp-header-shore">
                Total TOV
            </div>
            <div class="p-md-2 p-col-12 vdp-header-shore">
                MT
            </div>
            <div class="p-md-2 p-col-12 vdp-header-shore">
                LT
            </div>
            <div class="p-md-2 p-col-12">
                {{getCalculatedApi() | number: '1.1-1'}}
            </div>
            <div class="p-md-2 p-col-12">
                {{getCalculatedTemp() | number: '1.1-1'}}
            </div>
            <div class="p-md-2 p-col-12">
                {{getCalculatedGsv() | number: '1.1-1'}}
            </div>
            <div class="p-md-2 p-col-12">
                {{getCalculatedTov() | number: '1.1-1'}}
            </div>
            <div class="p-md-2 p-col-12">
                {{getCalculatedMt() | number: '1.1-1'}}
            </div>
            <div class="p-md-2 p-col-12">
                {{getCalculatedLt() | number: '1.1-1'}}
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" [disabled]="!isInVdpEditMode" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" [disabled]="!isInVdpEditMode" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showVesselTankEdit" [(visible)]="showVesselTankEdit" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-vdp-vessel-tank
        [vdp]="vdp"
        [vesselTanks]="vesselTanks"
        [vdpLineItem]="selectedVdpLineItem"
        [isNewVesselTank]="isNewVesselTank"
        (vesselTankEditCanceled)="onVesselTankEditCanceled()"
        (vesselTankEditSaved)="onVesselTankEditSaved($event)"></app-job-vdp-vessel-tank>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showVesselTankEdit" [(visible)]="showVesselTankEdit" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-vdp-vessel-tank
            [vdp]="vdp"
            [vesselTanks]="vesselTanks"
            [vdpLineItem]="selectedVdpLineItem"
            [isNewVesselTank]="isNewVesselTank"
            (vesselTankEditCanceled)="onVesselTankEditCanceled()"
            (vesselTankEditSaved)="onVesselTankEditSaved($event)"></app-job-vdp-vessel-tank>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>