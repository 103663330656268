import { Component, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User, UserRoles, ClientCompany } from 'src/app/models';
import { JobReportService } from 'src/app/services/http-services/job.report.service';
import { JobReportDataService } from 'src/app/services/job-report-data.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as appState from 'src/app/state';
import * as appActions from 'src/app/state/app.actions';
import { TerminalComparisonReport, CategoryTiming, TerminalTimingInfo, TerminalTiming, DowntimeReasonInfo } from 'src/app/models/reports/terminal-comparison-report';
import { JobTrendInfo } from 'src/app/models/reports/terminal-trend-report';
import { TimingInfo } from 'src/app/models/reports/job-performance';

@Component({
  selector: 'app-terminal-comparison',
  templateUrl: './terminal-comparison.component.html',
  styleUrls: ['./terminal-comparison.component.scss']
})
export class TerminalComparisonComponent implements OnInit, OnDestroy {

  currentUser: User = new User();
  report: TerminalComparisonReport;
  period: number = 90;
  subscriptions: Subscription[] = [];
  chartOptions: any;
  downtimeReasonInfos: DowntimeReasonInfo[];
  showDataInfo: boolean;
  showDowntimeDataInfo: boolean;
  dataCaption: string;
  dataInfos: JobTrendInfo[] = [];
  downtimeDataInfos: TimingInfo[] = [];
  chartsOptions: any;
  selectedCustomer: ClientCompany;

  constructor(
    private jobReportService: JobReportService,
    private jobReportDataService: JobReportDataService,
    private dataSharingService: DataSharingService,
    private appMessageService: AppMessageService, 
    private store: Store<appState.State>
  ) { }

  ngOnInit(): void {
    this.report = this.jobReportDataService.createBlankTerminalComparisonReport();
    this.listenToStateChanges();
    this.configChartsOptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(async user => {
        if (user?.id && user.id == this.currentUser?.id) return;

        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.customer)) {
            this.getReport(this.currentUser.client);
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin)){
            this.listentToCustomerChanges();
          }
        }
      })
    );
  }

  listentToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;
      
      if (!this.selectedCustomer || this.selectedCustomer.id != c.id){
        this.selectedCustomer = c;
        this.getReport(this.selectedCustomer.id);
      }
    });
  }

  async getReport(customerId: string){
    this.store.dispatch(appActions.showSpinner());
    var report = await this.jobReportService.getTerminalComparisonReport(customerId)
      .toPromise()
      .catch(error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error getting Terminal Comparison Report', null);
        console.log(error);
        return null;
      });
    if (report) {
      this.report = report;
      if (!this.selectedCustomer){
        this.selectedCustomer = this.report.customer;
        this.dataSharingService.setCustomer(this.selectedCustomer);
      }
    }
    this.computeAdditionaInfo(this.report);
    this.store.dispatch(appActions.hideSpinner());
  }

  configChartsOptions() {
    this.chartsOptions = {};
    this.chartsOptions['Average Downtime Trend'] = this.configChartOptions('Downtime (min)');
    this.chartsOptions['Average Job Time On Dock Trend'] = this.configChartOptions('Time on Dock (min)');
    this.chartsOptions['Average Gross Pumping Rate Trend'] = this.configChartOptions('Rate (bbls/h)');
    this.chartsOptions['Average Net Pumping Rate Trend'] = this.configChartOptions('Rate (bbls/h)');
    this.chartsOptions['Average Pre-Cargo Time Trend'] = this.configChartOptions('Time (min)');
    this.chartsOptions['Average Downtime By Reasons'] = this.configChartOptions('Downtime (min)');
  }

  configChartOptions(yAxisLabel: string): any {
    var jobReportDataService = this.jobReportDataService;
    return {
      legend: { position: 'bottom' },
      scales: {
        yAxes: [{ 
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: yAxisLabel
          }
        }],
        xAxes: [{ 
          position: 'bottom', 
          gridLines: {
            display:false
          }
        }],
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
            return jobReportDataService.convertDuration(tooltipItem.value);
          }
        }
      }
    }
  }

  computeAdditionaInfo(report: TerminalComparisonReport) {
    if (report.categoryTimings) {
      report.categoryTimings.forEach(t => {
        if (t.terminalTimings) {
          t.terminalTimings.forEach(i => {
            i.duration = Math.round(i.duration);
            i.durationText = this.jobReportDataService.convertDuration(i.duration);
            i.jobInfos.forEach(j => {
              j.duration = Math.round(j.duration);
              j.durationText = this.jobReportDataService.convertDuration(j.duration);
            });
          });
        }
        this.configCategoryChartData(t);
      });
      report.downtimeByReasonsTimings.forEach(d => {
        d.durationText = this.jobReportDataService.convertDuration(d.duration);
        if (d.categoryTimings)
          d.categoryTimings.forEach(c => c.durationText = this.jobReportDataService.convertDuration(c.duration))
      });
      this.configDowntimeChartData(report.downtimeByReasonsTimings);
    }
  }

  configCategoryChartData(timing: CategoryTiming){
    timing.chartData = {
      labels: timing.terminalTimings.map(t => t.terminal.name),
      datasets: [
        {
          label: 'Terminal',
          backgroundColor: '#FFA726',
          data: timing.terminalTimings.map(t => t.duration)
        }
      ]
    }
  }

  configDowntimeChartData(downtimes: TerminalTimingInfo[]){
    this.report.downtimeByReasonChartData = {
      labels: downtimes.map(d => d.terminal.name),
      datasets: [
        {
          label: 'Terminal',
          backgroundColor: '#FFA726',
          data: downtimes.map(t => t.duration)
        }
      ]
    }
  }

  selectData(event: any, title: string) {
    this.dataInfos = this.getJobTrendInfos(title, event.element._index);
    this.showDataInfo = true;
  }

  selectDowntimeData(event: any) {
    var terminalTimingInfo = this.report.downtimeByReasonsTimings[event.element._index];
    this.downtimeDataInfos = terminalTimingInfo.categoryTimings;
    this.dataCaption = terminalTimingInfo.terminal?.name + ' (Past 90 Days)';
    this.showDowntimeDataInfo = true;
  }

  getJobTrendInfos(title: string, index: number): JobTrendInfo[] {
    let categoryTiming: CategoryTiming;
    let terminalTiming: TerminalTiming;
    if (title == 'Average Downtime Trend')
      categoryTiming = this.report.categoryTimings[0];
    else if (title == 'Average Job Time On Dock Trend')
      categoryTiming = this.report.categoryTimings[1];
    else if (title == 'Average Gross Pumping Rate Trend')
      categoryTiming = this.report.categoryTimings[2];
    else if (title == 'Average Net Pumping Rate Trend')
      categoryTiming = this.report.categoryTimings[3];
    else if (title == 'Average Pre-Cargo Time Trend')
      categoryTiming = this.report.categoryTimings[4];
    terminalTiming = categoryTiming.terminalTimings[index];
    this.dataCaption = categoryTiming.category + ' ( ' + terminalTiming.terminal.name + ')';
    return terminalTiming.jobInfos;
  }

}
