import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { select, Store } from '@ngrx/store';
import { ClientCompany, Terminal, Dock, User, Job, JobOverview, JobStatuses, Ship } from 'src/app/models';
import { JobService } from 'src/app/services/http-services/job.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DownloadsService } from 'src/app/services/http-services/downloads.service';
import * as moment from 'moment';
import {ConfirmationService} from 'primeng/api';
import * as cloneDeep from 'lodash.clonedeep';
import { Guid } from "guid-typescript";

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit, OnDestroy {

  skeletonRows: any[] = [];
  jobSummaries: JobListItem[] = [];
  jobSummaryCols: any[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  jobStatuses = JobStatuses;
  jobsArr: any[] = [];
  incomingCounts: number = 0;
  activeCounts: number = 0;
  completedCounts: number = 0;
  avgTime: any = { 'days':0, 'hours':0, 'minutes':0 };

  constructor(
    private store: Store<appState.State>,
    private jobService: JobService,
    private router: Router,
    private appMessageService: AppMessageService,
    private downloadsService: DownloadsService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.jobSummaryCols = this.buildJobOverviewCols();
    this.skeletonRows = this.buildSkeletonRows();
    this.getJobOverviewData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  buildJobOverviewCols(): any[] {
    return [
      { field: 'jobNumberAsNumber', header: 'Job Number', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'date', header: 'Date', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: true },
      { field: 'shipName', header: 'Ship', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'clientName', header: 'Customer', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'port', header: 'Port', display: false, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'terminalName', header: 'Terminal', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'dockName', header: 'Dock', display: false, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'superintendentNames', header: 'Superintendent', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'status', header: 'Status', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'cargoName', header: 'Cargo', display: false, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'agent', header: 'Agent', display: false, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'inspectionCompany', header: 'Inspection Company', display: false, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  get globalFilterFields(): string[] {
    return this.jobSummaryCols.filter(j => j.searchable).map(j => j.field);
  }

  getJobOverviewData() {
    this.jobService.getJobOverviews().subscribe(
      results => {
        this.jobsArr = results;
        this.getCounts();
        this.jobSummaries = results
          .map(j => j as JobListItem)
          .sort((a, b) => a.jobNumberToInt > b.jobNumberToInt ? -1 : 1);
        this.jobSummaries.forEach(j => {
          j.jobNumberAsNumber = j.jobNumberToInt; 
          j.shipName = j.ship?.name;
          j.clientName = j.client?.name;
          j.terminalName = j.terminal?.name;
          j.superintendentNames = j.superintendents?.map(s => User.fromUserProperties(s).displayName).join(',');
          j.dockName = j.dock?.name;
          j.cargoName = j.cargoProduct?.name;
        })
        this.loaded = true;
      },
      error => {
        this.appMessageService.errorMessage('Error Getting Job Summary Information', error);
        this.loaded = true;
      }
    );
  }

  addJob() {
    this.router.navigate(['jobs/new']);
  }

  openJob(id: string) {
    this.router.navigate(['jobs/' + id]);
  }

  downloadBou(jobId: string, jobNumber: string) {
    this.downloadsService.downloadBou(jobId, jobNumber);
  }

  getCounts() {
    this.activeCounts = 0;
    this.incomingCounts = 0;
    this.completedCounts = 0;
    for (let i=0; i<this.jobsArr.length; i++) {
      if (this.jobsArr[i].status === this.jobStatuses.active) {
        this.activeCounts++;
      }
      if (moment().format('YYYY/MM') === moment(this.jobsArr[i].modifiedOn).format('YYYY/MM')) {
        if (this.jobsArr[i].status === this.jobStatuses.incoming) {
          this.incomingCounts++;
        } else if (this.jobsArr[i].status === this.jobStatuses.complete || this.jobsArr[i].status === this.jobStatuses.closed) {
          this.completedCounts++;
          var diffArr = [];
          var diff = moment(this.jobsArr[i].modifiedOn).diff(moment(this.jobsArr[i].createdOn), 'h');
          diffArr.push(diff);
          this.getAvgTime(diffArr);
        }
      }
    }
  }

  getAvgTime(arr: number[]) {
    const totalHrs = arr.reduce((a,b) => a + b, 0)/arr.length;
    this.getTime(totalHrs)
  }

  getTime(hrs: number) {
    var Days = Math.floor(hrs/24);
    var Remainder = hrs % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60*(Remainder-Hours));
    this.avgTime = {
      'days': Days,
      'hours': Hours,
      'minutes': Minutes,
    }
    return this.avgTime
  }

  deleteJob(jobId: string) {
    this.confirmationService.confirm({
        header: 'Delete Job Confirmation',
        message: 'Are you sure that you want to delete this job?',
        key: 'confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(appActions.showSpinner());
          this.jobService.deleteJobById(jobId).subscribe(res => {
            this.getJobOverviewData();
            this.store.dispatch(appActions.hideSpinner());
            this.appMessageService.successMessage('Job Deleted: ', jobId, true);
          }, error => {
            this.store.dispatch(appActions.hideSpinner());
            this.appMessageService.errorMessage('Job Delete Error', error);
          })
        },
        reject: (type) => {}
    });
  }

  copyJob(jobId: string) {
    this.confirmationService.confirm({
      header: 'Copy Job Confirmation',
      message: 'Are you sure that you want to copy this job?',
      key: 'confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(appActions.showSpinner());
        this.jobService.getJobById(jobId).subscribe(res => {
          let job: Job = new Job();
          job = cloneDeep(res);
          if (job) {
            let newJobId: string = Guid.create().toString();
            if (job.overview) {
              job.overview.jobId = newJobId;
              job.overview.id = Guid.create().toString();
            }

            if (job.blpRevisions) {
              job.blpRevisions.forEach((blpRev) => {
                blpRev.jobId = newJobId;
                blpRev.id = Guid.create().toString();
                if (blpRev.parcels) {
                  blpRev.parcels.forEach((blpRevParcel) => {
                    blpRevParcel.blpId = blpRev.id;
                    blpRevParcel.id = Guid.create().toString();
                  });
                }
                if (blpRev.allLineItems) {
                  blpRev.allLineItems.forEach((blpRevLI) => {
                    blpRevLI.blpId = blpRev.id;
                    blpRevLI.id = Guid.create().toString();
                    if (blpRevLI.nominatedQuantities) {
                      blpRevLI.nominatedQuantities.forEach((blpRevLINQ) => {
                        blpRevLINQ.blpLineItemId = blpRevLI.id;
                        blpRevLINQ.id = Guid.create().toString();
                      });
                    }
                    if (blpRevLI.adjustedQuantities) {
                      blpRevLI.adjustedQuantities.forEach((blpRevLIAQ) => {
                        blpRevLIAQ.blpLineItemId = blpRevLI.id;
                        blpRevLIAQ.id = Guid.create().toString();
                      });
                    }
                  });
                }
                if (blpRev.adjustedQuantityOverrides) {
                  blpRev.adjustedQuantityOverrides.forEach((blpRevAQO) => {
                    blpRevAQO.blpRevisionId = blpRev.id;
                    blpRevAQO.id = Guid.create().toString();
                  });
                }
                if (blpRev.nominatedQuantityTotals) {
                  blpRev.nominatedQuantityTotals.forEach((blpRevNQT) => {
                    blpRevNQT.blpId = blpRev.id;
                    blpRevNQT.id = Guid.create().toString();
                  });
                }
                if (blpRev.adjustedQuantityTotals) {
                  blpRev.adjustedQuantityTotals.forEach((blpRevAQT) => {
                    blpRevAQT.blpId = blpRev.id;
                    blpRevAQT.id = Guid.create().toString();
                  });
                }
                if (blpRev.stepTotals) {
                  blpRev.stepTotals.forEach((blpRevST) => {
                    blpRevST.blpId = blpRev.id;
                    blpRevST.id = Guid.create().toString();
                  });
                }
                if (blpRev.parcelFinalApi) {
                  blpRev.parcelFinalApi.forEach((blpRevPFA) => {
                    blpRevPFA.blpId = blpRev.id;
                    blpRevPFA.id = Guid.create().toString();
                  });
                } 
              });
            }

            if (job.timeTrackingEventLogs) {
              job.timeTrackingEventLogs.forEach((tteLog) => {
                tteLog.jobId = newJobId;
                tteLog.id = Guid.create().toString();
              });
            }

            if (job.bfr) {
              job.bfr.jobId = newJobId;
              job.bfr.id = Guid.create().toString();
              if (job.bfr.bfrTanks) {
                job.bfr.bfrTanks.forEach((bfrTank) => {
                  bfrTank.bfrId = job.bfr.id;
                  bfrTank.id = Guid.create().toString();
                  if (bfrTank.bfrTankLineItems) {
                    bfrTank.bfrTankLineItems.forEach((bfrTankLI) => {
                      bfrTankLI.brfTankId = bfrTank.id;
                      bfrTankLI.id = Guid.create().toString();
                    });
                  }
                });
              }
            }

            if (job.vdps) {
              job.vdps.forEach((vdp) => {
                vdp.jobId = newJobId;
                vdp.id = Guid.create().toString();
                if (vdp.shoreTanks) {
                  vdp.shoreTanks.forEach((vdpShoreTank) => {
                    vdpShoreTank.vdpId = vdp.id;
                    vdpShoreTank.id = Guid.create().toString();
                  });
                }
                if (vdp.lineItems) {
                  vdp.lineItems.forEach((vdpLI) => {
                    vdpLI.vdpId = vdp.id;
                    vdpLI.id = Guid.create().toString();
                    if (vdpLI.measurements) {
                      vdpLI.measurements.forEach((vdpLIMeas) => {
                      });
                    }
                  });
                }
              });
            }

            if (job.bou) {
              job.bou.jobId = newJobId;
              job.bou.id = Guid.create().toString();
              if (job.bou.estimationLineItems) {
                job.bou.estimationLineItems.forEach((bouELI) => {
                  bouELI.bouId = job.bou.id;
                  bouELI.id = Guid.create().toString();
                });
              }
              if (job.bou.actualLineItems) {
                job.bou.actualLineItems.forEach((bouALI) => {
                  bouALI.bouId = job.bou.id;
                  bouALI.id = Guid.create().toString();
                });
              }
            }

            this.jobService.upsertJob(newJobId, job).subscribe(j => {
              this.store.dispatch(appActions.hideSpinner());
              this.appMessageService.successMessage('Job Copied', '', true);
              this.getJobOverviewData();
            }, error => {
              this.store.dispatch(appActions.hideSpinner());
              this.appMessageService.errorMessage('Job Copy Error', error);
            });             
          }
        }, error => {
          this.store.dispatch(appActions.hideSpinner());
          this.appMessageService.errorMessage('Job Copy Error', error);
        })
      },
      reject: (type) => { }
    });
  }
}

class JobListItem extends JobOverview {
    jobNumberAsNumber: number;
    shipName: string;
    clientName: string;
    terminalName: string;
    superintendentNames: string;
    dockName: string;
    cargoName: string;
    constructor() {
      super();
    }
}
