import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult, AuthError } from '@azure/msal-browser';
import { b2cPolicies } from './b2c-config';
import { filter, takeUntil } from 'rxjs/operators';
import * as appState from './state';
import * as appActions from './state/app.actions';
import { select, Store } from '@ngrx/store';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { User, UserRoles, ClientCompany } from './models';
import { AppStatusService } from './services/app-status.service';
import { ApplicationInsightsService } from './services/application-insights.service';
import { DataSharingService } from './services/data-sharing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Cargo Document Program';
  items: MenuItem[] = [];
  userItems: MenuItem[] = [];
  isIframe = false;
  loginDisplay = false;
  userMenuWidth: string = '25em';
  showSpinner = false;
  private readonly _destroying$ = new Subject<void>();
  currentUser: User;
  isInitialized: boolean = false;
  showCustomerSelection: boolean = false;
  customers: ClientCompany[] = [];
  customerOptions: SelectItem[] = [];
  selectedCustomer: ClientCompany;
  currentRoute: string;
  showProfileDialog: boolean;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private store: Store<appState.State>,
    private router: Router,
    private titleService: Title,
    private appStatusService: AppStatusService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private applicationInsightsService: ApplicationInsightsService,
    private dataSharingService: DataSharingService
  ) { }

  ngOnInit() {
    this.applicationInsightsService.initialize();
    this.titleService.setTitle(this.title);
    this.msalInit();
    this.listenForAppUpdateAvailabilityChanges();
    this.listenToRouteChanges();
  }

  msalInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
      
        let payload: IdTokenClaims = <AuthenticationResult>result.payload;

        // We need to reject id tokens that were not issued with the default sign-in policy.
        // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

        if (payload.idTokenClaims?.acr === b2cPolicies.names.forgotPassword) {
          window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
          return this.authService.logout();
        } 

        this.isInitialized = this.initializeAppStateListeners(this.isInitialized);

        return result;
      });

      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          if (result.error.message.includes('AADB2C90118')) {
            
            // login request with reset authority
            let resetPasswordFlowRequest = {
              scopes: ["openid"],
              authority: b2cPolicies.authorities.forgotPassword.authority,
            };

            this.login(resetPasswordFlowRequest);
          }
        }
      });
  }

  setUpMainMenu(): MenuItem[] {
    let menu: MenuItem[] = [];
    if (this.currentUser && this.currentUser.role){
      if (this.currentUser.role.includes(UserRoles.superintendent) || this.currentUser.role.includes(UserRoles.superAdmin)
        || this.currentUser.role.includes(UserRoles.admin)) {
        menu.push({
          label: 'Jobs',
          icon: 'pi pi-fw pi-pencil',
          items: [
              { label: 'Create Job', routerLink: ['/jobs/new'] },
              { label: 'View Jobs', routerLink: ['/jobs']}
          ]
        });
      }
      if (this.currentUser.role.includes(UserRoles.admin) || this.currentUser.role.includes(UserRoles.superAdmin)) {
        menu.push({
          label: 'Configuration',
          icon: 'pi pi-fw pi-cog',
          items: [
              { label: 'Ships', routerLink: ['/config/ships'] },
              { label: 'Terminals', routerLink: ['/config/terminals'] },
              { label: 'Ports', routerLink: ['/config/ports'] },
              { label: 'Docks', routerLink: ['/config/docks'] },
              { label: 'Tanks', routerLink: ['/config/tanks'] },
              { label: 'Timing Categories', routerLink: ['config/timingcategories']},
              { label: 'Customers', routerLink: ['/config/clients'] },
              { label: 'Inspection Companies', routerLink: ['/config/inspcompanies'] },
              { label: 'Events', routerLink: ['/config/events'] },
              { label: 'Users', routerLink: ['/config/users'] },
              { label: 'Products', routerLink: ['/config/products'] },
          ]
        });
      }
      if (this.currentUser.role.includes(UserRoles.customer) ||
        this.currentUser.role.includes(UserRoles.superCustomer) ||
        this.currentUser.role.includes(UserRoles.superAdmin)) {
        menu.push({
          label: 'Dashboard',
          icon: 'pi pi-fw pi-home',
          routerLink: ['/dashboard']
        });
        menu.push({
          label: 'Reports',
          icon: 'pi pi-fw pi-file',
          items: [
              { label: 'Terminal Performance Report', routerLink: ['/report/customer'] },
              { label: 'Trending KPI Report', routerLink: ['/report/trend'] },
          ]
        });
      }
      if (!this.currentRoute.startsWith('/customer/main')) {
        if (this.currentUser.role.includes(UserRoles.newCustomer) ||
          this.currentUser.role.includes(UserRoles.superNewCustomer) ||
          this.currentUser.role.includes(UserRoles.superAdmin)) {
          menu.push({
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/customer/main']
          });
          menu.push({
            label: 'Active Assignments',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: ['/customer/jobs-list/active']
          });
          menu.push({
            label: 'Completed Assignments',
            icon: 'pi pi-fw pi-check-square',
            routerLink: ['/customer/jobs-list/complete']
          });
          menu.push({
            label: 'Key Performance Indicators',
            icon: 'pi pi-fw pi-id-card',
            routerLink: ['/customer/kpi']
          });
        }
      }
    }
    
    return menu;
  }

  setupNonLoggedInMenu(): MenuItem[] {
    return [
      {
        label: 'Log In',
        icon: 'pi pi-fw pi-user',
        command: () => this.login()
      }
    ];
  }

  setupUserMenu(currentUser: User = null): MenuItem[] {
    if (currentUser) {
      if (currentUser.email.length > currentUser.displayName.length) {
        this.userMenuWidth = (currentUser.email.length * 0.7).toString() + 'em';
      } else {
        this.userMenuWidth = (currentUser.displayName.length * 0.7).toString() + 'em';
      }
      
      return [
        {
          label: currentUser.displayName
        },
        {
          label: currentUser.email
        },
        {
          items: [
            {
              label: 'Available', icon: 'pi pi-sun', visible: currentUser.isClockIn && currentUser.role.includes(UserRoles.superintendent), command: () => this.toggleClockIn()
            },
            {
              label: 'Away', icon: 'pi pi-moon', visible: !currentUser.isClockIn && currentUser.role.includes(UserRoles.superintendent), command: () => this.toggleClockIn()
            }
          ]
        },
        {
          items: [
            {
              label: 'Edit Profile', icon: "pi pi-pencil", command: () => this.editProfile()
            }
          ]
        },
        {
          items: [
            {
              label: 'Log Out', command: () => this.logout()
            }
          ]
        }
      ]
    }
    else {
      return [
        {
          label: 'Log In', command: () => this.login()
        }
      ]
    }
  }

  async setLoginDisplay(): Promise<void> {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      const accountInfo = this.authService.instance.getAllAccounts()[0];
      this.store.dispatch(appActions.setCurrentUserFromToken({ accountInfo: accountInfo }));
      this.items = this.setUpMainMenu();
    } else {
      this.login();
      //this.items = this.setupNonLoggedInMenu();
    }
    this.userItems = this.setupUserMenu();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({...this.msalGuardConfig.authRequest, ...userFlowRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest, ...userFlowRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    this.authService.logout();
    this.applicationInsightsService.clearUserId();
  }

  initializeAppStateListeners(alreadyInitialzied: boolean): boolean{
    if (alreadyInitialzied) {
      return true;
    }

    this.store.dispatch(appActions.refreshSystemConfiguration());
    this.listenForSpinnerChanges();
    this.listenForCurrentUserChanges();
    this.appStatusService.initializeAppStatusListeners();
    return true;
  }

  listenToRouteChanges() {
    this.currentRoute = '';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // do nothing
      }

      if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
          this.checkCustomerListVisibility();
      }

      if (event instanceof NavigationError) {
          // Present error to user
          console.log(event.error);
      }
    });
  }

  listenForCurrentUserChanges() {
    this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.items = this.setUpMainMenu();
        this.userItems = this.setupUserMenu(user);
        this.applicationInsightsService.setUserId(user.email)
        this.listenForCustomersChanges();
        this.listenForCustomerChanges();
        this.checkCustomerListVisibility();
      }
    })
  }

  listenForCustomersChanges() {
    this.store.pipe(select(appState.getClients)).subscribe(results => {
      if (results){
        this.customers = [...results].sort((a, b) => a.name > b.name ? 1 : -1);
        this.customerOptions = this.customers.map(c => { return { 'label': c.name, value: c }; });
      }
    })
  }

  listenForCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!this.selectedCustomer || this.selectedCustomer.id != c.id){
        this.selectedCustomer = c;
      }
    });
  }

  customerChanged(){
    this.dataSharingService.setCustomer(this.selectedCustomer);
  }

  listenForSpinnerChanges() {
    this.store.pipe(select(appState.getShowSpinner)).subscribe(show => {
      if (this.showSpinner != show) {
        this.showSpinner = show;
        this.cdr.detectChanges();
      }
    });
  }

  listenForAppUpdateAvailabilityChanges() {
    this.store.pipe(select(appState.getShowAppUpdateAvailable)).subscribe(s => {
        if (s) {
            this.confirmationService.confirm({
                key: 'confirmation',
                header: 'Application Update Available',
                icon: 'pi pi-question-circle',
                message: 'An updated version of this application is available.  Do you wish to reload with the new update?',
                accept: () => { this.appStatusService.reloadAppAndLoadUpdate(); },
                reject: () => {}
            });
        }
        this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    console.log('**********DESTROYING APP COMPONENT***************');
    debugger;
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  toggleClockIn() {
    this.userItems = null;
    this.currentUser.isClockIn = !this.currentUser.isClockIn;
    this.store.dispatch(appActions.setUser({ data: this.currentUser }));
    window.location.reload();
  }

  editProfile(){
    this.showProfileDialog = true;
  }

  userProfileUpdated(user: User) {
    this.currentUser = user;
    this.showProfileDialog = false;
  }

  private checkCustomerListVisibility() {
    if (!this.currentUser || !this.currentUser.role) {
      this.showCustomerSelection = false;
    }
    else {
      if (this.currentUser.role.includes(UserRoles.superAdmin)) {
        if (this.currentRoute.startsWith('/dashboard') || this.currentRoute.startsWith('/report/customer')
          || this.currentRoute.startsWith('/report/trend') || this.currentRoute.startsWith('/report/terminalcomparison')
          || this.currentRoute.startsWith('/customer/main') || this.currentRoute.startsWith('/customer/jobs-list')
          || this.currentRoute.startsWith('/customer/kpi'))
          this.showCustomerSelection = true;
        else
          this.showCustomerSelection = false;
      }
      else if (this.currentUser.role.includes(UserRoles.superCustomer)) {
        if (this.currentRoute.startsWith('/dashboard') || this.currentRoute.startsWith('/report/customer')
          || this.currentRoute.startsWith('/report/trend') || this.currentRoute.startsWith('/report/terminalcomparison'))
          this.showCustomerSelection = true;
        else
          this.showCustomerSelection = false;
      }
      else if (this.currentUser.role.includes(UserRoles.superNewCustomer)) {
        if (this.currentRoute.startsWith('/customer/main') || this.currentRoute.startsWith('/customer/jobs-list')
          || this.currentRoute.startsWith('/customer/kpi'))
          this.showCustomerSelection = true;
        else
          this.showCustomerSelection = false;
      }
      else
        this.showCustomerSelection = false;
    }
  }
}

interface IdTokenClaims extends AuthenticationResult {
  idTokenClaims: {
    acr?: string
  }
}
