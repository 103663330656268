import { VdpLineItemMeasurement } from "../..";
import { AuditableEntity } from "../../auditable-entity";

export class VdpLineItem extends AuditableEntity {
    vdpId: string;
    tankId: string;
    tov: number;
    mt: number;
    gsv: number;
    pct: number;
    measurements: VdpLineItemMeasurement[] = [];

    constructor() {
        super();
    }

    static fromJson(vdpLineItem: VdpLineItem): VdpLineItem {
        const newVdpLineItem: VdpLineItem = new VdpLineItem();
        
        newVdpLineItem.id = vdpLineItem.id;
        newVdpLineItem.active = vdpLineItem.active;
        newVdpLineItem.createdOn = vdpLineItem.createdOn;
        newVdpLineItem.createdBy = vdpLineItem.createdBy;
        newVdpLineItem.modifiedOn = vdpLineItem.modifiedOn;
        newVdpLineItem.modifiedBy = vdpLineItem.modifiedBy;
        newVdpLineItem.vdpId = vdpLineItem.vdpId;
        newVdpLineItem.tankId = vdpLineItem.tankId;
        newVdpLineItem.tov = vdpLineItem.tov;
        newVdpLineItem.mt = vdpLineItem.mt;
        newVdpLineItem.gsv = vdpLineItem.gsv;

        return newVdpLineItem;
    }
}

export enum VesselSide {
    port = 'P',
    starboard = 'S'
}