<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Name</label>
                <div class="p-col-12 p-md-10">
                    <input id="name" type="text" pInputText [(ngModel)]="timeTrackingEvent.name"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="category" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Category</label>
                <div class="p-col-12 p-md-10">
                     <p-dropdown [options]="categories" [(ngModel)]="timeTrackingEvent.category" appendTo="body" placeholder="Select Category"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid" *ngIf="timeTrackingEvent.category === 'Delays'">
                <label for="delayType" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Delay Type</label>
                <div class="p-col-12 p-md-10">
                     <p-dropdown [options]="delayTypes" [(ngModel)]="delayDescription" appendTo="body" placeholder="Select Delay Type"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="duration" class="p-col-12 p-mb-4 p-md-4 p-mb-md-0">Requires Duration Tracking?</label>
                <div class="p-col-12 p-md-6">
                    <p-inputSwitch id="duration" [disabled]="timeTrackingEvent.isSystemTrigger" [(ngModel)]="timeTrackingEvent.requiresDuration"></p-inputSwitch>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="tankAssignment" class="p-col-12 p-mb-4 p-md-4 p-mb-md-0">Requires Tank Assignment Tracking?</label>
                <div class="p-col-12 p-md-6">
                    <p-inputSwitch id="tankAssignment" [disabled]="timeTrackingEvent.isSystemTrigger" [(ngModel)]="timeTrackingEvent.requiresTankAssignment"></p-inputSwitch>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="systemTrigger" class="p-col-12 p-mb-4 p-md-4 p-mb-md-0">Triggers System Event?</label>
                <div class="p-col-12 p-md-6">
                    <p-inputSwitch id="systemTrigger" disabled [(ngModel)]="timeTrackingEvent.isSystemTrigger"></p-inputSwitch>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</div>
