<div *ngIf="bfr" class="p-grid">
    <div class="p-md-6 p-col-12">
        <p-table [value]="dummyRowData">
            <ng-template pTemplate="header">
                <tr>
                    <th class="bfr-summary-header">SUMMARY</th>
                    <th class="bfr-summary-header">T.C.V.</th>
                    <th class="bfr-summary-header">G.S.V</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body">
                <tr>
                    <td class="bfr-summary-header">Gallons</td>
                    <td>{{bfr.tcvGallons | number: '1.1-2'}}</td>
                    <td>{{bfr.gsvGallons | number: '1.1-2'}}</td>
                </tr>
                <tr>
                    <td class="bfr-summary-header">Barrels</td>
                    <td>{{bfr.tcvBarrels | number: '1.1-2'}}</td>
                    <td>{{bfr.gsvBarrels | number: '1.1-2'}}</td>
                </tr>
                <tr>
                    <td class="bfr-summary-header">Long Tons</td>
                    <td>{{bfr.tcvLongTons | number: '1.1-2'}}</td>
                    <td>{{bfr.gsvLongTons | number: '1.1-2'}}</td>
                </tr>
                <tr>
                    <td class="bfr-summary-header">Metric Tons</td>
                    <td>{{bfr.tcvMetricTons | number: '1.1-2'}}</td>
                    <td>{{bfr.gsvMetricTons | number: '1.1-2'}}</td>
                </tr>
                <tr>
                    <td class="bfr-summary-header">Kilograms</td>
                    <td>{{bfr.tcvKilograms | number: '1.0-0'}}</td>
                    <td>{{bfr.gsvKilograms | number: '1.0-0'}}</td>
                </tr>
                <tr>
                    <td class="bfr-summary-header"></td>
                    <td class="bfr-summary-header">Average Temp</td>
                    <td>{{bfr.averageTemp | number: '1.1-1'}} ℉</td>
                </tr>
                <tr>
                    <td class="bfr-summary-header"></td>
                    <td class="bfr-summary-header">Average API</td>
                    <td>{{bfr.averageApi | number: '1.1-1'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-md-6 p-col-12">
        <div class="p-grid">
            <div class="p-col-12">
                <!-- UTI/MMC -->
                <p-table>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="bfr-summary-header">UTI/MMC</th>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="p-col-12">
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="gaugeTempId" class="p-col-12 p-mb-4 p-md-4 p-mb-md-0">Gauge Tape ID</label>
                        <div class="p-col-12 p-md-8">
                            <input id="gaugeTempId" type="text" pInputText [(ngModel)]="bfr.utiMmcGaugeTapeId"> 
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="thermoProbeId" class="p-col-12 p-mb-4 p-md-4 p-mb-md-0">Thermoprobe ID</label>
                        <div class="p-col-12 p-md-8">
                            <input id="thermoProbeId" type="text" pInputText [(ngModel)]="bfr.utiMmcGaugeThermoProbeId"> 
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="remarks" class="p-col-12 cancel-text">Remarks</label>
                        <div class="p-col-12">
                            <textarea id="remarks" pInputTextarea [(ngModel)]="bfr.remarks" [rows]="5"></textarea> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
