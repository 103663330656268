import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { JobSections, Ship, Tank, Product } from 'src/app/models';

@Component({
  selector: 'app-job-detail-selector',
  templateUrl: './job-detail-selector.component.html',
  styleUrls: ['./job-detail-selector.component.scss']
})
export class JobDetailSelectorComponent implements OnInit {

  @Input() selected: string;
  @Input() shoreTanks: Tank[] = [];
  @Input() vesselTanks: Tank[] = [];
  @Output() shipChanged = new EventEmitter<Ship>();
  @Output() cargoChanged = new EventEmitter<string>();
  @Output() terminalChanged = new EventEmitter<string>();
  jobSections = JobSections;
  constructor() { }

  ngOnInit(): void {
  }

  jobOverviewShipChanged(ship: Ship) {
    this.shipChanged.emit(ship);
  }

  jobOverviewCargoChanged(cargoId: string) {
    this.cargoChanged.emit(cargoId);
  }

  jobOverviewTerminalChanged(terminalId: string) {
    this.terminalChanged.emit(terminalId);
  }

}
