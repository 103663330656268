import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserRoles } from 'src/app/models';
import { User } from 'src/app/models/user';
import { ClientCompany } from "src/app/models/system-config/client-company";
import { Terminal } from "src/app/models/system-config/terminal"; 

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  @Input() user: User;
  @Input() clients: ClientCompany[] = [];
  @Input() terminals: Terminal[] = [];
  @Output() userUpdated = new EventEmitter<User>();
  @Output() resetUser = new EventEmitter();

  roleOptions: SelectItem[] = [];
  clientOptions: SelectItem[] = [];
  terminalOptions: SelectItem[] = [];
  selectedRoles: string[] = [];
  selectedTerminals: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.roleOptions = this.buildRoleOptions();
    this.clientOptions = this.buildClientOptions();
    this.terminalOptions = this.buildTerminalOptions();
    this.selectedRoles = this.user.role?.split(',');
    if (this.user.terminals)
      this.selectedTerminals = this.user.terminals.split(',');
  }

  buildRoleOptions(): SelectItem[] {
    const roles = [UserRoles.admin, UserRoles.superAdmin, UserRoles.customer, UserRoles.superCustomer, UserRoles.newCustomer, UserRoles.superNewCustomer, UserRoles.superintendent, UserRoles.terminal ];
    return roles.sort((a,b) => a > b ?  1 :  -1).map(p => { return { 'label': p, value: p }; });
  }

  buildClientOptions(): SelectItem[] {
    return this.clients.map(c => { return { 'label': c.name, value: c.id }; });
  }

  buildTerminalOptions(): SelectItem[] {
    return this.terminals.map(t => { return { 'label': t.port?.name + ' - ' + t.name, value: t.id }; });
  }

  save() {
    this.userUpdated.emit(this.user);
  }

  reset() {
    this.resetUser.emit();
  }

  rolesChanged() {
    this.user.role = this.selectedRoles?.join(',');
    if (!this.hasRole(UserRoles.customer))
      this.user.client = null;
    if (!this.hasRole(UserRoles.newCustomer))
      this.user.client = null;
    if (!this.hasRole(UserRoles.terminal))
      this.user.terminals = null;
  }

  terminalsChanged() {
    this.user.terminals = this.selectedTerminals?.join(',');
  }

  hasRole(role: string){
    return this.selectedRoles && this.selectedRoles.includes(role);
  }

}
