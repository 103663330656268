import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { JobVdp, VdpStorageEntryOptions } from 'src/app/models/job/vdp/job-vdp';
import * as appState from '../../../../state';
import * as appActions from '../../../../state/app.actions';
import { SelectItem } from 'primeng/api';
import { VdpShoreTank } from 'src/app/models/job/vdp/vdp-shore-tank';
import { VdpManagementService } from 'src/app/services/vdp-management.service';
import { VdpLineItem, VcfCalculations, Tank } from 'src/app/models';

@Component({
  selector: 'app-job-vdp-detail',
  templateUrl: './job-vdp-detail.component.html',
  styleUrls: ['./job-vdp-detail.component.scss']
})
export class JobVdpDetailComponent implements OnInit, OnChanges {

  @Input() vdp: JobVdp;
  @Input() vesselTanks: Tank[] = [];
  @Input() shoreTanks: Tank[] = [];

  subscriptions: Subscription[] = [];
  isInVdpEditMode: boolean = true;
  isNewVesselTank: boolean = false;
  showVesselTankEdit: boolean = false;
  jobId: string;
  jobNumber: string;
  ready = false;
  entryOptions: SelectItem[] = [];

  vcfOptions: SelectItem[] = [];
  
  largeScreenCols: any[] = [];
  mobileCols: any[] = [];
  shoreTankColCount: number = 0;

  tableData: any[] = [];
  selectedVdpLineItem: VdpLineItem;

  constructor(
    private store: Store<appState.State>,
    private vdpManagementService: VdpManagementService) { }

  async ngOnInit() {
    console.log('vdp', this.vdp);
    this.buildOptions();
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  buildOptions() {
    this.entryOptions = this.vdpManagementService.buildStorageEntryOptions();
    this.vcfOptions = [];
    for (const vcf of Object.keys(VcfCalculations)) {
      this.vcfOptions.push({ value: VcfCalculations[vcf], label: VcfCalculations[vcf] });
    }
  }

  get isEntryOptionsLocked(): boolean {
    return this.vdp && this.vdp.storageEntry && this.vdp.lineItems && (this.vdp.lineItems.length > 0);
  }

  async refreshColumnSettings() {
    this.largeScreenCols = this.buildLargeScreenCols(this.vdp?.shoreTanks, this.shoreTanks);
    this.shoreTankColCount = this.vdp?.shoreTanks.length;
    // this.mobileCols = this.buildMobileCols(this.vdp?.parcels);
  }

  refreshTableData() {
    this.tableData = this.vdpManagementService.buildTableData(this.vdp, this.vesselTanks);
  }
  
  buildLargeScreenCols(vdpShoreTanks: VdpShoreTank[], shoreTanks: Tank[]): any[] {
    const cols: any[] = [];
    if (vdpShoreTanks) {
      const columnDefs = this.vdpManagementService.buildVdpTableColumns(vdpShoreTanks, this.vdp.storageEntry, shoreTanks);
      columnDefs.forEach(c => {
        cols.push({
          field: c.name,
          header1: c.lineText1,
          header2: c.lineText2,
          header3: c.lineText3,
          header4: c.lineText4,
          header5: c.lineText5,
          header6: c.lineText6,
          header7: c.lineText7,
          header8: c.lineText8,
          header9: c.lineText9,
          split9: c.split9,
          showHeader2Icon: c.showLineIcon2,
          class1: c.class1,
          class2: c.class2,
          class3: c.class3,
          class4: c.class4,
          class5: c.class5,
          class6: c.class6,
          class7: c.class7,
          class8: c.class8,
          class9: c.class9,
          width: c.width,
          allowOverflow1: c.allowOverflow1,
          allowOverflow2: c.allowOverflow2,
          allowOverflow3: c.allowOverflow3,
          allowOverflow4: c.allowOverflow4,
          allowOverflow5: c.allowOverflow5,
          allowOverflow6: c.allowOverflow6,
          allowOverflow7: c.allowOverflow7,
          allowOverflow8: c.allowOverflow8,
          allowOverflow9: c.allowOverflow9,
          step: c.step,
          showInputs: c.showInputs
        });
      })
    }

    return cols;
  }

  refresh() {
    this.calculateVdp();
    this.refreshColumnSettings();
    this.refreshTableData();
  }

  calculateVdp() {
    this.vdp = this.vdpManagementService.calculateVdp(this.vdp);
  }

  isPort(tankId: string): boolean {
    return tankId.endsWith('P');
  }

  getShoreTankSiTotalValue(step: number): number {
    let siTotal = 0;
    this.vdp.lineItems.forEach(i => {
      const stepMeasurement = i.measurements.find(m => m.step === step);
      if (stepMeasurement) siTotal += isNaN(stepMeasurement.value) ? 0 : stepMeasurement.value;
    });
    return siTotal;
  }
  
  getShoreTankSiTotal(step: number): string {
    return this.getShoreTankSiTotalValue(step)?.toFixed(0);
  }

  getShoreTankMetricTotal(step: number): string {
    return (this.getShoreTankSiTotalValue(step) / 6.28981)?.toFixed(0);
  }

  getTempByStep(step: number): number {
    const stepTank = this.vdp.shoreTanks.find(t => t.step === step);
    if (!stepTank) return 0;
    return stepTank.temp;
  }

  getVcfFormulaByStep(step: number): string {
    return this.vdp.shoreTanks.find(t => t.step === step)?.vcfCalculation;
  }
  
  getCalculatedApi(): string {
    if (!this.vdp || !this.vdp.calculatedApi) return '';
    return this.vdp.calculatedApi?.toFixed(1);
  }

  getCalculatedTemp(): string {
    if (!this.vdp || !this.vdp.calculatedTemp) return '';
    return this.vdp.calculatedTemp?.toFixed(2);
  }

  getCalculatedGsv(): string {
    if (!this.vdp || !this.vdp.calculatedGsv) return '';
    return this.vdp.calculatedGsv?.toFixed(0);
  }

  getCalculatedTov(): string {
    if (!this.vdp || !this.vdp.calculatedTov) return '';
    return this.vdp.calculatedTov?.toFixed(0);
  }

  getCalculatedMt(): string {
    if (!this.vdp || !this.vdp.calculatedMt) return '';
    return this.vdp.calculatedMt?.toFixed(0);
  }

  getCalculatedLt(): string {
    if (!this.vdp || !this.vdp.calculatedLt) return '';
    return this.vdp.calculatedLt?.toFixed(0);
  }

  addTank() {
    this.selectedVdpLineItem = new VdpLineItem();
    this.isNewVesselTank = true;
    this.showVesselTankEdit = true;
  }

  editVesselTank(tankId: string) {
    this.selectedVdpLineItem = this.vdp.lineItems.find(l => l.tankId == tankId);
    this.isNewVesselTank = false;
    this.showVesselTankEdit = true;
  }

  deleteVesselTank(tankId: string) {
    console.log('deleting tank Id ', tankId);
    this.vdp.lineItems = this.vdp.lineItems.filter(i => i.tankId != tankId);
    this.refresh();
  }

  onTempChanged(step: number, temp: string) {
    const shoreTank = this.vdp.shoreTanks.find(t => t.step === step);
    if (shoreTank) {
      const tempNumber = parseFloat(temp); // what to do if parse fails?
      shoreTank.temp = tempNumber;
      this.refresh();
    }
  }

  onVcfChanged(step: number, selectedVcf: string) {
    const shoreTank = this.vdp.shoreTanks.find(t => t.step === step);
    shoreTank.vcfCalculation = selectedVcf;
    this.refresh();
  }

  onVesselTankEditCanceled() {
    this.isNewVesselTank = false;
    this.showVesselTankEdit = false;
  }

  onVesselTankEditSaved(vdpLineItem: VdpLineItem) {
    if (this.isNewVesselTank) {
      this.vdp.lineItems.push(vdpLineItem);
    }
    else {
      var current = this.vdp.lineItems.find(i => i.id === vdpLineItem.id);
      if (current) {
        current = vdpLineItem;
      }
    }

    this.refresh();
    this.isNewVesselTank = false;
    this.showVesselTankEdit = false;
  }

  reset() {
    // restore previous state
  }
  
  save() {
    const setVdpProperties = new appActions.SetJobActionProperties<JobVdp>();
    setVdpProperties.id = this.vdp.id;
    setVdpProperties.jobId = this.vdp.jobId;
    setVdpProperties.data = this.vdp;
    this.store.dispatch(appActions.setJobVdp({ vdp: setVdpProperties }));
  }

}
