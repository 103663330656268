import { JobSummary} from './job-summary';
import { ClientCompany } from 'src/app/models';

export class JobReport {
    customer: ClientCompany;
    activeJobs: number;
    completedJobs: number;
    recentJobs: number;
    activeJobSummaries: JobSummary[] = [];
    completedJobSummaries: JobSummary[] = [];
}