<div *ngIf="pageDataReady">

  <div class="p-grid">
    <div class="p-col-12">
      <div class="flex flex-column card-container">

        <div class="flex align-items-center justify-content-center text-padding">
          <h1 class="title-alignment"><i>Customer Feedback Form</i></h1>
        </div>

      </div>
    </div>
  </div>

  <div class="p-grid">

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding md-padding-right">
          <h3>1. How satisfied are you with our services?</h3>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="satRatingLevel" value="1" [(ngModel)]="feedback.satisfaction" inputId="satRatingLevel1" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="satRatingLevel1">Not Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="satRatingLevel" value="2" [(ngModel)]="feedback.satisfaction" inputId="satRatingLevel2" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="satRatingLevel2">Slightly Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="satRatingLevel" value="3" [(ngModel)]="feedback.satisfaction" inputId="satRatingLevel3" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="satRatingLevel3">Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="satRatingLevel" value="4" [(ngModel)]="feedback.satisfaction" inputId="satRatingLevel4" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="satRatingLevel4">Very Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2.4 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="satRatingLevel" value="5" [(ngModel)]="feedback.satisfaction" inputId="satRatingLevel5" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="satRatingLevel5">Extremely Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding">
          <h3>1.1 Please tell us why... (Optional)</h3>
        </div>
      </div>

    </div>

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding">
          <textarea rows="5" class="p-col-9 comments-font" pInputTextarea [(ngModel)]="feedback.sat_Comments" [disabled]="!createMode"></textarea>
        </div>
      </div>

    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding md-padding-right">
          <h3>2. Please rate the quality and communications provided by the Project Manager</h3>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="qcRatingLevel" value="1" [(ngModel)]="feedback.qualcomm" inputId="qcRatingLevel1" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="qcRatingLevel1">Not Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="qcRatingLevel" value="2" [(ngModel)]="feedback.qualcomm" inputId="qcRatingLevel2" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="qcRatingLevel2">Slightly Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="qcRatingLevel" value="3" [(ngModel)]="feedback.qualcomm" inputId="qcRatingLevel3" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="qcRatingLevel3">Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="qcRatingLevel" value="4" [(ngModel)]="feedback.qualcomm" inputId="qcRatingLevel4" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="qcRatingLevel4">Very Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-2.4 p-xl-2">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center xs-padding">
          <p-radioButton name="qcRatingLevel" value="5" [(ngModel)]="feedback.qualcomm" inputId="qcRatingLevel5" [disabled]="!createMode"></p-radioButton>
          <label [style]="{'padding-left':'0.5rem','font-size':'1.1rem','font-family':'sans-serif','font-weight':'bold'}" for="qcRatingLevel5">Extremely Satisfied</label>
        </div>
      </div>

    </div>

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding">
          <h3>2.1 Please tell us why... (Optional)</h3>
        </div>
      </div>

    </div>

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding">
          <textarea rows="5" class="p-col-9 comments-font" pInputTextarea [(ngModel)]="feedback.qC_Comments" [disabled]="!createMode"></textarea>
        </div>
      </div>

    </div>

  </div>

  <div class="p-grid">

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding md-padding-right">
          <h3>3. Do you have any suggestions on how we can improve?</h3>
        </div>
      </div>

    </div>

    <div class="p-col-12">

      <div class="flex flex-column card-container">
        <div class="flex align-items-center justify-content-center md-padding">
          <textarea rows="5" class="p-col-9 comments-font" pInputTextarea [(ngModel)]="feedback.gen_Comments" [disabled]="!createMode"></textarea>
        </div>
      </div>

    </div>

  </div>

  <div class="p-grid">
    <div *ngIf="createMode" class="p-xl-2 p-lg-2 p-md-2 p-sm-2">
      <p-button label="Cancel" icon="pi pi-undo" styleClass="p-button-warning" (onClick)="back()" [style]="{'width': '100%', 'margin-left':'2rem', 'margin-top': '2rem', 'margin-bottom':'2rem', 'align-items':'center'}"></p-button>
    </div>
    <div *ngIf="createMode" class="p-xl-2 p-lg-2 p-md-2 p-sm-2">
      <p-button label="Reset" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%', 'margin-left':'2rem', 'margin-top': '2rem', 'margin-bottom':'2rem', 'align-items':'center'}"></p-button>
    </div>
    <div *ngIf="createMode" class="p-xl-2 p-lg-2 p-md-2 p-sm-2">
      <p-button label="Submit" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%', 'margin-left':'2rem', 'margin-top': '2rem', 'margin-bottom':'2rem', 'align-items':'center'}"></p-button>
    </div>
    <div *ngIf="!createMode" class="p-xl-2 p-lg-2 p-md-2 p-sm-2">
      <p-button label="Back" icon="pi pi-arrow-circle-left" styleClass="p-button-warning" (onClick)="back()" [style]="{'width': '100%', 'margin-left':'2rem', 'margin-top': '2rem', 'margin-bottom':'2rem', 'align-items':'center'}"></p-button>
    </div>
  </div>

</div>


