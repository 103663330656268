import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { JobBlpRevision, User } from 'src/app/models';
import * as appState from '../../../../state';
import * as appActions from '../../../../state/app.actions';

@Component({
  selector: 'app-job-blp-revision-manager',
  templateUrl: './job-blp-revision-manager.component.html',
  styleUrls: ['./job-blp-revision-manager.component.scss']
})
export class JobBlpRevisionManagerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() blpRevisions: JobBlpRevision[] = [];
  @Output() newRevisionStarted = new EventEmitter();
  @Output() revisionSelected = new EventEmitter<string>();
  @Output() blpDownload = new EventEmitter<JobBlpRevision>();
  // Possible future events
  // @Output() revisionSelected = new EventEmitter<JobBlpRevision>();
  startRevisionButtonLabel = 'Start New';
  latestRevisionText = 'None';
  inBlpEditMode: boolean = false;
  subscriptions: Subscription[] = [];
  users: User[] = [];
  cols: any[] = [];
  tableData: JobBlpRevision[] = [];

  constructor(
    private datePipe: DatePipe,
    private store: Store<appState.State>) { }

  ngOnInit(): void {
    this.listenToBlpEditModeChanges();
    this.listenForUsers();
    this.cols = this.buildCols();
  }

  ngOnChanges() {
    this.startRevisionButtonLabel = this.latestRevision ? 'New Revision' : 'Start';
    this.latestRevisionText = this.latestRevision ?
      this.latestRevision.revisionNumber + ' (' + this.datePipe.transform(this.latestRevision.revisionDate, 'MMM d, yyyy, HH:mm') + ')' :
      'None';
    this.tableData = [...this.blpRevisions].sort((a,b) => a.revisionNumber > b.revisionNumber ? -1 : 1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Saving the job from Blp Detail will update this
  listenToBlpEditModeChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getInBlpEditMode)).subscribe(
        result => this.inBlpEditMode = result
      )
    );
  }

  listenForUsers() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getUsers)).subscribe(
        result => this.users = result
      )
    );
  }

  

  // Using component getter instead of listening to state of the job since
  // will be one revision of ahead of the job state when creating the newest revision
  get latestRevision(): JobBlpRevision {
    if (this.blpRevisions && this.blpRevisions.length) {
      return this.blpRevisions.sort((a, b) => a.revisionNumber > b.revisionNumber ?  -1 :  1)[0];
    } else {
        return null;
    }
  }

  startNewRevision() {
    this.newRevisionStarted.emit();
    this.store.dispatch(appActions.setJobInBlpEditMode({ inBlpEditMode: true }));
  }

  buildCols(): any[] {
    return [
      { field: 'revisionNumber', header: 'Revision', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'revisionDate', header: 'Date', display: true, sortable: true, searchable: true, textFilterable: false, dateFilterable: true },
      { field: 'preparedBy', header: 'Prepared By', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: '', header: 'Actions', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  selectRevision(id: string) {
    this.revisionSelected.emit(id);
  }

  getPreparedByName(userId: string): string {
    return User.fromUserProperties(this.users.find(u => u.id === userId))?.displayName;
  }

  download(blp: JobBlpRevision) {
    this.blpDownload.emit(blp);
  }
}
