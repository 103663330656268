<div class="p-grid">
    <div *ngIf="isExistingCargo" class="p-col-12 p-text-center existing-cargo">
        Managing Existing Cargo!
    </div>
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="stepNumber" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Step Number</label>
                <div class="p-col-12 p-md-10">
                    <input id="stepNumber" type="number" pInputText [(ngModel)]="localStep.step" [disabled]="isExistingCargo"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="shoreTanks" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Shore Tank</label>
                <div class="p-col-12 p-md-10">
                    <p-dropdown [options]="tankOptions" [(ngModel)]="selectedTank" [filter]="true" appendTo="body" placeholder="Select Shore Tank" (onChange)="onTankChanged()"></p-dropdown>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="api" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">API</label>
                <div class="p-col-12 p-md-10">
                    <input id="api" type="number" pInputText [(ngModel)]="localStep.apiNumber"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="comment" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Comment</label>
                <div class="p-col-12 p-md-10">
                    <input id="comment" type="text" pInputText [(ngModel)]="localStep.comment" [disabled]="isExistingCargo"> 
                </div>
            </div>
            <!-- <div *ngIf="!isNewStep"> -->
                <p-divider align="center" type="dashed">
                    <b>Nominated Quantities</b>
                </p-divider>
                <div *ngIf="!isExistingCargo"  class="p-field p-grid">
                    <label for="isPushSwitch" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Push?</label>
                    <div class="p-col-12 p-md-10">
                        <p-inputSwitch id="isPushSwitch" [(ngModel)]="isPush" (onChange)="isPushChanged()"></p-inputSwitch>
                    </div>
                </div>
                <div *ngIf="!isPush">
                    <div *ngFor="let nominatedQuantity of localStep.nominatedQuantities | parcelSort; index as i">
                        <app-job-blp-measurement [showPushOption]="false" [(ngModel)]="localStep.nominatedQuantities[i]"></app-job-blp-measurement>
                    </div>
                </div>
                <p-divider *ngIf="!isExistingCargo" align="center" type="dashed">
                    <b>Line Adjustments</b>
                </p-divider>
                <div *ngIf="!isExistingCargo"  class="p-field p-grid">
                    <label for="selectedParcel" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Parcel</label>
                    <div class="p-col-12 p-md-10">
                         <p-dropdown [options]="parcelNumberOptions" [showClear]="true" [(ngModel)]="localStep.lineAdjustmentParcelNumber" appendTo="body"  placeholder="Select Parcel"></p-dropdown>
                    </div>
                </div>
                <div *ngIf="!isExistingCargo"  class="p-field p-grid">
                    <label for="lineAdj" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Line +/-</label>
                    <div class="p-col-12 p-md-10">
                        <input id="lineAdj" type="number" pInputText [(ngModel)]="localStep.lineAdjustment"> 
                    </div>
                </div>
                <div *ngIf="!isExistingCargo"  class="p-field p-grid">
                    <label for="lineNumbers" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Line #(s)</label>
                    <div class="p-col-12 p-md-10">
                        <input id="lineNumbers" type="text" pInputText [(ngModel)]="localStep.lineNumbers"> 
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" [disabled]="isSaveDisabled()" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>
