import { Guid } from "guid-typescript";
import { ClientCompany, Contact, Dock, Terminal, Port, InspectionCompany, Ship, Product } from "..";
import { AuditableEntity } from "../auditable-entity";
import { User } from "../user";
import { JobStatuses } from "./job-statuses";

export class JobOverview extends AuditableEntity {
  jobId: string = Guid.create().toString();
  jobNumber: string;
  jobNumberToInt: number;
  ship: Ship;
  client: ClientCompany;
  port: Port;
  terminal: Terminal;
  dock: Dock;
  superintendents: User[] = [];
  date: Date;
  cargo: string;
  cargoProduct: Product;
  cargoQuantity: number;
  agent: string;
  inspectionCompany: InspectionCompany;
  status: string = JobStatuses.unconfirmed;
  reportContacts: Contact[] = [];
  reportHours: string = '7,16';
  isConfirmed: boolean = false;
  injection: string;
  isFirstLineOn: boolean = false;
  isLoadingComplete: boolean = false;
  isCancelled: boolean = false;
  cancelledReason: string;
  cancelledBy: string;
  cancelledOn: Date;
  hasFiguresBeenConfirmed: boolean = false;
  isVesselOnSpec: string;
  vesselNotSpecReason: string;

  constructor() {
    super();
  }

  static fromJson(jobOverview: JobOverview): JobOverview {
    const overview = new JobOverview();
    overview.id = jobOverview.id;
    overview.createdOn = jobOverview.createdOn;
    overview.createdBy = jobOverview.createdBy;
    overview.modifiedBy = jobOverview.modifiedBy;
    overview.modifiedOn = jobOverview.modifiedOn;
    overview.active = jobOverview.active;
    overview.jobId = jobOverview.jobId;
    overview.jobNumber = jobOverview.jobNumber;
    overview.ship = jobOverview.ship;
    overview.client = jobOverview.client;
    overview.port = jobOverview.port;
    overview.terminal = jobOverview.terminal;
    overview.dock = jobOverview.dock;
    overview.superintendents = jobOverview.superintendents;
    overview.date = jobOverview.date;
    overview.cargoProduct = jobOverview.cargoProduct;
    overview.cargoQuantity = jobOverview.cargoQuantity;
    overview.agent = jobOverview.agent;
    overview.inspectionCompany = jobOverview.inspectionCompany;
    overview.status = jobOverview.status;
    overview.reportContacts = jobOverview.reportContacts;
    overview.reportHours = jobOverview.reportHours;
    overview.isConfirmed = jobOverview.isConfirmed;
    overview.injection = jobOverview.injection;
    overview.isFirstLineOn = jobOverview.isFirstLineOn;
    overview.isLoadingComplete = jobOverview.isLoadingComplete;
    overview.isCancelled = jobOverview.isCancelled;
    overview.cancelledReason = jobOverview.cancelledReason;
    overview.cancelledBy = jobOverview.cancelledBy;
    overview.cancelledOn = jobOverview.cancelledOn;
    overview.hasFiguresBeenConfirmed = jobOverview.hasFiguresBeenConfirmed;
    overview.isVesselOnSpec = jobOverview.isVesselOnSpec;
    overview.vesselNotSpecReason = jobOverview.vesselNotSpecReason;
    return overview;
  }
}
