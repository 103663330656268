import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActiveJobInfo, ActiveJobDetails, CompletedJobInfo, HomeDetails } from "src/app/models";
import { Feedback } from "../../models/customer/feedback";
import { KpiFirstReport } from "../../models/customer/kpi-first-report";

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private customerJobApiUrl = '/api/customer/job';
    private customerKpiApiUrl = '/api/customer/kpi';
  private customerFeedbackApiUrl = '/api/customer/feedback';

    constructor(private httpClient: HttpClient) {}

    getActiveJobsCount(clientId: string): Observable<number> {
      return this.httpClient.get<number>(this.customerJobApiUrl + '/' + clientId + '/activecount');
    }

    getCompletedJobsCount(clientId: string): Observable<number> {
      return this.httpClient.get<number>(this.customerJobApiUrl + '/' + clientId + '/completedcount');
    }

    getHomeScreenDetails(clientId: string): Observable<HomeDetails> {
      return this.httpClient.get<HomeDetails>(this.customerJobApiUrl + '/' + clientId + '/homescreendetails');
    }

    getActiveJobsList(clientId: string): Observable<ActiveJobInfo[]> {
    return this.httpClient.get<ActiveJobInfo[]>(this.customerJobApiUrl + '/' + clientId + '/activelist');
    }

    getActiveJobDetail(jobId: string): Observable<ActiveJobDetails> {
      return this.httpClient.get<ActiveJobDetails>(this.customerJobApiUrl + '/activejobdetails' + '/' + jobId);
    }

    getCompletedJobsList(clientId: string): Observable<CompletedJobInfo[]> {
      return this.httpClient.get<CompletedJobInfo[]>(this.customerJobApiUrl + '/' + clientId + '/completedlist');
    }

    getKpiFirstReport(clientId: string): Observable<KpiFirstReport> {
      return this.httpClient.get<KpiFirstReport>(this.customerKpiApiUrl + '/' + clientId + '/firstreport');
    }

    getFeedback(jobId: string): Observable<Feedback> {
      return this.httpClient.get<Feedback>(this.customerFeedbackApiUrl + '/' + jobId);
    }
}
