import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { InputNumber } from 'primeng/inputnumber';
import { JobVdp, VdpLineItem, VdpLineItemMeasurement, Tank } from 'src/app/models';
import { VdpStorageEntryOptions } from 'src/app/models/job/vdp/job-vdp';

@Component({
  selector: 'app-job-vdp-vessel-tank',
  templateUrl: './job-vdp-vessel-tank.component.html',
  styleUrls: ['./job-vdp-vessel-tank.component.scss']
})
export class JobVdpVesselTankComponent implements OnInit {

  @Input() vdp: JobVdp;
  @Input() vdpLineItem: VdpLineItem = new VdpLineItem();
  @Input() vesselTanks: Tank[] = [];
  @Input() isNewVesselTank: boolean = false;
  @Output() vesselTankEditCanceled = new EventEmitter();
  @Output() vesselTankEditSaved = new EventEmitter<VdpLineItem>();

  value: number;
  entryOptions: SelectItem[] = [];
  tankOptions: SelectItem[] = [];

  constructor() { }

  ngOnInit(): void {
    if (!this.isNewVesselTank) {
      this.populateExistingData();      
    }
    this.buildOptions();
  }

  populateExistingData() {
    switch (this.vdp.storageEntry) {
      case VdpStorageEntryOptions.gsv: {
        if (this.vdpLineItem) {
          this.value = this.vdpLineItem.gsv;
        }
        break;
      }
      case VdpStorageEntryOptions.mt: {
        if (this.vdpLineItem) {
          this.value = this.vdpLineItem.mt;
        }
        break;
      }
      case VdpStorageEntryOptions.tov: {
        if (this.vdpLineItem) {
          this.value = this.vdpLineItem.tov;
        }
        break;
      }
      default:
        //report error
    }
  }

  buildOptions() {
    this.entryOptions = [];
    for (const option of Object.keys(VdpStorageEntryOptions)) {
      this.entryOptions.push({ value: VdpStorageEntryOptions[option], label: VdpStorageEntryOptions[option] });
    }

    this.tankOptions = this.vesselTanks.map(t => { return { label: t.name, value: t.id }});
  }

  cancel() {
    this.vesselTankEditCanceled.emit();
  }

  save() {
    switch (this.vdp.storageEntry) {
      case VdpStorageEntryOptions.gsv: {
        this.vdpLineItem.gsv = this.value;
        break;
      }
      case VdpStorageEntryOptions.mt: {
        this.vdpLineItem.mt = this.value;
        break;
      }
      case VdpStorageEntryOptions.tov: {
        this.vdpLineItem.tov = this.value;
        break;
      }
    }

    this.vesselTankEditSaved.emit(this.vdpLineItem);
  }

  entryOptionDisabled(): boolean {
    return this.vdp.lineItems.length > 0;
  }

  get canSave(): boolean {
    return this.vdpLineItem !== null && this.vdpLineItem.tankId !== undefined &&
      this.vdp && this.vdp.storageEntry && this.vdp.storageEntry !== '' &&
      this.value !== null && this.value !== undefined;
  }
}
