import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { merge, Observable, of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { User } from 'src/app/models/user';
import { ConfirmationService } from 'primeng/api';
import * as cloneDeep from 'lodash.clonedeep';
import { HttpClient } from '@angular/common/http';
import { ClientCompany } from 'src/app/models/system-config/client-company';
import { Terminal } from 'src/app/models/system-config/terminal';
import { UserService } from 'src/app/services/http-services/user.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  demoLoadTimeSeconds: number = 1.5;
  cols: any[] = [];
  skeletonRows: any[] = [];
  users: User[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedUser: User;
  infoWindow: any;
  showEditDialog = false;
  clients: ClientCompany[] = [];
  terminals: Terminal[] = [];

  constructor(
    protected httpClient: HttpClient,
    private store: Store<appState.State>,
    private userService: UserService,
    private appMessageService: AppMessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    console.log('init user list');
    this.cols = this.buildCols();
    this.skeletonRows = this.buildSkeletonRows();
    this.listenForClientChanges();
    this.listenForTerminalChanges();
    const addDelay = merge(of(null).pipe(delay(this.demoLoadTimeSeconds * 1000)));
    addDelay.subscribe(() => {
      this.listenForUserStateChanges();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForClientChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getClients))
      .subscribe(results => { 
        if (results)
          this.clients = [...results];
      })
    );
  }

  listenForTerminalChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTerminals))
        .subscribe(results => {
          if (results)
            this.terminals = [...results];
        })
    );
  }

  getUsers(): Observable<User[]> {
    //return await Promise.resolve().then(() => { return this.demoDataService.generateUsers(); });
    console.log('config service - getUsers()');
    return 
  }

  listenForUserStateChanges() {
    // TODO - get from state once we have a working model for populating state from API
    this.subscriptions.push(
      this.store.pipe(select(appState.getUsers))
      .subscribe(results => { 
        if(results){
          this.users = [...results];
          this.loaded = true;
        }
      })
    );
    // this.httpClient.get<Array<User>>('/api/user').subscribe(users => {
    //   this.users = users;
    //   this.loaded = true;
    // });
  }

  buildCols(): any [] {
    return [
      { field: 'firstName', header: 'First Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'lastName', header: 'Last Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'email', header: 'Email', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'roles', header: 'Roles', display: true, sortable: false, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Active', display: false, sortable: true, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addUser() {
    this.selectedUser = new User();
    this.showEditDialog = true;
  }

  openUser(termainal: User) {
   this.selectedUser = cloneDeep(termainal);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateUser(user: User) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate User: ' + user.email,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveUser: User = { ...user, active: false, displayName: user.displayName };
        this.store.dispatch(appActions.setUser({ data: deactiveUser }));
      }
    });
  }

  activateUser(user: User) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate User: ' + user.email,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveUser: User = { ...user, active: true, displayName: user.displayName };
        this.store.dispatch(appActions.setUser({ data: deactiveUser }));
      }
    });
  }
  
  rowClicked() {
    if (this.selectedUser) {
      const content = '<div class="p-grid>"' +
      '<div class="p-col-12">' +
      '<h3>' + this.selectedUser.lastName + ', ' + this.selectedUser.firstName + '</h3>' +
      '</div' +
      '<div class="p-col-12">' +
      '<p><b>Email :</b> ' + this.selectedUser.email + '</p>' +
      '<p><b>Roles :</b> ' + this.selectedUser.role + '</p>' +
      '</div>';
      this.infoWindow.setContent(content);
    }    
  }

  updateUser(user: User) {
    this.store.dispatch(appActions.setUser({ data: user }));
    this.showEditDialog = false;
  }

  async resetSelectedUser() {
    // this.users is already subscribed to latest state of users
    // reset selected user to state
    this.selectedUser = cloneDeep(this.users.find(u => u.id === this.selectedUser.id));
  }

  async resetPassword(user: User) {
    var success = true;
    await this.userService.resetPassword(user.email).toPromise()
      .catch(error =>{ 
        if (error.status != 200) {
          this.appMessageService.errorMessage('Error resetting password', error); 
          success = false;
        }
      });
    if (success)
      this.appMessageService.successMessage('Reset Password Successful', 'A temporary new password has been emailed to the user'); 
  }

}

