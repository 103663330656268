import { AuditableEntity } from "../../auditable-entity";
import { BlpLineItemMeasurement } from "./blp-line-item-measurement";

export class BlpLineItem extends AuditableEntity {
    blpId: string;
    step: number;
    tankLocation: string;
    tankId: string;
    apiNumber: number;
    nominatedQuantities: BlpLineItemMeasurement[] = [];
    lineNumbers: string;
    lineAdjustment: number;
    lineAdjustmentParcelNumber: number;
    adjustedQuantities: BlpLineItemMeasurement[] = [];
    comment: string;
    isExistingCargo: boolean;

    get isLoaded(): boolean {
        return this.adjustedQuantities && this.adjustedQuantities.some(a => a.value !== null && a.value !== undefined);
    }
}