export class VdpColumnDefinition {
    name: string;
    step?: number;
    lineText1: string;
    lineText2: string;
    lineText3: string;
    lineText4: string;
    lineText5: string;
    lineText6: string;
    lineText7: string;
    lineText8: string;
    lineText9: string;
    showLineIcon2: boolean = false;
    class1: string = VdpColumnStyleClasses.empty;
    class2: string = VdpColumnStyleClasses.empty;
    class3: string = VdpColumnStyleClasses.empty;
    class4: string = VdpColumnStyleClasses.empty;
    class5: string = VdpColumnStyleClasses.empty;
    class6: string = VdpColumnStyleClasses.empty;
    class7: string = VdpColumnStyleClasses.empty;
    class8: string = VdpColumnStyleClasses.empty;
    class9: string = VdpColumnStyleClasses.empty;
    allowOverflow1: boolean = false;
    allowOverflow2: boolean = false;
    allowOverflow3: boolean = false;
    allowOverflow4: boolean = false;
    allowOverflow5: boolean = false;
    allowOverflow6: boolean = false;
    allowOverflow7: boolean = false;
    allowOverflow8: boolean = false;
    allowOverflow9: boolean = false;
    showOnMobile: boolean = false;
    width: string = '120px';
    split9: boolean = false;
    showInputs: boolean = false;
}

export enum VdpColumnStyleClasses {
    empty = 'vdp-header-empty',
    shoreTankHeader = 'vdp-header-shore',
    vesselTankHeader = 'vdp-header-vessel',
    vesselTankHighlightedHeader = 'vdp-header-highlighted-vessel',
    lineAdjustemnetHeader = 'vdp-header-line-adjustement',
    parcelIndigo = 'vdp-header-parcel-indigo',
    greyedOut = 'vdp-header-greyed-out',
    stopHeader = 'vdp-header-stop',
    vesselTankValue = 'vdp-cell-vessel',
    shoreTankValue = 'vdp-cell-shore'
}