<div *ngIf="pageDataReady">

	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-card">
				<div class="flex flex-column card-container">

					<div class="flex align-items-center justify-content-center text-padding">
						<h1 *ngIf="isActive" class="title-alignment font-blue"><i>Active Assignments</i></h1>
						<h1 *ngIf="!isActive" class="title-alignment font-green"><i>Completed Assignments</i></h1>
					</div>

				</div>
			</div>
		</div>
	</div>

	<div class="p-grid">
		<div class="p-col-12">
			<p-table *ngIf="isActive" #activeJobsDt [value]="activeCustomerJobs"
							 styleClass="p-datatable-striped"
							 [columns]="activeJobCols"
							 [rows]="5"
							 [responsive]="true"
							 [paginator]="true"
							 [showCurrentPageReport]="true"
							 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
							 [rowHover]="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
							<div class="p-d-flex p-jc-between p-ai-center">
								{{col.header}}
								<p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
								<p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
								<p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
							</div>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-jobSummary let-columns="columns">
					<tr>
						<td *ngFor="let col of columns">
							<span class="p-column-title">{{ col.header }}</span>
							<span *ngIf="col.header === 'STATUS'">
								<p-button *ngIf="jobSummary.loadingStalled === false" label="Active" styleClass="p-button-info p-button-rounded" (onClick)="viewActiveJob(jobSummary.jobId)"></p-button>
								<p-button *ngIf="jobSummary.loadingStalled === true" label="Suspended" styleClass="p-button-danger p-button-rounded" (onClick)="viewActiveJob(jobSummary.jobId)"></p-button>
							</span>
							<span *ngIf="col.header === 'SHIP'">{{jobSummary.ship.name}}</span>
							<span *ngIf="col.header === 'LOADING STATUS'">{{jobSummary.loadingStatus}}</span>
							<span *ngIf="col.header === 'PROGRESS (%)'">{{jobSummary.percentLoaded | number: '1.0':'en-US'}}</span>
							<span *ngIf="col.header === 'NOMINATED'">{{jobSummary.nominatedVolume | number: '1.0':'en-US'}}</span>
							<span *ngIf="col.header === 'LOADED'">{{jobSummary.loadedVolume | number: '1.0':'en-US'}}</span>
							<span *ngIf="col.header === 'LOADING TIME'">{{jobSummary.loadingTime.hours}}:{{jobSummary.loadingTime.minutes}}</span>
							<span *ngIf="col.header === 'DOWN TIME'">{{jobSummary.downtime.hours}}:{{jobSummary.downtime.minutes}}</span>
							<span *ngIf="col.header === 'ALL FAST'">{{jobSummary.allFastTime | date: 'M/d/yyyy HH:mm'}}</span>
							<span *ngIf="col.header === 'ETC TIME'">{{jobSummary.estimatedCompletion | date: 'M/d/yyyy HH:mm'}}</span>
							<span *ngIf="col.header === 'FLAGGED'">
								<img *ngIf="jobSummary.loadingStalled === true" alt="Loading Suspended" src="assets/images/red-flag.png" [style]="{'margin-top':'10px', 'margin-left':'25px'}" height="24" width="18" class="vertical-align-middle" />
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>

			<p-table *ngIf="!isActive" #completedJobsDt [value]="completedCustomerJobs"
							 styleClass="p-datatable-striped"
							 [columns]="completedJobCols"
							 [rows]="5"
							 [responsive]="true"
							 [paginator]="true"
							 [showCurrentPageReport]="true"
							 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
							 [rowHover]="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns | colDisplayFilter" [pSortableColumn]="col.field">
							<div class="p-d-flex p-jc-between p-ai-center">
								{{col.header}}
								<p-sortIcon *ngIf="col.sortable" field="col.field"></p-sortIcon>
								<p-columnFilter *ngIf="col.textFilterable" type="text" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
								<p-columnFilter *ngIf="col.dateFilterable" type="date" [field]="col.searchField" display="menu" class="p-ml-auto"></p-columnFilter>
							</div>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-jobSummary let-columns="columns">
					<tr>
						<td *ngFor="let col of columns">
							<span class="p-column-title">{{ col.header }}</span>
							<span *ngIf="col.header === 'STATUS'">
								<p-button label="Completed" styleClass="p-button-success p-button-rounded" (onClick)="viewCompletedJob(jobSummary.jobId)"></p-button>
							</span>
							<span *ngIf="col.header === 'SHIP'">{{jobSummary.ship.name}}</span>
							<span *ngIf="col.header === 'JOB NUMBER'">{{jobSummary.jobNumber}}</span>
							<span *ngIf="col.header === 'NOMINATED'">{{jobSummary.nominatedVolume | number: '1.0':'en-US'}}</span>
							<span *ngIf="col.header === 'DOWN TIME'">{{jobSummary.downtime.hours}}:{{jobSummary.downtime.minutes}}</span>
							<span *ngIf="col.header === 'ALL FAST'">{{jobSummary.allFastTime | date: 'M/d/yyyy HH:mm'}}</span>
							<span *ngIf="col.header === 'COMPLETION'">{{jobSummary.completionDate | date: 'M/d/yyyy HH:mm'}}</span>
							<span *ngIf="col.header === 'FEEDBACK'">
								<button *ngIf="!jobSummary.survey" pButton type="button" class="p-button-success p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="createFeedback(jobSummary.jobId)" pTooltip="Create Feedback"></button>
								<button *ngIf="jobSummary.survey" pButton type="button" class="p-button-success p-button-rounded p-button-outlined action-icons" icon="pi pi-eye" (click)="viewFeedback(jobSummary.jobId)" pTooltip="View Feedback"></button>
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-card quick-look-background">
				<div class="flex flex-column card-container">
					<div class="flex align-items-center justify-content-center text-padding font-white">
						<h1 *ngIf="viewAssignment"><i>Quick Look: Last Updated {{ticker.createdOn | date: 'EEEE, MMMM d,y,h:mm a'}} (CST)</i></h1>
						<h1 *ngIf="!viewAssignment"><i>Quick Look: (No Active Jobs)</i></h1>
					</div>
					<div class="flex align-items-center justify-content-center text-padding font-white">
						<h2 *ngIf="viewAssignment">Ship: {{ticker.shipName}}</h2>
						<h2 *ngIf="!viewAssignment">Ship: N/A</h2>
					</div>
					<div class="flex align-items-center justify-content-center text-padding font-white">
						<h2 *ngIf="viewAssignment">Progress: {{ticker.percentLoaded}}%</h2>
						<h2 *ngIf="!viewAssignment">Progress: N/A</h2>
					</div>
					<div class="p-col-12 flex align-items-center justify-content-center button-padding">
						<p-button *ngIf="isActive" label="View Assignment" styleClass="p-button-raised p-button-lg" (onClick)="viewJobAssignment()" [disabled]="!viewAssignment"></p-button>
						<p-button *ngIf="!isActive" label="View Assignment" styleClass="p-button-raised p-button-lg p-button-success" (onClick)="viewJobAssignment()" [disabled]="!viewAssignment"></p-button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-grid">

		<div class="p-col-12">

			<div class="flex card-container">
				<div class="flex align-items-center justify-content-center text-padding center-alignment">
					<p-button *ngIf="isActive" style="{'margin-bottom':'2rem'}" label="View & Print Jobs List" (onClick)="downloadJobsList()" [disabled]="activeCustomerJobsCount === 0" pTooltip="View & Print Active Jobs List"></p-button>
					<p-button *ngIf="!isActive" styleClass="p-button-success" style="{'margin-bottom':'2rem'}" label="View & Print Jobs List" (onClick)="downloadJobsList()" [disabled]="completedCustomerJobsCount === 0" pTooltip="View & Print Completed Jobs List"></p-button>
				</div>
			</div>
			<div class="flex align-items-center justify-content-center text-padding center-alignment font-red">
				If no report is displayed, please enable all pop-ups from the site https://cdp.vesselblenders.com (coordinate with your local IT support if needed)
			</div>
	</div>


		</div>

</div>


