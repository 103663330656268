<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="comment" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Comment / Terminal PIC:</label>
                <div class="p-col-12 p-md-10">
                    <input id="comment" type="text" pInputText [(ngModel)]="lineItem.comments">
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>