import { Ship, Terminal } from 'src/app/models';
import { TimingInfo } from 'src/app/models/reports/job-performance';
import { ClientCompany } from 'src/app/models/system-config/client-company';

export class TerminalTrendReport {
    terminal: Terminal;
    customer: ClientCompany;
    trendInfos: TrendInfo[];
    downtimeByReasonsTrends: DowntimeTrendInfo[]
    downtimeByReasonChartData: any;
}

export class TrendInfo {
    category: string;
    trendTimingInfos: TrendTimingInfo[];
    chartData: any;
    chartOptions: any;
}

export class TrendTimingInfo {
    timingInfo: TimingInfo;
    jobInfos: JobTrendInfo[]
}

export class DowntimeTrendInfo {
    category: string;
    duration: number;
    durationText: string;
    categoryTimings: TimingInfo[];
}

export class CategoryTrendInfo {
    category: string;
    timingInfos: TimingInfo[];
}

export class JobTrendInfo {
    jobId: string;
    ship: Ship;
    terminal: Terminal;
    cargoCommenced: Date;
    completionTime: Date;
    displayValue: boolean;
    duration: number;
    durationText: string;
}