import { AuditableEntity } from "../auditable-entity";
import { Contact } from "./contact";

export class Company extends AuditableEntity {
    name: string;
    contacts: Contact[] = [];

    constructor() {
        super();
    }
}