import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlpParcel, Tank } from 'src/app/models';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-job-blp-parcel',
  templateUrl: './job-blp-parcel.component.html',
  styleUrls: ['./job-blp-parcel.component.scss']
})
export class JobBlpParcelComponent implements OnInit {

  @Input() vesselTanks: Tank[] = [];
  @Input() parcel: BlpParcel = new BlpParcel();
  @Output() parcelEditCanceled = new EventEmitter();
  @Output() parcelEditSaved = new EventEmitter();
 
  tankOptions: SelectItem[] = [];
  selectedTanks: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.buildTankOptions();
    this.selectedTanks = this.parcel.vesselTank?.split(',');
  }

  buildTankOptions() {
    this.tankOptions = this.vesselTanks.sort((a,b) => a.name > b.name ?  1 :  -1).map(c => { return { 'label': c.name, value: c.id }; });
  }

  tanksChanged() {
    this.parcel.vesselTank = this.selectedTanks?.join(',');
  }

  cancel() {
    this.parcelEditCanceled.emit();
  }

  save() {
    this.parcelEditSaved.emit();
  }

}
