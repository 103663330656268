<div *ngIf="ready" class="p-grid p-pl-2">
    <div class="p-col-12">
        <p-accordion (onOpen)="resetOpenDetail()">
            <p-accordionTab header="Loading Plan Revision Management" [selected]="!openDetail">
                <app-job-blp-revision-manager [blpRevisions]="blpRevisions" (newRevisionStarted)="revisionStarted()" (revisionSelected)="selectRevision($event)" (blpDownload)="download($event)"></app-job-blp-revision-manager>
            </p-accordionTab>
            <p-accordionTab *ngIf="blpSet" [header]="loadingPlanHeaderText">
                <app-job-blp-header [blp]="selectedBlpRevision"></app-job-blp-header>
            </p-accordionTab>
            <p-accordionTab *ngIf="blpSet" [header]="loadingPlanText" [selected]="openDetail">
                <app-job-blp-detail [blpToEdit]="selectedBlpRevision" [overview]="jobOverview" [shoreTanks]="shoreTanks" [vesselTanks]="vesselTanks" (blpReset)="reset()" (blpSaved)="save($event)"></app-job-blp-detail>
            </p-accordionTab>
        </p-accordion>
    </div>
    <!-- <p>BlpSet: {{blpSet}}</p>
    <p>selectedBlpRevision: {{selectedBlpRevisionJson}}</p> -->
    <!-- <div *ngIf="blpSet" class="p-col-12">
        <app-job-blp-detail [blp]="selectedBlpRevision" (blpReset)="reset()" (blpSaved)="save()"></app-job-blp-detail>
    </div> -->
</div>
