import { Weather } from "./weather";

export class HomeDetails {

  ticker: QuickLook;
  activeJobsCount: number;
  completedJobsCount: number;
  weather: Weather;

  constructor() {
  }
}

export class QuickLook {
  jobId: string;
  shipName: string;
  status: number;
  percentLoaded: number;
  createdOn: Date;

  constructor() {

  }
}
