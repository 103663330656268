import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bou } from 'src/app/models';

@Component({
  selector: 'app-job-bou-vessel-reporting',
  templateUrl: './job-bou-vessel-reporting.component.html',
  styleUrls: ['./job-bou-vessel-reporting.component.scss']
})
export class JobBouVesselReportingComponent implements OnInit {

  @Input() bou: Bou;
  @Output() editVesselReportingClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  editVesselReporting() {
    this.editVesselReportingClicked.emit();
  }
  
}
