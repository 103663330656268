// Not needed for Demo
// In full version, listen to each Job Section set action (i.e. setDemoJobOverview)
// And then call service method to create/update job accordingly

import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-common";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from '@angular/router';
import { TypedAction } from "@ngrx/store/src/models";
import { Observable, of } from "rxjs";
import { catchError, concatMap, map, mergeMap } from "rxjs/operators";
import { Bfr, Bou, ClientCompany, Contact, Dock, InspectionCompany, JobBlpRevision, JobOverview, JobVdp, Port, Terminal, TimeTrackingEvent, User, Tank, Ship, Product } from "../models";
import { TimeTrackingEventLog } from "../models/job/time-tracking/time-tracking-event-log";
import { SystemConfiguration } from "../models/system-config/system-configuration";
import { TimingCategory } from "../models/system-config/timing-category";
import { TimingCategoryService } from "../services/http-services/timingCategory.service";
import { AppMessageService } from "../services/app-message.service";
import { BouManagementService } from "../services/bou-managment.service";
import { JobService } from "../services/http-services/job.service";
import { SystemConfigurationService } from "../services/http-services/system-configuration.service";
import { UserService } from "../services/http-services/user.service";
import * as appActions from './app.actions';

@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private appMessageService: AppMessageService,
    private userHttpService: UserService,
    private systemConfigurationHttpService: SystemConfigurationService,
    private jobHttpService: JobService,
    private bouManagementService: BouManagementService,
    private timingCategoryHttpService: TimingCategoryService) {
  }

  getTimingCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.getTimingCategories),
      map((action: appActions.GetTimingCategoriesAction) => action),
      mergeMap(() => this.timingCategoryHttpService.getTimingCategories()
        .pipe(
          map((catData: TimingCategory[]) => {
            return appActions.getTimingCategoriesSuccess({ data: catData })
          }),
          catchError(errorCaught => {
            this.appMessageService.errorMessage('Get Timing Categories Error', errorCaught, true);
            return of(appActions.updateConfigurationFailure({ configurationType: 'System', error: errorCaught }));
          })
        )
      )
    )
  );

  refreshSystemConfiguration$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.refreshSystemConfiguration),
        map((action: appActions.RefreshSystemConfiguration) => action),
        mergeMap(() => this.systemConfigurationHttpService.getSystemConfiguration()
          .pipe(
            map((config: SystemConfiguration) => {
              return appActions.setSystemConfiguration({ configuration: config });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Refreshing System Configuration Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'System', error: errorCaught }));
            })
          )
        )
      )
  );

  setPort$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setPortLocation),
        map((action: appActions.SetPortLocationAction) => action.data),
        mergeMap((port: Port) => this.systemConfigurationHttpService.upsertPort(port)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Port Saved', '', true);
              return appActions.setPortLocationSuccess({ data: port });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Port Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Port', error: errorCaught }));
            })
          )
        )
      )
  );

  setShip$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setShip),
        map((action: appActions.SetShipAction) => action.data),
        mergeMap((ship: Ship) => this.systemConfigurationHttpService.upsertShip(ship)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Ship Saved', '', true);
              return appActions.setShipSuccess({ data: ship });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Ship Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Ship', error: errorCaught }));
            })
          )
        )
      )
  );

  setCargo$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setCargo),
        map((action: appActions.SetCargoAction) => action.data),
        mergeMap((cargo: Product) => this.systemConfigurationHttpService.upsertProduct(cargo)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Cargo Saved', '', true);
              return appActions.setCargoSuccess({ data: cargo });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Cargo Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Cargo', error: errorCaught }));
            })
          )
        )
      )
  );

  setTerminal$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setTerminal),
        map((action: appActions.SetTerminalAction) => action.data),
        mergeMap((terminal: Terminal) => this.systemConfigurationHttpService.upsertTerminal(terminal)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Terminal Saved', '', true);
              return appActions.setTerminalSuccess({ data: terminal });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Terminal Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Terminal', error: errorCaught }));
            })
          )
        )
      )
  );

  setDock$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setDock),
        map((action: appActions.SetDockAction) => action.data),
        mergeMap((dock: Dock) => this.systemConfigurationHttpService.upsertDock(dock)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Dock Saved', '', true);
              return appActions.setDockSuccess({ data: dock });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Dock Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Dock', error: errorCaught }));
            })
          )
        )
      )
  );

  setTank$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setTank),
        map((action: appActions.SetTankAction) => action.data),
        mergeMap((tank: Tank) => this.systemConfigurationHttpService.upsertTank(tank)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Tank Saved', '', true);
              return appActions.setTankSuccess({ data: tank });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Tank Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Tank', error: errorCaught }));
            })
          )
        )
      )
  );

  setClient$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setClientCompany),
        map((action: appActions.SetClientCompanyAction) => action.data),
        mergeMap((clientCompany: ClientCompany) => this.systemConfigurationHttpService.upsertClientCompany(clientCompany)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Client Company Saved', '', true);
              return appActions.setClientCompanySuccess({ data: clientCompany });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Client Company Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'ClientCompany', error: errorCaught }));
            })
          )
        )
      )
  );

  setInspectionCompany$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setInspectionCompany),
        map((action: appActions.SetInspectionCompanyAction) => action.data),
        mergeMap((inspectionCompany: InspectionCompany) => this.systemConfigurationHttpService.upsertInspectionCompany(inspectionCompany)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Inspection Company Saved', '', true);
              return appActions.setInspectionCompanySuccess({ data: inspectionCompany });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Inspection Company Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'InspectionCompany', error: errorCaught }));
            })
          )
        )
      )
  );

  setContact$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setContact),
        map((action: appActions.SetContactAction) => action.data),
        mergeMap((contact: Contact) => this.systemConfigurationHttpService.upsertContact(contact)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Contact Saved', '', true);
              return appActions.setContactSuccess({ data: contact });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Contact Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Contact', error: errorCaught }));
            })
          )
        )
      )
  );

  setTimeTrackingEvent$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setTimeTrackingEvent),
        map((action: appActions.SetTimeTrackingEventAction) => action.data),
        mergeMap((timeTrackingEvent: TimeTrackingEvent) => this.systemConfigurationHttpService.upsertTimeTrackingEvent(timeTrackingEvent)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('TimeTrackingEvent Saved', '', true);
              return appActions.setTimeTrackingEventSuccess({ data: timeTrackingEvent });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('TimeTrackingEvent Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'TimeTrackingEvent', error: errorCaught }));
            })
          )
        )
      )
  );

  setTimingCategory$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setTimingCategory),
        map((action: appActions.SetTimingCategoryAction) => action.data),
        mergeMap((timingCategory: TimingCategory) => this.timingCategoryHttpService.upsertTimingCategory(timingCategory)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Timing Categories Saved', '', true);
              return appActions.setTimingCategorySuccess({ data: timingCategory });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Timing Categories Save Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'TimingCategory', error: errorCaught }));
            })
          )
        )
      )
  );

  // When Job Overview modified is dispatched, call service  
  //
  setJobOverview$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setJobOverview),
        map((action: appActions.SetJobOverview) => action.overview),
        mergeMap((overviewAction: appActions.SetJobActionProperties<JobOverview>) => this.jobHttpService.upsertOverview(overviewAction.jobId, overviewAction.data)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Job Overview Saved', '', true);
              if (overviewAction.jobNumber == undefined)
                this.router.navigate(['jobs/' + overviewAction.jobId]);
              return appActions.jobSavedSuccessfully({ jobSection: 'Overview' });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Job Overview Saving Error', errorCaught, true);
              return of(appActions.jobSavedFailed({ jobSection: 'Overview', error: errorCaught }));
            })
          )
        )
      )
  );

  setJobBlp$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setJobBlp),
        map((action: appActions.SetBlp) => action.blpRevision),
        mergeMap((blpAction: appActions.SetJobActionProperties<JobBlpRevision>) => this.jobHttpService.upsertBlpRevision(blpAction.jobId, blpAction.data)
          .pipe(
            concatMap((result: JobBlpRevision) => [
              this.sendSuccessMessageAndReturnJobSaveSuccessDispatch('Job BLP Saved', 'BLP'),
              appActions.jobBlpSavedSuccessfully({
                blpRevision: {
                  ...blpAction.data,
                  revisionNumber: result?.revisionNumber,
                  lineItems: result.lineItems,
                  existingCargo: result.existingCargo
                }
              }),
              appActions.setJobInBlpEditMode({ inBlpEditMode: false }),
            ]),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Job BLP Saving Error', errorCaught, true);
              return of(appActions.jobSavedFailed({ jobSection: 'BLP', error: errorCaught }));
            })
          )
        )
      )
  );

  setJobTimeTrackingEventLog$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setJobTimeTrackingEventLog),
        map((action: appActions.SetTimeTrackingEventLog) => action.log),
        mergeMap((ttLogAction: appActions.SetJobActionProperties<TimeTrackingEventLog>) => this.jobHttpService.upsertTimeTrackingEventLog(ttLogAction.jobId, ttLogAction.data)
          .pipe(
            concatMap(() => [
              this.sendSuccessMessageAndReturnJobSaveSuccessDispatch('Job Time Tracking Saved', 'Time Tracking'),
              appActions.jobTimeTrackingSavedSuccessfully({ log: ttLogAction.data }),
            ]),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Job Time Tracking Saving Error', errorCaught, true);
              return of(appActions.jobSavedFailed({ jobSection: 'Time Tracking', error: errorCaught }));
            })
          )
        )
      )
  );

  setBfr$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setBfr),
        map((action: appActions.SetBfr) => action.bfr),
        mergeMap((bfrAction: appActions.SetJobActionProperties<Bfr>) => this.jobHttpService.upsertBfr(bfrAction.jobId, bfrAction.data)
          .pipe(
            concatMap(() => [
              this.sendSuccessMessageAndReturnJobSaveSuccessDispatch('Job BFR Saved', 'BFR'),
              appActions.jobBfrSavedSuccessfully({ bfr: bfrAction.data }),
            ]),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Job BFR Saving Error', errorCaught, true);
              return of(appActions.jobSavedFailed({ jobSection: 'BFR', error: errorCaught }));
            })
          )
        )
      )
  );

  setVdp$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setJobVdp),
        map((action: appActions.SetVdp) => action.vdp),
        mergeMap((vdpAction: appActions.SetJobActionProperties<JobVdp>) => this.jobHttpService.upsertVdp(vdpAction.jobId, vdpAction.data)
          .pipe(
            concatMap(() => [
              this.sendSuccessMessageAndReturnJobSaveSuccessDispatch('Job VDP Saved', 'VDP'),
              appActions.jobVdpSavedSuccessfully({ vdp: vdpAction.data }),
            ]),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Job VDP Saving Error', errorCaught, true);
              return of(appActions.jobSavedFailed({ jobSection: 'VDP', error: errorCaught }));
            })
          )
        )
      )
  );

  setBou$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setBou),
        map((action: appActions.SetBou) => action.bou),
        mergeMap((bouAction: appActions.SetJobActionProperties<Bou>) => this.jobHttpService.upsertBou(bouAction.jobId, bouAction.data)
          .pipe(
            concatMap(() => [
              this.sendSuccessMessageAndReturnJobSaveSuccessDispatch('Job BOU Saved', 'BOU', bouAction.silent),
              appActions.jobBouSavedSuccessfully({ bou: bouAction.data }),
            ]),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Job BOU Saving Error', errorCaught, true);
              return of(appActions.jobSavedFailed({ jobSection: 'BOU', error: errorCaught }));
            })
          )
        )
      )
  );

  private sendSuccessMessageAndReturnJobSaveSuccessDispatch(message: string, jobSection: string, silent: boolean = false): TypedAction<any> {
    if (!silent) {
      this.appMessageService.successMessage(message, '', true);
    }
    return appActions.jobSavedSuccessfully({ jobSection: jobSection });
  }

  setUser$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setUser),
        map((action: appActions.SetUserAction) => action.data),
        mergeMap((user: User) => this.userHttpService.upsertUser(user)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('User Saved', '', true);
              return appActions.setUserSuccess({ data: user });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('User Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'User', error: errorCaught }));
            })
          )
        )
      )
  );

  setCurrentUserFromToken$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setCurrentUserFromToken),
        map((action: appActions.SetCurrentUserFromToken) => action.accountInfo),
        mergeMap((accountInfo: AccountInfo) => this.userHttpService.getUserByEmail(accountInfo.username)
          .pipe(
            map((user: User) => {
              return appActions.setCurrentUserFromTokenSuccess({ user: user });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Failed to get user from token info', errorCaught, true);
              return of(appActions.setCurrentUserFromTokenFailure({ error: errorCaught }));
            })
          )
        )
      )
  );

  // To ensure the server always gets the latest version of the BOU when other parts of the job is saved,
  // chain the following effect on all job saves (except for BOU section) to process the Bou and save it silently
  jobSavedSuccessfully$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.jobSavedSuccessfully),
        map((action: appActions.JobSavedSuccessfully) => action.jobSection),
        mergeMap((jobSection: string) => {
          return jobSection !== 'BOU' ?
            this.bouManagementService.silentlyProcessBou() :
            new Observable(subcriber => subcriber.complete())
        })
      )
  );

  setProduct$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(appActions.setProduct),
        map((action: appActions.SetProductAction) => action.data),
        mergeMap((product: Product) => this.systemConfigurationHttpService.upsertProduct(product)
          .pipe(
            map(() => {
              this.appMessageService.successMessage('Product Saved', '', true);
              return appActions.setProductSuccess({ data: product });
            }),
            catchError(errorCaught => {
              this.appMessageService.errorMessage('Product Saving Error', errorCaught, true);
              return of(appActions.updateConfigurationFailure({ configurationType: 'Product', error: errorCaught }));
            })
          )
        )
      )
  );
}
