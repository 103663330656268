import { AuditableEntity } from "../../auditable-entity";
import { User } from "../../user";
import { BlpCalcMeasurement } from "./blp-calc-measurement";
import { BlpLineItem } from "./blp-line-item";
import { BlpOverride } from "./blp-override";
import { BlpParcel } from "./blp-parcel";

export class JobBlpRevision extends AuditableEntity {

    jobId: string;
    revisionNumber: number = 0;
    revisionDate: Date;
    preparedById: string;

    // Inputs
    parcels: BlpParcel[] = [];
    allLineItems: BlpLineItem[] = [];
    adjustedQuantityOverrides: BlpOverride[] = [];

    // Calculated values
    nominatedQuantityTotals: BlpCalcMeasurement[] = [];
    adjustedQuantityTotals: BlpCalcMeasurement[] = [];
    stepTotals: BlpCalcMeasurement[] = [];
    allStepTotal: number;
    parcelFinalApi: BlpCalcMeasurement[] = [];

    // Was original property
    // now readonly that filters out existing cargo
    get lineItems(): BlpLineItem[] {
        return this.allLineItems ? this.allLineItems.filter(l => !l.isExistingCargo) : [];
    }
    // Existing cargo add as special handled line item
    // is part of allLineItems to store in db together
    get existingCargo(): BlpLineItem {
        return this.allLineItems ? this.allLineItems.find(l => l.isExistingCargo) : null;
    }

    constructor() {
        super();
    }
}
