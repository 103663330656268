import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { Bfr, BfrTank, BlpStepTank, JobBlpRevision, User, Tank, JobOverview } from 'src/app/models';
import { BfrHeader } from 'src/app/models/job/bfr/bfr-header';
import { DatePipe } from '@angular/common';
import { BfrManagementService } from 'src/app/services/bfr-management.service';
import { DownloadsService } from '../../../services/http-services/downloads.service';
import { BfrTableData } from 'src/app/models/job/bfr/bfr-table-data';
import * as cloneDeep from 'lodash.clonedeep';
import { SelectItem } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { take } from 'rxjs/operators';
import { AppMessageService } from '../../../services/app-message.service';

@Component({
  selector: 'app-job-bfr',
  templateUrl: './job-bfr.component.html',
  styleUrls: ['./job-bfr.component.scss']
})
export class JobBfrComponent implements OnInit, OnDestroy {

  @Input() shoreTanks: Tank[];
  ready: boolean = true;
  blocked: boolean = true;
  subscriptions: Subscription[] = [];
  @ViewChild('section') section: ElementRef;
  blpTanks: BlpStepTank[] = [];
  bfrHeader: BfrHeader = new BfrHeader();
  largeTableCols: any[] = [];
  mobileTableCols: any[] = [];
  tableData: BfrTableData[];
  bfr: Bfr;
  jobOverview: JobOverview;
  jobId: string;
  selectedBfrTank: BfrTank;
  showEditDialog: boolean = false;
  latestBlp: JobBlpRevision;
  shoreTank: Tank;
  tankOptions: string[] = [];
  tankOptionId: string;
  selectedTanks: string[] = [];
  selectedTankList: string[] = [];
  showPopUp: boolean = false;
  selectedIds: BfrTank;
  jobNum: string;

  constructor(
    private store: Store<appState.State>,
    private cdr: ChangeDetectorRef,
    private downloadsService: DownloadsService,
    private appMessageService: AppMessageService,
    private datePipe: DatePipe,
    private bfrManagementService: BfrManagementService) { }

  ngOnInit(): void {
    this.listenForBlpSaved();
    this.listenForJob();
    this.largeTableCols = this.bfrManagementService.buildLargeTableCols();
    this.mobileTableCols = this.bfrManagementService.buildMobileTableCols();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForBlpSaved() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJobIsBlpBlocked)).subscribe(isBlocked => {
        this.blocked = isBlocked;
        this.cdr.detectChanges();
        this.buildTankOptions();
      })
    );

    this.subscriptions.push(
      this.store.pipe(select(appState.getJobAllTanksWithStepNumbers)).subscribe(
        tanks => {
          if (tanks) {
            this.blpTanks = [...tanks].sort((a, b) => a.step > b.step ? 1 : -1);
            this.bfr = this.buildBfr(this.bfr, this.jobId, this.blpTanks);
            this.tableData = this.bfrManagementService.buildTableData(this.bfr, this.shoreTanks);
          }
        })
    );

    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJobLatestBlp)).subscribe(
        blp => {
          this.latestBlp = blp;
        })
    );
  }

  listenForJob() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getSelectedJob)).subscribe(job => {
        // Set Header Details
        if (job && job.overview) {
          this.jobId = job.id;
          this.bfrHeader.vessel = job.overview.ship?.name;
          this.bfrHeader.port = job.overview.port ? job.overview.port.name : '';
          this.bfrHeader.terminal = job.overview.terminal ? job.overview.terminal.name : '';
          this.bfrHeader.date = this.datePipe.transform(job.overview.date, 'EEEE, MMMM d, y');
          this.jobNum = job.jobNumber;
          this.bfrHeader.superintendents = job.overview.superintendents ?
            job.overview.superintendents.map(s => User.fromUserProperties(s).displayName + ' ' + (s.phone ? s.phone : '')).join(', ') :
            '';
        }

        // Update Bfr
        this.bfr = this.buildBfr(cloneDeep(job.bfr), this.jobId, this.blpTanks);
        this.tableData = this.bfrManagementService.buildTableData(this.bfr, this.shoreTanks);
      })
    );
  }

  async buildTankOptions(): Promise<void> {
    let tanks = [...await this.store.pipe(select(appState.getJobAllTanksWithStepNumbers)).pipe(take(1)).toPromise()];
    tanks = tanks.filter(i => i.tankId);
    let tanks2 = [...await this.store.pipe(select(appState.getTanks)).pipe(take(1)).toPromise()];
    for (let i = 0; i < tanks.length; i++) {
      for (let j = 0; j < tanks2.length; j++) {
        if (tanks[i].tankId == tanks2[j].id) {
          this.tankOptions[i] = tanks2[j].name;
        }
      }
    }
    for (let i = 0; i < this.tankOptions.length; i++) {
      this.selectedTanks[i] = this.tankOptions[i];
    }
  }

  buildBfr(bfr: Bfr, jobId: string, blpTanks: BlpStepTank[]): Bfr {
    if (!bfr || !bfr.jobId || (bfr.jobId !== jobId)) {
      return this.bfrManagementService.buildNewBfrFromTanks(blpTanks, jobId);
    } else {
      let resultBfr = this.bfrManagementService.updateBfrFromTanks(bfr, blpTanks);
      this.bfrManagementService.calculateBfr(resultBfr, this.latestBlp);
      return resultBfr;
    }
  }

  editTank(stepNumber: number) {
    this.selectedBfrTank = this.bfr.bfrTanks.find(bt => bt.stepNumber === stepNumber);
    this.shoreTank = this.shoreTanks.find(t => t.id == this.selectedBfrTank.tankId);
    this.showEditDialog = true;
  }

  editCancelled() {
    this.showEditDialog = false;
    this.selectedBfrTank = null;
  }

  tankSaved() {
    this.bfr = this.bfrManagementService.calculateBfr(this.bfr, this.latestBlp);
    this.tableData = this.bfrManagementService.buildTableData(this.bfr, this.shoreTanks);
    this.showEditDialog = false;
    this.selectedBfrTank = null;
  }

  save() {
    this.bfr = this.bfrManagementService.calculateBfr(this.bfr, this.latestBlp);
    this.tableData = this.bfrManagementService.buildTableData(this.bfr, this.shoreTanks);
    
    const setBfrProperties = new appActions.SetJobActionProperties<Bfr>();
    setBfrProperties.id = this.bfr.id;
    setBfrProperties.jobId = this.bfr.jobId;
    setBfrProperties.data = this.bfr;
    this.store.dispatch(appActions.setBfr({ bfr: setBfrProperties }));

    this.closeDialog();
  }

  reset() {
    // TODO  
    this.closeDialog();
  }

  closeDialog() {
    this.selectedBfrTank = null;
    this.showEditDialog = false;
  }

  openPopUp() {
    console.log("PopUp Called");
    this.showPopUp = true;
  }

  async onTanksChanged() {
    console.log("Tanks Changed Called");
    console.log(this.jobNum);
    console.log(this.selectedTanks);
    this.tankOptionId = null;
    var d = "|";
    if (this.bfr.jobId) {
      if (this.selectedTanks.length > 0) {
        let tanks2 = [...await this.store.pipe(select(appState.getTanks)).pipe(take(1)).toPromise()];
        for (let k = 0; k < tanks2.length; k++) {
          for (let j = 0; j < this.selectedTanks.length; j++) {
            if (tanks2[k].name == this.selectedTanks[j]) {
              if (this.tankOptionId == null)
                this.tankOptionId = tanks2[k].id;
              else
                this.tankOptionId = this.tankOptionId + d + tanks2[k].id;
            }
          }
        }
        console.log(this.tankOptionId);
        this.downloadsService.downloadBfr(this.bfr.jobId, this.tankOptionId);
      }
      else {
        this.appMessageService.warnMessage('Cannot download BFR', 'Please Select at least 1 tank');
      }
    } else {
      this.appMessageService.warnMessage('Cannot download BFR', 'Job details are not known');
    }
  }
}

