import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import { Terminal } from 'src/app/models/system-config/terminal';
import { Tank } from 'src/app/models/system-config/tank';
import { Ship } from 'src/app/models/system-config/ship';
import { ConfirmationService } from 'primeng/api';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'app-tank-list',
  templateUrl: './tank-list.component.html',
  styleUrls: ['./tank-list.component.scss']
})
export class TankListComponent implements OnInit, OnDestroy {

  cols: any[] = [];
  skeletonRows: any[] = [];
  tanks: Tank[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedTank: Tank;
  showEditDialog = false;
  ships: Ship[] = [];
  terminals: Terminal[] = [];

  constructor(private store: Store<appState.State>,
    private actions$: Actions,
    private confirmationService: ConfirmationService,
    private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForTerminalChanges();
    this.listenForShipChanges();
    this.listenForTankChanges();
    this.listenForTankUpdated();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  buildSkeletonRows(): number[] {
    return [1, 2, 3, 4, 5];
  }

  buildCols(): any[] {
    return [
      { field: 'name', header: 'Id', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'location', header: 'Location', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'isShipTank', header: 'Type', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'shipInfo', header: 'Ship', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'terminalInfo', header: 'Terminal', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  getType(tank: Tank): string {
    return tank.isShipTank ? 'Ship' : 'Terminal';
  }

  listenForShipChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getShips))
        .subscribe(results => {
          if (results)
            this.ships = [...results];
        })
    );
  }

  listenForTerminalChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTerminals))
        .subscribe(results => {
          if (results)
            this.terminals = [...results];
        })
    );
  }

  listenForTankChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getTanks))
        .subscribe(results => {
          if (results) {
            this.tanks = JSON.parse(JSON.stringify(results));
            this.tanks.forEach(t => {
              if (t.isShipTank)
                t.shipInfo = t.ship?.name + ' ' + t.ship?.imoNo;
              else
                t.terminalInfo = t.terminal?.port?.name + ' ' + t.terminal?.name;
            })
          }
          this.loaded = true;
        })
    );
  }

  listenForTankUpdated() {
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(appActions.setTankSuccess))
        .subscribe(() => this.showEditDialog = false)
    );
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addTank() {
    this.selectedTank = new Tank();
    this.showEditDialog = true;
  }

  editTank(tank: Tank) {
    this.selectedTank = cloneDeep(tank);  // separate reference from state
    this.showEditDialog = true;
  }

  deactivateTank(tank: Tank) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Tank: ' + tank.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeTank = { ...tank, active: false };
        this.store.dispatch(appActions.setTank({ data: activeTank }));
      }
    });
  }

  activateTank(tank: Tank) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Tank: ' + tank.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const inactiveTank = { ...tank, active: true };
        this.store.dispatch(appActions.setTank({ data: inactiveTank }));
      }
    });
  }

  updateTank(tank: Tank) {
    if (tank.isShipTank == null) {
      this.appMessageService.errorMessage('Tank type Missing', 'Please specify a tank type');
      return;
    }
    if (tank.isShipTank) {
      if (!tank.ship) {
        this.appMessageService.errorMessage('Ship Missing', 'Please select a ship');
        return;
      }
      tank.terminal = null;
      tank.location = null;
    } else {
      if (!tank.terminal) {
        this.appMessageService.errorMessage('Terminal Missing', 'Please select a terminal');
        return;
      }
      tank.ship = null;
    }
    this.store.dispatch(appActions.setTank({ data: tank }));
    // this.showEditDialog = false;
  }

  async resetSelectedTank() {
    // this.tanks is already subscribed to latest state of Tanks
    // reset selected Tank to state
    this.selectedTank = cloneDeep(this.tanks.find(t => t.id === this.selectedTank.id));
  }

}
