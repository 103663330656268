import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Terminal, Tank, Ship } from 'src/app/models';

@Component({
  selector: 'app-tank-detail',
  templateUrl: './tank-detail.component.html',
  styleUrls: ['./tank-detail.component.scss']
})
export class TankDetailComponent implements OnInit {

  @Input() tank: Tank;
  @Input() terminals: Terminal[] = [];
  @Input() ships: Ship[] = [];
  @Output() tankUpdated = new EventEmitter<Tank>();
  @Output() resetTank = new EventEmitter();

  tankTypes: any[] = [];
  terminalOptions: SelectItem[] = [];
  shipOptions: SelectItem[] = [];

  constructor() { }

  ngOnInit(): void {
    this.tankTypes = [{ label: 'Terminal', value: false }, { label: 'Ship', value: true }];
    this.terminalOptions = this.buildTerminalOptions();
    this.shipOptions = this.buildShipOptions();
  }

  buildTerminalOptions(): SelectItem[] {
    const test = this.terminals.sort((a, b) => a.name > b.name ? 1 : -1);
    const test2 = test.map(t => { return { label: t.name, value: t }; });
    return this.terminals.sort((a, b) => a.name > b.name ? 1 : -1).map(t => { return { label: t.name, value: t }; });
  }

  buildShipOptions(): SelectItem[] {
    return this.ships.sort((a, b) => a.name > b.name ? 1 : -1).map(v => { return { label: v.name + ' ' + v.imoNo, value: v }; });
  }

  save() {
    this.tankUpdated.emit(this.tank);
  }

  reset() {
    this.resetTank.emit();
  }

}
