<div class="p-grid">
    <div class="p-col-12 p-md-8">
        <p-table [value]="[1]">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Received</th>
                    <th>V.E.F.</th>
                    <th>Adjusted</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body">
                <tr>
                    <td><button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editVesselReporting()" pTooltip="Edit Vessl Reporting"></button></td>
                    <td>{{bou.vesselReportingReceived | number: '1.0-2'}}</td>
                    <td>{{bou.vesselReportingVef | number: '1.0-5'}}</td>
                    <td>{{bou.vesselReportingAdjusted | number: '1.0-0' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-col-12 p-md-4">
        <div class="p-grid">
            <div *ngIf="bou.vesselReportingDifference" class="p-col-3 difference-metric p-text-center highlighted1">
                {{bou.vesselReportingDifference | number: '1.0-1'}}
            </div>
            <div *ngIf="!bou.vesselReportingDifference && bou.vesselReportingDifference !== 0" class="p-col-3 difference-metric-pending p-text-center highlighted1">
                Pending
            </div>
            <div class="p-col-1">
                <p-divider layout="vertical"></p-divider>
            </div>
            <div class="p-col-7">
                <div class="p-grid">
                    <div class="p-col-3 difference-percent">{{bou.vesselReportingApparentDifferencePercent ? (bou.vesselReportingApparentDifferencePercent | number: '1.2-3') : 0 }}%</div>
                    <div class="p-col-7">
                        <p class="difference-title">Apparent Difference</p>
                        <p class="difference-text">(As of completion of last transfer)</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="p-d-flex full-height">
            <div class="difference-metric">{{bou.vesselReportingDifference ? bou.vesselReportingDifference : 0}}</div>
            <p-divider layout="vertical"></p-divider>
            <div class="p-grid">
                <div class="p-col-4 difference-percent">0.0%</div>
                <div class="p-col-8">
                    <p class="difference-title">Apparent Difference</p>
                    <p class="difference-text">(As of completion of last transfer)</p>
                </div>
            </div>
        </div> -->
    </div>
</div>

