<div *ngIf="ready" class="p-grid p-pl-2">
    <div class="p-col-12">
        <p-accordion>
            <p-accordionTab header="VDP Management">
                <app-job-vdp-manager [vdps]="vdps" (vdpSelected)="selectVdp($event)"></app-job-vdp-manager>
            </p-accordionTab>
            <p-accordionTab *ngIf="vdpSet" [header]="vdpText">
                <app-job-vdp-detail [vdp]="selectedVdp" [vesselTanks]="jobVesselTanks" [shoreTanks]="shoreTanks" (vdpReset)="reset()" (vdpSaved)="save($event)"></app-job-vdp-detail>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
