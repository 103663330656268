<p-blockUI [target]="section" [blocked]="blocked">
    <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>
<app-blockable-div #section [class]="'p-grid p-pl-2'">
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-10 p-col-12">
                <app-job-bou-header [bou]="bou"></app-job-bou-header>
            </div>
            <div class="p-md-2 p-col-12">
                <p-button label="Download" [disabled]="blocked" styleClass="p-button-help" icon="pi pi-download" (onClick)="downloadBou()" pTooltip="Download the BOU" [style]="{'width':'100%'}"></p-button>
            </div>
        </div>
        
    </div>
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center bou-divider-content">
            Status
        </div>
    </p-divider>
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12">
                    <p-dropdown id="astmTableOption" [options]="bouStatusOptions" [editable]="true" [(ngModel)]="bou.statusDetail" appendTo="body" placeholder="Select Status"></p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center bou-divider-content">
            Key Times & O.B.Q.
        </div>
    </p-divider>
    <div class="p-col-12">
        <app-job-bou-key-times [bou]="bou" [blp]="latestBlp" [shoreTanks]="shoreTanks" (obqUpdated)="updateBou()"></app-job-bou-key-times>
    </div>
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center bou-divider-content">
            Shore/Barge Reporting
        </div>
    </p-divider>
    <div class="p-col-12">
        <app-job-bou-shore-barge-reporting
            [bou]="bou"
            (shoreBargeReportingUpdated)="updateBou()"
            (selectedEstimation)="editEstimation($event)"
            (selectedActual)="editActual($event)"
            ></app-job-bou-shore-barge-reporting>
    </div>
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center bou-divider-content">
            Vessel Reporting
        </div>
    </p-divider>
    <div class="p-col-12">
        <app-job-bou-vessel-reporting [bou]="bou" (editVesselReportingClicked)="editVesselReporting()"></app-job-bou-vessel-reporting>
    </div>
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center bou-divider-content">
            Operational Notes
        </div>
    </p-divider>
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12">
                    <textarea id="operationalNotes" pInputTextarea [(ngModel)]="bou.operationalNotes" [rows]="5"></textarea> 
                </div>
            </div>
        </div>
    </div>
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center bou-divider-content p-text-light">
            General Notes
        </div>
    </p-divider>
    <div class="p-col-12 p-text-light">
        <ol type="1">
            <li>All Time Estimates are estimates only and do not account for unforeseen circumstances.</li>
            <li>Time Estimates include a time increment to account for switching loading Origins.</li>
            <li>Numbers in parenthesis denote negative values.  Ex. (1029) = -1029 = negative 1029.</li>
            <li>All figures are in G.S.V. Barrels.</li>
        </ol>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-md-6 p-col-12">
                <p-button label="Reset" [disabled]="blocked" icon="pi pi-refresh" styleClass="p-button-secondary" (onClick)="reset()" [style]="{'width': '100%'}"></p-button>
            </div>
            <div class="p-md-6 p-col-12">
                <p-button label="Save" [disabled]="blocked" icon="pi pi-save" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
            </div>
        </div>
    </div>
</app-blockable-div>

<p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditEstimationDialog" [(visible)]="showEditEstimationDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
       <app-job-bou-estimation-detail
        [lineItem]="selectedEstimationLineItem"
        (saved)="saveEstimation()"
        (cancelled)="editEstimationCancelled()"
       ></app-job-bou-estimation-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showEditEstimationDialog" [(visible)]="showEditEstimationDialog" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-bou-estimation-detail
         [lineItem]="selectedEstimationLineItem"
         (saved)="saveEstimation()"
         (cancelled)="editEstimationCancelled()"
        ></app-job-bou-estimation-detail>
         <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
     </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditActualDialog" [(visible)]="showEditActualDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
       <app-job-bou-actual-detail
        [lineItem]="selectedActualLineItem"
        (saved)="saveActual()"
        (cancelled)="editActualCancelled()"
       ></app-job-bou-actual-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showEditActualDialog" [(visible)]="showEditActualDialog" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-bou-actual-detail
         [lineItem]="selectedActualLineItem"
         (saved)="saveActual()"
         (cancelled)="editActualCancelled()"
        ></app-job-bou-actual-detail>
         <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
     </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditVesselReporting" [(visible)]="showEditVesselReporting" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
       <app-job-bou-vessel-reporting-detail
        [bou]="bou"
        (saved)="saveVesselReporting()"
        (cancelled)="cancelVesselReporting()"
       ></app-job-bou-vessel-reporting-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showEditVesselReporting" [(visible)]="showEditVesselReporting" [style]="{width: '100%'}" [modal]="true" [closable]="false">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-job-bou-vessel-reporting-detail
        [bou]="bou"
        (saved)="saveVesselReporting()"
        (cancelled)="cancelVesselReporting()"
       ></app-job-bou-vessel-reporting-detail>
         <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
     </p-scrollPanel>
</p-dialog>