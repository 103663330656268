import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Product } from 'src/app/models';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  @Input() product: Product;
  @Output() productUpdated = new EventEmitter<Product>();
  @Output() resetProduct = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  save() {
    this.productUpdated.emit(this.product);
  }

  reset() {
    this.resetProduct.emit();
  }

}
