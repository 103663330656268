<div class="p-grid">
    <div class="p-col-12">
        <p-card header="Time Tracking Events">
            <div class="p-grid">
                <div *ngIf="!loaded" class="p-col-12">
                    <p-table
                        [value]="skeletonRows"
                        [columns]="cols"
                        [responsive]="true">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns | colDisplayFilter">{{col.header}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body">
                            <tr>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                                <td><p-skeleton></p-skeleton></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div *ngIf="loaded" class="p-col-12">
                    <p-table #timeTrackingEventDt
                    [value]="timeTrackingEvents"
                    [columns]="cols"
                    [rows]="5"
                    [responsive]="true"
                    [paginator]="true"
                    [showCurrentPageRetimeTrackingEvent]="true"
                    currentPageRetimeTrackingEventTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[3,5,10,25,50]"
                    [globalFilterFields]="globalFilterFields"
                    [rowHover]="true"
                    selectionMode="single"
                    [(selection)]="selectedEvent"
                    >
                    <ng-template pTemplate="caption">
                        <div class="p-grid">
                            <div class="p-md-10 p-col-12 p-jc-start">
                                <span class="p-input-icon-left p-text-left p-d-none p-d-lg-inline-flex full-width">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="timeTrackingEventDt.filterGlobal($event.target.value, 'contains')" placeholder="Time Tracking Event Search" [style.width]="'40%'"/>
                                </span>
                                <span class="p-input-icon-left p-text-left p-d-md-none full-width">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="timeTrackingEventDt.filterGlobal($event.target.value, 'contains')" placeholder="Time Tracking Event Search"[style.width]="'100%'"/>
                                </span>
                            </div>
                            <div class="p-md-2 p-col-12 p-jc-end">
                                <p-button label="Add" icon="pi pi-plus" class="p-button-success" (onClick)="addTimeTrackingEvent()" [style]="{'width': '100%'}"></p-button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col *ngFor="let col of columns"/>
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort" 
                                    ariaLabelDesc="Activate to sort in descending order" 
                                    ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-timeTrackingEvent>
                        <tr [pSelectableRow]="timeTrackingEvent">
                            <td>{{timeTrackingEvent.name}}</td>
                            <td>{{timeTrackingEvent.category}}</td>
                            <td>
                                <p-chip *ngIf="timeTrackingEvent.active" label="Active" styleClass="p-mr-2 active-chip"></p-chip>
                                <p-chip *ngIf="!timeTrackingEvent.active" label="Deactivated" styleClass="p-mr-2 deactive-chip"></p-chip>
                            </td>
                            <td>
                                <p-chip *ngIf="timeTrackingEvent.requiresDuration" label="Duration" styleClass="p-mr-2 active-chip"></p-chip>
                                <p-chip *ngIf="timeTrackingEvent.requiresTankAssignment" label="Tank Assigned" styleClass="p-mr-2 unconfirmed-chip"></p-chip>
                                <p-chip *ngIf="timeTrackingEvent.isSystemTrigger" label="System Trigger" styleClass="p-mr-2 deactive-chip"></p-chip>
                            </td>
                            <td>
                                <button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="openTimeTrackingEvent(timeTrackingEvent)" pTooltip="Edit this Time Tracking Event"></button>
                                <button *ngIf="timeTrackingEvent.active && !isSystemTrigger(timeTrackingEvent.id)" pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-ban" (click)="deactivateTimeTrackingEvent(timeTrackingEvent)" pTooltip="Deactivate this Time Tracking Event"></button>
                                <button *ngIf="!timeTrackingEvent.active && !isSystemTrigger(timeTrackingEvent.id)" pButton type="button" class="p-button-warning p-button-rounded p-button-outlined action-icons" icon="pi pi-check" (click)="activateTimeTrackingEvent(timeTrackingEvent)" pTooltip="Activate this Time Tracking Event"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                </div>
            </div>
        </p-card>
    </div>
</div>
<p-dialog class="p-d-none p-d-md-inline" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '50vw'}" [modal]="true" [closable]="true">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-time-tracking-detail [timeTrackingEvent]="selectedEvent" (timeTrackingEventUpdated)="updateTimeTrackingEvent($event)" (resetTimeTrackingEvent)="resetSelectedTimeTrackingEvent()"></app-time-tracking-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
<p-dialog class="p-d-md-none" *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{width: '100%'}" [modal]="true" [closable]="true">
    <p-scrollPanel [style]="{width: '100%'}" styleClass="custombar">
        <app-time-tracking-detail [timeTrackingEvent]="selectedEvent" (timeTrackingEventUpdated)="updateTimeTrackingEvent($event)" (resetTimeTrackingEvent)="resetSelectedTimeTrackingEvent()"></app-time-tracking-detail>
        <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
    </p-scrollPanel>
</p-dialog>
