<div class="p-grid less-top">
    
    <div *ngIf="!collapseMenu" class="p-d-none p-d-md-inline p-col-2 p-text-right less-bottom">
        <p-button icon="pi pi-angle-double-left" (onClick)="collapseMenuClicked()" styleClass="p-button-outlined"></p-button>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-md-none p-col-3 p-text-right less-bottom">
        <p-button icon="pi pi-angle-double-left" (onClick)="collapseMenuClicked()" styleClass="p-button-outlined"></p-button>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-none p-d-md-inline p-col-10 less-top less-bottom">
        <h2 class="less-bottom p-pl-2">{{selectedSection}}</h2>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-md-none p-col-9 less-top less-bottom">
        <h2 class="less-bottom p-pl-2">{{selectedSection}}</h2>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-none p-d-md-inline p-col-2 less-top">
        <p-menu [model]="jobMenuLarge"></p-menu>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-md-none p-col-3 less-top">
        <p-menu [model]="jobMenuSmall"></p-menu>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-none p-d-md-inline p-col-10 ">
        <p-scrollPanel [style]="{width: '100%', height: '80vh'}" styleClass="custombar">
            <app-job-detail-selector [selected]="selectedSection" [shoreTanks]="shoreTanks" [vesselTanks]="vesselTanks" 
                (shipChanged)="shipChanged($event)" (terminalChanged)="terminalChanged($event)"></app-job-detail-selector>
            <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
        </p-scrollPanel>
    </div>
    <div *ngIf="!collapseMenu" class="p-d-md-none p-col-9 ">
        <p-scrollPanel [style]="{width: '100%', height: '82vh'}" styleClass="custombar">
            <app-job-detail-selector [selected]="selectedSection" [shoreTanks]="shoreTanks" [vesselTanks]="vesselTanks" 
                (shipChanged)="shipChanged($event)" (terminalChanged)="terminalChanged($event)"></app-job-detail-selector>
            <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
        </p-scrollPanel>
    </div>

    <div *ngIf="collapseMenu" class="p-col-12 less-bottom">
        <h2 class="less-top less-bottom">
            <span class="p-pl-2">
                <p-button icon="pi pi-angle-double-right" (onClick)="collapseMenuClicked()" styleClass="p-button-outlined"></p-button>
                {{selectedSection}}
            </span>
        </h2>
    </div>
    <div *ngIf="collapseMenu" class="p-col-12">
        <p-scrollPanel [style]="{width: '100%', height: '80vh'}" styleClass="custombar">
            <app-job-detail-selector [selected]="selectedSection" [shoreTanks]="shoreTanks" [vesselTanks]="vesselTanks" 
                (shipChanged)="shipChanged($event)" (terminalChanged)="terminalChanged($event)"></app-job-detail-selector>
            <p-scrollTop [threshold]="100" target="parent"></p-scrollTop>
        </p-scrollPanel>
    </div>
</div>
