<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="received" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Received:</label>
                <div class="p-col-12 p-md-10">
                    <p-inputNumber id="received" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [(ngModel)]="vesselReportingReceived" placeholder="Received"></p-inputNumber>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="vef" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">V.E.F.:</label>
                <div class="p-col-12 p-md-10">
                    <p-inputNumber id="vef" mode="decimal" [minFractionDigits]="4" [maxFractionDigits]="5" [(ngModel)]="vesselReportingVef" placeholder="vef"></p-inputNumber>
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>