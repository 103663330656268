import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BlpStepTank, Job, User } from "../models";
import { VdpShoreTank } from "../models/job/vdp/vdp-shore-tank";
import { SystemConfiguration } from "../models/system-config/system-configuration";
import { TimingCategory } from "../models/system-config/timing-category";

export interface State {
  currentUser: User,
  selectedJob: Job,
  isNewJob: boolean,
  loadingJob: boolean,
  inBlpEditMode: boolean,
  systemConfiguration: SystemConfiguration,
  configurationLoaded: boolean,
  showSpinner: boolean,
  showAppUpdateAvailable: boolean,
  timingCategories: TimingCategory[]
}

const getAppState = createFeatureSelector<State>('app');

// Current User
export const getCurrentUser = createSelector(
  getAppState,
  state => User.fromUserProperties(state.currentUser)
)

// Spinner
export const getShowSpinner = createSelector(
  getAppState,
  state => state.showSpinner
);

// Application Updates
export const getShowAppUpdateAvailable = createSelector(
  getAppState,
  state => state.showAppUpdateAvailable
);

// Job Selectors
export const getSelectedJob = createSelector(
  getAppState,
  state => state.selectedJob
);

export const getIsJobNew = createSelector(
  getAppState,
  state => state.isNewJob
);

export const getIsJobLoading = createSelector(
  getAppState,
  state => state.loadingJob
);

export const getSelectedJobId = createSelector(
  getAppState,
  state => state.selectedJob?.id
);

export const getSelectedJobNumber = createSelector(
  getAppState,
  state => state.selectedJob?.jobNumber
);

export const getSelectedJobLatestBlp = createSelector(
  getAppState,
  state => state.selectedJob?.latestBlp
);

export const getInBlpEditMode = createSelector(
  getAppState,
  state => state.inBlpEditMode
);

export const getSelectedJobIsBlpBlocked = createSelector(
  getAppState,
  state => state.selectedJob?.blpRevisions?.length < 1
);

export const getJobVesselTankIds = createSelector(
  getAppState,
  state => [].concat.apply([], state.selectedJob?.latestBlp?.parcels.map(p => p.vesselTank.split(',')))
);

export const getJobShoreTankIds = createSelector(
  getAppState,
  state => state.selectedJob?.latestBlp?.lineItems?.map(l => l.tankId)
);

export const getJobAllTanksWithStepNumbers = createSelector(
  getAppState,
  state => state.selectedJob?.latestBlp?.lineItems?.map(l => { return new BlpStepTank(l.step, l.tankId); })
);

export const getJobTanksWithNominations = createSelector(
  getAppState,
  state => state.selectedJob?.latestBlp?.lineItems.filter(l => l.nominatedQuantities.some(n => n.value)).map(l => l.tankId)
);

export const getSelectedJobTimeTrackingEventLogs = createSelector(
  getAppState,
  state => state.selectedJob.timeTrackingEventLogs?.filter(t => t.active)
);

// Configuration Selectors
export const getSystemConfiguration = createSelector(
  getAppState,
  state => state.systemConfiguration
);

export const getPortLocations = createSelector(
  getAppState,
  state => state.systemConfiguration.ports
);

export const getShips = createSelector(
  getAppState,
  state => state.systemConfiguration.ships
);

export const getCargos = createSelector(
  getAppState,
  state => state.systemConfiguration.cargos
);

export const getTerminals = createSelector(
  getAppState,
  state => state.systemConfiguration.terminals
);

export const getDocks = createSelector(
  getAppState,
  state => state.systemConfiguration.docks
);

export const getTimingCategories = createSelector(
  getAppState,
  state => state.timingCategories
);

export const getTanks = createSelector(
  getAppState,
  state => state.systemConfiguration.tanks
);

export const getClients = createSelector(
  getAppState,
  state => state.systemConfiguration.clients
);

export const getInspectionCompanies = createSelector(
  getAppState,
  state => state.systemConfiguration.inspectionCompanies
);

export const getSuperintendents = createSelector(
  getAppState,
  state => state.systemConfiguration.superintendents
);

export const getContacts = createSelector(
  getAppState,
  state => state.systemConfiguration.contacts
);

export const getConfigurationLoaded = createSelector(
  getAppState,
  state => state.configurationLoaded
)

export const getUsers = createSelector(
  getAppState,
  state => state.systemConfiguration.users
);

export const getTimeTrackingEvents = createSelector(
  getAppState,
  state => state.systemConfiguration.timeTrackingEvents
);

export const getProducts = createSelector(
  getAppState,
  state => state.systemConfiguration.products
);
