import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { JobVdp, User } from 'src/app/models';
import * as appState from '../../../../state';
import * as appActions from '../../../../state/app.actions';

@Component({
  selector: 'app-job-vdp-manager',
  templateUrl: './job-vdp-manager.component.html',
  styleUrls: ['./job-vdp-manager.component.scss']
})
export class JobVdpManagerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() vdps: JobVdp[] = [];
  @Output() vdpSelected = new EventEmitter<string>();
  // Possible future events
  // @Output() revisionSelected = new EventEmitter<JobVdp>();
  startRevisionButtonLabel = 'Start New';
  selectedVdpText = 'None';
  inVdpEditMode: boolean = false;
  subscriptions: Subscription[] = [];
  users: User[] = [];
  cols: any[] = [];
  tableData: JobVdp[] = [];

  constructor(
    private store: Store<appState.State>) { }

  ngOnInit(): void {
    //this.listenToVdpEditModeChanges();
    // console.log('vdps - ', this.vdps);
    this.listenForUsers();
    this.cols = this.buildCols();
  }

  ngOnChanges() {
    //this.startRevisionButtonLabel = this.latestRevision ? 'New Revision' : 'Start';
    // this.selectedVdpText = this.latestRevision ?
    //   this.latestRevision.revisionNumber + ' (' + this.datePipe.transform(this.latestRevision.revisionDate, 'MMM d, yyyy, HH:mm') + ')' :
    //   'None';
    this.tableData = [...this.vdps].sort((a,b) => a.parcelNumber < b.parcelNumber ? -1 : 1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Saving the job from Vdp Detail will update this
  // listenToVdpEditModeChanges() {
  //   this.subscriptions.push(
  //     this.store.pipe(select(appState.getInVdpEditMode)).subscribe(
  //       result => this.inVdpEditMode = result
  //     )
  //   );
  // }

  listenForUsers() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getUsers)).subscribe(
        result => this.users = result
      )
    );
  }

  // Using component getter instead of listening to state of the job since
  // will be one revision of ahead of the job state when creating the newest revision
  // get latestRevision(): JobVdp {
  //   if (this.vdps && this.vdps.length) {
  //     return this.vdps.sort((a, b) => a.revisionNumber > b.revisionNumber ?  -1 :  1)[0];
  //   } else {
  //       return null;
  //   }
  // }

  buildCols(): any[] {
    return [
      { field: 'parcelNumber', header: 'Parcel', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'product', header: 'Product', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: '', header: 'Actions', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  selectVdp(id: string) {
    const selectedVdp = this.vdps.find(v => v.id === id);
    if (selectedVdp) this.selectedVdpText = selectedVdp.product;
    this.vdpSelected.emit(id);
  }

}
