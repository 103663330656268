import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { SelectItem } from "primeng/api";
import { BlpCalcMeasurement, BlpLineItem, BlpLineItemMeasurement, BlpParcel, JobBlpRevision, User, Tank } from "../models";
import { BlpColumnDefinition, BlpColumnStyleClasses } from "../models/job/blp/blp-column-definition";
import { CalculationReferenceService } from "./calculation-reference.service";

@Injectable({
    providedIn: 'root'
})
export class BlpManagementService {
    constructor(private calcReferenceService: CalculationReferenceService) {}

    // Add Parcel to Blp (Parcel Product and Vessel Tank info should already be collected from UI and passed here)
    addParcel(currentBlp: JobBlpRevision, blpParcel: BlpParcel): JobBlpRevision {
        const parcel = new BlpParcel();
        parcel.blpId = currentBlp.id;
        parcel.product = blpParcel.product;
        parcel.vesselTank = blpParcel.vesselTank;
        parcel.vesselTankLabel = blpParcel.vesselTankLabel;

        // Set Parcel Number and add parcel
        if (currentBlp.parcels.length) {
            const lastParcelNumber = currentBlp.parcels.sort((a,b) => { return (a.parcelNumber > b.parcelNumber) ?  -1 :  1; })[0].parcelNumber;
            parcel.parcelNumber = lastParcelNumber + 1;
        } else {
            parcel.parcelNumber = 1;
        }
        
        currentBlp.parcels.push(parcel);

        // Add Step/Line Item measurement nominated and adjusted measurements
        currentBlp.allLineItems.forEach(l => {
            const nominatedMeasurement = new BlpLineItemMeasurement();
            nominatedMeasurement.blpLineItemId = l.id;
            nominatedMeasurement.parcelNumber = parcel.parcelNumber;
            l.nominatedQuantities.push(nominatedMeasurement);

            const adjustedMeasurement = new BlpLineItemMeasurement();
            adjustedMeasurement.blpLineItemId = l.id;
            adjustedMeasurement.parcelNumber = parcel.parcelNumber;
            l.adjustedQuantities.push(adjustedMeasurement);
        });

        // Add Calculated Measurement Instance (calculated later) for nominated and adjusted
        const nominatedCalcMeasurement = new BlpCalcMeasurement();
        nominatedCalcMeasurement.blpId = currentBlp.id;
        nominatedCalcMeasurement.parcelOrStepNumber = parcel.parcelNumber;
        currentBlp.nominatedQuantityTotals.push(nominatedCalcMeasurement);

        const adjustedCalcMeasurement = new BlpCalcMeasurement();
        adjustedCalcMeasurement.blpId = currentBlp.id;
        adjustedCalcMeasurement.parcelOrStepNumber = parcel.parcelNumber;
        currentBlp.adjustedQuantityTotals.push(adjustedCalcMeasurement);

        // Add Calculated Measurement for Parcel Final Api
        const parcelFinalApiMeasurement = new BlpCalcMeasurement();
        parcelFinalApiMeasurement.blpId = currentBlp.id;
        parcelFinalApiMeasurement.parcelOrStepNumber = parcel.parcelNumber;
        currentBlp.parcelFinalApi.push(parcelFinalApiMeasurement);

        return currentBlp;
    }

    removeParcel(currentBlp: JobBlpRevision, parcelNumber: number): JobBlpRevision {

        // Remove parcels
        currentBlp.parcels = currentBlp.parcels.filter(p => p.parcelNumber !== parcelNumber);

        // Remove parcel column from line items
        currentBlp.allLineItems.forEach(l => {
            // remove parcel column from nominated inputs
            l.nominatedQuantities = l.nominatedQuantities.filter(n => n.parcelNumber !== parcelNumber);

            // remove parcel column from adjusted inputs
            l.adjustedQuantities = l.adjustedQuantities.filter(a => a.parcelNumber !== parcelNumber);
        })

        // Remove parcel column from nominated calcs
        currentBlp.nominatedQuantityTotals = currentBlp.nominatedQuantityTotals.filter(n => n.parcelOrStepNumber !== parcelNumber);

        // Remove parcel column from adjusted calcs
        currentBlp.adjustedQuantityTotals = currentBlp.adjustedQuantityTotals.filter(a => a.parcelOrStepNumber !== parcelNumber);

        // Remove parcel column from final api calcs
        currentBlp.parcelFinalApi = currentBlp.parcelFinalApi.filter(p => p.parcelOrStepNumber !== parcelNumber);

        // Recalculate
        currentBlp = this.calculateBlp(currentBlp);

        return currentBlp;
    }

    buildStep(currentBlp: JobBlpRevision): BlpLineItem {
        const newStep = new BlpLineItem();

        // Set Step Number
        if (currentBlp.lineItems.length) {
            // Ignore Existing Cargo
            const lastStepNumber = currentBlp.lineItems.sort((a,b) => { return (a.step > b.step) ?  -1 :  1; })[0].step;
            newStep.step = lastStepNumber + 1;
        } else {
            newStep.step = 1;
        }

        // Add Nominated input columns
        currentBlp.parcels.forEach(p => {
            const nominatedMeasurement = new BlpLineItemMeasurement();
            nominatedMeasurement.blpLineItemId = newStep.id;
            nominatedMeasurement.parcelNumber = p.parcelNumber;
            newStep.nominatedQuantities.push(nominatedMeasurement);
        });

        // Add Adjusted input columns
        currentBlp.parcels.forEach(p => {
            const adjustedMeasurement = new BlpLineItemMeasurement();
            adjustedMeasurement.blpLineItemId = newStep.id;
            adjustedMeasurement.parcelNumber = p.parcelNumber;
            newStep.adjustedQuantities.push(adjustedMeasurement);
        });

        return newStep;
    }

    buildExistingCargoStep(currentBlp: JobBlpRevision): BlpLineItem {
        //Check to see if existing cargo step exists (there should only be one step)
        const exists = currentBlp.existingCargo;
        if (exists) {
            return exists;
        }

        const existingCargoStep = new BlpLineItem();
        existingCargoStep.step = 0;
        existingCargoStep.comment = 'Existing Cargo On-Board';
        existingCargoStep.isExistingCargo = true;

        // Add Nominated input columns
        currentBlp.parcels.forEach(p => {
            const nominatedMeasurement = new BlpLineItemMeasurement();
            nominatedMeasurement.blpLineItemId = existingCargoStep.id;
            nominatedMeasurement.parcelNumber = p.parcelNumber;
            existingCargoStep.nominatedQuantities.push(nominatedMeasurement);
        });

        // Add Adjusted input columns
        currentBlp.parcels.forEach(p => {
            const adjustedMeasurement = new BlpLineItemMeasurement();
            adjustedMeasurement.blpLineItemId = existingCargoStep.id;
            adjustedMeasurement.parcelNumber = p.parcelNumber;
            existingCargoStep.adjustedQuantities.push(adjustedMeasurement);
        });

        return existingCargoStep;
    }

    addStep(currentBlp: JobBlpRevision, newStep: BlpLineItem): JobBlpRevision {
        currentBlp.allLineItems.push(newStep);

        // In case new step was inserted in previous step
        if (!newStep.isExistingCargo) {
            currentBlp = this.reorderSteps(currentBlp, newStep);
        }

        // Add Step Total
        // const stepTotal = new BlpCalcMeasurement();
        // stepTotal.blpId = currentBlp.id;
        // stepTotal.parcelOrStepNumber = newStep.step;
        // currentBlp.stepTotals.push(stepTotal);

        return currentBlp;
    }

    reorderSteps(currentBlp: JobBlpRevision, editedStep: BlpLineItem, previousStepNumber: number = null): JobBlpRevision {
        //Reorder steps and increase step number for each step after where step was inserted
        // Ignore Existing Cargo
        currentBlp.lineItems.sort((a, b) => a.step > b.step ?  1 :  -1);

        currentBlp.lineItems.forEach(l => {
            if (!previousStepNumber) {
                // New step inserted, every step after inserted needs to be incremented
                if (l.step >= editedStep.step && l.tankId !== editedStep.tankId) {
                    l.step++;
                }
            } else {
                // Determine range of which steps need to be changed
                const stepDifference = editedStep.step - previousStepNumber;
                if (stepDifference > 0) {
                    // Step moved higher, steps in between need to be decremented
                    // Example, Step 3 renumbered to 5: 4 -> 3, 5 -> 4, and 3 -> 5 
                    if (l.step <= editedStep.step &&
                        l.step > previousStepNumber &&
                        l.tankId !== editedStep.tankId) {
                        l.step--;
                    }
                } else {
                    // Step moved lower, steps in between need to be incremented
                    // Example, Step 5 renumbered to 3: 5 -> 3, 3 -> 4, and 4 -> 5
                    if (l.step >= editedStep.step &&
                        l.step < previousStepNumber &&
                        l.tankId !== editedStep.tankId) {
                        l.step++;
                    }
                }
            }
        });

        // Rebuild step totals (will be calculated again later)
        currentBlp.stepTotals = [];
        currentBlp.lineItems.forEach(l => {
            const stepTotal = new BlpCalcMeasurement();
            stepTotal.blpId = currentBlp.id;
            stepTotal.parcelOrStepNumber = l.step;
            currentBlp.stepTotals.push(stepTotal);
        });

        return currentBlp;
    }

    removeStep(currentBlp: JobBlpRevision, stepNumber: number): JobBlpRevision {
        // Remove step from line items
        currentBlp.allLineItems = currentBlp.allLineItems.filter(l => l.step !== stepNumber);

        // Remove step from step totals
        currentBlp.stepTotals = currentBlp.stepTotals.filter(s => s.parcelOrStepNumber !== stepNumber);
        // Reorder
        let stepCounter = 1;
        currentBlp.allLineItems.sort((a, b) => a.step > b.step ?  1 :  -1);
        currentBlp.allLineItems.forEach(l => {
            if (!l.isExistingCargo) {
                l.step = stepCounter;
                stepCounter++;
            }
        });
        // Recalculate
        currentBlp = this.calculateBlp(currentBlp);

        return currentBlp;
    }

    buildBlpTableColumns(parcels: BlpParcel[] = [], existingCargo: BlpLineItem = null): BlpColumnDefinition[] {
        const cols: BlpColumnDefinition[] = [];;

        // Action Column
        const actionCol = new BlpColumnDefinition();
        actionCol.name = 'actions';
        actionCol.lineText4 = 'Actions';
        actionCol.class4 = BlpColumnStyleClasses.stepHeader.valueOf();
        actionCol.class5 = BlpColumnStyleClasses.greyedOut.valueOf();
        actionCol.showOnMobile = true;
        actionCol.width= '120px';
        cols.push(actionCol);

        const stepNumberCol = new BlpColumnDefinition();
        stepNumberCol.name = 'step';
        stepNumberCol.lineText4 = 'Step';
        stepNumberCol.class4 = BlpColumnStyleClasses.stepHeader.valueOf();
        stepNumberCol.class5 = BlpColumnStyleClasses.greyedOut.valueOf();
        stepNumberCol.showOnMobile = true;
        stepNumberCol.width = '50px';
        cols.push(stepNumberCol);

        const tankLocationCol = new BlpColumnDefinition();
        tankLocationCol.name = 'tankLocation'
        tankLocationCol.lineText4 = 'Tank Location'
        tankLocationCol.class4 = BlpColumnStyleClasses.stepHeader.valueOf();
        tankLocationCol.class5 = existingCargo ? BlpColumnStyleClasses.existingCargo : BlpColumnStyleClasses.greyedOut.valueOf();
        if (existingCargo) {
            tankLocationCol.lineText5 = existingCargo.tankLocation;
        }
        tankLocationCol.showOnMobile = true;
        tankLocationCol.width = '150px';
        cols.push(tankLocationCol);

        const tankIdCol = new BlpColumnDefinition();
        tankIdCol.name = 'tankId';
        tankIdCol.lineText4 = 'Tank ID#';
        tankIdCol.class4 = BlpColumnStyleClasses.stepHeader.valueOf();
        tankIdCol.class5 = BlpColumnStyleClasses.greyedOut.valueOf();
        tankIdCol.showOnMobile = true;
        tankIdCol.width = '85px';
        cols.push(tankIdCol);

        const apiCol = new BlpColumnDefinition();
        apiCol.name = 'apiNumber';
        apiCol.lineText4 = 'API';
        apiCol.class4 = BlpColumnStyleClasses.stepHeader.valueOf();
        apiCol.class5 = existingCargo ? BlpColumnStyleClasses.existingCargo : BlpColumnStyleClasses.greyedOut.valueOf();
        if (existingCargo) {
            apiCol.lineText5 = existingCargo.apiNumber.toString();
        }
        apiCol.width = '50px';
        cols.push(apiCol);

        // Nominated Columns
        for(let i = 1; i <= parcels.length; i++) {
            const parcelCol = new BlpColumnDefinition();
            if (i == 1) {
                parcelCol.name = 'nomParcel' + i.toString();
                parcelCol.lineText1 = 'Nominated QTY.';
                parcelCol.allowOverflow1 = true;
                // If only one column, header needs extra width
                if (parcels.length == 1) {
                    parcelCol.width = '120px';
                }
            }
            const targetParcel = parcels.find(p => p.parcelNumber === i);
            if (targetParcel) {
                parcelCol.lineText2 = targetParcel.parcelNumber.toString();
                parcelCol.lineText3 = targetParcel.product;
                parcelCol.lineText4 = targetParcel.vesselTankLabel;
                if (existingCargo && existingCargo.nominatedQuantities) {
                    parcelCol.lineText5 = existingCargo.nominatedQuantities.find(n => n.parcelNumber === targetParcel.parcelNumber).value.toString();
                }
                parcelCol.showLineIcon2 = true;
            }
            parcelCol.class1 = BlpColumnStyleClasses.stepHeader.valueOf();
            parcelCol.class2 = BlpColumnStyleClasses.parcelRed.valueOf();
            parcelCol.class3 = BlpColumnStyleClasses.parcelRed.valueOf();
            parcelCol.class4 = BlpColumnStyleClasses.parcelIndigo.valueOf();
            parcelCol.class5 = existingCargo ? BlpColumnStyleClasses.existingCargo : BlpColumnStyleClasses.greyedOut.valueOf();
            cols.push(parcelCol);
        }

        // Line Adjustement
        const lineAdjustmentCol1 = new BlpColumnDefinition();
        lineAdjustmentCol1.name = 'lineAdjValue';
        lineAdjustmentCol1.lineText1 = 'Line Adjustments';
        lineAdjustmentCol1.lineText2 = '<<<---- Parcel # ---->>>';
        lineAdjustmentCol1.lineText3 = '<<<---- Product ---->>>';
        lineAdjustmentCol1.lineText4 = '<<<---- Vessel Tank ---->>>';
        lineAdjustmentCol1.lineText5 = 'Line +/-';
        lineAdjustmentCol1.allowOverflow1 = true;
        lineAdjustmentCol1.allowOverflow2 = true;
        lineAdjustmentCol1.allowOverflow3 = true;
        lineAdjustmentCol1.allowOverflow4 = true;
        lineAdjustmentCol1.allowOverflow5 = false;
        lineAdjustmentCol1.class1 = BlpColumnStyleClasses.lineAdjustemnetHeader.valueOf();
        lineAdjustmentCol1.class2 = BlpColumnStyleClasses.parcelIndigo.valueOf();
        lineAdjustmentCol1.class3 = BlpColumnStyleClasses.parcelRed.valueOf();
        lineAdjustmentCol1.class4 = BlpColumnStyleClasses.parcelIndigo.valueOf();
        lineAdjustmentCol1.class5 = BlpColumnStyleClasses.lineAdjustemnetHeader.valueOf();

        const lineAdjustmentCol2 = new BlpColumnDefinition();
        lineAdjustmentCol2.name = 'lineAdjNumber';
        lineAdjustmentCol2.lineText5 = 'Line #(s)';
        lineAdjustmentCol2.class1 = BlpColumnStyleClasses.lineAdjustemnetHeader.valueOf();
        lineAdjustmentCol2.class2 = BlpColumnStyleClasses.parcelIndigo.valueOf();
        lineAdjustmentCol2.class3 = BlpColumnStyleClasses.parcelRed.valueOf();
        lineAdjustmentCol2.class4 = BlpColumnStyleClasses.parcelIndigo.valueOf();
        lineAdjustmentCol2.class5 = BlpColumnStyleClasses.lineAdjustemnetHeader.valueOf();
        lineAdjustmentCol2.width = '200px';

        cols.push(lineAdjustmentCol1);
        cols.push(lineAdjustmentCol2);


        // Adjusted Columns
        for(let i = 1; i <= parcels.length; i++) {
            const parcelCol = new BlpColumnDefinition();
            if (i == 1) {
                parcelCol.name = 'adjParcel' + i.toString();
                parcelCol.lineText1 = 'Adjusted QTY.';
                parcelCol.allowOverflow1 = true;
            }
            const targetParcel = parcels.find(p => p.parcelNumber === i);
            if (targetParcel) {
                parcelCol.lineText2 = targetParcel.parcelNumber.toString();
                parcelCol.lineText3 = targetParcel.product;
                parcelCol.lineText4 = targetParcel.vesselTankLabel;
                if (existingCargo && existingCargo.adjustedQuantities) {
                    parcelCol.lineText5 = existingCargo.adjustedQuantities.find(n => n.parcelNumber === targetParcel.parcelNumber)?.value.toString();
                }
            }
            parcelCol.class1 = BlpColumnStyleClasses.stepHeader.valueOf();
            parcelCol.class2 = BlpColumnStyleClasses.parcelRed.valueOf();
            parcelCol.class3 = BlpColumnStyleClasses.parcelRed.valueOf();
            parcelCol.class4 = BlpColumnStyleClasses.parcelIndigo.valueOf();
            parcelCol.class5 = existingCargo ? BlpColumnStyleClasses.existingCargo : BlpColumnStyleClasses.greyedOut.valueOf();
            cols.push(parcelCol);
        }

        // Total Column
        if (parcels.length) {
            const totalCol = new BlpColumnDefinition();
            totalCol.name = 'stepTotals';
            totalCol.lineText2 = 'Total';
            totalCol.lineText3 = 'Per';
            totalCol.lineText4 = 'Tank';
            if (existingCargo && existingCargo.adjustedQuantities) {
                // Have to recalc existing cargo total since step totals are not passed into this method
                // Perhaps change to pass full blp instead of existing cargo line item?
                let total = 0;
                existingCargo.adjustedQuantities.forEach(a => total += a.value);
                totalCol.lineText5 = this.calcReferenceService.handleNaN(total).toString();
            }
            totalCol.class1 = BlpColumnStyleClasses.stepHeader.valueOf();
            totalCol.class2 = BlpColumnStyleClasses.parcelBlack.valueOf();
            totalCol.class3 = BlpColumnStyleClasses.parcelBlack.valueOf();
            totalCol.class4 = BlpColumnStyleClasses.parcelBlack.valueOf();
            totalCol.class5 = existingCargo ? BlpColumnStyleClasses.existingCargo : BlpColumnStyleClasses.greyedOut.valueOf();
            totalCol.showOnMobile = true;
            cols.push(totalCol);
        }

        // Comment Column
        const commentCol = new BlpColumnDefinition();
        commentCol.name = 'comment';
        commentCol.lineText4 = 'Comment';
        if (existingCargo) {
            commentCol.lineText5 = existingCargo.comment;
        }
        commentCol.class4 = BlpColumnStyleClasses.stepHeader.valueOf();
        commentCol.class5 = existingCargo ? BlpColumnStyleClasses.existingCargo : BlpColumnStyleClasses.greyedOut.valueOf();
        commentCol.width = '250px';
        cols.push(commentCol);

        return cols;
    }

    // Translate BLP Data model to flat table to display in table control
    buildTableData(blp: JobBlpRevision, shoreTanks: Tank[]): any[] {
        const tableData: any[] = [];

        // Ignore Existing Cargo
        if (blp && blp.lineItems) {
            blp.lineItems.forEach(l => {
                var shoreTank = shoreTanks.find(t => t.id == l.tankId);
                const tableLineItem: any = {
                    'step': l.step,
                    'tankLocation': l.tankLocation,
                    'tankId': l.tankId,
                    'tankName': shoreTank?.name,
                    'apiNumber': l.apiNumber
                };
                if (blp.parcels) {
                    for(let i = 1; i <= blp.parcels.length; i++) {
                        const nom = l.nominatedQuantities.find(n => n.parcelNumber === i);
                        tableLineItem['nomParcel' + i.toString()] = nom.isPush ? 'PUSH' : nom.value;

                        tableLineItem['adjParcel' + i.toString()] = l.adjustedQuantities.find(a => a.parcelNumber === i)?.value;

                        tableLineItem.total = blp.stepTotals.find(t => t.parcelOrStepNumber === l.step)?.value;
                    }
                    var parcel = blp.parcels.find(p => p.vesselTank.indexOf(l.tankId) > -1);
                    if (parcel)
                        tableLineItem['vesslTankLabel'] = parcel.vesselTankLabel;
                }
                tableLineItem.lineAdjValue = l.lineAdjustment;
                tableLineItem.lineAdjNumber = l.lineNumbers;
                tableLineItem.comment = l.comment;

                tableData.push(tableLineItem);
            });

            tableData.sort((a,b) => a.step > b.step ?  1 :  -1);
        }

        return tableData;
    }

    buildTankLocationOptions(terminalName: string, dockName: string): SelectItem[] {
        return [
            { label: terminalName, value: terminalName },
            { label: dockName, value: dockName },
            { label: 'KMCC Splitter', value: 'KMCC Splitter' },
            { label: 'GP-East 3100 Manifold', value: 'GP-East 3100 Manifold' },
            { label: 'GP-East 3400 Manifold', value: 'GP-East 3400 Manifold' },
            { label: 'GP-East 3700 Manifold', value: 'GP-East 3700 Manifold' },
            { label: 'GP-East 3800 Manifold', value: 'GP-East 3800 Manifold' },
            { label: 'GP-West 7100 Manifold', value: 'GP-West 7100 Manifold' },
            { label: 'Galena Park-North', value: 'Galena Park-North' }
        ];
    }

    calculateBlp(currentBlp: JobBlpRevision): JobBlpRevision {

        // Adjusted Quantities
        // Ignore Existing Cargo
        if (currentBlp && currentBlp.lineItems) {
            currentBlp.lineItems.forEach(l => {
                l.nominatedQuantities.forEach(n => {
                    const adjusted = l.adjustedQuantities.find(a => a.parcelNumber === n.parcelNumber);

                    // Check for override values to use, otherwise calculate
                    const override = currentBlp.adjustedQuantityOverrides.find(o => o.step === l.step && o.parcelNumber === n.parcelNumber);
                    if (override && override.value !== null && override.value !== undefined) {
                        adjusted.value = override.value;    
                    } else {
                        // Handle if parcel had a line adjustement otherwise equal to nomination
                        if (l.lineAdjustmentParcelNumber === n.parcelNumber) {
                            if (n.isPush) {
                                adjusted.value = l.lineAdjustment;
                            } else {
                                adjusted.value = l.lineAdjustment? n.value + l.lineAdjustment : n.value;
                            }
                        } else {
                            adjusted.value = n.value;
                        }
                    }
                })
            });
        }


        // AdjustedQuantity Totals
        // Ignore Existing Cargo
        if (currentBlp && currentBlp.lineItems) {
            
            currentBlp.lineItems.forEach(l => {
                let lineItemTotal = 0;
                l.adjustedQuantities.forEach(a => {
                    lineItemTotal += a.value ? a.value : 0;
                });
                const totalToUpdate = currentBlp.stepTotals.find(t => t.parcelOrStepNumber === l.step);
                if (totalToUpdate) {
                    totalToUpdate.value = this.calcReferenceService.handleNaN(lineItemTotal);
                }
            });
        }

        // Nominated Quantity Column Totals        
        // Ignore Existing Cargo
        if (currentBlp && currentBlp.nominatedQuantityTotals) {
            currentBlp.nominatedQuantityTotals.forEach(t => {
                let total = 0;
                const noms = currentBlp.lineItems.forEach(l => {
                    const quantity = l.nominatedQuantities.find(n => n.parcelNumber === t.parcelOrStepNumber);
                    total += quantity && quantity.value ? quantity.value : 0;
                });
                t.value = this.calcReferenceService.handleNaN(total);
            })
        }

        // Adjusted Quantity Column Totals
        // Ignore Existing Cargo
        if (currentBlp && currentBlp.adjustedQuantityTotals) {
            currentBlp.adjustedQuantityTotals.forEach(t => {
                let total = 0;
                const noms = currentBlp.lineItems.forEach(l => {
                    const quantity = l.adjustedQuantities.find(n => n.parcelNumber === t.parcelOrStepNumber);
                    total += quantity && quantity.value ? quantity.value : 0;
                });
                t.value = this.calcReferenceService.handleNaN(total);
            })
        }

        // All Steps Total of Tanks Total
        // Ignore Existing Cargo
        if (currentBlp && currentBlp.stepTotals) {
            let total = 0;
            if (currentBlp.existingCargo) {
                currentBlp.stepTotals.filter(s => s.parcelOrStepNumber !== currentBlp.existingCargo.step).forEach(t => total += t.value);
            } else {
                currentBlp.stepTotals.forEach(t => total += t.value);
            }
            currentBlp.allStepTotal = this.calcReferenceService.handleNaN(total);
        }

        // Final Api
        // SumProduct of Api Column and Targeted Parcel Nominations Column / Sum of Targeted Parcel Nominations Column
        // Ignore Existing Cargo
        currentBlp.parcels.forEach(p => {
            const sumProduct = this.calcReferenceService.sumProduct(
                currentBlp.lineItems.map(l => l.apiNumber),
                currentBlp.lineItems.map(l => {
                    const quantity = l.nominatedQuantities.find(n => n.parcelNumber === p.parcelNumber);
                    return (quantity && quantity.value) ? quantity.value : 0;
                })
            );
            const finalApi = currentBlp.parcelFinalApi.find(f => f.parcelOrStepNumber === p.parcelNumber);
            finalApi.value = this.calcReferenceService.handleNaN(sumProduct / currentBlp.nominatedQuantityTotals.find(t => t.parcelOrStepNumber === p.parcelNumber)?.value);
        });
        
        // Existing Cargo Calcs
        // Existing Cargo values are ignored in calculating table totals
        if (currentBlp.existingCargo) {
            // Adjusted Quantities
            currentBlp.existingCargo.nominatedQuantities.forEach(n => {
                const adjusted = currentBlp.existingCargo.adjustedQuantities.find(a => a.parcelNumber === n.parcelNumber);
                // Do not have to worry about push or line adjustments
                adjusted.value = n.value;
            })

            let totalToUpdate = currentBlp.stepTotals.find(t => t.parcelOrStepNumber === currentBlp.existingCargo.step);
            if (!totalToUpdate) {
                totalToUpdate = new BlpCalcMeasurement();
                totalToUpdate.blpId = currentBlp.id;
                totalToUpdate.parcelOrStepNumber = currentBlp.existingCargo.step;
                currentBlp.stepTotals.push(totalToUpdate);
            }
            let total = 0;
            currentBlp.existingCargo.adjustedQuantities.forEach(a => total += a.value);
            totalToUpdate.value = this.calcReferenceService.handleNaN(total);
        }
        
        return currentBlp;
    }

    // returns negative if no pushes
    public getPushParcelNumber(lineItem: BlpLineItem): number {
        if (lineItem && lineItem.nominatedQuantities) {
            const pushFound = lineItem.nominatedQuantities.find(n => n.isPush === true);
            if (pushFound) {
                return pushFound.parcelNumber;
            }
        }

        return -1;
    }

    cloneBlp(jobId: string, currentUser: User, latestBlp: JobBlpRevision): JobBlpRevision {
        const blpRevision = new JobBlpRevision();
        blpRevision.id = Guid.create().toString();
        blpRevision.jobId = jobId;
        blpRevision.preparedById = currentUser.id;
        // moving revision number setting to server side to avoid conflicts if multiple users
        // tried to create a new revision at the same time
        // blpRevision.revisionNumber = latestBlp ? latestBlp.revisionNumber + 1 : 0;
        blpRevision.revisionNumber = -1; // negative to indicate new revision
        blpRevision.revisionDate = new Date();
    
        if (latestBlp) {
          // Clone remaining properties but reset all ids and all blp Id to relate to this blp
          blpRevision.parcels = latestBlp.parcels ? [...latestBlp.parcels.map(i => { return {
            ...i,
            id: Guid.create().toString(),
            blpId: blpRevision.id
          }; })] : [];
          blpRevision.allLineItems = latestBlp.allLineItems ? [...latestBlp.allLineItems.map(i => {
            const lineItemId = Guid.create().toString();
            return {
                ...i,
                id: lineItemId,
                blpId: blpRevision.id,
                nominatedQuantities: i.nominatedQuantities.map(n => { return {
                    ...n,
                    id: Guid.create().toString(),
                    blpLineItemId: lineItemId
                }; }),
                adjustedQuantities: i.adjustedQuantities.map(a => { return {
                    ...a,
                    id: Guid.create().toString(),
                    blpLineItemId: lineItemId
                }; }),
                isExistingCargo: i.isExistingCargo,
                isLoaded: i.isLoaded,
            }; })] : [];
          blpRevision.nominatedQuantityTotals = latestBlp.nominatedQuantityTotals ? [...latestBlp.nominatedQuantityTotals.map(i => { return {
            ...i,
            id: Guid.create().toString(),
            blpId: blpRevision.id
          }; })] : [];
          blpRevision.adjustedQuantityTotals = latestBlp.adjustedQuantityTotals ? [...latestBlp.adjustedQuantityTotals.map(i => { return {
            ...i,
            id: Guid.create().toString(),
            blpId: blpRevision.id
          }; })] : [];
          blpRevision.stepTotals= latestBlp.stepTotals ? [...latestBlp.stepTotals.map(i => { return {
            ...i,
            id: Guid.create().toString(),
            blpId: blpRevision.id
          }; })] : [];
          blpRevision.allStepTotal = latestBlp.allStepTotal ? latestBlp.allStepTotal : 0;
          blpRevision.parcelFinalApi = latestBlp.parcelFinalApi ? [...latestBlp.parcelFinalApi.map(i => { return {
            ...i,
            id: Guid.create().toString(),
            blpId: blpRevision.id
          }; })] : [];
          blpRevision.adjustedQuantityOverrides = latestBlp.adjustedQuantityOverrides ? [...latestBlp.adjustedQuantityOverrides.map(i => { return {
              ...i,
              id: Guid.create().toString(),
              blpRevisionId: blpRevision.id
          }; })] : []
        }
    

        return blpRevision;
    }

    
    
}
