import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as appState from '../../state';
import * as appActions from '../../state/app.actions';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { Contact } from 'src/app/models';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() contact: Contact = new Contact();
  @Output() contactUpdated = new EventEmitter<Contact>();
  @Output() close = new EventEmitter();

  constructor(private store: Store<appState.State>) { }

  ngOnInit(): void {
  }

  save() {
    this.contact.id = Guid.create().toString();
    this.store.dispatch(appActions.setContact({ data: this.contact }));
    this.contactUpdated.emit(this.contact);
  }

  cancel() {
    this.close.emit();
  }

}
