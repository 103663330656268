import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TimeTrackingEvent } from 'src/app/models';
import { TimeTrackingEventCategories } from 'src/app/models/system-config/time-tracking-event-categories';

@Component({
  selector: 'app-time-tracking-detail',
  templateUrl: './time-tracking-detail.component.html',
  styleUrls: ['./time-tracking-detail.component.scss']
})
export class TimeTrackingDetailComponent implements OnInit {

  @Input() timeTrackingEvent: TimeTrackingEvent;
  @Output() timeTrackingEventUpdated = new EventEmitter<TimeTrackingEvent>();
  @Output() resetTimeTrackingEvent = new EventEmitter();
  categories: SelectItem[] = [];
  delayTypes: string[] = [];
  delayDescription: string = '';

  constructor() { }

  ngOnInit(): void {
    if (this.timeTrackingEvent.description != undefined)
      this.delayDescription = this.timeTrackingEvent.description.substring('Delay Type Description - '.length);

    this.categories = this.buildCategories();
    this.delayTypes = ['Customer', 'Inspector','Terminal', 'Vessel'];
  }

  buildCategories(): SelectItem[] {
    return Object.keys(TimeTrackingEventCategories).map(k => {
      return { label: TimeTrackingEventCategories[k], value: TimeTrackingEventCategories[k] }
    })
  }

  save() {
    if (this.timeTrackingEvent.category == TimeTrackingEventCategories.delays)
      this.timeTrackingEvent.description = 'Delay Type Description - ' + this.delayDescription;
    this.timeTrackingEventUpdated.emit(this.timeTrackingEvent);
  }

  reset() {
    this.resetTimeTrackingEvent.emit();
  }

}
