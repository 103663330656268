import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { JobReport } from 'src/app/models/reports/job-report';
import { User, UserRoles, ClientCompany, TimeTrackingReportFormats } from 'src/app/models';
import { JobReportService } from 'src/app/services/http-services/job.report.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DownloadsService } from 'src/app/services/http-services/downloads.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import * as appState from 'src/app/state';
import * as appActions from 'src/app/state/app.actions';
import { JobSummary } from 'src/app/models/reports/job-summary';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  activeIndex1: number = 0;
  report: JobReport;
  currentUser: User = new User();
  year: number;
  activeJobCols: any[];
  completedJobCols: any[];
  infoCols: any[];
  subscriptions: Subscription[] = [];
  showSuperintendents: boolean;
  superintendents: User[] = [];
  selectedCustomer: ClientCompany;

  constructor(
    private router: Router,
    private jobReportService: JobReportService,
    private dataSharingService: DataSharingService,
    private downloadsService: DownloadsService,
    private appMessageService: AppMessageService, 
    private store: Store<appState.State>) { }

  ngOnInit(): void {
    var date = new Date();
    this.year = date.getUTCFullYear();
    this.createBlankReport();
    this.buildJobColumns();
    this.listenToStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  createBlankReport() {
    this.report = new JobReport();
    this.report.activeJobSummaries = [];
    this.report.activeJobs = this.report.activeJobSummaries.length;
    this.report.completedJobSummaries = [];
    this.report.completedJobs = this.report.completedJobSummaries.length;
    this.report.recentJobs = 0;
  }

  buildJobColumns() {
    this.activeJobCols = [
      { field: '', header: 'View Job', display: true },
      { field: '', header: 'Status', display: true },
      { field: 'ship.name', header: 'Ship', display: true, sortable: true, searchable: true, textFilterable: true, searchField: "ship.name" },
      { field: 'nominatedVolume', header: 'Nominated Volume', display: true, sortable: true, searchable: false, textFilterable: false},
      { field: 'terminal.name', header: 'Terminal', display: true, sortable: true, searchable: true, textFilterable: true, searchField: "terminal.name" },
      { field: 'berthingDate', header: 'Berthing Date', display: true, sortable: true, searchable: false, textFilterable: false},
      { field: 'downtime', header: 'Down Time', display: true, sortable: true, searchable: false, textFilterable: false },
      { field: 'superintendent', header: 'Superintendent', display: true, sortable: false, searchable: true, textFilterable: true, searchField: "superintendentNames" },
      { field: '', header: 'BOU Report', display: true },
      { field: '', header: 'BLP Report', display: true },
    ];

    this.completedJobCols = [
      { field: '', header: 'View Job', display: true },
      { field: 'ship.name', header: 'Ship', display: true, sortable: true, searchable: true, textFilterable: true, searchField: "ship.name" },
      { field: 'nominatedVolume', header: 'Nominated Volume', display: true, sortable: true, searchable: false, textFilterable: false},
      { field: 'terminal.name', header: 'Terminal', display: true, sortable: true, searchable: true, textFilterable: true, searchField: "terminal.name" },
      { field: 'berthingDate', header: 'Berthing Date', display: true, sortable: true, searchable: false, textFilterable: false},
      { field: 'downtime', header: 'Down Time', display: true, sortable: true, searchable: false, textFilterable: false},
      { field: 'superintendent', header: 'Superintendent', display: true, sortable: false, searchable: true, textFilterable: true, searchField: "superintendentNames" },
      { field: '', header: 'BOU Report', display: true, searchable: false },
      { field: '', header: 'BLP Report', display: true, searchable: false },
      { field: '', header: 'Time Tracking Report', display: true, searchable: false },
    ];

    this.infoCols = [
      { field: 'name', header: 'Name', display: true },
      { field: 'email', header: 'Email', display: true, },
      { field: 'phone', header: 'Phone', display: true },
    ];
  }

  listenToStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getCurrentUser)).subscribe(user => {
        if (user?.id && user.id == this.currentUser?.id) return;
        
        this.currentUser = User.fromUserProperties(user);
        if (user && user.email) {
          if (this.currentUser.role.includes(UserRoles.customer)) {
            this.getSummary(this.currentUser.client);
          }
          else if (this.currentUser.role.includes(UserRoles.superAdmin)){
            this.listentToCustomerChanges();
          }
        }
      })
    );
  }

  listentToCustomerChanges() {
    this.dataSharingService.selectedCustomer.subscribe(c => {
      if (!c.name) return;
      
      if (!this.selectedCustomer || this.selectedCustomer.id != c.id){
        this.selectedCustomer = c;
        this.getSummary(this.selectedCustomer.id);
      }
    });
  }

  get completedFilterFields(): string[] {
    return this.completedJobCols.filter(j => j.searchable).map(j => j.searchField);
  }

  getSummary(customerId: string){
    this.store.dispatch(appActions.showSpinner());
    this.jobReportService.getJobReport(customerId).subscribe(
      result => {
        this.store.dispatch(appActions.hideSpinner());
        this.report = result;
        if (!this.selectedCustomer){
          this.selectedCustomer = this.report.customer;
          this.dataSharingService.setCustomer(this.selectedCustomer);
        }
        this.report.activeJobSummaries.forEach(s => {
          s.cargoCommenced = new Date(s.cargoCommenced);
          s.completionTime = new Date(s.completionTime);
          s.downtimeText = this.jobReportService.convertDuration(s.downtime);
          if (s.superintendents && s.superintendents.length > 0)
            s.superintendentNames = s.superintendents.map(i => i.firstName + ' ' + i.lastName).join(', ');
        });
        this.report.completedJobSummaries.forEach(s => {
          s.downtimeText = this.jobReportService.convertDuration(s.downtime);
          s.cargoCommenced = new Date(s.cargoCommenced);
          s.completionTime = new Date(s.completionTime);
          if (s.superintendents && s.superintendents.length > 0)
            s.superintendentNames = s.superintendents.map(i => i.firstName + ' ' + i.lastName).join(', ');
        });
      },
      error => {
        this.store.dispatch(appActions.hideSpinner());
        this.appMessageService.errorMessage('Error Getting Job Summaries for Dashboard', null);
        console.log(error);
      }
    );
  }

  viewActiveJob(jobId: string) {
    this.router.navigate(['report/jobperformance/active/' + jobId]);
  }

  viewCompletedJob(jobId: string) {
    this.router.navigate(['report/jobperformance/completed/' + jobId]);
  }

  viewSuperindentendsInfo(summary: JobSummary) {
    this.superintendents = [];
    if (summary.superintendents)
      this.superintendents = summary.superintendents;
    this.showSuperintendents = true;
  }

  downloadBou(summary: JobSummary) {
    this.downloadsService.downloadBou(summary.jobId, summary.jobNumber);
  }

  downloadBlp(summary: JobSummary) {
    this.downloadsService.downloadBlpRevision(summary.jobId, summary.jobNumber, summary.blpId);
  }

  downloadTimeTrackingXls(summary: JobSummary) {
    this.downloadsService.downloadTimeTracking(summary.jobId, TimeTrackingReportFormats.XLS);
  }

  downloadTimeTrackingPdf(summary: JobSummary) {
    this.downloadsService.downloadTimeTracking(summary.jobId, TimeTrackingReportFormats.PDF);
  }
}
