import { JobVdp } from "./vdp/job-vdp";
import { Bfr } from "./bfr/bfr";
import { JobBlpRevision } from "./blp/job-blp-revision";
import { Bou } from "./bou/bou";
import { JobOverview } from "./job-overview";
import { TimeTrackingEventLog } from "./time-tracking/time-tracking-event-log";

export interface IJob {
    overview: JobOverview;
    blpRevisions: JobBlpRevision[];
    timeTrackingEventLogs: TimeTrackingEventLog[];
    bfr: Bfr;
    vdps: JobVdp[];
    bou: Bou;
}

export class Job implements IJob {
    overview: JobOverview = new JobOverview();
    blpRevisions: JobBlpRevision[] = [];
    timeTrackingEventLogs: TimeTrackingEventLog[] = [];
    bfr: Bfr = new Bfr();
    vdps: JobVdp[] = [];
    bou: Bou = new Bou();

    get id(): string {
        return this.overview?.jobId;
    }

    get jobNumber(): string {
        return this.overview?.jobNumber;
    }

    get latestBlp(): JobBlpRevision {
        if (this.blpRevisions && this.blpRevisions.length) {
            const revisions = [...this.blpRevisions];
            revisions.sort((a, b) => a.revisionNumber > b.revisionNumber ?  -1 :  1);
            return revisions[0];
        } else {
            return null;
        }
    }

    constructor() {}

    // Construct new instance of job object with properties set so getters can work
    static fromJson(job: IJob): Job {
        const newJob = new Job();
        if (job) {
            newJob.overview = job.overview ? job.overview : new JobOverview();
            newJob.blpRevisions = job.blpRevisions ? job.blpRevisions : [];
            newJob.timeTrackingEventLogs = job.timeTrackingEventLogs ? job.timeTrackingEventLogs : [];
            newJob.bfr = job.bfr ? job.bfr : new Bfr();
            newJob.vdps = job.vdps ? job.vdps.map(v => JobVdp.fromJson(v)) : [];
            newJob.bou = job.bou ? job.bou : new Bou();
        }   
        return newJob;     
  }
}
