import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimingCategory } from 'src/app/models/system-config/timing-category';

@Component({
  selector: 'app-timing-category-detail',
  templateUrl: './timing-category-detail.component.html',
  styleUrls: ['./timing-category-detail.component.scss']
})
export class TimingCategoryDetailComponent implements OnInit {

  @Input() cat: TimingCategory;
  @Output() timingCategoryUpdated = new EventEmitter<TimingCategory>();
  @Output() resetTimingCategory = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.timingCategoryUpdated.emit(this.cat);
  }

  reset() {
    this.resetTimingCategory.emit();
  }
}
