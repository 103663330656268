<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="product" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Product</label>
                <div class="p-col-12 p-md-10">
                    <input id="product" type="text"pInputText [(ngModel)]="parcel.product"> 
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="vesselTank" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Tank</label>
                <div class="p-col-12 p-md-10">
                    <p-multiSelect [options]="tankOptions" [(ngModel)]="selectedTanks" appendTo="body" placeholder="Select Vessel Tanks" (onChange)="tanksChanged()"></p-multiSelect>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="vesselTankLabel" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Label</label>
                <div class="p-col-12 p-md-10">
                    <input id="vesselTankLabel" type="text" pInputText [(ngModel)]="parcel.vesselTankLabel"> 
                </div>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="cancel()" [style]="{'width': '100%'}"></p-button>
    </div>
    <div class="p-md-6 p-col-12">
        <p-button label="Apply" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save()" [style]="{'width': '100%'}"></p-button>
    </div>
</div>

