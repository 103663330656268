import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Store } from "@ngrx/store";
import { interval } from "rxjs";
import * as appState from '../state';
import * as appActions from '../state/app.actions';

@Injectable({
    providedIn: 'root'
})
export class AppStatusService {
    
    constructor(
        private updates: SwUpdate,
        private store: Store<appState.State>) {}

    // Inject this service and call this initializer from main app component
    initializeAppStatusListeners() {
        this.updates.available.subscribe(updatedEvent => {
            console.log('Service Worker Updates found');
            this.store.dispatch(appActions.ShowUpdateAvailable());
        });

        // Force check for updates on hourly basis.  Also forces check on start in case
        // this.updates.available subscription above is not triggered on app start
        if (this.updates.isEnabled) {
            const intervalTime = 60 * 60 * 1000; // 1 hour
            interval(intervalTime).subscribe(async () => {
                console.log('Checking for Service Worker Updates');
                await this.updates.checkForUpdate();
            });
        }
    }

    // If user chooses to load available update call this method
    async reloadAppAndLoadUpdate() {
        // Calls a post message to the service worker's inner scope (ACTIVATE_UPDATE)
        // Then reloads application
        await this.updates.activateUpdate();
        return document.location.reload();
    }
}