<div *ngIf="bou" class="p-grid">
    <!-- <div class="p-col-12">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="useNoms" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Use Nominated Quantities?</label>
                <div class="p-col-12 p-md-10">
                    <p-inputSwitch id="useNoms" [(ngModel)]="bou.useNominatedQuantities" (onChange)="useNominatedQuantitiesChanged()"></p-inputSwitch>
                </div>
            </div>
        </div>
    </div> -->
    <div class="p-col-12">
        <p-tabView>
            <p-tabPanel header="Estimated" [cache]="false">
                <!-- Use ng-template container with pTemplate="content" to use lazy loading of non-active tabs content.
                     Without this, the tab content does not render correctly when making this tab active -->
                <ng-template pTemplate="content">
                    <p-table
                        [value]="bou.estimationLineItems"
                        [columns]="estimatedCols"
                        [scrollable]="true"
                        [style]="{width: '100%'}">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style]="{'width':col.width}">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="estimated-header"></th>
                                <th class="estimated-header">Origin Switch</th>
                                <th class="estimated-header">Origin ID</th>
                                <th class="estimated-header">Start</th>
                                <th class="estimated-header">Rate</th>
                                <th class="estimated-header">Stripping</th>
                                <th class="estimated-header">Duration</th>
                                <th class="estimated-header">Completion</th>
                                <th class="highlighted1">Nominated</th>
                            </tr>
                            <tr>
                                <th class="estimated-header"></th>
                                <th class="estimated-header">(hh:mm)</th>
                                <th class="estimated-header"></th>
                                <th class="estimated-header">(Date & Time)</th>
                                <th class="estimated-header">(Bbls/Hr)</th>
                                <th class="estimated-header">(hh:mm)</th>
                                <th class="estimated-header">(hh:mm)</th>
                                <th class="estimated-header">(Date & Time)</th>
                                <th class="highlighted1">(Bbls)</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row>
                            <tr>
                                <td><button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editEstimation(row.blpStep)" pTooltip="Edit this Estimation"></button></td>
                                <td>{{(row.originSwitchHours ? row.originSwitchHours : 0) | number:'2.0'}}:{{(row.originSwitchMinutes ? row.originSwitchMinutes : 0) | number:'2.0'}}</td>
                                <td>{{row.tankDescription}}</td>
                                <td *ngIf="row.startDate">{{row.startDate | date: 'MMM d, yyyy, HH:mm'}}</td>
                                <td *ngIf="!row.startDate"></td>
                                <td>{{row.rate}}</td>
                                <td>{{(row.strippingHours ? row.strippingHours : 0) | number:'2.0'}}:{{(row.strippingMinutes ? row.strippingMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.durationHours ? row.durationHours : 0) | number:'2.0'}}:{{(row.durationMinutes ? row.durationMinutes : 0) | number:'2.0'}}</td>
                                <td *ngIf="row.completionDate">{{row.completionDate | date: 'MMM d, yyyy, HH:mm'}}</td>
                                <td *ngIf="!row.completionDate"></td>
                                <td *ngIf="!row.isPush">{{row.nominated}}</td>
                                <td *ngIf="row.isPush">PUSH</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Totals</td>
                                <td>{{bou.estimationDurationHoursTotal ? bou.estimationDurationHoursTotal : 0}}:{{(bou.estimationDurationMinutesTotal ? bou.estimationDurationMinutesTotal : 0) | number:'2.0'}}</td>
                                <td></td>
                                <td class="highlighted1">{{bou.estimationNominatedTotal}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Actual" [cache]="false">
                <!-- Use ng-template container with pTemplate="content" to use lazy loading of non-active tabs content.
                     Without this, the tab content does not render correctly when making this tab active -->
                <ng-template pTemplate="content">
                    <p-table
                        [value]="bou.actualLineItems"
                        [columns]="actualCols"
                        [scrollable]="true"
                        [style]="{width: '100%'}">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style]="{'width':col.width}">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="actual-header"></th>
                                <th class="actual-header">Origin ID</th>
                                <th class="actual-header">Start</th>
                                <th class="actual-header">Net Rate</th>
                                <th class="actual-header">Duration</th>
                                <th class="actual-header">Vapor</th>
                                <th class="actual-header">Weather</th>
                                <th class="actual-header">L.D.</th>
                                <th class="actual-header">Slow Rate</th>
                                <th class="actual-header">Power Out.</th>
                                <th class="actual-header">Terminal R.</th>
                                <th class="actual-header">Other</th>
                                <th class="actual-header">Tank Swap</th>
                                <th class="actual-header">Completion</th>
                                <th class="highlighted1">Delivered</th>
                                <th class="highlighted1">Variance</th>
                                <th>Comments/Terminal PIC</th>
                                <th>Completed?</th>
                            </tr>
                            <tr>
                                <th class="actual-header"></th>
                                <th class="actual-header"></th>
                                <th class="actual-header">(Date & Time)</th>
                                <th class="actual-header">(Bbls/Hr)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(hh:mm)</th>
                                <th class="actual-header">(Date & Time)</th>
                                <th class="highlighted1">(Bbls)</th>
                                <th class="highlighted1">(Bbls)</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row>
                            <tr>
                                <td><button pButton type="button" class="p-button-primary p-button-rounded p-button-outlined action-icons" icon="pi pi-pencil" (click)="editActual(row.blpStep)" pTooltip="Edit this Actual"></button></td>
                                <td>{{row.tankDescription}}</td>
                                <td *ngIf="row.startDate">{{row.startDate | date: 'MMM d, yyyy, HH:mm'}}</td>
                                <td *ngIf="!row.startDate"></td>
                                <td>{{row.rate | number: '1.0-0'}}</td>
                                <td>{{(row.durationHours ? row.durationHours : 0) | number:'2.0'}}:{{(row.durationMinutes ? row.durationMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.vaporHours ? row.vaporHours : 0) | number:'2.0'}}:{{(row.vaporMinutes ? row.vaporMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.weatherHours ? row.weatherHours : 0) | number:'2.0'}}:{{(row.weatherMinutes ? row.weatherMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.lineDisplacementHours ? row.lineDisplacementHours : 0) | number:'2.0'}}:{{(row.lineDisplacementMinutes ? row.lineDisplacementMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.slowRateHours ? row.slowRateHours : 0) | number:'2.0'}}:{{(row.slowRateMinutes ? row.slowRateMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.powerOutageHours ? row.powerOutageHours : 0) | number:'2.0'}}:{{(row.powerOutageMinutes ? row.powerOutageMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.terminalReasonHours ? row.terminalReasonHours : 0) | number:'2.0'}}:{{(row.terminalReasonMinutes ? row.terminalReasonMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.otherDelayHours ? row.otherDelayHours : 0) | number:'2.0'}}:{{(row.otherDelayMinutes ? row.otherDelayMinutes : 0) | number:'2.0'}}</td>
                                <td>{{(row.tankSwapHours ? row.tankSwapHours : 0) | number:'2.0'}}:{{(row.tankSwapMinutes ? row.tankSwapMinutes : 0) | number:'2.0'}}</td>
                                
                                <td *ngIf="row.completionDate">{{row.completionDate | date: 'MMM d, yyyy, HH:mm'}}</td>
                                <td *ngIf="!row.completionDate"></td>

                                <!-- <td *ngIf="!row.isPush">{{row.delivered | number: '1.0-0'}}</td>
                                <td *ngIf="row.isPush">PUSH</td>
                                Now including Push rows
                                -->
                                <td>{{row.delivered | number: '1.0-0'}}</td>

                                <!-- <td *ngIf="!row.isPush && row.variance < 0" class="debted-number">{{'(' + (-row.variance | number: '1.0-0') + ')'}}</td>
                                <td *ngIf="!row.isPush && row.variance >= 0">{{row.variance | number: '1.0-0'}}</td> -->
                                <td *ngIf="row.variance < 0" class="debted-number">{{'(' + (-row.variance | number: '1.0-0') + ')'}}</td>
                                <td *ngIf="row.variance >= 0">{{row.variance | number: '1.0-0'}}</td>
                                <td *ngIf="row.isPush"></td>

                                <td>{{row.comments}}</td>

                                <td *ngIf="row.isCompleted"><i class="pi pi-check"></i></td>
                                <td *ngIf="!row.isCompleted"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Totals</td>
                                <td>{{(bou.actualDurationHoursTotal ? bou.actualDurationHoursTotal : 0) | number:'2.0'}}:{{(bou.actualDurationMinutesTotal ? bou.actualDurationMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualVaporDelayHoursTotal ? bou.actualVaporDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualVaporDelayMinutesTotal ? bou.actualVaporDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualWeatherDelayHoursTotal ? bou.actualWeatherDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualWeatherDelayMinutesTotal ? bou.actualWeatherDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualLineDisplacementDelayHoursTotal ? bou.actualLineDisplacementDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualLineDisplacementDelayMinutesTotal ? bou.actualLineDisplacementDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualRateSlowdownDelayHoursTotal ? bou.actualRateSlowdownDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualRateSlowdownDelayMinutesTotal ? bou.actualRateSlowdownDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualPowerOutageDelayHoursTotal ? bou.actualPowerOutageDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualPowerOutageDelayMinutesTotal ? bou.actualPowerOutageDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualTerminalReasonDelayHoursTotal ? bou.actualTerminalReasonDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualTerminalReasonDelayMinutesTotal ? bou.actualTerminalReasonDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualOtherDelayHoursTotal ? bou.actualOtherDelayHoursTotal : 0) | number:'2.0'}}:{{(bou.actualOtherDelayMinutesTotal ? bou.actualOtherDelayMinutesTotal : 0) | number:'2.0'}}</td>
                                <td>{{(bou.actualTankSwapHoursTotal ? bou.actualTankSwapHoursTotal : 0) | number:'2.0'}}:{{(bou.actualTankSwapMinutesTotal ? bou.actualTankSwapMinutesTotal : 0) | number:'2.0'}}</td>
                                <td></td>
                                <td class="highlighted1">{{bou.actualDeliveredTotal | number: '1.0-0'}}</td>
                                <td *ngIf="bou.actualVarianceTotal < 0" class="highlighted1 debted-number">{{'(' + (-bou.actualVarianceTotal | number: '1.0-0') + ')'}}</td>
                                <td *ngIf="!bou.actualVarianceTotal || bou.actualVarianceTotal >= 0" class="highlighted1">{{bou.actualVarianceTotal | number: '1.0-0'}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Shore Figures">
                <p-table
                    [value]="[1]"
                    >
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th>Time</th>
                            <th>Shore Figures</th>
                            <th>Rate</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>(h:m)</th>
                            <th>(Bbls)</th>
                            <th>(Bbls/h)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>GROSS Loading Time  (Start - Complete)</td>
                            <td>{{bou.grossLoadingTimeHours ? bou.grossLoadingTimeHours : 0}}:{{bou.grossLoadingTimeMinutes ? bou.grossLoadingTimeMinutes : 0}}</td>
                            <td>{{bou.grossLoadingTimeShoreFigures | number: '1.0-0'}}</td>
                            <td>{{bou.grossLoadingTimeRate | number: '1.0-0'}}</td>
                        </tr>
                        <tr>
                            <td>NET Loading Time ( Gross Loading Time - Total Stoppages)</td>
                            <td>{{bou.netLoadingTimeHours ? bou.netLoadingTimeHours : 0}}:{{bou.netLoadingTimeMinutes ? bou.netLoadingTimeMinutes : 0}}</td>
                            <td>{{bou.netLoadingTimeShoreFigures | number: '1.0-0'}}</td>
                            <td>{{bou.netLoadingTimeRate | number: '1.0-0'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

