<div class="p-grid" class="page-font">
    <div class="p-col-12">
        <div class="p-field p-grid">
            <span style="margin: 6px;">Terminal</span>
            <p-dropdown [options]="terminalOptions" [(ngModel)]="selectedTerminal" appendTo="body" placeholder="Select Terminal" (onChange)="terminalChanged()"></p-dropdown>            
        </div>
    </div>
    <div class="p-col-12">
        <h2 style="text-align: center;">Terminal Performance Report</h2>
    </div>
    <div class="p-col-12">
        <div class="p-grid" style="text-align: center;">
            <div class="p-col-12 p-md-6 p-lg-6">
                <p-table [value]="timingInfos" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Description</th>
                      <th>Customer Average Timings</th>
                      <th>Overall Average Timings</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-timingInfo>
                    <tr>
                      <td>{{timingInfo.description}}</td>
                      <td>{{timingInfo.averageDurationText}}</td>
                      <td>{{timingInfo.averageDurationAllText}}</td>
                    </tr>
                  </ng-template>
                </p-table>
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
                <div class="p-grid" style="height: 100%;">
                    <div class="p-col-6">
                        <p-card [style]="{'font-weight': 'bold'}">
                            <div class="rcorners">{{report.grossPumpingRate | number : '1.0-0'}}</div>
                            <ng-template pTemplate="footer">
                                Gross Pumping Rate
                            </ng-template>
                        </p-card>
                    </div>
                    <div class="p-col-6">
                        <p-card [style]="{'font-weight': 'bold'}">
                            <div class="rcorners">{{report.netPumpingRate | number : '1.0-0'}}</div>
                            <ng-template pTemplate="footer">
                                Net Pumping Rate
                            </ng-template>
                        </p-card>
                    </div>
                    <div class="p-col-12">
                        <p-card>
                            <p-chart type="pie" [data]="chartData" [options]="chartOptions"></p-chart>
                            <ng-template pTemplate="footer">
                                Downtime Reasons (hours)
                            </ng-template>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
