import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor() {}

    static getCentralTimezone(): string {
      var currentDate = new Date();
      var daylightStartDate = new Date(currentDate.getFullYear(), 3, 12);
      var daylightEndDate = new Date(currentDate.getFullYear(), 11, 5);

      if (currentDate >= daylightStartDate && currentDate < daylightEndDate)
        return 'CDT';
      else
        return 'CST';
    }

}
