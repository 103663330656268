import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import * as appState from '../../../state';
import * as appActions from '../../../state/app.actions';
import * as cloneDeep from 'lodash.clonedeep';
import { ClientCompany, Contact } from 'src/app/models';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit, OnDestroy {

  cols: any[] = [];
  skeletonRows: any[] = [];
  clients: ClientCompany[] = [];
  subscriptions: Subscription[] = [];
  loaded = false;
  selectedClient: ClientCompany;
  showEditDialog = false;
  allContacts: Contact[] = [];

  constructor(
    private store: Store<appState.State>,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.skeletonRows = this.buildSkeletonRows();
    this.cols = this.buildCols();
    this.listenForClientChanges();
    this.listenForContactsStateChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  listenForClientChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getClients))
        .subscribe(results => {
          this.clients = [...results];
          this.loaded = true;
        })
    );
  }

  listenForContactsStateChanges() {
    this.subscriptions.push(
      this.store.pipe(select(appState.getContacts))
      .subscribe(results => { 
        this.allContacts = [...results].map(c => Contact.fromJson(c));
      })
    );
  }

  buildCols(): any [] {
    return [
      { field: 'name', header: 'Name', display: true, sortable: true, searchable: true, textFilterable: true, dateFilterable: false },
      { field: 'active', header: 'Status', display: true, sortable: true, searchable: false, textFilterable: false, dateFilterable: false },
      { field: 'actions', header: '', display: true, sortable: false, searchable: false, textFilterable: false, dateFilterable: false }
    ];
  }

  buildSkeletonRows(): number[] {
    return [1,2,3,4,5];
  }

  get globalFilterFields(): string[] {
    return this.cols.filter(c => c.searchable).map(c => c.field);
  }

  addClient() {
    this.selectedClient = new ClientCompany();
    this.showEditDialog = true;
  }

  openClient(client: ClientCompany) {
   this.selectedClient = cloneDeep(client);  // separate reference from state
   this.showEditDialog = true;
  }

  deactivateClient(client: ClientCompany) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to deactivate Client: ' + client.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deactiveClient = { ...client, active: false };
        this.store.dispatch(appActions.setClientCompany({ data: deactiveClient }));
      }
    });
  }

  activateClient(client: ClientCompany) {
    this.confirmationService.confirm({
      key: 'confirmation',
      message: 'Are you sure you want to activate Client: ' + client.name,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const activeClient = { ...client, active: true };
        this.store.dispatch(appActions.setClientCompany({ data: activeClient }));
      }
    });
  }

  updateClient(client: ClientCompany) {
    this.store.dispatch(appActions.setClientCompany({ data: client }));
    this.showEditDialog = false;
  }

  async resetSelectedClient() {
    // this.clients is already subscribed to latest state of Clients
    // reset selected Client to state
    this.selectedClient = cloneDeep(this.clients.find(t => t.id === this.selectedClient.id));
  }

}
